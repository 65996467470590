import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

function DropDownMenu({ element, menuItems, setActiveCateg }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { category } = useParams();
  const navigate = useNavigate();

  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const handleSelectedItem = (item) => {
    if (item?.NameEn === "All") {
      navigate(
        `/shop-products/${element?.NameEn.replaceAll(" ", "")}/${element?.Id}?MinPrice=0&MaxPrice=500000&PageNumber=1&PageSize=9`
      );
      setActiveCateg({ Id: element?.Id });
    } else {
      navigate(
        `/shop-products/${item?.NameEn.replaceAll(" ", "")}/${item?.Id}?MinPrice=0&MaxPrice=500000&PageNumber=1&PageSize=9`
      );
      setActiveCateg({ Id: item?.Id });
    }

    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-owns={anchorEl ? "simple-menu" : undefined}
        aria-haspopup="true"
        // onClick={handleClick}
        onMouseOver={handleClick}
        style={{
          color:
            category === element.NameEn.replaceAll(" ", "")
              ? "white"
              : "inherit",
          backgroundColor:
            category === element.NameEn.replaceAll(" ", "") ? "#00EC85" : "",
        }}
      >
        {element?.NameEn}
      </Button>

      <Menu
        // anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        // transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        // className='container'
        MenuListProps={{
          className: "row",
          onMouseLeave: handleClose,
        }}
        PaperProps={{
          className: "col-md-9",
          // style: { border: '3px solid black', left: '25%' },
        }}
      >
        {menuItems?.map((item) => (
          <div className="col-12 col-md-6 col-lg-4">
            <MenuItem
              key={item?.Id}
              onClick={() => handleSelectedItem(item)}
              // style={{ width: '100%' }}
              // className='col-12 col-md-6 col-lg-4'

              // divider
            >
              {item?.NameEn}
            </MenuItem>
          </div>
        ))}
      </Menu>
    </>
  );
}

export default DropDownMenu;
