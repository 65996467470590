import React, { useEffect, useState } from "react";
import "./Experts.css";
import Footer1 from "../../components/Footer/Footer1";
import Navbar from "../../components/Navbar/Navbar";
import ProfileImg from "../../assests/img/profile.png";
import ExpertImg from "../../assests/img/expertPageImg.png";
import axios from "axios";
import { BsCheckCircleFill, BsFillStarFill } from "react-icons/bs";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BsCheckCircle } from "react-icons/bs";
import Footer2 from "../../components/Footer/Footer2";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { DotLoader } from "react-spinners";
import { BreadCrumb } from "../../components/BreadCrumb";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SendRequestModal from "./SendRequestModal";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";

const ExpertDetails = ({ match }) => {
  const { Id, UserName } = useParams();
  const [expertDetails, setExpertDetails] = useState({});
  const [RecommendedExperts, setRecommendedExperts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMain, setIsLoadingMain] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/Expert/GetExpertDetails?Id=${Id}`
      )
      .then((res) => {
        setExpertDetails(res.data.Data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });
  }, [Id]);
  const GetRecommendedExperts = async () => {
    setIsLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/Expert/GetRecommendedExpertList`
      )
      .then((res) => {
        setRecommendedExperts(res.data.Data);
        setIsLoadingMain(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoadingMain(false);
      });
  };

  useEffect(() => {
    GetRecommendedExperts();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [Id]);

  const handleShowModal = () => {
    const token = localStorage.getItem("token");
    if (!token || token === "loggedOut") {
      toast.warn("Please log in first!");
      setTimeout(() => {
        navigate("/loginuser");
      }, 1000);
      return;
    }
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  let currency = localStorage.getItem("currency");

  const sliderSettings = {
    loop: true,
    speed: 500,
    slidesToShow: Math.min(RecommendedExperts?.length, 3),
    slidesToScroll: 1,
    centerMode: true,
    dots: true,
    arrows: false,
    centerPadding: "10px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: true,
          arrows: false,
          centerMode: false,
          centerPadding: "40px",
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: true,
          arrows: false,
          centerMode: false,
          centerPadding: "40px",
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        <title>
          Clicktick: Professional Technical Experts With Best Hour Rate In Egypt
        </title>
        <meta
          name="title"
          content="Professional Technical Experts With Best Hour Rate In Egypt"
        />
        <meta
          name="description"
          content="Contact now with Professional Technical Experts With Best Hour Rate In Egypt."
        />
        <meta
          name="keywords"
          content="IT Expert, Technical Support Agent, Network architect, Systems analyst, IT coordinator, Network administrator, Network engineer, Service desk analyst, System administrator"
        />
      </Helmet>
      <Navbar />
      <BreadCrumb
        list={[
          { name: "Experts", link: "/experts" },
          { name: expertDetails?.UserName || "Expert", link: "" },
        ]}
      />

      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <DotLoader color={"#00EC85"} loading={isLoading} />
        </div>
      ) : (
        <>
          <section className="expertDetailsPage">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="expertImgContainer">
                    <img
                      src={ExpertImg}
                      alt="Expert Profile"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="ExpertDetails">
                    <h3 className="ExpertName">{expertDetails.UserName}</h3>
                    <h5 className="ExpertPosition">{expertDetails.TitleEn}</h5>
                    <p className="ExpertDesc">{expertDetails.Summary}</p>
                    <div className="d-flex justify-content-start align-items-center">
                      <p className="expertPricePerHour">
                        <span>{expertDetails.HourRate}</span> {currency}/hr
                      </p>
                      <p className="d-flex align-items-center justify-content-center mb-0 ms-5">
                        <BsFillStarFill
                          style={{
                            color: "#FDCC0D",
                            marginRight: "10px",
                            fontSize: "2rem",
                          }}
                        />
                        <span style={{ color: "#313131", fontSize: "2rem" }}>
                          {expertDetails.Rate}
                        </span>
                      </p>
                    </div>
                    <button onClick={handleShowModal}>Send Request</button>

                    {/* Render the modal */}
                    <SendRequestModal
                      show={showModal}
                      handleClose={handleCloseModal}
                      Id={Id}
                    />
                  </div>
                </div>
                <div className="expertNumbers">
                  <div className="row">
                    <div
                      className="col-md-4"
                      style={{ borderRight: "3px solid #F0F0F0" }}
                    >
                      <p style={{ fontWeight: "700" }}>
                        {expertDetails.ProjectCount}
                      </p>
                      <p>Projects</p>
                    </div>
                    <div
                      className="col-md-4"
                      style={{ borderRight: "3px solid #F0F0F0" }}
                    >
                      <p>
                        <span>{expertDetails.Rate}</span>/5
                      </p>
                      <p>Rate</p>
                    </div>
                    <div className="col-md-4">
                      <p>
                        <span>{expertDetails.TotalProjectHours}</span> hr
                      </p>
                      <p>Total Hours</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {expertDetails?.Certifications?.length > 0 && (
              <div className="expertCertificates">
                <div className="container">
                  <p>Expert Certificates</p>
                  <div className="certificatesImgs">
                    <div className="row">
                      {expertDetails.Certifications &&
                        expertDetails.Certifications?.map(
                          (certification, index) => (
                            <div className="col-md-4" key={index}>
                              <img
                                src={certification.Url}
                                alt="Expert Certificate"
                                className="img-fluid"
                              />
                            </div>
                          )
                        )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {expertDetails?.Services?.length > 0 && (
              <div className="expertServices">
                <div className="container">
                  <p>Expert Service</p>
                  <div className="expertService">
                    <div className="row">
                      {expertDetails.Services &&
                        expertDetails.Services?.map((service, index) => (
                          <div
                            className="col-md-4 d-flex align-items-center justify-content-center"
                            key={index}
                          >
                            <div className="serviceName">
                              <BsCheckCircleFill />
                              <span>{service.ServiceNameEn}</span>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            )}

            <div className="expertRecommended">
              <div className="container">
                <div className="head">
                  <p>Recommended Experts</p>
                  <a href="/all-experts">See All</a>
                </div>
                <div className="expertsRecommendation">
                  <div className="row">
                    <Slider {...sliderSettings}>
                      {RecommendedExperts?.map((expert, index) => (
                        <div className="col-md-3" key={index}>
                          <Link
                            style={{ color: "inherit" }}
                            to={`/expert-details/${expert.Id}/${expert.UserName.replaceAll(" ","")}`}
                          >
                            <div
                              className="recExpt me-4 mb-3"
                              data-aos="zoom-in-down"
                              data-aos-duration="7000"
                              data-aos-once="true"
                              style={{
                                borderRadius: "0.3125rem",
                                background: "#FFF",
                                boxShadow:
                                  "0px 0px 8px 0px rgba(0, 0, 0, 0.10)",
                              }}
                            >
                              <div>
                                <img
                                  src={ProfileImg}
                                  alt={expert.UserName}
                                  className="object-fit-cover img-fluid"
                                  style={{
                                    width: "20%",
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                              <div className="exptInfo">
                                <p className="m-0">{expert.UserName}</p>
                                <p className="m-0 mt-2">{expert.TitleEn}</p>
                                <div className="d-flex align-items-center justify-content-center mt-1">
                                  <BsCheckCircle
                                    style={{ color: "rgba(3, 190, 108, 1)" }}
                                  />
                                  <span className="projectsDone">
                                    {expert.ProjectCount} Done Projects
                                  </span>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="me-5">
                                    <p className="m-0">
                                      <span
                                        className="me-2"
                                        style={{
                                          color: "#000056",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {expert.HourRate}
                                      </span>
                                      {currency}/hr
                                    </p>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <BsFillStarFill
                                      style={{
                                        color: "#FDCC0D",
                                        fontSize: "25px",
                                      }}
                                      className="me-2"
                                    />
                                    <span
                                      style={{
                                        color: "#313131",
                                        fontWeight: "bold",
                                        fontSize: "18px",
                                      }}
                                    >
                                      {expert.Rate}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
            <Footer1
              backgroundColor="#303030"
              colorWhite="#FFFFFF"
              colorGreen="rgba(0, 237, 133, 1)"
            />
            <Footer2 />
          </section>
        </>
      )}
    </>
  );
};

export default ExpertDetails;
