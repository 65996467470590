import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  quntityBtn: {
    width: "6.75rem",
    display: "flex",
    justifyContent: "space-around",
    gap: "15px",
    fontSize: "1rem",
    alignItems: "center",
    backgroundColor: "#E9E9E9",
    borderRadius: "8px",
    padding: "0.2rem",
    "@media (max-width: 600px)": {
      width: "auto",
    },
    "& .plus, .minus": {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      border: "1px solid #cacaca",
      height: "22px",
      width: "22px",
      display: "flex",
      justifyContent: "center",
      borderRadius: "6px",
      color: "#000000",
      backgroundColor: "#ffffff",
    },

    "& img": { width: "12px" },
  },
  "& .minus": {
    opacity: (props: any) => (props.count === 1 ? "0.3" : "1"),
    cursor: (props: any) => (props.count === 1 ? "not-allowed" : "pointer"),
  },
  "& .amount": { margin: "0 20px", color: "#000000", fontWeight: 500 },
});
