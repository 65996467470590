import React, { useEffect, useState } from "react";
import "./Privacy.css";
import Navbar from "../../components/Navbar/Navbar";
import Footer2 from "../../components/Footer/Footer2";
import { Link } from "react-router-dom";
import { DotLoader } from "react-spinners";
import { BreadCrumb } from "../../components/BreadCrumb";
import axios from "axios";
import { Helmet } from "react-helmet-async";

const Privacy = () => {
  const [apiData, setApiData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getAbout = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/Home/Web_Privacy`)
      .then((res) => {
        setApiData(res.data.Data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getAbout();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Helmet>
        <title>Our Privacy Policy | Clicktick</title>
        <meta name="title" content="Our Privacy Policy | Clicktick" />
        <meta
          name="description"
          content="Clicktick respect your privacy and are committed to its protection, each time you use the Website and App you agree the privacy policy"
        />
        <meta
          name="keywords"
          content="Clicktick, Click tick, Privacy Policy, Clicktick Privacy Policy"
        />
      </Helmet>
      <Navbar />

      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <DotLoader color={"#00EC85"} loading={isLoading} />
        </div>
      ) : (
        <section className="TAC mb-5">
          <div className="container-fluid BgColorCopyRight">
            <div className="CopyRightHeading">
              <h2>Privacy</h2>
            </div>
          </div>
          <div className="container mt-5 info">
            <div></div>
            <div className="first justify-content-start">
              <p className="m-0">
                <div
                  style={{
                    letterSpacing: "0.7px",
                    lineHeight: "2.2rem",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: `<div>${apiData.ValueEn}</div>`,
                  }}
                ></div>
              </p>
            </div>
          </div>
        </section>
      )}

      <Footer2 />
    </>
  );
};

export default Privacy;
