import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HashLoader, DotLoader } from "react-spinners";
import { Helmet } from "react-helmet-async";
import OwlCarousel from "react-owl-carousel";
import { BsFillStarFill, BsCheckCircle } from "react-icons/bs";
import axios from "axios";

import Navbar from "../../components/Navbar/Navbar";
import Footer1 from "../../components/Footer/Footer1";
import Footer2 from "../../components/Footer/Footer2";

import ProfileImg from "../../assests/img/profile.png";
import ExpertImg from "../../assests/img/expertImg.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./Experts.css";

const Experts = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMain, setIsLoadingMain] = useState(true);
  const [homeServiceExperts, setHomeServiceExperts] = useState([]);

  // const [recExpt, setRecExpt] = useState([]);
  // const [itExpert, setItExpert] = useState([]);
  // const [networkExpert, setNetworkExpert] = useState([]);
  const [showModal, setShowModal] = useState(false);

  //Owl Carousel Settings
  const options1 = {
    items: 6,
    dots: true,
    margin: 0,
    responsiveClass: true,
    nav: false,
    autoplay: false,
    navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 2,
      },
      768: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
  };

  const options2 = {
    items: 4,
    dots: true,
    margin: 10,
    responsiveClass: true,
    nav: false,
    autoplay: false,
    navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
  };

  let currency = localStorage.getItem("currency");

  const HomeExpert = async () => {
    try {
      const {
        data: { Data },
      } = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/Home/Web_ServiceWithExperts?PageSize=9`
      );
      console.log(Data);
      setHomeServiceExperts(Data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // const RecExpert = async () => {
  //   await axios
  //     .get(
  //       `${process.env.REACT_APP_API_BASE_URL}/Expert/GetRecommendedExpertList`
  //     )
  //     .then((error) => setRecExpt(error.data.Data));
  // };

  // const ITExpert = async () => {
  //   await axios
  //     .get(
  //       `${process.env.REACT_APP_API_BASE_URL}/Expert/GetExpertList?ServiceId=37`
  //     )
  //     .then((response) => {
  //       setItExpert(response.data.Data);
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       setIsLoading(false);
  //     });
  // };

  // const NetWorkExpert = async () => {
  //   await axios
  //     .get(
  //       `${process.env.REACT_APP_API_BASE_URL}/Expert/GetExpertList?ServiceId=36`
  //     )
  //     .then((response) => {
  //       setNetworkExpert(response.data.Data);
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       setIsLoading(false);
  //     });
  // };

  const handleClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoadingMain(false);
    }, 1500);
  });

  useEffect(() => {
    HomeExpert();
  }, []);

  // useEffect(() => {
  //   RecExpert();
  // }, []);

  // useEffect(() => {
  //   ITExpert();
  // }, []);

  // useEffect(() => {
  //   NetWorkExpert();
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <Helmet>
        <title>
          Clicktick - Technical Experts With Best Hour Rate In Egypt
        </title>
        <meta
          name="title"
          content="Clicktick - Technical Experts With Best Hour Rate In Egypt"
        />
        <meta
          name="description"
          content="Contact now with Professional Technical Experts With Best Hour Rate In Egypt."
        />
        <meta
          name="keywords"
          content="Clicktick, Click tick, IT Expert, Technical Support Agent, Network architect, Systems analyst, IT coordinator, Network administrator, Network engineer, Service desk analyst, System administrator"
        />
        <link rel="canonical" href="https://clicktick.app/experts" />
      </Helmet>

      <Navbar />

      {isLoadingMain ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <DotLoader color={"#00EC85"} loading={isLoadingMain} />
        </div>
      ) : (
        <section className="expertsPage">
          <div className="ExpertBachColor">
            <div className="container ">
              <div className="expertHeader">
                <p className="centered headerTitleExpert">Clicktick Experts</p>

                {showModal && (
                  <div className="modal">
                    <div className="modal-content">
                      <span
                        className="close"
                        // onClick={handleClose}
                      >
                        &times;
                      </span>
                      <p className="d-flex align-items-center justify-content-center p-3 mb-0">
                        Coming Soon...
                      </p>
                    </div>
                  </div>
                )}

                <Link
                  to="/LoginUser"
                  className="signIbBut"
                  // onClick={handleClick}
                >
                  <img src={ProfileImg} alt="" className="me-2" />

                  <p className="m-0">Sign in as Expert</p>
                </Link>
              </div>
            </div>
          </div>

          {homeServiceExperts.map((serviceExpert) => {
            return (
              <div className="topRatedExperts container mt-4">
                <p className="m-0 text-center topRated position-relative">
                  {serviceExpert.NameEn}
                  <a
                    href={`/all-experts?serviceId=${serviceExpert.Id}`}
                    className="seeAll--toAllPage"
                  >
                    See All
                  </a>
                </p>
                <div className="ExpertSlider d-flex align-items-center justify-content-center mt-4">
                  {isLoading ? (
                    <HashLoader color={"#00EC85"} loading={isLoading} />
                  ) : serviceExpert?.Experts.length > 0 ? (
                    <OwlCarousel
                      className="owl-theme"
                      loop
                      margin={10}
                      {...options1}
                    >
                      {serviceExpert?.Experts?.map((expert) => {
                        console.log(expert);
                        return (
                          <div key={expert?.Id} className="mb-5">
                            <Link
                              style={{ color: "inherit" }}
                              to={`/expert-details/${
                                expert?.Id
                              }/${expert?.UserName.replaceAll(" ", "")}`}
                            >
                              <div
                                className="singleExpert"
                                data-aos="zoom-in"
                                data-aos-duration="7000"
                                data-aos-once="true"
                              >
                                <div className="expertImage">
                                  <img
                                    src={ExpertImg}
                                    alt={expert?.UserName}
                                    className="img-fluid"
                                  />
                                  <div className="d-flex align-items-center justify-content-center expertBlock">
                                    <BsFillStarFill className="star-icon" />
                                    <div className="rateContainer flex-Mob">
                                      <span className="expertRating">
                                        {expert?.Rate}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="expertInfo">
                                  <p className="m-0">{expert?.UserName}</p>
                                  <p className="m-0">{expert?.TitleEn}</p>
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      })}
                    </OwlCarousel>
                  ) : (
                    <p>No Experts Available Now....</p>
                  )}
                </div>
              </div>
            );
          })}

          <Footer1
            backgroundColor="#303030"
            colorWhite="#FFFFFF"
            colorGreen="rgba(0, 237, 133, 1)"
          />

          <Footer2 />
        </section>
      )}
    </>
  );
};

export default Experts;
