import { useStyles } from "../style";
import Item from "../../../components/item/Item";
import { Button, Typography } from "@material-ui/core";
import axios from "axios";
import { useContext, useState } from "react";
import Clicktick from "../../../context/context";
import { compact, find, isEmpty, map } from "lodash";
import { useNavigate } from "react-router-dom";
import "./style.css";

export default function WishlistItems({ items }) {
  const classes = useStyles();
  const { setFavourites, setNotify } = useContext(Clicktick);
  const [temp, setTemp] = useState([]);
  const navigate = useNavigate();
  const oneAvailable = find(items, (i) => i.IsAvailable);
  const handleFlush = async () => {
    // setAsyncLoading(true);
    const response = await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/Product/RemoveAllFavorites`
    );

    if (response.data.Data) {
      setFavourites([]);
    }
    // setAsyncLoading(false);`
  };
  // useEffect(()=>{

  //   let favs= temp
  //   map(items,i=>( favs[i.ProductCountryId]: 1))  },[temp])

  const addAllToCart = async () => {
    let out = [];
    let arr = map(temp, (el, i) => {
      console.log(el,i)
      if (el.IsAvailable)
        return {
          ProductCountryId: el.ProductCountryId,
          Count: el.count || 1,
          ProductAttributeId: el.ProductAttributeId,
        };
      else
        out.push({
          ProductCountryId: parseInt(i),
        });
    });

    console.log(arr, "arrrrrrrrr");
    arr = compact(arr);
    out = compact(out);
    // setAsyncLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/Order/AddItemsToCart`, arr)
      .then(() =>
        setNotify({
          open: true,
          message: !isEmpty(out)
            ? "Items Added to Cart without Out of Stock Items"
            : "Items Added to Cart Successfully",
          severity: "success",
        })
      );
    navigate(`/cart`);
  };

  return (
    <div className={classes.cartItemsC}>
      <div className={classes.cartItems}>
        {items?.map((item, i) => (
          <Item
            key={i}
            item={item}
            type="wishlist"
            setTemp={setTemp}
            temp={temp}
          />
        ))}
        <div className={classes.btns}>
          <Button
            disabled={isEmpty(oneAvailable)}
            style={{
              backgroundColor: isEmpty(oneAvailable) ? "lightgray" : undefined,
            }}
            className={` ${classes.addCart} wishlist-btn`}
            onClick={addAllToCart}
            variant="contained"
          >
            <Typography
              style={{ color: isEmpty(oneAvailable) ? "#ffffff" : undefined}}
            >
              Add to Cart
            </Typography>
          </Button>

          <Button
            variant="outlined"
            className={classes.flush}
            onClick={handleFlush}
          >
            <Typography>Remove all</Typography>
          </Button>
        </div>
      </div>
    </div>
  );
}
