import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Accordion } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Slider,
  makeStyles,
} from "@material-ui/core";
import DotLoader from "react-spinners/DotLoader";
import { map, isEmpty, includes, filter } from "lodash";
import axios from "axios";

import Clicktick from "../../context/context";

import Navbar from "../../components/Navbar/Navbar";
import { BreadCrumb } from "../../components/BreadCrumb/index";
import ShopItem from "../../components/ShopItem/ShopItem";
import Footer1 from "../../components/Footer/Footer1";
import Footer2 from "../../components/Footer/Footer2";
import DropDownMenu from "../../components/Shared/DropDownMenu";
import SearchImg from "../../assests/icons/Button.svg";
import SiteLogo from "../../assests/img/favicon.ico";
import "./Shop.css";

const useStyles = makeStyles({
  radio: {
    "&$checked": {
      color:
        "linear-gradient(103.41deg, #0BCC77 10.76%, #08A761 85.94%) !important",
    },
  },
});

export default function ShopProducts() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { setAsyncLoading } = useContext(Clicktick);
  const { category, catId } = useParams();
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const containerMobileRef = useRef(null);
  const buttonMobileRef = useRef(null);
  const navigate = useNavigate();
  const classes = useStyles();

  const [activeCat, setActiveCat] = useState({ Id: null });
  const [loading, setLoading] = useState(false); // Added loading state
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPageItems, setCurrentPageItems] = useState([]);
  const [isMobileView, setIsMobileView] = useState(false);
  const [brandHeight, setBrandHeight] = useState("46vh");
  const [all, setAll] = useState([]);
  const [data, setData] = useState({ MinPrice: 0 });
  const [searchFor, setSearch] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [categories, setCats] = useState([]);
  const [brands, setBrands] = useState([]);
  const [routerBrands, setRouterBrands] = useState(
    searchParams.getAll("BrandId") || []
  );
  const [value, setValue] = useState([
    searchParams.get("MinPrice") || 0,
    searchParams.get("MaxPrice") || 500000,
  ]);

  const forceNum =
    Number(searchParams.get("PageNumber")) > 0
      ? Number(searchParams.get("PageNumber")) - 1
      : 0;

  let ActiveButtonCategory = buttonRef.current;
  let ActiveButtonMobileCategory = buttonMobileRef.current;

  const seeMoreStyle = {
    color: "#052C65",
    margin: " 16px 0 0 0",
    fontWeight: "bold",
  };

  function numFormatter(num) {
    if (num > 10000 && num < 5000000) {
      return (num / 1000).toFixed(0) + "K"; // convert to K for number from > 1000 < 1 million
    } else {
      return num;
    }
  }
  const handleStaticFilters = (key, id, temp) => {
    let name = searchParams.get(key);

    if (temp === "brand") {
      let arr = routerBrands;

      if (includes(arr, id)) {
        const index = arr?.indexOf(id);
        if (index > -1) {
          arr.splice(index, 1);
          setRouterBrands([...arr]);
          if (isEmpty(arr)) {
            setRouterBrands([]);
          }
        }
      } else {
        if (id === 0) {
          setRouterBrands([0]);
        } else {
          arr.push(id);
          setRouterBrands([...arr]);
        }
      }
    }
    if (name === id) {
      const t = data;
      delete t[key];
      setData({ ...t });
    } else if (!temp && name !== id) {
      setData({
        ...data,
        [key]: id,
      });
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleRange = (e, id, key) => {
    const val = value;
    val[id] = e.target.value;
    handleStaticFilters(key, e.target.value);
  };

  const handleActions = async (page = 1, searchQuery = "") => {
    console.log(searchQuery);
    try {
      const path = window.location.pathname;
      let queryParams = `?`;
      queryParams += `MinPrice=${value[0]}&MaxPrice=${value[1]}`;
      queryParams += searchQuery ? `&keyword=${searchQuery}` : "";
      queryParams += `&PageNumber=${page}&PageSize=${9}`;

      if (!isEmpty(routerBrands)) {
        queryParams += `&${routerBrands
          .map((w, i) =>
            i === routerBrands.length - 1 ? `BrandId=${w}` : `BrandId=${w}&`
          )
          .join("")}`;
      }

      const finalURL = path + queryParams;
      navigate(finalURL);
    } catch (error) {
      console.log(error);
    }
  };

  const constructRequestString = () => {
    let reqString = `${process.env.REACT_APP_API_BASE_URL}/Product/ProductsLanding?CategoryId=${catId}`;

    reqString +=
      searchParams.getAll("BrandId").length > 0
        ? searchParams
            .getAll("BrandId")
            .map((Id) => `&BrandId=${Id}`)
            .join("")
        : "";

    reqString += searchParams.get("keyword")
      ? `&keyword=${searchParams.get("keyword")}`
      : "";
    reqString += `&MinPrice=${value[0]}&MaxPrice=${value[1]}`;
    reqString += `&PageNumber=${
      searchParams.get("PageNumber") || 1
    }&PageSize=${9}`;

    return reqString;
  };

  const getProducts = async () => {
    try {
      setLoading(true);
      setAsyncLoading(true);

      const response = await axios.get(constructRequestString());
      setCurrentPageItems(response.data.Data.Products);
      setPageCount(Math.ceil(response.data.Data.TotalCount / 9));
      setTotalCount(response.data.Data.TotalCount);
    } catch (error) {
    } finally {
      setLoading(false);
      setAsyncLoading(false);
    }
  };

  const getCategories = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/Category/CategoriesWithSubs`)
      .then((res) => {
        setActiveCat({ Id: catId });
        // setActiveCategory(category);
        setCats(res.data.Data);
      });
  };

  const getBrands = async () => {
    let s = "";
    if (all)
      map(all, (a, indx) => (s += indx === all?.length - 1 ? `${a}` : `${a}`));
    else s += data.CategoryId;
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/Brand/CategoryBrands?CategoryId=${catId}`
      )
      .then((res) => setBrands(res.data.Data));
  };

  useEffect(() => {
    getBrands();
    setRouterBrands(searchParams.getAll("BrandId"));
    setValue([
      searchParams.get("MinPrice") || 0,
      searchParams.get("MaxPrice") || 500000,
    ]);
    setSearch(searchParams.get("keyword") || "");
    getProducts();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [navigate, searchParams]);

  useEffect(() => {
    getCategories();
  }, []);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
    handleActions(selectedPage.selected + 1, searchFor);
    window.scrollTo({ top: 150, behavior: "smooth" });
  };

  const CustomPaginationButton = ({ selected, children }) => {
    return (
      <div className={`custom-pagination-button ${selected ? "selected" : ""}`}>
        {children}
      </div>
    );
  };

  // Function to determine pagination items for mobile view
  const getMobilePaginationItems = () => {
    const items = [];

    items.push(currentPage);

    if (currentPage < pageCount - 1) {
      items.push(currentPage + 1);
    }

    // Show dots (...) if there are more than 2 pages
    if (pageCount > 2) {
      items.push("...");
    }

    // Show the penultimate and last pages
    items.push(pageCount - 2, pageCount - 1, pageCount);

    return items;
  };

  const [screenSize, setScreenSize] = useState(""); // State to track screen size

  // Function to handle screen size changes
  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setScreenSize("mobile");
    } else if (window.innerWidth <= 1024) {
      setScreenSize("tablet");
    } else {
      setScreenSize("desktop");
    }
  };

  // Listen for window resize events
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Initial screen size detection on component mount
    handleResize();

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleBrandHeight = () => {
    if (brandHeight === "46vh") {
      // Expand to "See all"
      setBrandHeight("fit-content");
    } else {
      // Get the current scroll position
      const scrollY = window.scrollY;
      // Collapse to "See less"
      setBrandHeight("46vh");
      // Scroll to the previous position
      window.scrollTo({ top: scrollY, behavior: "smooth" });
    }
  };

  const scrollContainer = (containerRef, buttonRef) => {
    if (containerRef.current && buttonRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const containerScrollLeft = containerRef.current.scrollLeft;

      const newScrollLeft =
        buttonRect.left + containerScrollLeft - containerRect.left;

      containerRef.current.scrollTo({
        left: newScrollLeft,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    scrollContainer(containerRef, buttonRef);
    scrollContainer(containerMobileRef, buttonMobileRef);
  };

  useEffect(() => {
    scrollRight();
  }, [ActiveButtonCategory, ActiveButtonMobileCategory]);
  return (
    <>
      <Helmet>
        <title>{category} Products | Clicktick</title>
        <meta name="title" content={`${category} Products | Clicktick`} />
        <meta
          name="description"
          content={`Clicktick online shopping for ${category} products, Best Prices, Secure Payment, Easy Returns, Cash and installments | Shop Now!`}
        />
        <meta name="keywords" content={`Clicktick, Click tick, ${category}`} />
        <link rel="icon" type="image/png" href={SiteLogo} />
        <link
          rel="canonical"
          href={`https://clicktick.app/shop-products/${category}/${catId}`}
        />
      </Helmet>

      <Navbar />

      <BreadCrumb
        list={[
          { name: "Shop", link: "/shop" },
          {
            name: `${category || "Shop"} Products`,
          },
        ]}
      />

      <div
        className="container shopProducts mb-5"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleActions(1, searchFor);
          }
        }}
      >
        {screenSize === "desktop" && (
          <div className="row">
            <div className="col-md-3">
              <div className="leftSideBar">
                <div className="category">
                  {(category || activeCat) && (
                    <>
                      <Accordion
                        {...([...routerBrands]?.length === 0
                          ? "flash"
                          : { defaultActiveKey: "0" })}
                      >
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>Brands</Accordion.Header>

                          <Accordion.Body>
                            <div
                              className="d-flex flex-column checkImgs"
                              style={{
                                height: brandHeight,
                                overflow: "auto",
                              }}
                            >
                              {brands?.map((brand, i) => {
                                return (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        classes={{ root: classes.check }}
                                        checked={
                                          // routerBrands.indexOf(brand.Id) > -1 ||
                                          // routerBrands.indexOf(0) > -1 ||
                                          // searchParamsArray.includes(
                                          //   String(brand?.Id)
                                          // ) &&
                                          routerBrands.includes(
                                            String(brand?.Id)
                                          )
                                        }
                                        onChange={(event) => {
                                          //   if (brand.Id == "0") {
                                          //     setRouterBrands([]);
                                          //   }
                                          //   brand.Id != "0"
                                          //     ? handleStaticFilters(
                                          //         "BrandId",
                                          //         brand.Id,
                                          //         "brand"
                                          //       )
                                          //     : handleStaticFilters(
                                          //         "BrandId",
                                          //         brand.Id,
                                          //         "brand"
                                          //       );
                                          // }}
                                          setRouterBrands((prev) =>
                                            prev.includes(`${brand.Id}`)
                                              ? prev.filter(
                                                  (brandId) =>
                                                    brandId != brand.Id
                                                )
                                              : [...prev, `${brand.Id}`]
                                          );
                                        }}
                                      />
                                    }
                                    label={brand?.NameEn}
                                  />
                                );
                              })}
                            </div>

                            <p
                              style={seeMoreStyle}
                              onClick={() => {
                                brandHeight == "46vh"
                                  ? setBrandHeight("fit-content")
                                  : setBrandHeight("46vh");
                                window.scrollTo({ top: 0, behavior: "smooth" });
                              }}
                            >
                              {brandHeight <= "46vh" ? "See all" : "See less"}
                            </p>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </>
                  )}

                  <Accordion flush>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Price range</Accordion.Header>

                      <Accordion.Body>
                        <Slider
                          getAriaLabel={() => "Temperature range"}
                          value={value}
                          onChange={handleChange}
                          valueLabelDisplay="auto"
                          min={0}
                          max={500000}
                          step={100}
                          valueLabelFormat={numFormatter}
                        />
                        <div className="d-flex justify-content-between hedd">
                          <span>Min</span>
                          <span>Max</span>
                        </div>
                        <div className="d-flex justify-content-between">
                          <input
                            type="number"
                            style={{ width: "5rem" }}
                            value={value[0]}
                            onChange={(e) => handleRange(e, 0, "MinPrice")}
                          ></input>
                          <input
                            type="number"
                            value={value[1]}
                            style={{ width: "5rem" }}
                            onChange={(e) => handleRange(e, 1, "MaxPrice")}
                          ></input>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                  <div className="d-flex justify-content-center">
                    <button
                      className="applyFilter"
                      onClick={() => handleActions(1, searchFor)}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-9">
              <div className="righSideBar">
                <div className="productsFound my-3 p-0 d-flex justify-content-between">
                  <input
                    value={searchFor}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      handleActions(1, e.target.value);
                    }}
                  />
                  <img
                    src={SearchImg}
                    alt="search"
                    onClick={() => handleActions(1, searchFor)}
                  />
                </div>

                <div
                  className="d-flex align-items-center  overflow-auto my-2 p-2"
                  ref={containerRef}
                >
                  <Button
                    style={{
                      color: category === "All" ? "white" : "inherit",
                      backgroundColor: category === "All" ? "#00EC85" : "",
                      padding: "6px 8px",
                      borderRadius: "8px",
                      marginBottom: 0,
                      marginRight: "10px",
                    }}
                    onClick={() => {
                      navigate(`/shop-products/All/0${window.location.search}`);
                      setActiveCat({ Id: 0 });
                      // setActiveCategory(0);
                    }}
                  >
                    All
                  </Button>
                  {map(
                    filter(categories, (d) => isEmpty(d.SubCategories)),
                    (r) => (
                      <Button
                        style={{
                          color:
                            category === r.NameEn.replaceAll(" ", "")
                              ? "white"
                              : "inherit",
                          backgroundColor:
                            category === r.NameEn.replaceAll(" ", "")
                              ? "#00EC85"
                              : "",
                          padding: "6px 8px",
                          borderRadius: "8px",
                          marginBottom: 0,
                        }}
                        onClick={() => {
                          navigate(`/shop-products/${r.NameEn}/${r.Id}`);
                          setActiveCat({ Id: r?.Id });
                        }}
                      >
                        {r.NameEn}
                      </Button>
                    )
                  )}
                  {map(
                    filter(categories, (d) => !isEmpty(d.SubCategories)),
                    (el) => {
                      return (
                        <div
                          className="col-md-3 col-6 d-flex align-items-center justify-content-center"
                          style={{
                            color:
                              category === el.NameEn.replaceAll(" ", "")
                                ? "white"
                                : "inherit",
                            backgroundColor:
                              category === el.NameEn.replaceAll(" ", "")
                                ? "#00EC85"
                                : "",
                            borderRadius: "8px",
                          }}
                          ref={
                            category === el.NameEn.replaceAll(" ", "")
                              ? buttonRef
                              : null
                          }
                        >
                          <DropDownMenu
                            key={el?.Id}
                            element={el}
                            menuItems={el?.SubCategories}
                            setActiveCateg={setActiveCat}
                          />
                        </div>
                      );
                    }
                  )}
                </div>

                <div className="productsFound my-3">
                  {totalCount === 0 ? (
                    <p>No items added</p>
                  ) : (
                    <p>{`${
                      !isEmpty(searchFor) ? totalCount : totalCount
                    } items`}</p>
                  )}
                </div>

                <div className="row">
                  {loading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        height: "100vh",
                      }}
                    >
                      <DotLoader color={"#00EC85"} loading={loading} />
                    </div>
                  ) : (
                    currentPageItems.map((el) => (
                      <div className="col-lg-4 col-md-6 col-6" key={el.Id}>
                        <ShopItem item={el} key={el.Id} />
                      </div>
                    ))
                  )}
                </div>

                {totalCount <= 9 ? null : (
                  <div className="container">
                    <div className="pagination-container">
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={isMobileView ? 0 : 5}
                        onPageChange={handlePageChange}
                        forcePage={forceNum}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        previousClassName={"previous"}
                        nextClassName={"next"}
                        pageClassName={"page"}
                        pageLinkClassName={"page-link"}
                        previousLinkClassName={"page-link"}
                        nextLinkClassName={"page-link"}
                        disabledClassName={"disabled"}
                        previousLinkRenderer={() => (
                          <CustomPaginationButton>{"<"}</CustomPaginationButton>
                        )}
                        nextLinkRenderer={() => (
                          <CustomPaginationButton>{">"}</CustomPaginationButton>
                        )}
                        pageLinkRenderer={({ selected, children }) => {
                          const mobilePaginationItems =
                            getMobilePaginationItems();
                          return (
                            <CustomPaginationButton
                              selected={selected || 0}
                              // Show the corresponding pagination item based on the view
                              children={
                                isMobileView
                                  ? mobilePaginationItems[selected]
                                  : children
                              }
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {(screenSize === "tablet" || screenSize === "mobile") && (
          <div className="new--div">
            <div className="righSideBar">
              <div className="productsFound my-3 p-0 d-flex justify-content-between">
                <input
                  value={searchFor}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    handleActions(1, e.target.value);
                  }}
                />
                <img src={SearchImg} alt="search" />
              </div>
              <div
                className="d-flex align-items-center flex-nowrap overflow-auto my-2 p-2"
                ref={containerMobileRef}
              >
                <Button
                  style={{
                    color: category === "All" ? "white" : "inherit",
                    backgroundColor: category === "All" ? "#00EC85" : "",
                    padding: "6px 8px",
                    borderRadius: "8px",
                    marginBottom: 0,
                  }}
                  onClick={() => {
                    // navigate("/shop-products/All/0");
                    navigate(`/shop-products/All/0${window.location.search}`);
                    setActiveCat({ Id: 0 });
                    // setActiveCategory(0);
                  }}
                >
                  All
                </Button>

                {map(
                  filter(categories, (d) => isEmpty(d.SubCategories)),
                  (r) => (
                    <Button
                      style={{
                        color: category === r.NameEn ? "white" : "inherit",
                        backgroundColor:
                          category === r.NameEn.replaceAll(" ", "")
                            ? "#00EC85"
                            : "",
                        padding: "6px 8px",
                        borderRadius: "8px",
                        marginBottom: 0,
                      }}
                      onClick={() => {
                        navigate(
                          `/shop-products/${r.NameEn.replaceAll(" ", "")}/${
                            r.Id
                          }`
                        );
                        setActiveCat({ Id: r?.Id });
                      }}
                    >
                      {r.NameEn}
                    </Button>
                  )
                )}

                {map(
                  filter(categories, (d) => !isEmpty(d.SubCategories)),
                  (el) => {
                    return (
                      <div
                        className="col-md-3 col-6 d-flex align-items-center justify-content-center"
                        ref={
                          category === el.NameEn.replaceAll(" ", "")
                            ? buttonMobileRef
                            : null
                        }
                      >
                        <DropDownMenu
                          key={el?.Id}
                          element={el}
                          menuItems={el?.SubCategories}
                          setActiveCateg={setActiveCat}
                        />
                      </div>
                    );
                  }
                )}
              </div>
            </div>
            <div className="leftSideBar">
              <div className="category">
                {(category || activeCat) && (
                  <>
                    <Accordion
                      {...([...routerBrands]?.length === 0
                        ? "flash"
                        : { defaultActiveKey: "0" })}
                    >
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Brands</Accordion.Header>

                        <Accordion.Body>
                          <div
                            className="d-flex flex-column checkImgs"
                            style={{
                              height: brandHeight,
                              overflow: "auto",
                            }}
                          >
                            {brands?.map((brand, i) => {
                              return (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      classes={{ root: classes.check }}
                                      checked={routerBrands.includes(
                                        String(brand?.Id)
                                      )}
                                      onChange={(event) => {
                                        setRouterBrands((prev) =>
                                          prev.includes(`${brand.Id}`)
                                            ? prev.filter(
                                                (brandId) => brandId != brand.Id
                                              )
                                            : [...prev, `${brand.Id}`]
                                        );
                                      }}
                                    />
                                  }
                                  label={brand?.NameEn}
                                />
                              );
                            })}
                          </div>
                          <p onClick={toggleBrandHeight} style={seeMoreStyle}>
                            {brandHeight <= "46vh" ? "See all" : "See less"}
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </>
                )}

                <Accordion flush>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Price range</Accordion.Header>

                    <Accordion.Body>
                      <Slider
                        getAriaLabel={() => "Temperature range"}
                        value={value}
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        min={0}
                        max={500000}
                      />
                      <div className="d-flex justify-content-between hedd">
                        <span>Min</span>
                        <span>Max</span>
                      </div>
                      <div className="d-flex justify-content-between">
                        <input
                          type="number"
                          style={{ width: "5rem" }}
                          value={value[0]}
                          onChange={(e) => handleRange(e, 0, "MinPrice")}
                        ></input>
                        <input
                          type="number"
                          value={value[1]}
                          style={{ width: "5rem" }}
                          onChange={(e) => handleRange(e, 1, "MaxPrice")}
                        ></input>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <div className="d-flex justify-content-center">
                  <button
                    className="applyFilter"
                    onClick={() => handleActions(1, searchFor)}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "100vh",
                  }}
                >
                  <DotLoader color={"#00EC85"} loading={loading} />
                </div>
              ) : (
                currentPageItems?.map((el) => (
                  <div className="col-lg-4 col-md-6 col-6" key={el.Id}>
                    <ShopItem item={el} key={el.Id} />
                  </div>
                ))
              )}
            </div>

            {totalCount <= 9 ? null : (
              <div className="container">
                <div className="pagination-container">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={isMobileView ? 0 : 5}
                    onPageChange={handlePageChange}
                    forcePage={forceNum}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    previousClassName={"previous"}
                    nextClassName={"next"}
                    pageClassName={"page"}
                    pageLinkClassName={"page-link"}
                    previousLinkClassName={"page-link"}
                    nextLinkClassName={"page-link"}
                    disabledClassName={"disabled"}
                    previousLinkRenderer={() => (
                      <CustomPaginationButton>{"<"}</CustomPaginationButton>
                    )}
                    nextLinkRenderer={() => (
                      <CustomPaginationButton>{">"}</CustomPaginationButton>
                    )}
                    pageLinkRenderer={({ selected, children }) => {
                      const mobilePaginationItems = getMobilePaginationItems();
                      return (
                        <CustomPaginationButton
                          selected={selected || 0}
                          // Show the corresponding pagination item based on the view
                          children={
                            isMobileView
                              ? mobilePaginationItems[selected]
                              : children
                          }
                        />
                      );
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <Footer1
        backgroundColor="#303030"
        colorWhite="#FFFFFF"
        colorGreen="rgba(0, 237, 133, 1)"
      />

      <Footer2 />
    </>
  );
}
