import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { BsCheckCircle } from "react-icons/bs";
import { BsFillStarFill, BsSearch } from "react-icons/bs";
import { Helmet } from "react-helmet-async";
import { HashLoader, DotLoader } from "react-spinners";
import OwlCarousel from "react-owl-carousel";
import { Dropdown, Form } from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import ReactPaginate from "react-paginate";

import Slider from "rc-slider";
import axios from "axios";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Footer2 from "../../components/Footer/Footer2";
import { BreadCrumb } from "../../components/BreadCrumb";
import Footer1 from "../../components/Footer/Footer1";
import Navbar from "../../components/Navbar/Navbar";
import ProfileImg from "../../assests/img/profile.png";
import ExpertImg from "../../assests/img/expertImg.png";

import "./Experts.css";
import "rc-slider/assets/index.css";

const AllExperts = () => {
  const [services, setServices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [itemsPerPage, setItemsPerPage] = useState(9);
  const [filteredExperts, setFilteredExperts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMain, setIsLoadingMain] = useState(true); // change this to true when api
  const [values, setValues] = useState([50]);
  const [range, setRange] = useState([0, 50000]); // Initial range values
  const [minValue, setMinValue] = useState(range[0]);
  const [maxValue, setMaxValue] = useState(range[1]);
  const [filterType, setFilterType] = useState(2); // price=1, rate=2
  const [selectedServices, setSelectedServices] = useState([]);
  const [showAllServices, setShowAllServices] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState(""); // Add a state variable for the search keyword
  const [sortingOrder, setSortingOrder] = useState("asc"); // Add a state variable for sorting order
  // Pagination
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [isMobileView, setIsMobileView] = useState(false);
  // const [forceNum, setForced] = useState(0);

  // Function to handle changes in the price range
  const handlePriceChange = (newRange) => {
    setRange(newRange);
  };
  const handleMinInputChange = (event) => {
    const value = parseInt(event.target.value);
    if (!isNaN(value)) {
      setRange([value, range[1]]);
    } else {
      setRange(["", range[1]]);
    }
  };

  const handleMaxInputChange = (event) => {
    const value = parseInt(event.target.value);
    if (!isNaN(value)) {
      setRange([range[0], value]);
    } else {
      setRange([range[0], ""]);
    }
  };
  // const nextPage = () => {
  //   setCurrentPage(currentPage + 1);
  // };
  // const prevPage = () => {
  //   if (currentPage > 1) {
  //     setCurrentPage(currentPage - 1);
  //   }
  // };

  const handleServiceCheckboxChange = (serviceId) => {
    // Check if the serviceId is already in selectedServices
    if (selectedServices.includes(serviceId)) {
      // If it is, remove it from the selected services
      setSelectedServices(selectedServices.filter((id) => id !== serviceId));
    } else {
      // If it's not, add it to the selected services
      setSelectedServices([...selectedServices, serviceId]);
    }
  };

  const toggleServicesDisplay = () => {
    setShowAllServices(!showAllServices);
  };
  const displayedServices = showAllServices ? services : services.slice(0, 4);

  let currency = localStorage.getItem("currency");

  const GetServices = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/Service/ExpertServices`)
      .then((res) => {
        setServices(res.data.Data);
        setIsLoadingMain(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoadingMain(false);
      });
  };

  const GetExpertFilter = async () => {
    try {
      setIsLoadingMain(true);
      const serviceId = searchParams.get("serviceId");
      const res = await axios.get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/Expert/GetExpertFilter2?FilterType=${filterType}&PageNumber=${currentPage}&PageSize=${itemsPerPage}${
          serviceId ? "&ServiceIds=" + serviceId : ""
        }`
      );
      setFilteredExperts(res.data.Data?.Experts);

      setTotalCount(res.data.Data?.TotalCount);
      setPageCount(res.data.Data?.PageNumbers);
      console.log(searchParams.get("serviceId"));
      // if (serviceId) handleServiceCheckboxChange(serviceId);
      setIsLoadingMain(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setIsLoadingMain(false);
    }
  };

  const handleFilterApply = () => {
    searchParams.delete("serviceId");
    setSearchParams(searchParams);
    setIsLoading(true);
    const isAscending = sortingOrder === "asc";

    axios
      .get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/Expert/GetExpertFilter2?FilterType=${filterType}&PageNumber=${
          currentPage || 1
        }&PageSize=${itemsPerPage}${
          selectedServices?.length
            ? "&ServiceIds=" + selectedServices.join(",")
            : ""
        }${searchKeyword ? `&Keyword=${searchKeyword}` : ``}&minPrice=${
          range[0]
        }&maxPrice=${range[1]}&IsAsc=${isAscending}`
      )

      .then((res) => {
        setFilteredExperts(res.data?.Data.Experts);
        setTotalCount(res.data?.Data.TotalCount);
        setPageCount(res.data?.Data.PageNumbers);
        setCurrentPage(1);
        setIsLoading(false); // Set loading state to false after fetching data
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false); // Set loading state to false on error
      });
  };

  const handlePageChange = async (selectedPage) => {
    try {
      // setCurrentPage(selectedPage.selected);
      setCurrentPage(selectedPage.selected + 1);
      window.scrollTo({ top: 150, behavior: "smooth" });

      setIsLoading(true); // Set loading state to true while fetching data
      const isAscending = sortingOrder === "asc"; // Use sortingOrder instead of sortingOption

      axios
        .get(
          `${
            process.env.REACT_APP_API_BASE_URL
          }/Expert/GetExpertFilter2?FilterType=${filterType}&PageNumber=${
            selectedPage.selected + 1
          }&PageSize=${itemsPerPage}${
            selectedServices?.length
              ? "&ServiceIds=" + selectedServices.join(",")
              : ""
          }${searchKeyword ? `&Keyword=${searchKeyword}` : ``}&minPrice=${
            range[0]
          }&maxPrice=${range[1]}&IsAsc=${isAscending}`
        )

        .then((res) => {
          setFilteredExperts(res.data?.Data.Experts);
          setTotalCount(res.data?.Data.TotalCount);
          setPageCount(res.data?.Data.PageNumbers);
          setIsLoading(false); // Set loading state to false after fetching data
        })
        .catch((err) => {
          console.error(err);
          setIsLoading(false); // Set loading state to false on error
        });
    } catch (error) {
      console.log(error);
      setIsLoadingMain(false);
    }
  };

  // Function to determine pagination items for mobile view
  const getMobilePaginationItems = () => {
    const items = [];

    items.push(currentPage);

    if (currentPage < pageCount - 1) {
      items.push(currentPage + 1);
    }

    // Show dots (...) if there are more than 2 pages
    if (pageCount > 2) {
      items.push("...");
    }

    // Show the penultimate and last pages
    items.push(pageCount - 2, pageCount - 1, pageCount);

    return items;
  };

  const CustomPaginationButton = ({ selected, children }) => {
    return (
      <div className={`custom-pagination-button ${selected ? "selected" : ""}`}>
        {children}
      </div>
    );
  };

  // const getExpertListPagination = async () => {
  //   try {
  //     let res = await axios.get(
  //       `${process.env.REACT_APP_API_BASE_URL}/Expert/GetExpertListPagination?PageNumber=${currentPage}&PageSize=9`
  //     );
  //     setFilteredExperts(res.data.Data.Experts);
  //     setTotalCount(res.data.Data.TotalCount);
  //     setPageCount(res.data.Data.PageNumbers);
  //   } catch (error) {
  //     console.log(error);
  //     setIsLoadingMain(false);
  //   }
  // };

  useEffect(() => {
    GetServices();
    // getExpertListPagination();
    GetExpertFilter();
    // handleFilterApply();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Clicktick: Professional Technical Experts With Best Hour Rate In Egypt
        </title>
        <meta
          name="title"
          content="Professional Technical Experts With Best Hour Rate In Egypt"
        />
        <meta
          name="description"
          content="Contact now with Professional Technical Experts With Best Hour Rate In Egypt."
        />
        <meta
          name="keywords"
          content="IT Expert, Technical Support Agent, Network architect, Systems analyst, IT coordinator, Network administrator, Network engineer, Service desk analyst, System administrator"
        />
      </Helmet>
      <Navbar />
      <BreadCrumb
        list={[
          { name: "Experts", link: "/experts" },
          { name: "Recommended Experts", link: "" },
        ]}
      />
      {isLoadingMain ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <DotLoader color={"#00EC85"} loading={isLoadingMain} />
        </div>
      ) : (
        <>
          <div className="container mb-5">
            <div className="row">
              <div className="col-md-3">
                <div className="expertFilterContainer">
                  <Accordion
                    defaultActiveKey={["0", "1", "2"]}
                    alwaysOpen
                    flush
                  >
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Service</Accordion.Header>
                      <Accordion.Body>
                        <Form>
                          {services?.map((service, index) => (
                            <div key={index} className="mb-3">
                              <Form.Check
                                type="checkbox"
                                id={`service-${service.Id}`}
                                label={service.NameEn}
                                onChange={() =>
                                  handleServiceCheckboxChange(service.Id)
                                }
                              />
                            </div>
                          ))}
                        </Form>
                        {/* {services?.length >= 5 && (
                          <button
                            onClick={toggleServicesDisplay}
                            className="expertSeeAll"
                          >
                            {showAllServices ? "See less" : "See all"}
                          </button>
                        )} */}
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Price Range for hour</Accordion.Header>
                      <Accordion.Body>
                        <div>
                          <Slider
                            range
                            min={0}
                            max={50000}
                            step={10}
                            value={range}
                            onChange={handlePriceChange}
                            trackStyle={[{ backgroundColor: "#000056" }]}
                          />
                          <div className="price--range">
                            <div>
                              <p>min</p>
                              <input
                                type="number"
                                value={range[0]}
                                onChange={handleMinInputChange}
                              />
                            </div>
                            <div>
                              <p>max</p>
                              <input
                                type="number"
                                value={range[1]}
                                onChange={handleMaxInputChange}
                              />
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Sorting by</Accordion.Header>
                      <Accordion.Body>
                        <Accordion className="w-100">
                          <Accordion.Item>
                            <Accordion.Header>Price</Accordion.Header>
                            <Accordion.Body>
                              <div className="sortingOptions">
                                {/* <select
                                  className="w-100 border-none outline-none"
                                  value={sortingOrder}
                                  onChange={(e) => {
                                    setFilterType(1); // change from rate to price
                                    setSortingOrder(e.target.value);
                                  }}
                                >
                                  <option value="asc">Low to High</option>
                                  <option value="desc">High to Low</option>
                                </select> */}
                                <div className="outer">
                                  <div>
                                    <Form.Select
                                      value={sortingOrder}
                                      onChange={(e) => {
                                        setFilterType(1); // change from rate to price
                                        setSortingOrder(e.target.value);
                                      }}
                                    >
                                      <option value="asc">Low to High</option>
                                      <option value="desc">High to Low</option>
                                    </Form.Select>
                                  </div>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                  <button
                    className="applyExpertFilter"
                    onClick={handleFilterApply}
                  >
                    Apply
                  </button>
                </div>
              </div>
              <div className="col-md-9">
                <div className="expertList">
                  <div className="ExpertSearchBar">
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder="What are you looking for?"
                      value={searchKeyword}
                      onChange={(e) => setSearchKeyword(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleFilterApply();
                        }
                      }}
                    />

                    <BsSearch onClick={handleFilterApply} />
                  </div>
                  <div className="expertsFoundFilter">
                    <p>{totalCount || 0} Experts for Recommended Experts</p>
                  </div>
                  <div className="row expert-card-row">
                    {isLoading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width:"100%"
                        }}
                      >
                      <DotLoader color={"#00EC85"} loading={isLoading} />
                     </div>
                    ) : (
                      filteredExperts?.map((expert, index) => (
                        <div className="col-md-4 col-6">
                          <Link
                            to={`/expert-details/${
                              expert.Id
                            }/${expert.UserName.replaceAll(" ", "")}`}
                          >
                            <div
                              key={index}
                              className="recExpt me-4 mb-3 recExpterInAll"
                              data-aos="zoom-in-down"
                              data-aos-duration="7000"
                              data-aos-once="true"
                              style={{
                                borderRadius: "0.3125rem",
                                background: "#FFF",
                                boxShadow:
                                  "0px 0px 8px 0px rgba(0, 0, 0, 0.10)",
                                marginTop: "2rem",
                                minWidth: "14rem",
                              }}
                            >
                              <div>
                                <img
                                  src={ExpertImg}
                                  alt="expert"
                                  className="img-fluid"
                                />
                              </div>
                              <div className="exptInfo">
                                <p className="m-0">{expert.UserName}</p>
                                <p className="m-0 mt-2">{expert.TitleEn}</p>
                                <div className="d-flex align-items-center justify-content-center mt-1">
                                  <BsCheckCircle
                                    style={{ color: "rgba(3, 190, 108, 1)" }}
                                  />
                                  <span className="projectsDone">
                                    {expert.ProjectCount} Done Projects
                                  </span>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="me-5">
                                    <p className="m-0">
                                      <span
                                        className="me-2"
                                        style={{
                                          color: "#000056",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {expert.HourRate}
                                      </span>
                                      {currency}/hr
                                    </p>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <BsFillStarFill
                                      style={{
                                        color: "#FDCC0D",
                                        fontSize: "25px",
                                      }}
                                      className="me-2"
                                    />
                                    <span
                                      style={{
                                        color: "#313131",
                                        fontWeight: "bold",
                                        fontSize: "18px",
                                      }}
                                    >
                                      {expert.Rate}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {totalCount <= 9 ? null : (
            <div className="container">
              <div className="pagination-container">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={isMobileView ? 0 : 5}
                  onPageChange={handlePageChange}
                  forcePage={currentPage - 1}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  previousClassName={"previous"}
                  nextClassName={"next"}
                  pageClassName={"page"}
                  pageLinkClassName={"page-link"}
                  previousLinkClassName={"page-link"}
                  nextLinkClassName={"page-link"}
                  disabledClassName={"disabled"}
                  previousLinkRenderer={() => (
                    <CustomPaginationButton>{"<"}</CustomPaginationButton>
                  )}
                  nextLinkRenderer={() => (
                    <CustomPaginationButton>{">"}</CustomPaginationButton>
                  )}
                  pageLinkRenderer={({ selected, children }) => {
                    const mobilePaginationItems = getMobilePaginationItems();
                    return (
                      <CustomPaginationButton
                        selected={selected || 0}
                        // Show the corresponding pagination item based on the view
                        children={
                          isMobileView
                            ? mobilePaginationItems[selected]
                            : children
                        }
                      />
                    );
                  }}
                />
              </div>
            </div>
          )}

          <Footer1
            backgroundColor="#303030"
            colorWhite="#FFFFFF"
            colorGreen="rgba(0, 237, 133, 1)"
          />
          <Footer2 />
        </>
      )}
    </>
  );
};

export default AllExperts;
