import React, { useEffect, useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer2 from "../../../components/Footer/Footer2";
import { BreadCrumb } from "../../../components/BreadCrumb/index";
import axios from "axios";
import DotLoader from "react-spinners/DotLoader";
import { Helmet } from "react-helmet-async";

const AboutUs = () => {
  const [apiData, setApiData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getAbout = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/Home/Web_AboutUs`)
      .then((res) => {
        setApiData(res.data.Data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getAbout();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Helmet>
        <title>About Us | Clicktick Solutions</title>
        <meta name="title" content="About Us | Clicktick Solutions" />
        <meta
          name="description"
          content="Clicktick is a platform designed to provide a user-friendly and seamless experience for customers looking to purchase Technology products and services"
        />
        <meta
          name="keywords"
          content=" Clicktick, Click tick, About us, Clicktick about us, e-commerce, tech, technology"
        />
        <link rel="canonical" href="https://clicktick.app/about-us" />
        <link rel="canonical" href="https://clicktick.app/contact-us" />
      </Helmet>
      <Navbar />
      <BreadCrumb list={[{ name: "About Us", link: "" }]} />

      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            // alignItems: "center",
            height: "100vh",
          }}
        >
          <DotLoader color={"#00EC85"} loading={isLoading} />
        </div>
      ) : (
        <div className="container">
          <div
            style={{
              letterSpacing: "0.7px",
              lineHeight: "2.2rem",
            }}
            dangerouslySetInnerHTML={{
              __html: `<div>${apiData.ValueEn}</div>`,
            }}
          ></div>
        </div>
      )}
      <Footer2 />
    </>
  );
};

export default AboutUs;
