import React, { useEffect, useState } from "react";
import "./Notifications.css";
import Navbar from "../../../components/Navbar/Navbar";
import { BreadCrumb } from "../../../components/BreadCrumb/index";
import Footer2 from "../../../components/Footer/Footer2";
import { BsCheckLg } from "react-icons/bs";
import { MdOutlineCelebration } from "react-icons/md";
import axios from "axios";
import { DotLoader } from "react-spinners";
import { Helmet } from "react-helmet-async";

const Notifications = () => {
  const [notification, setNotification] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const userName = localStorage.getItem("username");

  const getNotifications = async () => {
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/Notification/UserNotifications`
        )
        .then((response) => {
          setNotification(response.data.Data);
          setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getNotifications();
  }, []);
  return (
    <>
      <Helmet>
        <title>Notifications | Check Notifications With Clicktick</title>
        <meta name="title" content="Notifications | Check Notifications With Clicktick" />
        <meta
          name="description"
          content="Check your notifications center and don't miss previous notifications easily with Clicktick"
        />
        <meta
          name="keywords"
          content="Clicktick, Click tick, Notifications, Notifications center"
        />
      </Helmet>
      <Navbar />
      <BreadCrumb
        list={[{ name: "My Notifications", half: "my account", link: "" }]}
      />

      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            // alignItems: "center",
            height: "100vh",
          }}
        >
          <DotLoader color={"#00EC85"} loading={isLoading} />
        </div>
      ) : (
        <div className="container">
          <div className="notifications">
            <div className="row">
              <div className="col-md-7">
                {notification?.length === 0 ? (
                  <p>You have no notifications</p>
                ) : (
                  notification?.map((item) => {
                    return (
                      <div className="notification-card">
                        <div className="row">
                          <div className="col-md-2 d-flex align-items-center justify-content-center">
                            <BsCheckLg className="check-icon" />
                          </div>
                          <div className="col-md-10">
                            <p>{item.Title}</p>
                            <p>{item.Body}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}

                {/* <div className="notification-card">
                <div className="row">
                  <div className="col-md-2 d-flex align-items-center justify-content-center">
                    <BsCheckLg className="check-icon" />
                  </div>
                  <div className="col-md-10">
                    <p>Tech Service Visit</p>
                    <p>We Remind you that the Tech visit will be tomorrow.</p>
                  </div>
                </div>
              </div> */}
                <div className="welcome-card">
                  <div className="row">
                    <div className="col-md-2 d-flex align-items-center justify-content-center">
                      <MdOutlineCelebration className="check-icon" />
                    </div>
                    <div className="col-md-10">
                      <p>welcome, {userName}</p>
                      <p>Welcome {userName} to Clicktick App.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5"></div>
            </div>
          </div>
        </div>
      )}

      <Footer2 />
    </>
  );
};

export default Notifications;
