import React, { useState, useContext, useEffect } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Button,
  InputLabel,
} from "@material-ui/core";
import EmptyCircle from "../assests/icons/emptyCircle.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { makeStyles } from "@material-ui/core";
import Clicktick from "../context/context";
import axios from "axios";
import { BasicInput } from "../components/BasicInput";
import Checkk from "../assests/img/checkk.png";
import { useNavigate } from "react-router-dom";
import { SelectInput } from "../components/SelectInput";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import AddressForm from "./addressForm";
export const useStyles = makeStyles({
  radio: {
    "&$checked": {
      color:
        "linear-gradient(103.41deg, #0BCC77 10.76%, #08A761 85.94%) !important",
    },
  },
  serviceCont: {
    backgroundColor: "#ffffff",
    padding: "2rem",
    display: "flex",
    flexDirection: "column",
    width: "50%",
    overflowY: "auto",
    maxHeight: "90vh",
    "@media (max-width: 1200px)": {
      width: "100%",
    },
    "& .serviceDiv": {
      display: "flex",
      alignItems: "center",
      margin: "0.5rem 0",
      border: "1px solid #B9B9B9",
      borderRadius: "10px",
      padding: "3%",
      justifyContent: "center",
      width: "90%",
      alignSelf: "center",
      "& svg": {
        fontSize: "2rem",
      },
      "& p": {
        fontWeight: 500,
        fontSize: "2rem",
        lineHeight: "39px",
        textAlign: "center",
        color: "#777777",
        width: "55%",
        "@media (max-width: 600px)": {
          fontSize: "1rem",
        },
      },
    },
    "& .headerContainer": {
      alignItems: "center",
      width: "95%",
      alignSelf: "center",
      // padding: "2rem 0",
      "& .title": {
        fontWeight: 300,
        fontSize: "2rem",
        lineHeight: "1.5rem",
        letterSpacing: "0.3px",
        color: "#545454",
        whiteSpace: "nowrap",
        margin: 0,
        "@media (max-width: 600px)": {
          fontSize: "1.5rem",
        },
      },
      "& .iconCont": {
        "& svg": {
          width: "3rem",
          height: "3rem",
          color: "#545454",
          cursor: "pointer",
        },
      },
    },
    "& button": {
      width: "95%",
      background: "linear-gradient(103.41deg, #0BCC77 10.76%, #08A761 85.94%)",
      borderRadius: "10px",
      marginTop: "2rem",
      alignSelf: "center",
      padding: "1rem",
      "& p": {
        fontWeight: 600,
        fontSize: "2.125rem",
        lineHeight: "44px",
        textAlign: "center",
        textTransform: "initial",
        color: "#ffffff",
      },
    },
    "& .restFields": {
      width: "80%",
      display: "flex",
      flexDirection: "column",
      alignSelf: "center",
      "& .inputWrapper": {
        margin: "15px 25px",
      },
    },
    "& .finish": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& .sent": {
        fontWeight: 500,
        fontSize: "2.5rem",
        lineHeight: "24px",
        color: "#545454",
      },
      "& svg": {
        width: "50%",
        margin: "2rem 0",
      },
      "& .contact": {
        fontWeight: 400,
        fontSize: "2rem",
        lineHeight: "24px",
        color: "#545454",
      },
      "& .check": {
        width: "50%",
        margin: "1rem 0",
      },
    },
  },
});

const ServiceType = ({ ...props }) => {
  const types = [
    { id: "office", name: "Site visit" },
    { id: "online", name: "Online" },
  ];

  const [activeType, setType] = useState();
  const [flag, setFlag] = useState(false);
  const [isAddAddress, setIsAddAddress] = useState(false);
  const [customId, setCustom] = useState(false);

  const [finish, setFinish] = useState(false);
  const [errors, setErrors] = useState({
    CustomerFullName: "",
    CustomerPhone: "",
    CustomerCompany: "",
  });
  const [addresses, setAddresses] = useState([]);
  const [form, setForm] = useState({
    CustomerFullName: "",
    CustomerPhone: "",
    ProblemDescription: "",
    CustomerCompany: "",
    UserAddressId: 0,
  });

  async function getInitFormDetails() {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/Account/UserDetails`
      );
      setForm({
        CustomerFullName: res.data.Data.Name,
        CustomerPhone: res.data.Data.Phone,
        ProblemDescription: "",
        CustomerCompany: "",
        UserAddressId: 0,
      });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getInitFormDetails();
  }, []);

  useEffect(() => {
    if (props.open && finish) {
      setTimeout(() => {
        props.onClose();
      }, 3000);
    }
  }, [finish]);

  useEffect(() => {
    const getAddresses = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/UserAddress/GetUserAddresses`
      );
      setAddresses(data.Data);
    };
    getAddresses();
  }, [isAddAddress]);

  const classes = useStyles();

  const onSubmit = (e) => {
    e.preventDefault();
    if (!flag) {
      setFlag(true);
    }

    if (flag) {
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/Service/SubmitServicRequest`,
          {
            ServiceId: props?.ServiceId,
            CustomerFullName: form.CustomerFullName,
            CustomerPhone: form.CustomerPhone,
            CustomerCompany: form.CustomerCompany,
            ProblemDescription: form.ProblemDescription,
            RequstType: activeType === "office" ? 1 : 2,
            CountryCodeId: localStorage.getItem("CountryId") || 3,
            UserAddressId: form.UserAddressId,
          }
        )
        .then((res) => {
          setType("");
          setForm({});
          setFinish(true);
        });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // if (name === "CustomerFullName") {
    //   const isValid = /^[a-zA-Z\s]*$/.test(value);
    //   if (!isValid) {
    //     setErrors((prevErrors) => ({
    //       ...prevErrors,
    //       CustomerFullName: "Name cannot contain numbers",
    //     }));
    //   } else {
    //     setErrors((prevErrors) => ({
    //       ...prevErrors,
    //       CustomerFullName: "",
    //     }));
    //   }
    // }

    if (name === "CustomerPhone") {
      const isValid = /^[0-9]+$/.test(value);
      if (!isValid) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          CustomerPhone: "Phone number should only contain numbers",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          CustomerPhone: "",
        }));
      }
    }

    setForm((prevState) => {
      return { ...prevState, [name]: value };
    });
  };
  if (isAddAddress)
    return (
      <div className={`${classes.serviceCont}`}>
        <AddressForm setIsAddAddress={setIsAddAddress} setCustom={setCustom} />
      </div>
    );
  else {
    return (
      <div className={classes.serviceCont}>
        {finish ? (
          <div className="finish">
            <p className="sent">Request Sent</p>
            <img src={Checkk} alt="Check" className="check" />
            <p className="contact">Contact you soon</p>
          </div>
        ) : (
          <>
            <div className="d-flex justify-content-between headerContainer">
              <p className="title">How can we help you?</p>
              <div
                className="iconCont"
                onClick={() => {
                  setType("");
                  setForm({});
                  props.onClose();
                }}
              >
                <HighlightOffIcon />
              </div>
            </div>
            {!flag && (
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="type"
                  name="type"
                  value={activeType}
                  onChange={(e) => setType(e.target.value)}
                  className={classes.group}
                >
                  {types?.map((d) => (
                    <div className="serviceDiv" key={d.id}>
                      <FormControlLabel
                        value={d.id}
                        control={<Radio checkedIcon={<CheckCircleIcon />} />}
                        label=""
                        classes={{ root: classes.radio }}
                      />
                      <Typography>{d.name}</Typography>
                    </div>
                  ))}
                </RadioGroup>
              </FormControl>
            )}
            <form onSubmit={onSubmit}>
              {flag && (
                <div className="restFields">
                  <div className="inputWrapper">
                    <BasicInput
                      variant="filled"
                      label={"Full name"}
                      name="CustomerFullName"
                      required
                      fullWidth
                      className={classes.inputCust}
                      value={localStorage.getItem("username")}
                      onChange={handleChange}
                      disabled
                    />
                    {errors.CustomerFullName && (
                      <p className="fw-bold text-danger mb-0">
                        {errors.CustomerFullName}
                      </p>
                    )}
                  </div>
                  <div className="inputWrapper">
                    <BasicInput
                      variant="filled"
                      label={"Mobile phone"}
                      name="CustomerPhone"
                      required
                      fullWidth
                      className={classes.inputCust}
                      value={form.CustomerPhone}
                      onChange={handleChange}
                    />
                    {errors.CustomerPhone && (
                      <p className="fw-bold text-danger mb-0">
                        {errors.CustomerPhone}
                      </p>
                    )}
                  </div>
                  {/* <div className="inputWrapper">
                    <BasicInput
                      variant="filled"
                      label={"Company name"}
                      name="CustomerCompany"
                      required
                      fullWidth
                      className={classes.inputCust}
                      value={form.CustomerCompany}
                      onChange={handleChange}
                    />
                    {errors.CustomerCompany && (
                      <p className="fw-bold text-danger mb-0">
                        {errors.CustomerCompany}
                      </p>
                    )}
                  </div> */}
                  {activeType != "online" && (
                    <div className="inputWrapper d-flex">
                      <SelectInput
                        variant="filled"
                        label={"Address"}
                        name="UserAddressId"
                        required
                        customId={customId}
                        value={form.UserAddressId}
                        onChange={handleChange}
                        fullWidth
                        options={addresses}
                        custom={true}
                        style={{ width: "100%", marginRight: "0.5rem" }}
                      />
                      <AddCircleOutline
                        className="heartIcon "
                        style={{ cursor: "pointer" }}
                        onClick={() => setIsAddAddress(true)}
                      />
                    </div>
                  )}
                  <div className="inputWrapper">
                    <BasicInput
                      variant="filled"
                      label={"Description"}
                      name="ProblemDescription"
                      multiline
                      rows={4}
                      required
                      fullWidth
                      value={form.ProblemDescription}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              )}
              <Button
                // onClick={handleServiceRequest}
                disabled={!activeType}
                type="submit"
                style={{
                  background:
                    !activeType &&
                    "linear-gradient(180deg, #626262 0%, #3E3E3E 100%)",
                }}
              >
                <Typography>Confirm</Typography>
              </Button>
            </form>
          </>
        )}
      </div>
    );
  }
};

export default ServiceType;
