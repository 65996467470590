import React, { useState } from "react";
import axios from "axios";
import "./Login.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assests/img/logo.png";
import VerifySMS from "../../assests/img/verifySMS.png";
import { BsLock, BsEye, BsEyeSlash } from "react-icons/bs";
import Footer2 from "../../components/Footer/Footer2";

const ResetPassword = () => {
  const location = useLocation();
  const otp = location.state.resKey;
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isTypingConfirmPassword, setIsTypingConfirmPassword] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setIsTypingConfirmPassword(false);
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);

    if (isTypingConfirmPassword) {
      setPasswordMatch(password === newConfirmPassword);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!passwordMatch) {
      return;
    }

    // Validate password format
    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])/;
    if (!passwordRegex.test(password)) {
      setErrMsg(
        "Password should contain at least one capital letter and one symbol."
      );
      return;
    }


    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/Account/ResetPassword`, {
        NewPassword: password,
        ConfirmPassword: password,
        Key: otp,
      })
      .then((res) => {
        console.log(res);
        setShowSuccessMessage(true); 
        setTimeout(() => {
          navigate("/loginuser"); 
        }, 7000); 
      })
      .catch((err) => {
        setErrMsg(err.response.data.Message);
      });
  };

  return (
    <>
      <div className="login resetPassword">
        <div className="container">
          <div className="row">
            <div className="col-md-6 d-flex align-items-center flex-column">
              <div className="logo mb-4">
                <Link to="/">
                  <img src={Logo} alt="logo" />
                </Link>
              </div>
              <div className="userLoginImg mt-5">
                <img src={VerifySMS} alt="user login" className="w-100" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="forget">
                <h4 className="mb-5">Reset Password</h4>
                {showSuccessMessage && (
                  <p className="success-message">
                    Password changed successfully.You will be redirected to
                    Login page, <a href="/loginuser">Click here</a> if you are
                    not directed
                  </p>
                )}
                <form className="reset-password" onSubmit={handleSubmit}>
                  <div className="firstInput">
                    <BsLock />
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="New Password"
                      className="w-100 ms-2"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    {showPassword ? (
                      <BsEyeSlash
                        className="secondSvg"
                        onClick={handleTogglePasswordVisibility}
                      />
                    ) : (
                      <BsEye
                        className="secondSvg"
                        onClick={handleTogglePasswordVisibility}
                      />
                    )}
                  </div>

                  <div className="secondInput">
                    <BsLock />
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      name="confirmPassword"
                      placeholder="Confirm New Password"
                      className="w-100 ms-2"
                      onFocus={() => setIsTypingConfirmPassword(true)}
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                    />

                    {showConfirmPassword ? (
                      <BsEyeSlash
                        className="secondSvg"
                        onClick={handleToggleConfirmPasswordVisibility}
                      />
                    ) : (
                      <BsEye
                        className="secondSvg"
                        onClick={handleToggleConfirmPasswordVisibility}
                      />
                    )}
                  </div>

                  {!passwordMatch && (
                    <p className="error-message">
                      Passwords do not match. Please try again.
                    </p>
                  )}
                  <p className="m-0 invalid text-center">{errMsg}</p>

                  <button type="submit" className="mt-4">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
};

export default ResetPassword;
