import { useContext, useEffect, useState } from "react";
// import { useRequest } from "mongez/http";
import Clicktick from "../../context/context";
// import { accountServices } from "../services";
import { Container, Grid } from "@material-ui/core";
import { useStyles } from "./style";
import { BreadCrumb } from "../../components/BreadCrumb/index";
import Receipt from "./receipt/Receipt";
import CartItems from "./cartItems/CartItems";
import axios from "axios";
import { EmptyComponent } from "../../components/EmptyComponents";
import { isEmpty } from "lodash";
import Navbar from "../../components/Navbar/Navbar";
import { DotLoader } from "react-spinners";
import { Helmet } from "react-helmet-async";

export default function Cart() {
  const { cart, setCart } = useContext(Clicktick);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getCartItems = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/Order/GetCartList`
      );
      setCart(data.Data);
      setIsLoading(false);
    };
    getCartItems();
  }, []);
  return (
    <>
       <Helmet>
        <title>Cart | Checkout Your Items with Clicktick</title>
        <meta name="title" content="Cart | Checkout Your Items with Clicktick" />
        <meta
          name="description"
          content=" Add to cart items to buy later with total amount and total discount and promo code apply with Clicktick | Check Out"
        />
        <meta
          name="keywords"
          content="Clicktick, Click tick, Add to cart, Cart, Check out"
        />
      </Helmet>
      <Navbar />

      <BreadCrumb list={[{ name: "Cart", link: "" }]} />
      <div className={classes.cart}>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              // alignItems: "center",
              height: "100vh",
            }}
          >
            <DotLoader color={"#00EC85"} loading={isLoading} />
          </div>
        ) : (
          <Container maxWidth="lg">
            {isEmpty(cart) ? (
              <EmptyComponent content={"Empty Cart"} />
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={12} md={9}>
                  <CartItems items={cart} />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Receipt />
                </Grid>
              </Grid>
            )}
          </Container>
        )}
      </div>
    </>
  );
}
