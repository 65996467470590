import { Link } from "react-router-dom";

import { BsChevronRight, BsClock } from "react-icons/bs";
import { IoPersonOutline, IoWalletOutline } from "react-icons/io5";
import moment from "moment";

import { getStatusColor } from "../utils/getStatusColor";

const ExpertRequestsCard = ({
  request,
  selectedRequest,
  handleSelectedItem,
}) => {
  const currency = localStorage.getItem("currency");

  return (
    <div className="expertRequestsCard d-sm-block">
      <div
        style={{
          display: "flex",
          justifyItems: "center",
          justifyContent: "space-between",
        }}
        className="d-flex flex-column flex-xxl-row justify-content-between"
      >
        <p
          style={{
            color: "#03BE6C",
            fontWeight: "700",
            fontFamily: "Poppins",
          }}
        >
          {request.ServiceNameEn}
        </p>

        <p style={{color:getStatusColor(request.RequestStatusId)}}>{request.RequestStatusEn}</p>
      </div>

      <p
        style={{
          color: "#4D4D4D",
          fontSize: "0.95rem",
          fontWeight: "600",
          fontFamily: "Poppins",
          display: "flex",
          alignItems: "center",
        }}
      >
        <IoPersonOutline className="me-2" />
        {request.UserName}
      </p>

      <div className="row">
        <div className="col-md-6">
          <p
            style={{
              color: "#4D4D4D",
              fontSize: "0.95rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsClock className="me-2" />
            {moment.unix(request.ExpertRequestDate).format("DD/MM/YYYY")}
          </p>
        </div>

        <div className="col-md-6">
          <p
            style={{
              color: "#4D4D4D",
              fontSize: "0.95rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IoWalletOutline className="me-2" style={{ fontSize: "1.3rem" }} />
            {request.ExpectedBudget} {currency}
          </p>
        </div>
      </div>

      {handleSelectedItem ? (
        <button
          onClick={() => handleSelectedItem(request.Id, "request")}
          style={{
            width: "100%",
            backgroundColor: `${
              request.Id === selectedRequest?.Id ? "#03be6c" : ""
            }`,
            color: `${request.Id === selectedRequest?.Id ? "white" : ""}`,
          }}
        >
          Details
          {request.Id === selectedRequest?.Id && (
            <BsChevronRight className="ms-2" />
          )}
        </button>
      ) : (
        <Link
          to={`/expert-requests?type=request&id=${request.Id}`}
          style={{ color: "inherit", display: "flex", alignItems: "center" }}
        >
          <button>
            Details
            <BsChevronRight className="ms-2" />
          </button>
        </Link>
      )}
    </div>
  );
};

export default ExpertRequestsCard;
