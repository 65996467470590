import { useState } from "react";
import { Accordion } from "react-bootstrap";
import { AiOutlineFileText } from "react-icons/ai";
import { Modal } from "react-bootstrap";

import ChatBox from "../Chat2/ChatBox";

import ExpertImg from "../../assests/img/Group-1000004820.png";
import DownloadIcon from "../../assests/icons/download.svg";

const AttachmentsDetails = ({ details, senderId, receiverId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImg, setSelectedImg] = useState("");

  const { RequestAttachments } = details;

  const imageAttachments = RequestAttachments?.filter((attachment) => {
    const imageUrl = attachment.AttachmentUrl.toLowerCase();
    return (
      imageUrl.endsWith(".jpg") ||
      imageUrl.endsWith(".jpeg") ||
      imageUrl.endsWith(".png") ||
      imageUrl.endsWith(".gif") ||
      imageUrl.endsWith(".webp")
    );
  });

  const documentAttachments = RequestAttachments?.filter((attachment) => {
    const file = attachment.AttachmentUrl.toLowerCase();
    return (
      file.endsWith(".pdf") ||
      file.endsWith(".docx") ||
      file.endsWith(".txt") ||
      file.endsWith(".xlsx")
    );
  });

  return (
    <>
      <div className="userDetailsForExpert">
        <div className="container">
          <div className="pt-3 d-flex align-items-center justify-content-center flex-column">
            <img src={ExpertImg} alt="expert" className="img-fluid" />

            <p className="mt-2">{details?.UserName}</p>
          </div>

          <hr />

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Recent Files
                <span>
                  &nbsp;({documentAttachments.length}{" "}
                  {documentAttachments.length > 1 ? "files" : "file"})
                </span>
              </Accordion.Header>

              <Accordion.Body>
                {documentAttachments?.map((attachment, index) => (
                  <div
                    key={attachment.Id}
                    className="d-flex align-items-center justify-content-between mb-2"
                  >
                    <div>
                      <AiOutlineFileText className="me-2" size={24} />

                      <span
                        style={{
                          color: "#5D6F6D",
                        }}
                      >
                        {/* {documentFileNames[index]} */}
                        {`File_${index + 1}`}
                      </span>
                    </div>

                    <div>
                      <a href={attachment.AttachmentUrl}>
                        <img
                          src={DownloadIcon}
                          alt="Download"
                          style={{
                            width: 20,
                            height: 20,
                            cursor: "pointer",
                          }}
                        />
                      </a>
                    </div>

                    {/* <p className="mb-0">
                  <BiDotsHorizontalRounded />
                 </p> */}
                  </div>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <hr />

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Images
                <span>
                  &nbsp;({imageAttachments.length}{" "}
                  {imageAttachments.length > 1 ? "images" : "image"})
                </span>
              </Accordion.Header>

              <Accordion.Body>
                <div className="d-flex align-items-center flex-wrap gap-2">
                  {imageAttachments?.map((attachment) => (
                    <img
                      key={attachment.Id}
                      role="button"
                      onClick={() => {
                        setIsOpen(true);
                        setSelectedImg(attachment.AttachmentUrl);
                      }}
                      src={attachment.AttachmentUrl}
                      alt="attachment"
                      className="img-fluid"
                      style={{
                        borderRadius: "16px",
                        width: "85px",
                        height: "85px",
                        objectFit: "cover",
                      }}
                    />
                  ))}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <hr />

          {[3, 5, 7, 9, 10, 11].includes(details.RequestStatusId) && (
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Chat</Accordion.Header>

                <Accordion.Body>
                  <ChatBox
                    chatId={details.Id}
                    senderId={senderId}
                    receiverId={receiverId}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
        </div>
      </div>

      <Modal show={isOpen} onHide={() => setIsOpen(false)} centered>
        <Modal.Body>
          <img
            className="img-fluid"
            src={selectedImg}
            alt="selected"
            style={{ objectFit: "cover" }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AttachmentsDetails;
