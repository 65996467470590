import React, { useEffect, useState } from "react";
import "./MyOrders.css";
import Navbar from "../../../components/Navbar/Navbar";
import { BreadCrumb } from "../../../components/BreadCrumb/index";
import Footer2 from "../../../components/Footer/Footer2";
import { BsClock } from "react-icons/bs";
import { RiShoppingBasketLine } from "react-icons/ri";
import { IoWarningOutline } from "react-icons/io5";
import { HiCheckCircle } from "react-icons/hi";
import axios from "axios";
import moment from "moment";
import { DotLoader } from "react-spinners";
import { Button, Modal } from "react-bootstrap";
import Delete from "../../../assests/img/delIcon.png";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";

const MyOrders = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [orderedItems, setOrderedItems] = useState([]);
  const [productsPerOrder, setProductsPerOrder] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isloadingMain, setIsLoadingMain] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [errPaymentMsg, setErrPaymentMsg] = useState("");

  const getOrders = async () => {
    try {
      setIsLoadingMain(true);
      await axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/Order/GetOrderList`)
        .then((response) => {
          setOrders(response.data.Data);
          setIsLoadingMain(false);
        });
    } catch (error) {
      console.log(error);
      setIsLoadingMain(false);
    }
  };

  const handleOrderClick = async (orderId) => {
    setSelectedOrder(orderId);
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/Order/GetOrderDetails?Id=${orderId}`
      );
      // Scroll to top
      window.scrollTo({ top: 180, behavior: "smooth" });
      setOrderedItems(response.data.Data);
      setProductsPerOrder(response.data.Data.OrderProducts);
      setPaymentMethod(response.data.Data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedServiceId, setSelectedServiceId] = useState(null);

  const handleDelete = (orderId) => {
    setSelectedServiceId(orderId);
    setShowDeleteModal(true);
  };

  const deleteOrder = (orderId) => {
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/Order/CancelOrder?OrderId=${orderId}`
      )
      .then((response) => {
        getOrders();
        setIsLoading(false);
        toast.success("Order is cancelled");
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const retryPayment = () => {
    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/Order/PaymentRetry?OrderId=${selectedOrder}`
      )
      .then((response) => {
        setIsLoading(false);
        window.location.href = response.data.Data.IframeLink;
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setErrPaymentMsg("an error has accured, please try again later.");
      });
  };

  let currency = localStorage.getItem("currency");
  const sortedOrders = orders.sort((a, b) => b.CreatedDate - a.CreatedDate);

  useEffect(() => {
    getOrders();
  }, []);

  return (
    <>
      <Helmet>
        <title> My Orders | Track Your Order With Clicktick</title>
        <meta name="title" content="Change Password | Clicktick" />
        <meta
          name="description"
          content="Track your orders status or orders details and amount easily with Clicktick"
        />
        <meta
          name="keywords"
          content="Clicktick, Click tick, orders, track orders, my orders"
        />
      </Helmet>
      <Navbar />
      <BreadCrumb list={[{ name: "My Orders", link: "" }]} />
      {isloadingMain ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            // alignItems: "center",
            height: "100vh",
          }}
        >
          <DotLoader color={"#00EC85"} loading={isloadingMain} />
        </div>
      ) : (
        <div className="container">
          <div className="orders">
            <div className="row">
              <div className="col-md-6">
                <div className="left">
                  {orders?.length === 0 ? (
                    <p>No orders have been made yet.</p>
                  ) : (
                    sortedOrders?.map((order) => {
                      const isDeletable = order.CanCancel;
                      return (
                        <div
                          className="orderDetails"
                          key={order.Id}
                          onClick={() => handleOrderClick(order.Id)}
                        >
                          <div className="row">
                            <div className="col-md-4 d-flex align-items-center justify-content-center">
                              <span className="cartBorder">
                                <RiShoppingBasketLine className="shopCart" />
                              </span>
                            </div>
                            <div className="col-md-7">
                              <p>Order id {order.SerialNumber}</p>
                              {order.OrderStatusId === 1 && (
                                <p className="new-order">
                                  {order.OrderStatusNameEn}
                                </p>
                              )}
                              {order.OrderStatusId === 2 && (
                                <p className="in-progress">
                                  {order.OrderStatusNameEn}
                                </p>
                              )}
                              {order.OrderStatusId === 3 && (
                                <p className="completed">
                                  {order.OrderStatusNameEn}
                                </p>
                              )}
                              {order.OrderStatusId === 4 && (
                                <p className="scheduled">
                                  {order.OrderStatusNameEn}
                                </p>
                              )}
                              {order.OrderStatusId === 5 && (
                                <p className="cancelled">
                                  {order.OrderStatusNameEn}
                                </p>
                              )}
                              <p className="d-flex align-items-center justify-content-between mb-0">
                                <span>
                                  {order.Total} {currency}
                                </span>
                                {order.CreatedDate === 0 ? null : (
                                  <span>
                                    <BsClock className="me-2" />
                                    {moment
                                      .unix(order.CreatedDate)
                                      .format("DD/MM/YYYY")}
                                    <br />
                                    Time:{" "}
                                    {moment
                                      .unix(order.CreatedDate)
                                      .format("hh:mm A")}
                                  </span>
                                )}
                              </p>
                            </div>
                            <div className="col-md-1">
                              {isDeletable ? (
                                <div
                                  className="img-wrapper deletable"
                                  onClick={() => handleDelete(order.Id)}
                                >
                                  <img
                                    src={Delete}
                                    alt="delete"
                                    className="mt-1"
                                  />
                                </div>
                              ) : (
                                <div className="img-wrapper disabled"></div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
              <div className="col-md-6">
                {isLoading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100vh",
                    }}
                  >
                    <DotLoader color={"#00EC85"} loading={isLoading} />
                  </div>
                ) : (
                  <div
                    className="orderedItems"
                    style={{ display: selectedOrder ? "block" : "none" }}
                  >
                    {/* {orderedItems.PaymentMethodId === 1 && (
                      <div className="orderPaymentStatusFail">
                        <p className="d-flex align-items-center justify-content-center gap-2 mb-2">
                          <IoWarningOutline />
                          <span className="fw-bold text-danger">
                            A Payment revision needed
                          </span>
                        </p>
                        <p className="my-2">
                          Please update your payment method to process your
                          order, Revise Payment Method
                        </p>
                        <button onClick={retryPayment}>
                          Revise payment method
                        </button>
                        {errPaymentMsg && (
                          <p className="invalid fw-bold">{errPaymentMsg}</p>
                        )}
                      </div>
                    )} */}

                    {orderedItems.PaymentMethodId === 2 &&
                      // Check if paymentStatusId is 2 (Success)
                      (orderedItems.PaymentStatusId === 2 ? (
                        <div className="orderPaymentStatusSuccess">
                          <p>
                            <HiCheckCircle />
                            <span className="fw-bold ms-2">
                              Payment information has been updated
                            </span>
                          </p>
                        </div>
                      ) : (
                        // Payment revision needed (Failure)
                        <div className="orderPaymentStatusFail">
                          <p className="d-flex align-items-center justify-content-center gap-2 mb-2">
                            <IoWarningOutline />
                            <span className="fw-bold text-danger">
                              A Payment revision needed
                            </span>
                          </p>
                          <p className="my-2">
                            Please update your payment method to process your
                            order, Revise Payment Method
                          </p>
                          <button onClick={retryPayment}>
                            Revise payment method
                          </button>
                          {errPaymentMsg && (
                            <p className="invalid fw-bold">{errPaymentMsg}</p>
                          )}
                        </div>
                      ))}
                    {orderedItems.PaymentMethodId === 3 &&
                      // Check if paymentStatusId is 2 (Success)
                      (orderedItems.PaymentStatusId === 2 ? (
                        <div className="orderPaymentStatusSuccess">
                          <p>
                            <HiCheckCircle />
                            <span className="fw-bold ms-2">
                              Payment information has been updated
                            </span>
                          </p>
                        </div>
                      ) : (
                        // Payment revision needed (Failure)
                        <div className="orderPaymentStatusFail">
                          <p className="d-flex align-items-center justify-content-center gap-2 mb-2">
                            <IoWarningOutline />
                            <span className="fw-bold text-danger">
                              A Payment revision needed
                            </span>
                          </p>
                          <p className="my-2">
                            Please update your payment method to process your
                            order, Revise Payment Method
                          </p>
                          <button onClick={retryPayment}>
                            Revise payment method
                          </button>
                          {errPaymentMsg && (
                            <p className="invalid fw-bold">{errPaymentMsg}</p>
                          )}
                        </div>
                      ))}
                    {Array.isArray(productsPerOrder) ? (
                      productsPerOrder?.map((item) => (
                        <div className="item" key={item.id}>
                          <p className="mb-0">Order Items</p>
                          <div className="item">
                            <div className="row">
                              <div className="col-md-3 img-background">
                                <div className="">
                                  <img
                                    src={item?.DefaultImageUrl}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 d-flex justify-content-start flex-column">
                                <p>{item.NameEn}</p>
                                {item.AttributeOptionEn && (
                                  <div className="d-flex justify-content-start align-items-start">
                                    <p className="mb-0 me-3">Color:</p>
                                    <p
                                      style={{
                                        backgroundColor: item.AttributeOptionEn,
                                        border: "1px solid palevioletred",
                                        marginBottom: "0",
                                        width: "26px",
                                        height: "26px",
                                        borderRadius: "50%",
                                      }}
                                    ></p>
                                  </div>
                                )}
                              </div>
                              <div className="col-md-3 d-flex align-items-center justify-content-center">
                                <p className="fw-bold">{item?.Count}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>No items found</p>
                    )}
                    <div className="orderTotal mt-4">
                      <div className="orderAddress">
                        <p className="fw-bold text-dark">Address</p>
                        <p className="p-3">
                          {orderedItems.Address?.BuildingNo},
                          <span>{orderedItems.Address?.Street},</span>
                          <span>{orderedItems.Address?.CityEn}</span>
                        </p>
                      </div>
                      <div className="orderPayment">
                        <p className="fw-bold text-dark">Payment Method</p>
                        <p className="p-3">
                          {orderedItems.PaymentMethodNameEn}
                        </p>
                        <div className="table-container">
                          <table>
                            <tbody>
                              <tr>
                                <td className="left-column text-muted">
                                  Sub-total
                                </td>
                                <td className="right-column">
                                  {orderedItems.Subtotal} {currency}
                                </td>
                              </tr>
                              <tr>
                                <td className="left-column text-muted">
                                  Voucher
                                </td>
                                <td className="right-column">
                                  {orderedItems.PromoDiscountAmount > 0
                                    ? ` ${orderedItems.PromoDiscountAmount} ${currency}`
                                    : "No voucher"}
                                </td>
                              </tr>
                              <tr>
                                <td className="left-column text-muted">
                                  Delivery Fees
                                </td>
                                <td className="right-column">
                                  {orderedItems.DeliveryFees} {currency}
                                </td>
                              </tr>

                              <tr>
                                <td colSpan="2">
                                  <hr className="divider" />
                                </td>
                              </tr>
                              <tr>
                                <td className="left-column fw-bold text-dark">
                                  Total
                                </td>
                                <td className="right-column fw-bold text-dark">
                                  {orderedItems.Total} {currency}
                                </td>
                              </tr>
                              <tr>
                                <td className="left-column text-muted">
                                  Discount
                                </td>
                                <td className="right-column">
                                  {orderedItems.Subtotal -
                                    orderedItems.Total +
                                    orderedItems.DeliveryFees}{" "}
                                  {currency}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer2 />
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm cancellation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to cancel this Order?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              deleteOrder(selectedServiceId);
              setShowDeleteModal(false);
            }}
          >
            Confirm
          </Button>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MyOrders;
