import { IoPersonOutline } from "react-icons/io5";
import { BsChevronRight } from "react-icons/bs";

import { getStatusColor } from "../../../utils/getStatusColor";

const UserRequestsCard = ({ request, selectedRequest, handleSelectedItem }) => {
  return (
    <div className="userRequestsCard d-flex flex-column justify-center">
      <div>
        <div className="d-flex flex-column flex-xxl-row justify-content-between flex-row">
          <p
            style={{
              color: "#03BE6C",
              fontWeight: "700",
              fontFamily: "Poppins",
            }}
          >
            {request.TitleEn}
          </p>

          <p
            style={{
              color: getStatusColor(request.RequestStatusId),
              fontWeight: "500",
              fontFamily: "Poppins",
            }}
          >
            {request.RequestStatusEn}
          </p>
        </div>

        <p
          style={{
            color: "#4D4D4D",
            fontSize: "0.95rem",
            fontWeight: "600",
            fontFamily: "Poppins",
          }}
        >
          <IoPersonOutline className="me-2" />

          {request.ExpertUserName}
        </p>
      </div>

      <div className="row">
        <div className="col-md-12">
          <button
            onClick={() => handleSelectedItem(request.Id, "request")}
            style={{
              width: "100%",
              backgroundColor: `${
                request.Id === selectedRequest?.Id ? "#03be6c" : ""
              }`,
              color: `${request.Id === selectedRequest?.Id ? "white" : ""}`,
            }}
          >
            Details
            {request.Id === selectedRequest?.Id && (
              <BsChevronRight className="ms-2" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserRequestsCard;
