import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  cartItemsC: {
    display: "flex",
    flexDirection: "column",
  },
  cartItems: {
    padding: "15px 30px 15px 30px",
    "@media (max-width: 600px)": {
      padding: "15px 5px",
    },
  },
  btns: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flush: {
    margin: "10px 0",
    alignSelf: "flex-end",
    color: "#0D6EFD !important",
    textTransform: "initial !important",
  },
  addCart: {
    background: "#000056",
    borderRadius: "8px",
    width: "50%",
    color: "#ffffff",
    textTransform: "initial !important",
    padding: "10px 0",
  }
});
