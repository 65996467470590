import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet-async";
import "../pages/Account/Contact-Us/ContactUs.css";
import NavbarExpert from "./NavbarExpert";
import Footer1 from "../components/Footer/Footer1";
import Footer2 from "../components/Footer/Footer2";
import { BreadCrumb } from "../components/BreadCrumb/index";
import { BsTelephone } from "react-icons/bs";
import { CiMail, CiLocationOn } from "react-icons/ci";
import axios from "axios";
import DotLoader from "react-spinners/DotLoader";

const ContactUsExpertView = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    company: "",
    email: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    phone: "",
    company: "",
    email: "",
    message: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoadingMain, setIsLoadingMain] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [apiInfo, setApiInfo] = useState([]);
  const mapRef = useRef(null);
  const [mapLink, setMapLink] = useState("");
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(true);

  const getContactInfo = async (e) => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/Home/ContactUsAddresses`)
        .then((res) => {
          setApiInfo(res.data.Data);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getContactInfo();
  }, []);

  useEffect(() => {
    if (apiInfo?.length > 0) {
      const mapInfo = apiInfo.find((info) => info.Type === 5);
      setMapLink(mapInfo ? mapInfo.Link : "");
    }
  }, [apiInfo]);

  const Spinner = () => {
    return <DotLoader color={"#00EC85"} loading={true} />;
  };

  const { name, phone, company, email, message } = formData;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });

    if (name === "name" && !isNaN(value)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        name: "Please enter a valid name.",
      }));
    }

    if (name === "phone" && isNaN(value)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        phone: "Please enter a valid phone number.",
      }));
    }

    if (name === "email") {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(value)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: "Please enter a valid email address.",
        }));
        setIsSendButtonDisabled(true);
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: "",
        }));
        setIsSendButtonDisabled(false);
      }
    }
  };

  const validateFormData = () => {
    let isValid = true;

    if (!name) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        name: "Please enter your name.",
      }));
      isValid = false;
    }

    if (!phone) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        phone: "Please enter your phone number.",
      }));
      isValid = false;
    }

    if (!company) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        company: "Please enter your company name.",
      }));
      isValid = false;
    }

    if (!email) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        email: "Please enter your email address.",
      }));
      isValid = false;
    } else {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(email)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: "Please enter a valid email address.",
        }));
        isValid = false;
      }
    }

    if (!message) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        message: "Please enter your message.",
      }));
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    setSuccessMessage("");
    setErrorMessage("");
    e.preventDefault();
    setIsLoading(true);
    if (!validateFormData()) {
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/Home/SubmitContactUsRequest`,
        {
          Name: name,
          Phone: phone,
          Company: company,
          Email: email,
          Message: message,
        }
      );

      setFormData({
        name: "",
        phone: "",
        company: "",
        email: "",
        message: "",
      });

      setSuccessMessage("Message received successfully!");
      setErrorMessage("");
    } catch (error) {
      setErrorMessage(
        "Error: Failed to send the message. Please try again later."
      );
      setSuccessMessage("");
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Simulating loading time
    setTimeout(() => {
      setIsLoadingMain(false);
    }, 2000);
  }, []);

  return (
    <>
      <Helmet>
        <title>Clicktick: Contact Us</title>
        <meta name="title" content="Contact Us" />
        <meta
          name="description"
          content="Contact with Clicktick for more information, or report issues, or for any request."
        />
        <meta
          name="keywords"
          content="Contact Us, Clicktick Customer Service, Clicktick Address, Clicktick Location, Clicktick Mobile, Clicktick Email"
        />
      </Helmet>

      <NavbarExpert />
      <BreadCrumb list={[{ name: "Contact Us", link: "" }]} />

      {isLoadingMain ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <DotLoader color={"#00EC85"} loading={isLoadingMain} />
        </div>
      ) : (
        <>
          <div className="container mb-5">
            <div className="contactUs">
              <div className="row">
                <div className="col-md-7">
                  <div
                    className="custWidth"
                    data-aos="zoom-in"
                    data-aos-duration="10000"
                    data-aos-once="true"
                  >
                    <form onSubmit={handleSubmit}>
                      <div className="row my-2">
                        <div className="col-md-6">
                          <input
                            type="text"
                            name="name"
                            value={name}
                            onChange={handleChange}
                            placeholder="Name"
                            className="w-100 first"
                            required
                          />
                          {formErrors.name && (
                            <p className="error-message">{formErrors.name}</p>
                          )}
                        </div>
                        <div className="col-md-6">
                          <input
                            type="tel"
                            name="phone"
                            value={phone}
                            onChange={handleChange}
                            placeholder="Phone"
                            className="w-100"
                            required
                          />
                          {formErrors.phone && (
                            <p className="error-message">{formErrors.phone}</p>
                          )}
                        </div>
                      </div>
                      <div className="d-flex justify-content-start flex-column ">
                        <input
                          type="text"
                          name="company"
                          value={company}
                          onChange={handleChange}
                          placeholder="Company"
                          className="my-2"
                          required
                        />
                        {formErrors.company && (
                          <p className="error-message">{formErrors.company}</p>
                        )}
                        <input
                          type="email"
                          name="email"
                          value={email}
                          onChange={handleChange}
                          placeholder="Email"
                          className="my-2"
                          required
                        />
                        {formErrors.email && (
                          <p className="error-message">{formErrors.email}</p>
                        )}
                      </div>
                      <textarea
                        name="message"
                        value={message}
                        onChange={handleChange}
                        placeholder="Message"
                        className="my-2 w-100"
                        required
                      ></textarea>
                      {formErrors.message && (
                        <p className="error-message">{formErrors.message}</p>
                      )}
                      <button
                        type="submit"
                        disabled={isLoading || isSendButtonDisabled}
                      >
                        {isLoading ? <Spinner /> : "Send"}
                      </button>
                    </form>

                    {/* Success message */}
                    {successMessage && (
                      <p className="success-message">{successMessage}</p>
                    )}
                    {/* Error message */}
                    {errorMessage && (
                      <p className="error-message">{errorMessage}</p>
                    )}
                  </div>
                </div>
                <div
                  className="col-md-5 p-2"
                  data-aos="zoom-out"
                  data-aos-duration="10000"
                  data-aos-once="true"
                >
                  <div className="contactInfo">
                    {apiInfo?.map((info) => {
                      return (
                        <>
                          <p key={info.Id}>
                            {info.Type === 1 && (
                              <div className="d-flex align-items-center mb-4">
                                <BsTelephone className="me-3" />
                                <p
                                  className="infoContact"
                                  dangerouslySetInnerHTML={{
                                    __html: `<div>${info.Link} <span className="infoContact">/ ${apiInfo[3]?.Link}</span></div>`,
                                  }}
                                ></p>
                              </div>
                            )}
                            {info.Type === 2 && (
                              <div className="d-flex align-items-center mb-4">
                                <CiMail className="me-3" />
                                <p
                                  className="infoContact"
                                  dangerouslySetInnerHTML={{
                                    __html: `<div>${info.Link}</div>`,
                                  }}
                                ></p>
                              </div>
                            )}
                            {info.Type === 3 && (
                              <div className="d-flex align-items-center mb-4">
                                <CiLocationOn
                                  className="me-3"
                                  style={{ fontSize: "2rem" }}
                                />
                                <p
                                  className="infoContact"
                                  dangerouslySetInnerHTML={{
                                    __html: `<div>${info.Link}</div>`,
                                  }}
                                ></p>
                              </div>
                            )}
                          </p>
                        </>
                      );
                    })}

                    <div>
                      <iframe
                        style={{
                          display: "block",
                          width: "100%",
                          maxWidth: "400px",
                          height: "300px",
                          margin: "2rem auto",
                        }}
                        src="https://www.google.com/maps?q=30.0133399963379,31.4140243530273&amp;output=embed"
                        referrerPolicy="no-referrer"
                        title="map"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer1
            backgroundColor="#303030"
            colorWhite="#FFFFFF"
            colorGreen="rgba(0, 237, 133, 1)"
          />
          <Footer2 />
        </>
      )}
    </>
  );
};

export default ContactUsExpertView;
