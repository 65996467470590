import {
  collection,
  doc,
  setDoc,
  deleteDoc,
  getDocs,
  writeBatch,
} from "firebase/firestore";
import { db } from "../firebase";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

export const sendMessageToFirebase = async ({
  collectionName,
  documentName,
  documentData,
  secondDocumentName,
  secondDocumentData,
}) => {
  try {
    const adminChatsCollectionRef = collection(db, collectionName);

    const adminChatsDocumentRef = doc(adminChatsCollectionRef, documentName);

    await setDoc(adminChatsDocumentRef, documentData);

    const chatCollectionRef = collection(
      adminChatsDocumentRef,
      secondDocumentName
    );

    const newChatDocumentRef = doc(chatCollectionRef);

    await setDoc(newChatDocumentRef, secondDocumentData);
  } catch (error) {
    console.log(error);
  }
};

export const sendFileToFireBase = async (file) => {
  try {
    const fileName = Math.random() * 1000000000 + "_" + file.name;
    const storage = getStorage();
    const storageRef = ref(storage, "files/" + fileName);

    // Upload the file to Firebase Storage
    const uploadTask = await uploadBytes(storageRef, file);
    return await getDownloadURL(uploadTask.ref);
  } catch (error) {
    console.log(error);
  }
};

export const removeDoc = async (collectionName, documentName) => {
  try {
    const CollectionRef = collection(db, collectionName);
    const DocumentRef = doc(CollectionRef, documentName);
    const chatCollectionRef = collection(DocumentRef, "chat");

    const querySnapshot = await getDocs(chatCollectionRef);

    querySnapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref);
    });

    console.log('All documents in the "chat" subcollection have been deleted.');
  } catch (error) {
    console.error("Error removing document:", error);
  }
};

export const updateChatDocsVisibility = async (
  collectionName,
  documentName
) => {
  try {
    const CollectionRef = collection(db, collectionName);
    const DocumentRef = doc(CollectionRef, documentName);
    const chatCollectionRef = collection(DocumentRef, "chat");

    const querySnapshot = await getDocs(chatCollectionRef);
    const AdminQuerySnapshot = await getDocs(CollectionRef);

    const batch = writeBatch(db);

    AdminQuerySnapshot.forEach((doc) => {
      const docRef = doc.ref;
      batch.update(docRef, { isFinished: true });
    });

    querySnapshot.forEach((doc) => {
      const docRef = doc.ref;
      batch.update(docRef, { isVisible: false });
    });

    

    await batch.commit();

    console.log(
      'Visibility attribute updated for all documents in the "chat" subcollection.'
    );
  } catch (error) {
    console.error("Error updating document visibility:", error);
  }
};
