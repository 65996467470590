import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useStyle } from "./style";
import axios from "axios";
import ShopItem from "../../components/ShopItem/ShopItem";
import { isEmpty, map } from "lodash";
import OwlCarousel from "react-owl-carousel";
import DotLoader from "react-spinners/DotLoader";

const SecondPart = ({ product }) => {
  const classes = useStyle();
  const [recProducts, setRecProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const recomendedProductsSlider = {
    items: 3,
    center: false,
    margin: 20,
    responsiveClass: true,
    nav: false,
    autoplay: true,
    navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 2,
      },
      768: {
        items: 2,
        center: true,
      },
      1000: {
        items: 3,
      },
    },
  };

  const homeRec = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/Product/RecommendedProducts?ProductId=${product.Id}&PageSize=10`
      )
      .then((res) => {
        setRecProducts(res.data.Data);
        setIsLoading(false);
        window.scrollTo({ top: 0, behavior: "smooth" });
      });
  };
  useEffect(() => {
    homeRec();
  }, []);

  return isLoading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        // alignItems: "center",
        height: "100vh",
      }}
    >
      <DotLoader color={"#00EC85"} loading={isLoading} />
    </div>
  ) : (
    <Grid container spacing={3}>
      <div className={classes.SecondDiv}>
        <p> Description of product</p>
        <p
          dangerouslySetInnerHTML={{
            __html: `<div>${product.DescriptionEn}</div>`,
          }}
        ></p>
        <div className="Attr">
          <p> Key Specifications</p>
          {map(product.AttributeWithOptions, (attr) => {
            return (
              <div>
                <span>{`${attr.AttributeNameEn} : `}</span>
                <span>{attr.OptionNameEn}</span>
              </div>
            );
          })}
        </div>
      </div>

      <div className="col-md-12" style={{ overflowX: "hidden" }}>
        {!isEmpty(recProducts) && (
          <p className={classes.recommend}>Recommended</p>
        )}
        <OwlCarousel
          className="owl-theme"
          style={{ marginBottom: "3%" }}
          margin={10}
          {...recomendedProductsSlider}
        >
          {recProducts?.map((recommended) => {
            return <ShopItem item={recommended} key={recommended.Id} />;
          })}
        </OwlCarousel>
        {/* <Slider content={recProducts} child={ShopItem} /> */}
      </div>
    </Grid>
  );
};
export default SecondPart;
