import React, { useState, useEffect, useContext } from 'react';
import './Navbar.css';
import Logo from '../../assests/img/logo.png';
import { Link, useLocation } from 'react-router-dom';
import ProfilePic from '../../assests/img/profile.png';
import ComingSoonImg from '../../assests/img/coming-soon.png';
import Favorite from '../../assests/img/fav.png';
import Cart from '../../assests/img/cart.png';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import { find } from 'lodash';
import Clicktick from '../../context/context';
import axios from 'axios';

const Navbar = () => {
  const [addClass, setAddClass] = useState(false);
  const [countryCodes, setCountryCodes] = useState([]);
  const location = useLocation();
  const [url, setUrl] = useState(null);
  const [active, setActive] = useState();
  const [tokenvalue, setsessionn] = useState(false);
  const [show, setShow] = useState(false);

  const { cart, setUser } = useContext(Clicktick);

  const CountryId = localStorage.getItem('CountryId');

  const handleClose = () => {
    // if (!active?.IsActive) {
    //   navigate("/coming-soon");
    // } else if (active.IsActive) navigate("/");

    localStorage.setItem('CountryId', active?.Id || 3);
    setShow(false);
    // navigate("/",{reload:true, replace:true});

    window.location.reload();
  };

  const handleShow = () => setShow(true);

  const logoutFunc = () => {
    localStorage.setItem('token', 'loggedOut');
    localStorage.removeItem('CountryId', active?.Id);
    localStorage.removeItem('username');
    localStorage.removeItem('profilePic');
    localStorage.removeItem('email');
    localStorage.removeItem('role');
    setsessionn('loggedOut');
    setUser(null);
  };

  const AddClassSlide = () => {
    setAddClass(!addClass);
  };

  const handleChange = (code) => {
    setActive(code);
  };

  const getCountryCodes = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/Account/CountryCodes`)
      .then((res) => setCountryCodes(res.data.Data))
      .catch((err) => console.log(err));
  };

  // Check if the token is already set in localStorage
  if (!localStorage.getItem('token')) {
    // If not set, add the token with value "loggedOut"
    localStorage.setItem('token', 'loggedOut');
  }

  const setCurrencyInLocalStorage = () => {
    if (!CountryId) {
      localStorage.setItem('currency', 'EGP');
    } else {
      const selectedCountry = countryCodes.find(
        (country) => country?.Id.toString() === CountryId
      );

      if (selectedCountry) {
        localStorage.setItem('currency', selectedCountry.CurrencyEn);
      }
    }
  };

  useEffect(() => {
    const tokenString = localStorage.getItem('token');
    setsessionn(tokenString);
  }, []);

  useEffect(() => {
    const flag = find(countryCodes, (c) => c?.Id == CountryId);
    setActive(flag);
  }, [countryCodes]);

  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  useEffect(() => {
    getCountryCodes();
  }, []);

  useEffect(() => {
    setCurrencyInLocalStorage();
  }, [CountryId, countryCodes]);

  return (
    <nav className='navbar navbar-expand-lg navbar-light bg-white maxHeight p0Mob position-relative'>
      <div className='container-fluid p-0Mob'>
        <div>
          <Link className='navbar-brand ms-2' to='/'>
            <img src={Logo} alt='logo' className='logoImgMob' />
          </Link>
        </div>

        <button
          className='navbar-toggler me-3 right-Mob'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div
          className='collapse navbar-collapse bg-white navBarCust'
          id='navbarSupportedContent'
        >
          <ul
            className='navbar-nav ms-4Mob mb-2 mb-lg-0 gap15'
            onClick={AddClassSlide}
          >
            <li className='nav-item'>
              <Link
                to='/'
                aria-current='page'
                className={url === '/' ? ' nav-link active' : 'nav-link'}
              >
                Home
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={url === '/shop' ? ' nav-link active' : 'nav-link'}
                to='/shop'
              >
                Shop
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  url === '/services' ? ' nav-link active' : 'nav-link'
                }
                to='/services'
              >
                Services
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={url === '/experts' ? ' nav-link active' : 'nav-link'}
                to='/experts'
              >
                Experts
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={url === '/careers' ? ' nav-link active' : 'nav-link'}
                to='/careers'
              >
                Careers
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  url === '/contact-us' ? ' nav-link active' : 'nav-link'
                }
                to='/contact-us'
              >
                Contact Us
              </Link>
            </li>
          </ul>

          <div className='d-flex justify-content-center align-items-center'>
            {tokenvalue === 'loggedOut' || false ? (
              <ul className='list-unstyled d-flex align-items-center justify-conent-center mb-0 Mob-Flex-Column'>
                <li className='nav-item d-flex align-items-center justify-conent-center'>
                  <p className='m-0 me-2 deliverTo'>Deliver to</p>
                  <Button
                    variant='primary'
                    onClick={handleShow}
                    className='ms-1 navbarDeliverBtn'
                  >
                    <img
                      src={active?.FlagUrl || countryCodes[0]?.FlagUrl}
                      alt=''
                      height={16}
                      // className="me-2 w-10"
                    />
                    {/* <Flag code="eg" height="16" /> */}
                  </Button>

                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header
                      closeButton
                      className='border-none'
                    ></Modal.Header>

                    <Modal.Body className='text-center '>
                      Choose your delivery location
                      <Dropdown className='mt-3'>
                        <Dropdown.Toggle
                          id='dropdown-basic'
                          className='deliverToBtn'
                        >
                          {active?.CountryEn || countryCodes[0]?.CountryEn}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {countryCodes?.map((code) => {
                            return (
                              <Dropdown.Item
                                key={code?.Id}
                                onClick={() => {
                                  if (code.IsActive) {
                                    handleChange(code);
                                  }
                                }}
                                disabled={!code?.IsActive}
                              >
                                <img
                                  src={code?.FlagUrl}
                                  alt=''
                                  className='me-2 w-10 img-fluid'
                                />
                                {`${code?.CountryEn}`}

                                {!code.IsActive && (
                                  <span className='ms-2'>
                                    <img
                                      src={ComingSoonImg}
                                      alt='coming soon'
                                      className='img-fluid'
                                      style={{
                                        width: '4rem',
                                        height: '100%',
                                        objectFit: 'cover',
                                      }}
                                    />
                                  </span>
                                )}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Modal.Body>

                    <Modal.Footer className='d-flex align-items-center justify-content-center border-none'>
                      <Button variant='success' onClick={handleClose}>
                        Confirm
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </li>
                <li className='nav-item mt-Mob mb-Mob'>
                  <Link className='nav-link' to='/loginuser'>
                    Login
                  </Link>
                </li>
              </ul>
            ) : (
              <div className='d-flex align-items-center gap10 iconsMob'>
                <ul className='list-unstyled d-flex align-items-center mb-0 navbar-nav ms-4Mob mb-2 mb-lg-0 cusNav'>
                  <li className='nav-item d-flex align-items-center justify-conent-center'>
                    <p className='m-0 me-2 '>Deliver to</p>
                    <Button
                      variant='primary'
                      onClick={handleShow}
                      className='ms-1 navbarDeliverBtn'
                    >
                      <img
                        src={active?.FlagUrl || countryCodes[0]?.FlagUrl}
                        alt=''
                        height={16}
                      />
                    </Button>

                    <Modal show={show} onHide={handleClose}>
                      <Modal.Header closeButton></Modal.Header>

                      <Modal.Body className='text-center'>
                        Choose your delivery location
                        <Dropdown className='mt-3'>
                          <Dropdown.Toggle id='dropdown-basic'>
                            {active?.CountryEn || countryCodes[0]?.CountryEn}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {countryCodes?.map((code) => {
                              return (
                                <Dropdown.Item
                                  key={code?.Id}
                                  onClick={() => {
                                    if (code.IsActive) {
                                      handleChange(code);
                                    }
                                  }}
                                  disabled={!code.IsActive}
                                >
                                  <img
                                    src={code.FlagUrl}
                                    alt=''
                                    className='me-2 w-10 img-fluid'
                                  />
                                  {`${code.CountryEn}`}

                                  {!code.IsActive && (
                                    <span className='ms-2'>
                                      <img
                                        src={ComingSoonImg}
                                        alt='coming soon'
                                        className='img-fluid'
                                        style={{
                                          width: '4rem',
                                          height: '100%',
                                          objectFit: 'cover',
                                        }}
                                      />
                                    </span>
                                  )}
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Modal.Body>

                      <Modal.Footer className='d-flex align-items-center justify-content-center'>
                        <Button variant='success' onClick={handleClose}>
                          Confirm
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </li>

                  <li className='nav-item'>
                    <Link className='nav-link' to='/wishlist'>
                      <img src={Favorite} alt='Favorite' />
                    </Link>
                  </li>
                  <li className='nav-item position-relative'>
                    <Link className='nav-link cart' to='/cart'>
                      <img src={Cart} alt='Cart' />
                      <span className='cartNumber'>{cart?.length}</span>
                    </Link>
                  </li>
                  <li className='nav-item dropdown'>
                    <Link
                      to='/'
                      className='nav-link dropdown-toggle'
                      data-bs-toggle='dropdown'
                    >
                      <img
                        src={ProfilePic}
                        alt='Profile'
                        className='profilePic'
                      />
                    </Link>
                    <div className='dropdown-menu dropdown-menu-end'>
                      <Link to='/edit-profile' className='dropdown-item'>
                        Edit Profile
                      </Link>
                      {/* <Link
                        to="/change-password"
                        className="dropdown-item"
                      >
                        Change Password
                      </Link> */}
                      <Link to='/orders' className='dropdown-item'>
                        My Orders
                      </Link>
                      <Link to='/my-services' className='dropdown-item'>
                        My Services
                      </Link>
                      {/* <Link to="/expert-requests?type=requests" className="dropdown-item">
                        My Requests
                      </Link> */}
                      <Link to='/my-address' className='dropdown-item'>
                        My Addresses
                      </Link>
                      <Link to='/notifications' className='dropdown-item'>
                        Notification
                      </Link>
                      <div className='dropdown-divider'></div>
                      <Link
                        to='/LoginUser'
                        className='dropdown-item mb-0'
                        onClick={logoutFunc}
                      >
                        Logout
                      </Link>
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>

        {/* <div></div> */}
      </div>
    </nav>
  );
};

export default Navbar;
