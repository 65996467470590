import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HashLoader } from "react-spinners";

import { MdClear } from "react-icons/md";
import { BsPlus } from "react-icons/bs";
import Thumb from "../assests/img/3dicons.png";
import Logo from "../assests/img/logo.png";
import axios from "axios";

import "./ExpertView.css";

const ExpertCompleteProfile = () => {
  const [services, setServices] = useState([]);
  const [codes, setCodes] = useState([]);
  const [titles, setTitles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [titleIdError, setTitleIdError] = useState("");
  const [countryIdError, setCountryIdError] = useState("");
  const [certificateError, setCertificateError] = useState("");
  const [cvError, setCvError] = useState("");
  const [idUrlError, setIdUrlError] = useState("");
  const [selectedServicesIds, setSelectedServicesIds] = useState([]);
  const [selectedServiceId, setSelectedServiceId] = useState("");
  const navigate = useNavigate();

  const getServices = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/Service/ExpertServices`
      );
      setServices(res.data.Data);
    } catch (error) {
      console.error("Error fetching services", error);
    }
  };

  const getCodes = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/Account/CountryCodes`
      );
      setCodes(res.data.Data);
    } catch (error) {
      console.error("Error fetching country codes", error);
    }
  };

  const getTitles = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/ExpertView/GetExpertTitles`
      );
      setTitles(res.data.Data);
    } catch (error) {
      console.error("Error fetching titles", error);
    }
  };

  useEffect(() => {
    Promise.all([getServices(), getCodes(), getTitles()]).then(() => {
      // setIsLoading(false);
    });
  }, []);

  const userName = localStorage.getItem("username");

  const handleSubmit = async () => {
    setIsLoading(true);
    setTitleIdError(false);
    setCountryIdError(false);
    setCertificateError("");
    setCvError("");
    setIdUrlError("");

    const titleId = document.getElementById("selectServicesTitle").value;
    const about = document.getElementById("about").value;
    const countryId = document.getElementById("selectCountry").value;
    const hourRate = document.getElementById("hourRate").value || 150;
    const cvFile = document.getElementById("cvFile").files[0];
    const certificationsFiles = document.getElementById(
      "certificationsFiles"
    ).files;
    const idUrl = document.getElementById("idUrl").files[0];

    let isValid = true;

    if (!titleId) {
      isValid = false;
      setTitleIdError(true);
    }

    if (!countryId) {
      isValid = false;
      setCountryIdError(true);
    }

    if (!cvFile) {
      isValid = false;
      setCvError("CV Required");
    }

    if (!certificationsFiles[0]) {
      isValid = false;
      setCertificateError("Certificates Required");
    }

    if (!idUrl) {
      isValid = false;
      setIdUrlError("National Id Required");
    }

    if (cvFile) {
      const allowedFileTypes = [
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
      ];
      if (!allowedFileTypes.includes(cvFile.type)) {
        setCvError("CV must be in Word or PDF format.");
        isValid = false;
      }
    }
    const imageTypes = ["image/jpeg", "image/png"];

    if (certificationsFiles?.length > 0) {
      for (let i = 0; i < certificationsFiles.length; i++) {
        if (!imageTypes.includes(certificationsFiles[i].type)) {
          setCertificateError("Certificates must be in JPG or PNG format.");
          isValid = false;
        }
      }
    }

    if (idUrl?.length > 0) {
      for (let i = 0; i < idUrl.length; i++) {
        if (!imageTypes.includes(idUrl[i].type)) {
          setIdUrlError("National Id  must be in JPG or PNG format.");
          isValid = false;
        }
      }
    }

    if (!isValid) {
      setIsLoading(false);
      return;
    }

    const formData = new FormData();

    formData.append("TitleId", titleId);
    selectedServicesIds.forEach((value) => {
      formData.append("ServiceIds", value);
    });
    formData.append("HourRate", hourRate);
    formData.append("About", about);
    formData.append("CountryId", countryId);
    formData.append("IdURL", idUrl);
    formData.append("CvURL", cvFile);
    for (let i = 0; i < certificationsFiles.length; i++) {
      formData.append("Certifications", certificationsFiles[i]);
    }

    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/ExpertView/CompleteExpertProfile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Request success", response.data);
      navigate("/");
    } catch (error) {
      console.error("Request error", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="expertCompleteProfile min-vh-100">
        {/* {isLoading ? (
          <div className="loading-spinner d-flex justify-content-center align-items-center">
            <HashLoader size={50} color={"#03BE6C"} loading={isLoading} />
          </div>
        ) : ( */}
        <div className="row min-vh-100">
          <div className="col-md-4">
            <div className="left-green-bg min-vh-100">
              <img src={Thumb} alt="thump up" className="img-fluid" />
            </div>
          </div>
          <div className="col-md-8">
            <div className="right-expertSide">
              <div className="d-flex align-items-center justify-content-center">
                <img src={Logo} alt="expert" className="img-fluid" />
              </div>
              <p className="expertCompleteP">
                <span>Complete</span> Your Profile
              </p>
              <p className="expertCompleteP2">Complete with your data</p>
            </div>
            <div className="row px-4">
              <div className="col-md-6">
                <div className="d-flex flex-column mt-3">
                  <label htmlFor="inputField">Name</label>
                  <input
                    type="text"
                    id="inputField"
                    name="inputField"
                    value={userName}
                    disabled
                  />
                </div>
                <div className="d-flex flex-column mt-3">
                  <label htmlFor="selectServicesTitle">Select your Title</label>
                  <select
                    id="selectServicesTitle"
                    name="selectServicesTitle"
                    className={titleIdError ? "error" : ""}
                  >
                    <option value="">Select a title</option>
                    {titles?.map((title) => (
                      <option key={title.Id} value={title.Id}>
                        {title.NameEn}
                      </option>
                    ))}
                  </select>
                  {titleIdError && (
                    <span className="error-message">Title required</span>
                  )}
                </div>
                <div className="d-flex flex-column mt-3">
                  <label htmlFor="selectServicesServices">
                    Select your Services
                  </label>
                  <div
                    id="selectServicesServices"
                    name="selectServicesServices"
                  >
                    {/* {services?.map((service) => (
                        <div
                          key={service.Id}
                          value={service.Id}
                          style={
                            selectedServicesIds?.includes(service.Id)
                              ? { background: "#1967d2", color: "white" }
                              : {}
                          }
                          onClick={() => {
                            console.log(selectedServicesIds, service.Id);
                            setSelectedServicesIds((prevState) => {
                              if (prevState.includes(service.Id)) {
                                return prevState.filter(
                                  (serviceId) => serviceId !== service.Id
                                );
                              } else {
                                return [...prevState, service.Id];
                              }
                            });
                          }}
                        >
                          {service.NameEn}
                        </div>
                      ))} */}
                    <select
                      className={titleIdError ? "error" : ""}
                      value={selectedServiceId}
                      onChange={(event) => {
                        setSelectedServicesIds((prevState) => {
                          if (prevState.includes(Number(event.target.value))) {
                            return prevState;
                            // prevState.filter(
                            //   (serviceId) =>
                            //     serviceId !== Number(event.target.value)
                            // );
                          } else {
                            return [...prevState, Number(event.target.value)];
                          }
                        });
                      }}
                    >
                      <option value="">Select your Services</option>
                      {services?.map((service) => (
                        <option key={service.Id} value={service.Id}>
                          {service.NameEn}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="d-flex flex-wrap mt-3 gap-2">
                    {selectedServicesIds.length === 0 && (
                      <span className="text-danger">Add Some Services</span>
                    )}
                    {selectedServicesIds?.map((serviceId) => {
                      const selectedService = services.find(
                        (s) => s.Id === serviceId
                      );
                      return (
                        <span
                          className="p-2 rounded"
                          style={{ border: "1px solid #03BE6C" }}
                        >
                          {selectedService?.NameEn}
                          <MdClear
                            style={{ cursor: "pointer",marginLeft:"5px" }}
                            color="red"
                            onClick={() => {
                              setSelectedServicesIds((prevState) =>
                                prevState.filter(
                                  (selectedServiceId) =>
                                    selectedServiceId !== selectedService.Id
                                )
                              );
                            }}
                          />
                        </span>
                      );
                    })}
                  </div>
                </div>

                <div className="d-flex flex-column mt-3">
                  <label htmlFor="about">About</label>
                  <textarea
                    id="about"
                    name="about"
                    cols="30"
                    rows="3"
                    placeholder="There is a problem in our Hardware and we need to fix it."
                  ></textarea>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column mt-3">
                  <label htmlFor="selectCountry">Country </label>
                  <select
                    id="selectCountry"
                    name="selectCountry"
                    className={countryIdError ? "error" : ""}
                  >
                    <option value="">Select a Country</option>
                    {codes?.map((code) => (
                      <option key={code.Id} value={code.Id}>
                        {code.CountryEn}
                      </option>
                    ))}
                  </select>
                  {countryIdError && (
                    <span className="error-message">Country is required</span>
                  )}
                </div>
                <div className="d-flex flex-column mt-3">
                  <label htmlFor="hourRate">Cost per hour</label>
                  <input
                    type="number"
                    id="hourRate"
                    name="hourRate"
                    placeholder="150 EGP"
                  />
                </div>
                <div className="d-flex flex-column mt-3">
                  <span className="file-label">
                    Upload your CV (Word or PDF)
                  </span>
                  <label htmlFor="cvFile" className="custom-file-input-label">
                    <BsPlus size={5} />

                    <input type="file" id="cvFile" name="cvFile" />
                  </label>
                  {cvError && <span className="error-message">{cvError}</span>}
                </div>
                <div className="d-flex flex-column mt-3">
                  <span className="file-label">
                    Upload your Certificates (JPG or PNG)
                  </span>
                  <label
                    htmlFor="certificationsFiles"
                    className="custom-file-input-label"
                  >
                    <BsPlus />
                    <input
                      type="file"
                      id="certificationsFiles"
                      name="certificationsFiles"
                      multiple
                    />
                  </label>
                  {/* <label
                    className="custom-file-input-label"
                    htmlFor="certificationsFiles"
                  >
                    <BsPlus />
                    <div className="d-flex align-items-start justify-content-start flex-column">
                      <span
                        style={{
                          color:
                            certificates.length > 0 ? "#03BE6C" : "#545454",
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                      >
                        {certificates.length > 0
                          ? "File Uploaded!"
                          : "Upload Certificates"}
                      </span>

                      <span
                        style={{
                          color: "#545454",
                          fontSize: "14px",
                        }}
                      >
                        {certificates[certificates.length - 1]?.name ||
                          "Upload more certificates"}
                      </span>
                    </div>

                    {certificates.length > 0 ? (
                      <img
                        src={DelIcon}
                        alt="delete icon"
                        className="img-fluid"
                        // onClick={() => {
                        //   setCertificates([]);
                        //   fileInputRef.current.value = null;
                        // }}
                      />
                    ) : null}

                    <input
                      // ref={fileInputRef}

                      id="certificationsFiles"
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={(e) =>
                        setCertificates((prevState) => [
                          ...prevState,
                          e.target.files[0],
                        ])
                      }
                    />
                  </label> */}
                  {certificateError && (
                    <span className="error-message">{certificateError}</span>
                  )}
                </div>
                <div className="d-flex flex-column mt-3">
                  <span className="file-label">
                    Upload your National Id (JPG or PNG)
                  </span>
                  <label htmlFor="idUrl" className="custom-file-input-label">
                    <BsPlus />

                    <input
                      type="file"
                      id="idUrl"
                      name="idUrl"
                      accept="image/*"
                    />
                  </label>
                  {idUrlError && (
                    <span className="error-message">{idUrlError}</span>
                  )}
                </div>
                <button
                  className="expertCompleteBtn"
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <HashLoader
                      size={20}
                      color={"#03BE6C"}
                      loading={isLoading}
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* )} */}
      </div>
    </>
  );
};

export default ExpertCompleteProfile;
