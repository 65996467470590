import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
	checkout: {
		alignItems:"baseline",
    //   "& .left":{
	// 	border: "1px solid #DEE2E7",

	//   },
	  "& .leftContainer":{
		border: "1px solid #DEE2E7",
		borderRadius: "6px"

	  }
	},

		
});
