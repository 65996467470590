import React, { useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Button, Container, Typography } from "@material-ui/core";
import { useStyle } from "./style";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Slider from "./Slider";
import SmallImage from "./smallImage";
import QuantityButton from "./QuantityBtn";
import { BreadCrumb } from "../../components/BreadCrumb";
import { find, isEmpty, isNull, map } from "lodash";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { Link, useNavigate, useParams } from "react-router-dom";
import Check from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import True from "../../assests/img/true.png";
import BTrue from "../../assests/icons/blackTrue.svg";
import Clicktick from "../../context/context";
import SecondPart from "./secondPart";
import Favorite from "../../assests/img/fav.png";
import Navbar from "../../components/Navbar/Navbar";
import { toast } from "react-toastify";
import DotLoader from "react-spinners/DotLoader";
import Footer2 from "../../components/Footer/Footer2";
import Footer1 from "../../components/Footer/Footer1";
import { Helmet } from "react-helmet-async";
import Valu from "../../assests/img/valu.svg";
import Paymob from "../../assests/img/paymob.png";
import Aman from "../../assests/img/amanLogo.svg";
import Forsa from "../../assests/img/forsaLogo.svg";
import MasterCard from "../../assests/img/MasterCardLogo.svg";
import VisaLogo from "../../assests/img/visaLogo.svg";
import SiteLogo from "../../assests/img/favicon.ico";
import DeliveryTruck from "../../assests/img/DeliveryTruck.png";
import PackageImg from "../../assests/img/Package.png";
import axios from "axios";

export default function ProductDetails(props) {
  const [product, setProduct] = useState({});
  const [isOpenBox, setOpenBox] = useState(false);
  const [count, setCount] = useState(1);
  const [state, setState] = useState([]);
  const [images, setImages] = useState([]);
  const [allImages, setAllImages] = useState([]);
  const [activeImg, SetAcImg] = useState();
  const [index, setIndex] = useState(allImages?.indexOf(activeImg));
  const [activeColor, SetActiveColor] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const { Id } = useParams();

  const { setNotify, cart } = useContext(Clicktick);
  const [fav, setFav] = useState(product.IsFavorite);
  const navigate = useNavigate();
  const classes = useStyle();
  const colorId = localStorage.getItem("color");
  const handleFavourite = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const token = localStorage.getItem("token");
    if (!token || token === "loggedOut") {
      toast.warn("Please log in first!");
      setTimeout(() => {
        navigate("/loginuser");
      }, 1000);
      return;
    }
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/Product/ChangeToFavorite`, {
        ProductCountryId: product?.ProductCountryId,
        ProductAttributeId: activeColor?.ProductAttributeId || 0,
      })
      .then((res) => {
        setNotify({
          open: true,
          message: "Item Changed Successfully",
          severity: "success",
        });
        setIsLoading(false);
      })
      .then(() => setFav(!fav))
      .catch((err) => console.log(err));
  };

  window.addEventListener("popstate", () => {
    window.location.reload();

    // navigate(-1);
  });
  useEffect(() => {
    setFav(product.IsFavorite);
  }, [product]);

  useEffect(() => {
    const getProduct = async () => {
      setIsLoading(true);

      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/Product/ProductDetails?ProductId=${Id}`
        );
        setIsLoading(false);

        setProduct({ ...product, ...res.data.Data });
        const ac = find(
          res.data.Data.Colors,
          (v) => v.ProductAttributeId == colorId
        );
        SetActiveColor(ac || res.data.Data.Colors[0]);
        setTotal(ac?.TotalPrice);
        let smalls = map(
          ac.ProductAttributeImages || res.data.Data?.ProductImages,
          (img) => img.ImageUrl != undefined && img?.ImageUrl
        );
        setImages([...smalls]);
        if (
          !isEmpty(ac.ProductAttributeImages) &&
          !isNull(ac.ProductAttributeImages)
        )
          setAllImages([...smalls]);
        else setAllImages([...smalls]);
      } catch (err) {
        setIsLoading(false);
        console.log(err);
      }
    };
    getProduct();
  }, [navigate]);

  useEffect(() => {
    let smalls = map(
      !isEmpty(activeColor?.ProductAttributeImages)
        ? activeColor?.ProductAttributeImages
        : product?.ProductImages,
      (img) => img?.ImageUrl != undefined && img?.ImageUrl
    );
    setImages([...smalls]);
    if (activeColor?.ProductAttributeImages) setAllImages([...smalls]);
    else setAllImages([...smalls]);
    setTotal(activeColor?.TotalPrice);
  }, [activeColor]);

  const checkIFExist = (value) => {
    const e = (obj) => obj.values.includes(value);
    const check = state.some(e);
    return check;
  };

  const stateChanges = (key, value) => {
    const i = state?.findIndex((_item) => _item.id === key);
    if (i !== -1) {
      let items = [...state];
      let item = { ...items[i] };
      item.values = [value];
      items[i] = item;
      setState(items);
    } else {
      let items = [...state];
      items?.push({ id: key, values: [value] });
      setState(items);
    }
  };

  const existed = find(
    cart,
    (c) =>
      c.Id === product.Id &&
      c?.ProductAttributeId === product?.ProductAttributeId
  );

  const activeIt = (item) => {
    SetAcImg(item);
    setIndex(allImages?.indexOf(item));
    setOpenBox(true);
  };

  const sendToCart = (e) => {
    let newCount = count;
    if (existed) {
      newCount = existed.Count + count;
    }
    const token = localStorage.getItem("token");
    if (!token || token === "loggedOut") {
      toast.warn("Please log in first!");
      setTimeout(() => {
        navigate("/loginuser");
      }, 1000);
      return;
    }
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/Order/AddItemCart`, {
        ProductCountryId: product?.ProductCountryId,
        Count: newCount,
        ProductAttributeId: activeColor?.ProductAttributeId,
      })
      .then((res) => {
        navigate(`/cart`);
        setNotify({
          open: true,
          message: "Item Added Successfully",
          severity: "success",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  let currency = localStorage.getItem("currency");
  let pageTitle = product?.NameEn || "Product";

  const handleSendNotification = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const token = localStorage.getItem("token");
    if (!token || token === "loggedOut") {
      toast.warn("Please log in first!");
      setTimeout(() => {
        navigate("/loginuser");
      }, 1000);
      return;
    }
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/Product/SubmitStockNotificationRequest`,
        {
          ProductCountryId: product?.ProductCountryId,
          ProductAttributeId: activeColor?.ProductAttributeId,
        }
      )
      .then((res) => {
        setIsLoading(false);
        toast.success(
          "You will receive a notification when the product is available"
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

  return (
    <>
      <Helmet>
        <title>{`${product.NameEn || "Product"}`} | Clicktick </title>
        <link rel="icon" type="image/png" href={SiteLogo} />
        <meta
          name="title"
          content={`${product.NameEn || "product"} | Clicktick `}
        />
        <meta
          name="description"
          content={`Clicktick Online shopping for ${
            product?.NameEn || "Product"
          } Best Prices, Secure Payment, Easy Returns, Cash and installments | Shop Now!`}
        />
        <meta
          name="keywords"
          content={product?.URLName + ", Clicktick, Click tick"}
        />
        <link
          rel="canonical"
          href={`https://clicktick.app/productDetails/${product?.URLName}/${product?.Id}`}
        />
      </Helmet>
      <Navbar />
      <BreadCrumb
        list={[
          { name: "Shop", link: "/shop" },
          { name: product?.NameEn || "product" },
        ]}
      />

      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <DotLoader color={"#00EC85"} loading={isLoading} />
        </div>
      ) : (
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid item className={classes.mainImg}>
                <div>
                  <img
                    src={
                      !isNull(activeColor?.ProductAttributeImages) &&
                      !isEmpty(activeColor?.ProductAttributeImages)
                        ? activeColor?.ProductAttributeImages[0]?.ImageUrl
                        : product?.DefaultImageUrl
                    }
                    alt={product?.NameEn}
                    onClick={() =>
                      activeIt(
                        !isNull(activeColor?.ProductAttributeImages) &&
                          !isEmpty(activeColor?.ProductAttributeImages)
                          ? activeColor?.ProductAttributeImages[0]?.ImageUrl
                          : product?.DefaultImageUrl
                      )
                    }
                  />
                </div>
              </Grid>
              <Grid container spacing={3}>
                {!isEmpty(allImages) && (
                  <Grid item xs={12} style={{ margin: "1.5% 0" }}>
                    <Slider
                      content={map(images, (img) => img)}
                      onClick={activeIt}
                      child={SmallImage}
                      spaceBetween={10}
                      className={classes.swiperCont}
                      breakpoints={{ 300: { slidesPerView: 3.5 } }}
                      pagination={false}
                    />

                    {isOpenBox && (
                      <Lightbox
                        name="LightBox"
                        mainSrc={allImages[index] || activeImg}
                        nextSrc={allImages[(index + 1) % allImages?.length]}
                        prevSrc={
                          allImages[
                            (index + allImages?.length - 1) % allImages?.length
                          ]
                        }
                        onCloseRequest={() => setOpenBox(false)}
                        onMovePrevRequest={() => {
                          activeIt(
                            allImages[
                              (index + allImages?.length - 1) %
                                allImages?.length
                            ]
                          );

                          setIndex(
                            (index + allImages?.length - 1) % allImages?.length
                          );
                        }}
                        onMoveNextRequest={() => {
                          activeIt(setIndex((index + 1) % allImages?.length));

                          setIndex((index + 1) % allImages?.length);
                        }}
                      />
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className={classes.firstDiv}>
                <div className="words">
                  <Typography variant="body1" className={classes.name}>
                    {product?.NameEn}
                  </Typography>
                  <div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={(e) => handleFavourite(e)}
                    >
                      {fav ? (
                        <img
                          src={Favorite}
                          alt="Favorite"
                          style={{ width: "26px", height: "26px" }}
                        />
                      ) : (
                        <FavoriteBorderOutlinedIcon className="heart" />
                      )}
                    </div>
                  </div>
                </div>
                <div className="priceDiv">
                  <Typography>{total || product?.Price}</Typography>
                  <Typography>{currency}</Typography>
                </div>

                {product?.OfferPercentageDiscount === 0 ? null : (
                  <div className="discountD">
                    <Typography>{`${product?.OfferPercentageDiscount}%`}</Typography>
                    {product?.OldPrice === 0 ? null : (
                      <Typography>
                        <span>{product?.OldPrice}</span>
                        <span style={{ marginLeft: "10px" }}>{currency}</span>
                      </Typography>
                    )}
                  </div>
                )}

                <div className="avaiD d-flex align-items-center">
                  <Typography>{`Availability`}</Typography>
                  {(
                    !isEmpty(activeColor)
                      ? activeColor.StockAmount > 0
                      : product?.StockAmount > 0
                  ) ? (
                    <div>
                      <Check />
                      <Typography href="https://schema.org/InStock">
                        In Stock
                      </Typography>
                    </div>
                  ) : (
                    <div className="text-danger fw-bold d-flex align-items-center">
                      <CloseIcon />
                      <Typography className="fw-bold">Out of Stock</Typography>
                    </div>
                  )}
                </div>

                {!isEmpty(product.Colors) &&
                  !isNull(product.Colors) &&
                  product.Colors.every((c) => hexColorRegex.test(c.Color)) && (
                    <div className="colour d-flex">
                      <span>Colors: </span>
                      <div>
                        {map(product.Colors, (c, i) => (
                          <div
                            key={i}
                            className="colorsCont"
                            onClick={() => SetActiveColor(c)}
                          >
                            <p
                              style={{
                                backgroundColor: c.Color,

                                border:
                                  activeColor?.ProductAttributeId ==
                                  c.ProductAttributeId
                                    ? "1px solid lightgreen"
                                    : "1px solid palevioletred",
                              }}
                            ></p>
                            <img
                              style={{
                                display:
                                  activeColor?.ProductAttributeId ===
                                  c.ProductAttributeId
                                    ? "flex"
                                    : "none",
                                position:
                                  activeColor?.ProductAttributeId ===
                                    c.ProductAttributeId && "absolute",
                              }}
                              src={
                                ["#ffffff", "#FFFFFF"].includes(c.Color)
                                  ? BTrue
                                  : True
                              }
                              alt="true"
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                <div className="orderDeliveryDate mt-3">
                  <p className="d-flex align-items-center">
                    <img
                      src={DeliveryTruck}
                      alt="delivery truck"
                      className="me-2"
                    />
                    Order Delivery within 3 days - 7 days
                  </p>
                  <p className="d-flex align-items-center">
                    <img src={PackageImg} alt="package" className="me-2" />
                    Return Available within 14 days of Delivery.
                    <Link
                      to="/return-policy"
                      style={{ color: "rgba(0, 0, 86, 1)", fontWeight: "bold" }}
                    >
                      Read More
                    </Link>
                  </p>
                </div>

                {(!isEmpty(activeColor)
                  ? activeColor.StockAmount > 0
                  : product?.StockAmount > 0) && (
                  <div className="d-flex qtyDiv">
                    <span>Quantity: </span>
                    <QuantityButton
                      item={product}
                      id={product.Id || 0}
                      quantity={product.Count || 1}
                      count={count || 1}
                      setCount={setCount}
                      max={product.MaxCountPerOrder}
                    />
                  </div>
                )}

                <div className="d-flex align-items-center justify-content-center gap-5 flex-wrap">
                  <img
                    src={MasterCard}
                    alt="Mastercard"
                    style={{ width: "75px" }}
                  />
                  <img src={VisaLogo} alt="Visa" style={{ width: "75px" }} />
                  <img src={Paymob} alt="Paymob" style={{ width: "100px" }} />
                  <img src={Valu} alt="Valu" style={{ width: "100px" }} />

                  <img src={Forsa} alt="Forsa" style={{ width: "75px" }} />
                  <img src={Aman} alt="Aman" style={{ width: "75px" }} />
                </div>

                {(!isEmpty(activeColor) && !activeColor?.StockAmount > 0) ||
                !product?.StockAmount > 0 ||
                (existed &&
                  existed?.Count + count > product.MaxCountPerOrder) ? (
                  <div className="d-flex align-items-center justify-content-center mt-4">
                    <button
                      onClick={handleSendNotification}
                      style={{
                        borderRadius: "0.9375rem",
                        border: "2px solid #000056",
                        backgroundColor: "rgba(0, 0, 86, 0.00)",
                        padding: "1rem 2rem",
                        textAlign: "center",
                        color: "#000056",
                        width: "70%",
                      }}
                    >
                      Notify me when back in stock
                    </button>
                  </div>
                ) : (
                  <Button className="addCart" onClick={sendToCart}>
                    <Typography color="white">Add to Cart</Typography>
                  </Button>
                )}

                {/* 
                {(!isEmpty(activeColor) && !isNull(activeColor)
                  ? activeColor.StockAmount > 0
                  : product?.StockAmount > 0) && (

                )}

                {activeColor?.StockAmount ||
                  (product?.StockAmount && (
                    
                  ))} */}

                {existed &&
                existed?.Count + count > product.MaxCountPerOrder ? (
                  <span style={{ color: "red", alignSelf: "center" }}>
                    {product.MaxCountPerOrder - existed?.Count > 0
                      ? `You Exceeded the max per Order. You can only buy ${
                          product.MaxCountPerOrder - existed?.Count
                        }`
                      : "You Exceeded the Max per Order"}
                  </span>
                ) : (
                  <></>
                )}
              </div>
            </Grid>
          </Grid>
          <SecondPart product={product} />
        </Container>
      )}
      <Footer1
        backgroundColor="#303030"
        colorWhite="#FFFFFF"
        colorGreen="rgba(0, 237, 133, 1)"
      />
      <Footer2 />
    </>
  );
}
