import { useState } from "react";
import CheckoutStore from "./context";

export default function CheckoutProvider({ children }) {
  const [addressID, setAddressID] = useState({});
  const [addressMethod, setAddressMethod] = useState("list");

  const [paymentMethod, setPaymentMethod] = useState({});

  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <CheckoutStore.Provider
      value={{
        addressID,
        setAddressID,
        paymentMethod,
        setPaymentMethod,
         activeIndex,
        setActiveIndex,
        addressMethod,
        setAddressMethod
      }}
    >
      {children}
    </CheckoutStore.Provider>
  );
}
