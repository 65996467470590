import { useEffect, useRef, useState } from "react";

import { query, collection, orderBy, onSnapshot } from "firebase/firestore";

import { db } from "../../firebase";

import Message from "./Message";
import SendMessage from "./SendMessage";
import "./Chat.css";
import { Spinner } from "react-bootstrap";
const ChatBox = ({ chatId, senderId, receiverId }) => {
  const [messages, setMessages] = useState([]);
  const [isMessageLoading, setIsMessageLoading] = useState(false);

  const scroll = useRef();
  const chatBoxHeight = scroll.current?.scrollHeight
  const chatName =
    localStorage.getItem("role") === "User"
      ? `${receiverId}_${senderId}_${chatId}`
      : `${senderId}_${receiverId}_${chatId}`;

  useEffect(() => {
    const chatCollectionRef = collection(db, `ExpertChats/${chatName}/chat`);
    const q = query(chatCollectionRef, orderBy("insertDate", "asc"));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const Messages = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setMessages(Messages);
    });

    return () => unsubscribe;
  }, []);
  
  useEffect(() => {
    scroll.current.scrollTop = scroll.current.scrollHeight;
  }, [chatBoxHeight]);

  return (
    <main className="chat-box">
      <div className="messages-wrapper" ref={scroll}>
        {messages?.map((message) => (
          <Message key={message.id} message={message} senderId={senderId} />
        ))}
        {isMessageLoading && (
          <div className="text-end pe-4 py-2 mb-5">
            <Spinner
              animation="border"
              role="status"
              variant="success"
              size="md"
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
      </div>

      <SendMessage
        scroll={scroll}
        chatId={chatId}
        senderId={senderId}
        receiverId={receiverId}
        setIsMessageLoading={setIsMessageLoading}
      />
    </main>
  );
};

export default ChatBox;
