import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  basicInput: {
    "& .MuiInputBase-root": {
      backgroundColor: "transparent",
      border: "1px solid #aaaaaa5e",
      borderRadius: "12px",
      "&:before, &:after": { display: "none" },
    },
  },
});
