import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({

  receipt: {
    position: "relative",
    padding: "20px",
    border: "1px solid #DEE2E7",
    borderRadius:"6px",
        "& .receipt-header": {
      marginBottom: "30px",
      textAlign: "center",
      fontWeight: "400",
    },
    "& .receipt-item": {
      marginBottom: "5px",
      display: "flex",
      justifyContent: "space-between",
      "& p":{
        margin:0
      },

      "& .key":{
        fontWeight: "400",
        fontSize: "16px",
        color: "#505050"
    },
    "& div":{
      display:"flex",

    },
    "& .total":{
fontSize:"1rem",
color:"1c1c1c",
fontWeight:600

    },
    "& .val":{
      marginRight: "0.2rem"
    }
  },
  "& .tax":{
    borderBottom : "1px solid #d1d1d1"
  },
  "& button":{
    backgroundColor: "#000056",
    color: "#ffffff",
    borderRadius: "8px",
    padding: "5px",
    textTransform: "initial !important",
    width:"100%",
    fontSize:"1.125rem",
    fontWeight:500,
    margin: "10px 0px 20px 0",
  
  





  }
}
});
