import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import "./Careers.css";
import Navbar from "../../components/Navbar/Navbar";
import Footer2 from "../../components/Footer/Footer2";
import { BreadCrumb } from "../../components/BreadCrumb";
import JobImage from "../../assests/img/JobDetails.png";
import Chair from "../../assests/img/chair.png";
import WorkingDays from "../../assests/img/workingDays.png";
import Clock from "../../assests/img/clock.png";
import Book from "../../assests/img/book.png";
import Date from "../../assests/img/date.png";
import SuitCase from "../../assests/img/suitCase.png";
import LocationPin from "../../assests/img/locationPin.png";
import axios from "axios";
import { DotLoader } from "react-spinners";

const JobDetails = () => {
  const queryString = window.location.search;
  const [isLoading, setIsLoading] = useState(true);

  const params = new URLSearchParams(queryString);
  const [job, setJob] = useState({});

  const getJob = async () => {
    await axios
      .get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/Vacancy/VacancyDetails?Id=${params.get("id")}`
      )
      .then((res) => {
        setJob(res.data.Data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getJob();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  let pageTitle = job.TitleEn || "Job Details";
  return (
    <>
      <Helmet>
        <title>Clicktick - {pageTitle}</title>
        <link
          rel="canonical"
          href={`https://clicktick.app/job-details?id=${job?.Id}`}
        />
      </Helmet>

      <Navbar />
      <BreadCrumb list={[{ name: "Careers", link: "" }]} />

      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            // alignItems: "center",
            height: "100vh",
          }}
        >
          <DotLoader color={"#00EC85"} loading={isLoading} />
        </div>
      ) : (
        <div className="jobDetails">
          <div className="container">
            <div className="jobImg mb-4">
              <img src={JobImage} alt="job" className="img-fluid" />
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="jobLeft p-3">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `<div>${job.DescriptionEn}</div>`,
                    }}
                  ></div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="jobRight">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `<div>${job.JobSummaryEn}</div>`,
                    }}
                  ></div>
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-center">
              <a
                href={`/job-apply?id=${params.get("id")}`}
                className="mainApply"
              >
                Apply Now
              </a>
            </div>
          </div>
        </div>
      )}
      <Footer2 />
    </>
  );
};

export default JobDetails;
