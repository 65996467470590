import React, { useEffect, useState } from "react";
import "./Careers.css";
import Navbar from "../../components/Navbar/Navbar";
import Footer2 from "../../components/Footer/Footer2";
import { BreadCrumb } from "../../components/BreadCrumb";
import JobImage from "../../assests/img/JobDetails.png";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { DotLoader } from "react-spinners";
import SuccessfulCV from "../../assests/img/CV-sent.png";

const JobApply = () => {
  const location = useLocation();
  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const jobId = queryParams.get("id");
  const [job, setJob] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [formData, setFormData] = useState({
    VacancyId: jobId,
    FullName: "",
    LastName: "",
    Email: "",
    PhoneNumber: "",
    Description: "",
    CV: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Validation for name and last name (only characters allowed)
    if (name === "FullName" || name === "LastName") {
      if (/^[A-Za-z]*$/.test(value)) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setErrMsg("");
      } else {
        setErrMsg("Invalid input. Please enter only characters.");
      }
    }
    // Validation for phone number (only numbers allowed)
    else if (name === "PhoneNumber") {
      if (/^[0-9]*$/.test(value)) {
        // Adjusted the regex to allow empty string
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setErrMsg("");
      } else {
        setErrMsg("Invalid input. Please enter only numbers.");
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      setErrMsg("");
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();

      // Validation for file type (accepts Word and PDF)
      if (
        fileExtension === "doc" ||
        fileExtension === "docx" ||
        fileExtension === "pdf"
      ) {
        setFormData((prevData) => ({
          ...prevData,
          CV: file,
        }));
        setErrMsg("");
      } else {
        setErrMsg("Invalid file type. Please choose a Word or PDF file.");
      }
    }
  };

  const postJob = async (e) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/Vacancy/ApplyToVacancy`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        setSuccessMsg("Job application submitted successfully.");
        setShowSuccess(true);
        console.log(res);
      })
      .catch((error) => {
        setErrMsg("Error submitting the job application");
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postJob();
  };

  const getJob = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/Vacancy/VacancyDetails?Id=${jobId}`
      )
      .then((res) => setJob(res.data.Data));
  };

  useEffect(() => {
    getJob();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      <Navbar />
      <BreadCrumb list={[{ name: "Careers", link: "" }]} />

      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <DotLoader color={"#00EC85"} loading={isLoading} />
        </div>
      ) : (
        <div className="applyPage">
          <div className="container">
            {showSuccess ? (
              <div className="success-image">
                <img
                  src={SuccessfulCV}
                  alt="Successful CV"
                  className="img-fluid"
                />
              </div>
            ) : (
              <>
                <div className="jobImg">
                  <img src={JobImage} alt="job" className="img-fluid" />
                </div>
                <div className="jobTitle">
                  <h3>{job.TitleEn}</h3>
                </div>
                <div className="applyForm">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="">First Name*</label>
                        <input
                          type="text"
                          name="FullName"
                          value={formData.FullName}
                          onChange={handleInputChange}
                          placeholder="Enter First Name"
                          required
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="">Last Name*</label>
                        <input
                          type="text"
                          name="LastName"
                          value={formData.LastName}
                          onChange={handleInputChange}
                          placeholder="Enter Last Name"
                          required
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="">Email*</label>
                        <input
                          type="email"
                          name="Email"
                          value={formData.Email}
                          onChange={handleInputChange}
                          placeholder="Enter You Email"
                          required
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="">Phone Number*</label>
                        <input
                          type="phone"
                          name="PhoneNumber"
                          value={formData.PhoneNumber}
                          onChange={handleInputChange}
                          placeholder="Enter Your Phone Number"
                          required
                        />
                      </div>
                    </div>
                    <label htmlFor="">Description* </label>
                    <input
                      name="Description"
                      value={formData.Description}
                      onChange={handleInputChange}
                      placeholder="Cover Letter"
                      className="secondInp"
                      required
                    />
                    <label htmlFor="">Upload Your CV*</label>
                    <input
                      type="file"
                      className="secondInp"
                      onChange={handleFileChange}
                      required
                    />
                    <div className="text-center">
                      {errMsg && <p className="error-message mb-0">{errMsg}</p>}
                      {successMsg && (
                        <p className="success-message mb-0">{successMsg}</p>
                      )}
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                      <button
                        type="submit"
                        className="mainApply"
                        disabled={errMsg}
                      >
                        Apply Now
                      </button>
                    </div>
                  </form>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      <Footer2 />
    </>
  );
};

export default JobApply;
