import { useEffect, useRef, useState } from "react";

import { query, collection, orderBy, onSnapshot } from "firebase/firestore";

import { db } from "./../../firebase";

import { Spinner } from "react-bootstrap";

import styles from "./AdminChat.module.css";
import Message from "./Message";
import SendAdminMessage from "./SendAdminMessage";
import AdminChatHeader from "./AdminChatHeader";

const AdminChatBox = ({ handleCloseChat, chatRef }) => {
  const [messages, setMessages] = useState([]);
  const [isMessageLoading, setIsMessageLoading] = useState(false);

  const scroll = useRef();
  const chatBoxHeight = scroll.current?.scrollHeight;
  const chatName = localStorage.getItem("userId");

  useEffect(() => {
    const chatCollectionRef = collection(db, `chats/${chatName}/chat`);
    const q = query(chatCollectionRef, orderBy("insertDate", "asc"));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const Messages = querySnapshot.docs
        .map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        .filter((message) => message.isVisible);
      setMessages(Messages);
    });

    return () => unsubscribe;
  }, []);

  useEffect(() => {
    scroll.current.scrollTop = scroll.current.scrollHeight;
  }, [chatBoxHeight]);

  return (
    <main className={styles["chat-box"]} ref={chatRef}>
      <AdminChatHeader onClose={handleCloseChat} />
      <div className="messages-wrapper" ref={scroll}>
        {messages?.map((message) => (
          <Message key={message.id} message={message} />
        ))}

        {isMessageLoading && (
          <div className="text-end pe-4 py-2 mb-5">
            <Spinner
              animation="border"
              role="status"
              variant="success"
              size="md"
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
      </div>
      <SendAdminMessage
        scroll={scroll}
        setIsMessageLoading={setIsMessageLoading}
      />
    </main>
  );
};

export default AdminChatBox;
