import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import "./MyServices.css";
import Navbar from "../../../components/Navbar/Navbar";
import { BreadCrumb } from "../../../components/BreadCrumb/index";
import Footer2 from "../../../components/Footer/Footer2";
import { BsClock, BsThreeDotsVertical } from "react-icons/bs";
import axios from "axios";
import moment from "moment";
import Delete from "../../../assests/img/delIcon.png";
import { Modal, Button } from "react-bootstrap";
import { DotLoader } from "react-spinners";
import { toast } from "react-toastify";

const MyServices = () => {
  const [services, setServices] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getService = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/Account/UserServices`)
      .then((response) => {
        setServices(response.data.Data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getService();
  }, []);

  const handleDelete = (serviceId) => {
    setSelectedServiceId(serviceId);
    setShowDeleteModal(true);
  };
  const getStatusColor = (status) => {
    switch (status) {
      case 1:
        return "green";
      case 2:
        return "#f5b342";
      case 3:
        return "green";
      case 4:
        return "green";
      case 5:
        return "red";
      default:
        return "green";
    }
  };
  const deleteService = (serviceId) => {
    axios
      .delete(
        `${process.env.REACT_APP_API_BASE_URL}/Service/DeleteRequest?RequestId=${serviceId}`
      )
      .then((response) => {
        // Update the services list after deleting
        getService();
        setIsLoading(false);
        toast.success("Services is cancelled");
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  const sortedServices = services.sort((a, b) => b.VisitDate - a.VisitDate);

  return (
    <>
      <Helmet>
        <title>My Services | Check Your Services With Clicktick</title>
        <meta name="title" content="My Services | Check Your Services With Clicktick" />
        <meta
          name="description"
          content="Check your services status and scheduled visit datetime easily with Clicktick"
        />
        <meta
          name="keywords"
          content="Clicktick, Click tick, Services, My services"
        />
      </Helmet>
      <Navbar />
      <BreadCrumb list={[{ name: "My Services", link: "" }]} />
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            // alignItems: "center",
            height: "100vh",
          }}
        >
          <DotLoader color={"#00EC85"} loading={isLoading} />
        </div>
      ) : (
        <div className="container">
          <div className="my-services">
            <div className="row">
              <div className="col-md-7">
                {sortedServices?.length === 0 ? (
                  <p className="fw-bold">
                    You have not requested any service yet.
                  </p>
                ) : (
                  sortedServices?.map((service) => {
                    const isDeletable = service.CanDelete;

                    return (
                      <div className="service-card" key={service.Id}>
                        <div className="row">
                          <div className="col-md-2 d-flex align-items-center justify-content-center">
                            <div className="img-wrapper">
                              <img
                                src={service.ImageUrl}
                                alt="mac"
                                className="img-fluid"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <p>{service.NameEn}</p>
                            <p
                              style={{
                                color: getStatusColor(service.RequestStatusId),
                              }}
                            >
                              {service.RequestStatusEn}
                            </p>
                          </div>
                          <div className="col-md-3">
                            <p>{service.TypeEn}</p>
                            {service.VisitDate === 0 ? null : (
                              <p>
                                <BsClock className="me-2" />
                                {moment
                                  .unix(service.VisitDate)
                                  .format("DD/MM/YYYY")}
                                <br />
                                Time:{" "}
                                {moment.unix(service.VisitDate).format("hh:mm A")}
                              </p>
                            )}
                          </div>
                          <div className="col-md-1">
                            {isDeletable ? (
                              <div
                                className="img-wrapper deletable"
                                onClick={() => handleDelete(service.Id)}
                              >
                                <img
                                  src={Delete}
                                  alt="delete"
                                  className="img-fluid mt-1"
                                  style={{ minWidth: "20px" }}
                                />
                              </div>
                            ) : (
                              <div className="img-wrapper disabled">
                                {/* <img
                                  src={Delete}
                                  alt="delete"
                                  className="img-fluid mt-1"
                                /> */}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer2 />

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm cancellation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to cancel this service?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              deleteService(selectedServiceId);
              setShowDeleteModal(false);
            }}
          >
            Confirm
          </Button>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MyServices;
