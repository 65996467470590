import React, { useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { HashLoader } from "react-spinners";
import StarPicker from "react-star-picker";
import { Helmet } from "react-helmet-async";

import axios from "axios";

import NavbarExpert from "./NavbarExpert";
import { EmptyComponent } from "../components/EmptyComponents";

import Profile from "../assests/img/Group-1000004820.png";

import "./ExpertView.css";

const ReviewsExpertView = () => {
  const [expertReviews, setExpertReviews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const GetExpertReviews = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/ExpertView/GetExpertReviews`
      );

      setExpertReviews(response.data.Data);
    } catch (err) {
      console.log(
        "🚀 ~ file: ReviewsExpertView.jsx:29 ~ GetExpertReviews ~ err:",
        err
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    GetExpertReviews();
  }, []);

  return (
    <>
      <Helmet>
        <title>Clicktick: Expert Reviews</title>
      </Helmet>

      <NavbarExpert />

      {isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "200px" }}
        >
          <HashLoader
            color={"#00EC85"}
            loading={isLoading}
          />
        </div>
      ) : (
        <div className="container">
          <div className="reviewsExpertView">
            {expertReviews.length > 0 ? (
              expertReviews?.map((review) => {
                return (
                  <div
                    className="expertReviewCard"
                    key={review.Id}
                  >
                    <div className="row">
                      <div className="col-md-3 d-flex align-items-center center-mob">
                        <img
                          src={Profile}
                          alt="profile"
                          className="img-fluid"
                        />
                      </div>

                      <div className="col-md-7">
                        <p className="mb-0 text-center-mob">
                          {review.UserName}
                        </p>
                        <p className="mb-0 d-flex align-items-center center-mob">
                          <StarPicker value={review.Rate} />
                          <span className="ms-3">{review.Rate}</span>
                        </p>
                      </div>

                      <div className="col-md-2 d-flex justify-content-end d-none-mob">
                        <BsThreeDotsVertical />
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <EmptyComponent content="No Reviews Yet" />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ReviewsExpertView;
