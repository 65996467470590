import { removeDoc, updateChatDocsVisibility } from "../../utils/firebaseHelper";
import styles from "./AdminChat.module.css";
import { GoTrash } from "react-icons/go";

const AdminChatHeader = ({ onClose }) => {
  const chatName = localStorage.getItem("userId");

  return (
    <div className={styles["live-chat"]}>
      <span></span>
      <p>Clicktick live chat</p>
      <button
        onClick={() => {
          // removeDoc("chats", chatName);
          updateChatDocsVisibility("chats", chatName);
        }}
      >
        <GoTrash />
      </button>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClose}
      >
        <path
          d="M15.4511 1.25191L15.0977 0.898581L14.7442 1.2517L8.7737 7.21497L2.83318 1.28167L2.47963 0.928552L2.12629 1.28188L1.24215 2.166L0.888166 2.51997L1.24258 2.87353L7.18181 8.79848L1.24236 14.7307L0.886867 15.0858L1.24366 15.4395L2.1278 16.3162L2.48092 16.6663L2.83297 16.3151L8.7737 10.3886L14.7444 16.345L15.0964 16.6962L15.4496 16.3461L16.3337 15.4695L16.6905 15.1157L16.335 14.7607L10.3656 8.79848L16.3348 2.84356L16.6892 2.49L16.3352 2.13603L15.4511 1.25191Z"
          fill="#F8F8F8"
          stroke="white"
        />
      </svg>
    </div>
  );
};

export default AdminChatHeader;
