import { useContext } from "react";
import CheckoutStore from "../context/context";
import Address from "./address/Address";
import Payment from "./payment/Payment";
import { Container, Grid } from "@material-ui/core";
import { isEmpty } from "lodash";
import { EmptyComponent } from "../../../components/EmptyComponents";
import CustomizedSteppers from "./step/stepper";
import Clicktick from "../../../context/context";
import { useStyles } from "./style";
import OrderBrief from "./orderBrief/OrderBrief";
import { BreadCrumb } from "../../../components/BreadCrumb";
const components = [Address, Payment];

export default function CheckoutStep() {
  const { activeIndex } = useContext(CheckoutStore);
  const { cart, setCart } = useContext(Clicktick);
  const { addressMethod } = useContext(CheckoutStore);
  const classes = useStyles();

  const ActiveStep = components[activeIndex];
  let list = [{ name: "Cart", link: "/cart" }];
  const obj =
    activeIndex == 0
      ? list.push({ name: "Address", link: "" })
      : list.push({ name: "Payment", link: "" });
  return (
    <Container maxWidth="lg">
      <BreadCrumb list={list} />
      {isEmpty(cart) ? (
        <EmptyComponent content={"emptyCart"} />
      ) : (
        <Grid container spacing={3} className={classes.checkout}>
          <Grid
            item
            className="leftContainer"
            xs={12}
            sm={addressMethod !== "newAddress" ? 7 : 12}
            md={addressMethod !== "newAddress" ? 8 : 12}
          >
            {/* <div className="leftContainer"> */}
            {addressMethod !== "newAddress" && (
              <CustomizedSteppers stepNum={1} />
            )}
            <ActiveStep />
            {/* </div> */}
          </Grid>
          {addressMethod !== "newAddress" && (
            <Grid item xs={12} sm={5} md={4}>
              <OrderBrief />
            </Grid>
          )}
        </Grid>
      )}
    </Container>
  );
}
