import { useEffect, useState, useContext } from "react";
import CheckoutStore from "../../context/context";
import { useStyles } from "./style";
import {
  FormControlLabel,
  Checkbox,
  MenuItem,
  Modal,
  Container,
  Grid,
} from "@material-ui/core";
import AddressMap from "./AddressMap";
import { BasicInput } from "../../../../components/BasicInput";
import { SelectInput } from "../../../../components/SelectInput";

import Clicktick from "../../../../context/context";
import { Notify } from "../../../../components/Notify";
import axios from "axios";
import { add, isEmpty } from "lodash";
import { HashLoader } from "react-spinners";
export default function AddressForm({ setAddressMethod }) {
  const classes = useStyles();
  const { setNotify } = useContext(Clicktick);
  const { addressID, setActiveIndex, activeIndex } = useContext(CheckoutStore);

  // Cities will be rendered in the select input of cities
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [areas, setAreas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [addressForm, setAddressForm] = useState({
    CityId: "",
    DistrictId: "",
    AreaId: "",
    Street: "",
    BuildingNo: 0,
    FloorNo: 0,
    ApartmentNo: 0,
    AditionalDetails: "",
  });

  useEffect(() => {
    const getCities = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/UserAddress/GetCities`
      );
      setCities(data.Data);
    };
    getCities();
  }, []);

  useEffect(() => {
    const GetDistricts = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/UserAddress/GetDistricts?CityId=${addressForm.CityId}`
      );
      setDistricts(data.Data);
    };
    addressForm.CityId && GetDistricts();
  }, [addressForm.CityId]);

  useEffect(() => {
    const GetAreas = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/UserAddress/GetAreas?DistrictId=${addressForm.DistrictId}`
      );
      setAreas(data.Data);
    };
    addressForm.DistrictId && GetAreas();
  }, [addressForm.DistrictId]);

  useEffect(() => {
    setAddressForm({
      ...addressForm,
      CityId: addressID?.CityId,
      DistrictId: addressID?.DistrictId,
      AreaId: addressID?.AreaId,
      Id: addressID?.Id,
      Street: addressID?.Street,
      BuildingNo: addressID?.BuildingNo,
      FloorNo: addressID?.FloorNo,
      ApartmentNo: addressID.ApartmentNo,
      AditionalDetails: addressID?.AditionalDetails || "",
    });
  }, []);

  // submit form => add newAddress =>
  // update address in checkout context => move to verification code
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!isEmpty(addressID))
      await axios
        .put(
          `${process.env.REACT_APP_API_BASE_URL}/UserAddress/UpdateAddress`,
          addressForm
        )
        .then((res) => {
          setNotify({
            open: true,
            message: "Address Updated Successfully",
            severity: "success",
          });
          setIsLoading(false);
        });
    else
      await axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/UserAddress/AddAddress`,
          addressForm
        )
        .then((res) => {
          setNotify({
            open: true,
            message: "Address Added Successfully",
            severity: "success",
          });
          setIsLoading(false);
        });

    setAddressMethod("list");
  };

  // update the addressForm state upon onChange the input
  const handleChange = (e) => {
    setAddressForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  return (
    <Container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <form className={classes.addressForm} onSubmit={(e) => handleSubmit(e)}>
          <Grid xs={12} md={12}>
            <SelectInput
              variant="filled"
              label={"City"}
              name="CityId"
              required
              value={addressForm?.CityId}
              onChange={handleChange}
              fullWidth
              options={cities}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <SelectInput
              variant="filled"
              label={"District"}
              name="DistrictId"
              required
              value={addressForm?.DistrictId}
              onChange={handleChange}
              fullWidth
              options={districts}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <SelectInput
              variant="filled"
              label={"Area"}
              name="AreaId"
              required
              value={addressForm?.AreaId}
              onChange={handleChange}
              fullWidth
              options={areas}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <BasicInput
              variant="filled"
              label={"Street"}
              name="Street"
              required
              value={addressForm?.Street}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          <Grid xs={12} md={6}>
            <BasicInput
              variant="filled"
              label={"BuildingNo"}
              name="BuildingNo"
              required
              value={addressForm?.BuildingNo}
              onChange={handleChange}
              type="number"
              fullWidth
            />
          </Grid>

          <Grid xs={12} md={6}>
            <BasicInput
              variant="filled"
              required
              type="number"
              label="FloorNo"
              name="FloorNo"
              value={addressForm?.FloorNo}
              onChange={handleChange}
              fullWidth
            ></BasicInput>
          </Grid>

          <Grid xs={12} md={6}>
            <BasicInput
              variant="filled"
              label="ApartmentNo"
              name="ApartmentNo"
              type="number"
              required
              fullWidth
              value={addressForm?.ApartmentNo}
              onChange={handleChange}
            />
          </Grid>

          <Grid xs={12} md={12}>
            <BasicInput
              variant="filled"
              label="AditionalDetails"
              name="AditionalDetails"
              multiline
              rows={3}
              required
              value={addressForm?.AditionalDetails}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          {/* <FormControlLabel
        control={<Checkbox onChange={(e) => setIsPrimary(e.target.checked)} />}
        label={"setAsDefaultAddress"}
      /> */}

          <Grid xs={12} md={12} className={classes.addressActions}>
            <button className="add" type="submit">
              {isLoading ? (
                <HashLoader
                  size={24}
                  className="d-flex justify-content-center align-items-center w-100"
                  color={"#00EC85"}
                />
              ) : (
                <>Save Address</>
              )}
            </button>
          </Grid>

          <Notify />
        </form>
      </Grid>
      <Grid xs={12} sm={6} md={6}>
        {/* <AddressMap
          setAddressForm={setAddressForm}
          setOpenMapModal={setOpenMapModal}
        /> */}
      </Grid>
    </Container>
  );
}
