import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import Navbar from "../../../components/Navbar/Navbar";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { DotLoader } from "react-spinners";

import "./Addresses.css";

import axios from "axios";

import DelIcon from "../../../assests/img/delIcon.png";
import EditIcon from "../../../assests/img/edit.png";
import Footer2 from "../../../components/Footer/Footer2";

const EditAddressForm = ({ address, onSave }) => {
  const [addressData, setAddressData] = useState({ ...address });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAddressData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = () => {
    onSave(addressData);
  };

  return (
    <div className="add-address-form mt-3">
      <h4 className="form-title">Edit Address</h4>
      <div className="form-group">
        <label className="label">Street</label>
        <input
          className="form-input form-edit"
          type="text"
          name="Street"
          value={addressData.Street}
          onChange={handleInputChange}
          placeholder="Street"
        />
      </div>
      <div className="form-group">
        <label className="label">Building No</label>
        <input
          className="form-input form-edit"
          type="text"
          name="BuildingNo"
          value={addressData.BuildingNo}
          onChange={handleInputChange}
          placeholder="Building Number"
        />
      </div>
      <div className="form-group ">
        <label className="label">Floor No</label>
        <input
          className="form-input form-edit"
          type="text"
          name="FloorNo"
          value={addressData.FloorNo}
          onChange={handleInputChange}
          placeholder="Floor Number"
        />
      </div>
      <div className="form-group ">
        <label className="label">Apartment No</label>
        <input
          className="form-input form-edit"
          type="text"
          name="ApartmentNo"
          value={addressData.ApartmentNo}
          onChange={handleInputChange}
          placeholder="Apartment Number (Optional)"
        />
      </div>
      <div className="form-group ">
        <label className="label">Additional Details</label>
        <input
          className="form-input form-edit"
          type="text"
          name="AdditionalDetails"
          value={addressData.AdditionalDetails}
          onChange={handleInputChange}
          placeholder="(e.g., near a supermarket or school)"
        />
      </div>
      <button className="form-button" onClick={handleSave}>
        Save Changes
      </button>
    </div>
  );
};

const AddAddressForm = ({ onSave }) => {
  const [addressData, setAddressData] = useState({
    CityId: "",
    DistrictId: "",
    AreaId: "",
    Street: "",
    BuildingNo: "",
    FloorNo: "",
    ApartmentNo: "",
    AdditionalDetails: "",
  });

  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [areas, setAreas] = useState([]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAddressData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = () => {
    onSave(addressData);
  };

  const handleCityChange = (event) => {
    const cityId = event.target.value;
    setAddressData((prevData) => ({
      ...prevData,
      CityId: cityId,
      DistrictId: "",
      AreaId: "",
    }));

    setDistricts([]);
    setAreas([]);
    if (cityId !== "") {
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/UserAddress/GetDistricts?CityId=${cityId}`
        )
        .then((response) => {
          setDistricts(response.data.Data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleDistrictChange = (event) => {
    const districtId = event.target.value;
    setAddressData((prevData) => ({
      ...prevData,
      DistrictId: districtId,
      AreaId: "",
    }));
    setAreas([]);
    if (districtId !== "") {
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/UserAddress/GetAreas?DistrictId=${districtId}`
        )
        .then((response) => {
          setAreas(response.data.Data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/UserAddress/GetCities`)
      .then((response) => {
        setCities(response.data.Data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="add-address-form mt-3">
      <h4 className="form-title">Add New Address</h4>
      <select
        className="form-select"
        name="CityId"
        value={addressData.CityId}
        onChange={handleCityChange}
        placeholder="Select City"
      >
        <option value="">Select City</option>
        {cities?.map((city) => (
          <option key={city.Id} value={city.Id}>
            {city.NameEn}
          </option>
        ))}
      </select>
      <select
        className="form-select"
        name="DistrictId"
        value={addressData.DistrictId}
        onChange={handleDistrictChange}
        placeholder="Select District"
        disabled={!addressData.CityId}
      >
        <option value="">Select District</option>
        {districts?.map((district) => (
          <option key={district.Id} value={district.Id}>
            {district.NameEn}
          </option>
        ))}
      </select>
      <select
        className="form-select"
        name="AreaId"
        value={addressData.AreaId}
        onChange={handleInputChange}
        placeholder="Select Area"
        disabled={!addressData.DistrictId}
      >
        <option value="">Select Area</option>
        {areas?.map((area) => (
          <option key={area.Id} value={area.Id}>
            {area.NameEn}
          </option>
        ))}
      </select>
      <input
        className="form-input"
        type="text"
        name="Street"
        value={addressData.Street}
        onChange={handleInputChange}
        placeholder="Street"
      />
      <input
        className="form-input"
        type="text"
        name="BuildingNo"
        value={addressData.BuildingNo}
        onChange={handleInputChange}
        placeholder="Building No"
      />
      <input
        className="form-input"
        type="text"
        name="FloorNo"
        value={addressData.FloorNo}
        onChange={handleInputChange}
        placeholder="Floor No"
      />
      <input
        className="form-input"
        type="text"
        name="ApartmentNo"
        value={addressData.ApartmentNo}
        onChange={handleInputChange}
        placeholder="Apartment No"
      />
      <input
        className="form-input"
        type="text"
        name="AdditionalDetails"
        value={addressData.AdditionalDetails}
        onChange={handleInputChange}
        placeholder="Additional Details"
      />
      <button className="form-button" onClick={handleSave}>
        Save Address
      </button>
    </div>
  );
};

const MyAddresses = () => {
  const [userAddresses, setUserAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [showAddAddressForm, setShowAddAddressForm] = useState(false);
  const [showEditAddressForm, setShowEditAddressForm] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [addressData, setAddressData] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state

  const handleAddressChange = (event) => {
    setSelectedAddress(event.target.value);
  };

  const getAddresses = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/UserAddress/GetUserAddresses`)
      .then((response) => {
        setUserAddresses(response.data.Data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAddresses();
  }, []);

  const handleAddNewAddress = (addressData) => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/UserAddress/AddAddress`,
        addressData
      )
      .then((response) => {
        setSuccessMessage("Address added successfully.");
        setShowAddAddressForm(false);
        getAddresses();
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        toast.warn("Please enter a valid address");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEditAddress = (addressData) => {
    setLoading(true);
    axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/UserAddress/UpdateAddress`,
        addressData
      )
      .then((response) => {
        setSuccessMessage("Address updated successfully.");
        setShowEditAddressForm(false);
        getAddresses();
      })
      .catch((error) => {
        console.log(error);
        toast.warn("Please enter a valid address");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDeleteAddress = (addressId) => {
    axios
      .delete(
        `${process.env.REACT_APP_API_BASE_URL}/UserAddress/DeleteAddress?addressId=${addressId}`
      )
      .then((response) => {
        setSuccessMessage("Address deleted successfully.");
        getAddresses();
        setShowDeleteModal(false);
      })
      .catch((error) => {
        setErrorMessage("Failed to delete address.");
        console.log(error);
      });
  };

  const handleEditButtonClick = (addressId) => {
    const selectedAddress = userAddresses.find(
      (address) => address.Id === addressId
    );
    setSelectedAddress(addressId);
    setAddressData({ ...selectedAddress });
    setShowEditAddressForm(true);
  };

  return (
    <>
      <Helmet>
        <title>My Address | Add or Edit Address With Clicktick</title>
        <meta name="title" content=" My Address | Add or Edit Address With Clicktick" />
        <meta
          name="description"
          content="Add and save your address list to receive your orders easily with Clicktick"
        />
        <meta
          name="keywords"
          content="Clicktick, Click tick, Address, My address"
        />
      </Helmet>
      <Navbar />
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <DotLoader color={"#00EC85"} loading={loading} />
        </div>
      ) : (
        <div className="container">
          <div className="address">
            <h4>Select An Address</h4>
            {userAddresses?.length === 0 ? (
              <p>No addresses added. Please add an address.</p>
            ) : (
              <>
                {userAddresses?.map((address) => (
                  <div className="optionStyle" key={address.Id}>
                    <div className="row w-100">
                      <div className="col-md-7">
                        <label>
                          <input
                            type="radio"
                            value={address.Id}
                            checked={selectedAddress === address.Id}
                            onChange={handleAddressChange}
                            className="me-4"
                          />
                          {address.BuildingNo} {address.Street}
                          {address.DistrictEn}, {address.CityEn}
                        </label>
                      </div>
                      <div className="col-md-5">
                        <div className="iconContainer">
                          <img
                            src={EditIcon}
                            alt="Edit"
                            onClick={() => handleEditButtonClick(address.Id)}
                          />
                          <img
                            src={DelIcon}
                            alt="Delete"
                            onClick={() => handleDeleteAddress(address.Id)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {successMessage && (
                  <p className="success-message">{successMessage}</p>
                )}
                {errorMessage && (
                  <p className="error-message">{errorMessage}</p>
                )}
              </>
            )}

            {/* Add Address button */}
            <div className="addNewAddress">
              <button
                className="addNewAddressButton"
                onClick={() => setShowAddAddressForm(true)}
              >
                + Add New Address
              </button>
            </div>

            {/* Add/Edit Address forms */}
            {showAddAddressForm && (
              <AddAddressForm onSave={handleAddNewAddress} />
            )}
            {showEditAddressForm && (
              <EditAddressForm
                address={addressData}
                onSave={handleEditAddress}
              />
            )}
          </div>
        </div>
      )}
      <Footer2 />
    </>
  );
};

export default MyAddresses;
