import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import FooterImg from '../../assests/img/footerMobile.png';
import GooglePlay from '../../assests/img/GooglePlay.png';
import AppStore from '../../assests/img/AppStore.png';

const Footer = (props) => {
  return (
    <section
      style={{ backgroundColor: props.backgroundColor }}
      className='py-4'
    >
      <div className='container'>
        <div className='row'>
          <div
            className='col-md-6'
            data-aos='fade-right'
            data-aos-duration='2000'
            data-aos-once='true'
          >
            <div className='left mt-3'>
              <img
                src={FooterImg}
                alt='footerimg'
                className='w-100 footerImgMob'
              />
            </div>
          </div>
          <div
            className='col-md-6 mt-5'
            data-aos='fade-left'
            data-aos-duration='2000'
            data-aos-once='true'
          >
            <div className='right'>
              <div className='rightText'>
                <p className='mb-0 font-size-Mobile'>
                  <span className='percent' style={{ color: props.colorWhite }}>
                    10%
                  </span>
                  <br></br>
                  <span style={{ color: props.colorWhite }}>Discount</span>
                  <br></br>
                  <span style={{ color: props.colorWhite }}>On Your</span>
                  <span
                    className='greenColor'
                    style={{ color: props.colorGreen }}
                  >
                    &nbsp;First Order
                  </span>
                  <br></br> <span style={{ color: props.colorWhite }}>In</span>
                  <span
                    className='font-weight-bold'
                    style={{ color: props.colorGreen }}
                  >
                    &nbsp;Clicktick&nbsp;
                  </span>
                  <span className='fw-bold' style={{ color: props.colorWhite }}>
                    app
                  </span>
                </p>
              </div>
              <div className='rightText'>
                <div
                  className='fs-24 mb-4 mt-4 mb-1'
                  style={{ color: props.colorWhite }}
                >
                  Download Now
                </div>
                <div className='downloadBut'>
                  <Link
                    to='https://play.google.com/store/apps/details?id=com.sbtech.clicktick'
                    target='_blank'
                  >
                    <img
                      src={GooglePlay}
                      alt='Google Play Download'
                      className='me-4'
                    />
                  </Link>
                  <Link
                    to='https://apps.apple.com/us/app/clicktick/id6449221939'
                    target='_blank'
                  >
                    <img src={AppStore} alt='App Store Download' />
                  </Link>
                </div>
                <p style={{ color: 'rgb(0, 237, 133)', marginTop: '10px' }}>
                  Terms and Conditions Apply
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
