import { makeStyles } from "@material-ui/core";
export const useStyle = makeStyles({
  mainImg: {
    // display: "flex",
    // padding: "0 3%",
    cursor: "pointer",
    "& div": {
      height: "550px",
      // backgroundColor: "#F4F4F4",
      borderRadius: "15px",
      display: "flex",
      justifyContent: "center",

      "& img": {
        width: "80%",
        height: "100%",
        objectFit: "contain",
      },
    },
  },
  sizes: {
    display: "flex",
    justifyContent: "center",
    "& p": {
      textDecoration: "underline",
      fontSize: "1.2rem",
      cursor: "pointer",
      "&:hover": {
        // color: styleSettings.get("colors.light_red_color"),
      },
    },
  },
  swiperCont: {
    // height: "300px",
    paddingBottom: "0px !important",

    "& img": {
      width: "100%",
      height: "208px",
      // backgroundColor: "#F4F4F4",
      borderRadius: "15px",
      // boxShadow: "0 15px 10px #acacac24",
      cursor: "pointer",
      objectFit: "contain",

      padding: "10%",
    },
  },
  // contImages: {
  //   display: "flex",
  //   "& .smallImages": {
  //     // padding: "3% 0%",
  //     display: "flex",
  //     flexDirection: "column",
  //     // justifyContent: "space-between",
  //   },
  // },

  firstDiv: {
    "& .words": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",

      "& div": {
        // width: "10rem",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
      },

      "& p": {
        fontSize: "2.1rem",
        color: "#313131",
        lineHeight: "54px",
        marginBottom: "3%",
      },

      "& .heart": {
        width: "2rem !important",
        height: "5rem !important",
        color: "#585858",
      },
    },
    "& .priceDiv": {
      display: "flex",
      "& p": {
        fontWeight: "600",
        fontSize: "1.9rem",
        lineHeight: "1.5rem",
        letterSpacing: "0.3px",
        color: "#000056",

        "&:last-child": {
          color: "#313131",
          marginLeft: "2%",
        },
      },
    },
    "& .discountD": {
      display: "flex",
      margin: "1rem 0",

      "& p": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "&:first-child": {
          textAlign: "center",
          padding: "0px 2px",
          gap: "10px",
          width: "57px",
          height: "35px",
          background: "#03BE6C",
          borderRadius: "5px",
          fontWeight: 600,
          fontSize: "1.125rem",
          lineHeight: " 1.5rem",
          letterSpacing: "0.3px",
          color: "#FFFFFF",
          margin: "2% 0",
        },
        "&:last-child": {
          color: "#545454",
          textDecoration: "line-through",
          marginLeft: "2%",
        },
      },
    },
    "& .avaiD": {
      display: "flex",
      margin: "1rem 0",

      "& p": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "&:first-child": {
          fontWeight: 500,
          fontSize: " 1.136rem",
          lineHeight: "27px",
          color: "#545454",
        },
      },
      "& div": {
        color: "#03BE6C",
        margin: " 0 2%",
        display: "flex",
      },
    },
    "& .reviewPro": {
      display: "flex",
      alignItems: "center",
      margin: "1.5rem 0",

      "& span": {
        fontSize: "1.125rem",
        fontWeight: "400",
        color: "#545454",
        lineHeight: "1.5rem",

        "&:nth-child(3)": {
          marginLeft: "0.5rem",
        },
      },
    },
    "& .sold": {
      margin: "1rem 0 2rem 0",
      "& span": {
        fontSize: "1.188rem",
        lineHeight: "24px",
        color: "#545454",
      },
    },
    "& .qtyDiv": {
      margin: "1rem 0 2rem 0",
      alignItems: "center",

      "& span": {
        marginRight: "1rem",
        fontSize: "1.188rem",
        lineHeight: "24px",
        color: "#313131",
      },
    },
    "& .colour": {
      "& span": {
        // marginRight: "1rem",
      },
      "& div": {
        display: "flex",
        "& .colorsCont": {
          position: "relative",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "0.5rem",
          borderRadius: "50%",
          width: "30px",
          height: " 30px",

          "& p": {
            width: "26px",
            height: "26px",
            borderRadius: "50%",
            margin: 0,
            cursor: "pointer",
            // marginLeft:"0.5rem"
          },
          // "& img": {
          // position: "absolute",
          // },
        },
      },
    },
    "& .addCart": {
      background: "#000056",
      borderRadius: "8px",
      width: "100%",
      color: "#ffffff !important",
      textTransform: "initial !important",
      padding: "10px 0",
      margin: "2rem 0 0rem 0",
    },
  },
  SecondDiv: {
    "& p": {
      "&:nth-child(1)": {
        fontWeight: 500,
        fontSize: "1.3rem",
        lineHeight: "3rem",
        margin: "1.8rem 0 0.8rem 0",
        color: "#545454",
      },
      "&:nth-child(2)": {
        fontWeight: 400,
        fontSize: "1.3rem",
        lineHeight: "2.5rem",
        color: "#596064",
        marginBottom: "3rem",
      },
    },
    "& .Attr": {
      marginBottom: "3rem",
      "& p": {
        fontWeight: 500,
        fontSize: "1.3rem !important",
        lineHeight: "3rem",
        margin: "1.8rem 0 0.8rem 0",
        color: "#545454",
      },

      "& span": {
        fontWeight: 400,
        fontSize: "1.0rem",
        lineHeight: "2.5rem",
        color: "#596064",
        "&:nth-child(1)": {
          fontWeight: "bold",
        },
        "&: nth-child(2)": {},
      },
    },
  },

  recommend: {
    fontWeight: 500,
    fontSize: "1.3rem",
    lineHeight: "3rem",
    color: "#545454",
  },
});
