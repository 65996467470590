import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  selectCont: {
    width: "95%",
    "& label":{
      margin: "0.5rem 0 0 0.5rem"
    }
  },
  basicSelect: {
    backgroundColor: "transparent",
      border: "1px solid #aaaaaa5e",
      borderRadius: "12px",
      "&:before, &:after": { display: "none" },
  },
});
