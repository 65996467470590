import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import FooterImg2 from "../../assests/img/FooterLogo.png";
import PayMobLogo from "../../assests/img/valuFooter.png";
import AmanLogo from "../../assests/img/amanFooter.png";
import ForsaLogo from "../../assests/img/forsaFooter.svg";
import ValuLogo from "../../assests/img/paymobFooter.png";
import Mastercard from "../../assests/img/Mastercard.png";
import Visa from "../../assests/img/Visa.png";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import {
  BsFillEnvelopeFill,
  BsTelephone,
  BsTelephoneFill,
} from "react-icons/bs";
import { CiLocationOn, CiMail } from "react-icons/ci";
import { MdLocationOn } from "react-icons/md";
import { HiLocationMarker } from "react-icons/hi";

const Footer2 = () => {
  const [apiData, setApiData] = useState([]);
  const [apiInfo, setApiInfo] = useState([]);

  const getContactData = async (e) => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/Home/ContactUsLinks`)
        .then((res) => {
          setApiData(res.data.Data);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const getContactInfo = async (e) => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/Home/ContactUsAddresses`)
        .then((res) => {
          setApiInfo(res.data.Data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getContactData();
    getContactInfo();
  }, []);
  const filteredApiData = apiData?.filter((item) => item.Type !== 7);

  const customSortOrder = [2, 1, 4, 3];

  // Sort the apiInfo array based on the custom order
  const sortedApiInfo = apiInfo?.sort((a, b) => {
    return customSortOrder?.indexOf(a.Type) - customSortOrder?.indexOf(b.Type);
  });
  return (
    <>
      <section className="footer2">
        <div className="secondFooter">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 px-0">
                <div className="left p-0Mob ps-5">
                  <Link to="/">
                    <img
                      src={FooterImg2}
                      alt="logo"
                      className="mt-4 ms-4 footer2ImgMob"
                    />
                  </Link>

                  <div className="socialMedia d-flex align-items-center flex-row m-0Mob gap-3 mt-5 ms-4">
                    {filteredApiData.map((item, index) => (
                      <Link to={item.Link} target="_blank" key={index}>
                        <img
                          src={item.Logo}
                          alt={item.Name}
                          className="socialLinksImgs"
                        />
                      </Link>
                    ))}
                  </div>

                  {/* <div className="brandName ms-4">Clicktick CO</div> */}
                </div>
              </div>

              <div className="col-lg-8">
                <div className="row mt-3 ms-5 ms0Mob">
                  <div className="col-sm-6 p-3 p2Mob">
                    <h5 className="greenColor mb-4">Quick Links</h5>
                    <ul className="list-unstyled text-white fs-17px">
                      <div className="row">
                        <div className="col-md-5">
                          <Link to="/shop" className="text-decoration-none">
                            <li className="listColor ">Shopping</li>
                          </Link>
                          <Link to="/services" className="text-decoration-none">
                            <li className="mt-4 listColor">Services</li>
                          </Link>
                          <Link to="/experts" className="text-decoration-none">
                            <li className="mt-4 listColor">Experts</li>
                          </Link>
                          <Link
                            to="/contact-us"
                            className="text-decoration-none"
                          >
                            <li className="mt-4 listColor">Contact Us</li>
                          </Link>
                        </div>
                        <div className="col-md-7 mt-4-Mob">
                          <Link to="/about-us" className="text-decoration-none">
                            <li className="listColor">About Us</li>
                          </Link>
                          <Link to="/terms" className="text-decoration-none">
                            <li className="mt-4 listColor">
                              Terms and Conditions
                            </li>
                          </Link>
                          <Link to="/privacy" className="text-decoration-none">
                            <li className="mt-4 listColor">Privacy</li>
                          </Link>
                          <Link
                            to="/return-policy"
                            className="text-decoration-none"
                          >
                            <li className="mt-4 listColor">Return Policy</li>
                          </Link>
                        </div>
                        <div className="mt-4">
                          <Link
                            to="/copy-right"
                            className="text-decoration-none"
                          >
                            <li className="listColor">Copyright</li>
                          </Link>
                        </div>
                      </div>
                    </ul>
                  </div>
                  <div className="col-sm-6 p-3 p2Mob">
                    <h5 className="greenColor mb-4">Contact</h5>
                    <ul className="list-unstyled text-white">
                      {sortedApiInfo?.map((info, index) => {
                        if (info.Type === 5) return <></>;

                        return (
                          <li
                            className="d-flex align-items-center mt-4"
                            key={index}
                          >
                            {info.Type === 2 && (
                              <div className="d-flex align-items-center">
                                <BsFillEnvelopeFill className="me-3" />
                                <p
                                  className="mb-0 listColor"
                                  dangerouslySetInnerHTML={{
                                    __html: `<div>${info.Link}</div>`,
                                  }}
                                ></p>
                              </div>
                            )}
                            {info.Type === 1 && (
                              <div className="d-flex align-items-center">
                                <BsTelephoneFill className="me-3" />
                                <p
                                  className="mb-0 listColor"
                                  dangerouslySetInnerHTML={{
                                    __html: `<div>${info.Link}</div>`,
                                  }}
                                ></p>
                              </div>
                            )}
                            {info.Type === 4 && (
                              <div className="d-flex align-items-center">
                                <BsTelephoneFill className="me-3" />
                                <p
                                  className="mb-0 listColor"
                                  dangerouslySetInnerHTML={{
                                    __html: `<div>${info.Link}</div>`,
                                  }}
                                ></p>
                              </div>
                            )}
                            {info.Type === 3 && (
                              <div className="d-flex align-items-center">
                                <MdLocationOn
                                  className="me-3"
                                  style={{ fontSize: "1.5rem" }}
                                />
                                <p
                                  className="mb-0 listColor"
                                  dangerouslySetInnerHTML={{
                                    __html: `<div>${info.Link}</div>`,
                                  }}
                                ></p>
                              </div>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                {/* <div className="copyright px-5 ms-5">
                  Copyright © 2023 All right reserved
                </div> */}
              </div>

              <div>
                <div className="row my-3 ps-xl-5 ms0Mob">
                  <div className="col-12 col-xl-7 socialMedia d-flex align-items-center justify-content-center justify-content-xl-end flex-wrap flex-row m-0Mob gap-3">
                    <img
                      src={Mastercard}
                      alt="PaMastercard Logo"
                      className="img-fluid payment--fotoer"
                    />

                    <img
                      src={Visa}
                      alt="Valu Logo"
                      className="img-fluid payment--fotoer"
                    />

                    <img
                      src={PayMobLogo}
                      alt="Paymob Logo"
                      className="img-fluid"
                    />

                    <img src={ValuLogo} alt="Valu Logo" className="img-fluid" />

                    <img
                      src={ForsaLogo}
                      alt="forsa Logo"
                      className="img-fluid payment--fotoer"
                    />

                    <img
                      src={AmanLogo}
                      alt="Aman Logo"
                      className="img-fluid payment--fotoer"
                    />
                  </div>

                  <div className="col-12 col-xl-4 copyright d-flex align-items-center justify-content-center mt-3 mt-xl-0 ">
                    Copyright © 2024 All right reserved
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer2;
