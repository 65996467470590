import React, { useEffect, useState } from "react";
import Clicktick from "./context";

function StoreProvider({ children }) {
  const [user, setUser] = useState({});
  const [cart, setCart] = useState([]);
  const [asyncLoading, setAsyncLoading] = React.useState(false);
  const [favourites, setFavourites] = useState([]);
  const [invoice, setInvoice] = useState({});
  const [logout, setLogout] = useState({});
  const [country, setCountry] = useState({});
  const [activeProductColor, SetActiveProductColor] = useState({});
  const [UserTypeId, setUserTypeId] = useState(null);
  const [myOrders, setMyOrders] = useState([]);

  const [notify, setNotify] = useState({
    open: false,
    message: "",
  });

  return (
    <Clicktick.Provider
      value={{
        user,
        setUser,
        notify,
        setNotify,
        cart,
        setCart,
        asyncLoading,
        setAsyncLoading,
        favourites,
        setFavourites,
        invoice,
        setInvoice,
        logout,
        setLogout,
        myOrders,
        setMyOrders,
        setCountry,
        country,
        activeProductColor,
        SetActiveProductColor,
        UserTypeId,
        setUserTypeId,
      }}
    >
      {children}
    </Clicktick.Provider>
  );
}

export default StoreProvider;
