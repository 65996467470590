import {
  Breadcrumbs,
  makeStyles,
  Typography,
  Container,
  Link,
} from "@material-ui/core";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';


const useStyle = makeStyles({
  wrapper: {
    paddingTop: 10,
    margin: "20px 0 20px",
    "& *": {
      fontSize: "1rem",
    },
  },
  breadcrumb: {
    // border: `1px solid ${styleSettings.get("colors.mid_grey_color")}`,
    // padding: "10px 30px",
  },
});

export function BreadCrumb({ name, list }) {
  const classes = useStyle();
  return (
    <div className={classes.wrapper}>
      <Container maxWidth="lg">
        <Breadcrumbs 
          separator={<NavigateNextIcon fontSize="small" />}

        aria-label="breadcrumb" className={classes.breadcrumb}>
                <Link href={'/'}>Home</Link>
          {list?.map((item, index) => (
            
            <span key={index}>
              {item.link ? (
                <Link href={item.link}>{item.name}</Link>
              ) : (
                <Typography>{item.name}</Typography>
              )}
            </span>
          ))}
        </Breadcrumbs>
      </Container>
    </div>
  );
}
