import { useState, useContext, useEffect } from "react";

import { useStyles } from "../../components/quantityBtn/style";

export default function QuantityButton({
  item,
  id,
  count,
  setCount,
  max,
  type,
}) {
  const classes = useStyles();

  const increaseCount = () => {
    if (count == max) return;
    else {
      setCount(count + 1);
    }
  };

  const decreaseCount = () => {
    if (count === 1) {
      return;
    } else {
      setCount(count - 1);
    }
  };

  return (
    <>
      <div className={classes.quntityBtn}>
        <div className="minus" style={{backgroundColor:count==1&&"lightgray"}} onClick={decreaseCount}>
          -
        </div>
        <div className="amount"> {count || 1} </div>
        <div className="plus" onClick={increaseCount}>
          +
        </div>
      </div>

      {/* <Notify /> */}
    </>
  );
}
