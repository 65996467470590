import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  item: {
    borderBottom: `1px solid #d1d1d1`,
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
    "&:last-of-type": { border: "none" },
    "&:last-child": {
      border: "none",
    },

    "& div.itemInfo": {
      width: "60%",
      display: "flex",
      "& img": {
        width: "120px",
        height: "100px",
        marginRight: "15px",
        objectFit: "contain",
        objectPosition: "top",
        "@media (max-width: 600px)": {
          width: "80px",
          height: "80px",
          marginRight: "5px",
        },
      },
      // padding: "25px 0",
      "& .info": {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        flexDirection: "column",
        "& .priceInfo": {
          display: "flex",
          alignItems: "center",
          "& div": {
            width: "2rem",
            height: "2rem",
            borderRadius: "50%",
          },
          "& p": {
            fontSize: "1.5rem",
            color: "#000056",
            margin: "0",

            "@media (max-width: 700px)": { fontSize: "0.9rem" },
          },
          "& .price": {
            fontWeight: 600,
            marginRight: "0.5rem",
          },
        },
        "& p": {
          whiteSpace: "wrap",
          fontSize: "1.5rem",
          color: "#000000",
          "@media (max-width: 700px)": { fontSize: "0.9rem" },
        },
        "& .option": {
          border: "1px solid",
          padding: "4px",
          marginBottom: "5px",
          width: "fit-content",
        },
      },
    },
    "& .deleteItem": {
      position: "absolute",
      top: "5px",
      right: "5px",
      "& img": { width: "20px" },
    },
    "& .righ": {
      display: "flex",
      flexDirection: "column",
      alignItems: "end",
      justifyContent: "space-between",
      
    },
   
    "& .close": {
      color: "#575757",
    },
    "& .fav": {
      color: "#BB1717",
    },
    
    "& .moveToWishlist": {
      border: "1px solid #757575",
      display: "flex",
      alignItems: "center",
      padding: "5px 10px",
      borderRadius: "5px",
      marginRight: "10px",
      cursor: "pointer",
      "& span": {
        color: "#757575",
        marginLeft: "0.5rem",
        fontSize: "0.6rem",
        fontWeight: "600",
        lineHeight: "17.535px",
      },
    },
   
  },
   removeFav: {
    borderRadius: "8px",
    border: "1px solid #000056",
    backgroundColor:"transparent",
    color: "#000056",
    display: " flex",
    width: "45%",
    height: "55px",
    padding: "5px 10px",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    textTransform:"uppercase"

  },
  removeBin: {
    borderRadius: "8px",
    border: "1px solid #000056",
    backgroundColor:"#000056",
    color: "#fff",
    display: " flex",
    width: "45%",
    height: "55px",
    padding: "5px 10px",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    textTransform:"uppercase"
  },
  p:{
    color:"#545454",
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: "1.5rem",
    fontWeight: "400",
    lineHeight: "42px",
    letterSpacing: "-0.362px",
  }
});
