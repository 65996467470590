import React, { useContext, useState } from "react";
import { useStyles } from "../address/style";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import Clicktick from "../../../../context/context";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import axios from "axios";
import CheckoutStore from "../../context/context";
import { useEffect } from "react";
import { isEmpty, map } from "lodash";
import Checkk from "../../../../assests/img/checkk.png";
import { redirect, useNavigate, useSearchParams } from "react-router-dom";
import { HashLoader } from "react-spinners";

export default function Payment() {
  const classes = useStyles();
  const [method, setPaymentMethod] = useState("");
  const [flag, setFlag] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [methods, setPaymentMethods] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const { setNotify, invoice, setCart, cart } = useContext(Clicktick);
  const { addressID, setActiveIndex, activeIndex } = useContext(CheckoutStore);
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const type = queryParameters.get("success");

  const getCartItems = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/Order/GetCartList`
    );
    setCart(data.Data);
  };
  useEffect(() => {}, [window.location.href]);

  useEffect(() => {
    const getPaymentMethods = async () => {
      await axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/Order/GetPaymentMethods`)
        .then((res) => setPaymentMethods(res.data.Data))
        .catch((err) => console.log(err));
    };
    getPaymentMethods();
  }, []);
  const Confirm = () => {
    setIsLoading(true);
    if (!method) {
      return setError("Choose Payment Method First !");
    }
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/Order/AddOrder`, {
        UserAddressId: parseInt(addressID),
        PaymentMethodId: parseInt(method),
        // Voucher: String(invoice?.Voucher),
        Voucher: localStorage.getItem("Voucher"),
      })
      .then((res) => {
        localStorage.removeItem("Voucher");
        if (res?.data?.Data?.IframeLink) {
          window.open(res?.data?.Data?.IframeLink);
          // window.location.href = res.data.Data.IframeLink;
          // window.location.target = "_blank";
          setTimeout(() => {
            getCartItems();
          }, 1000);
        } else {
          // setActiveIndex(activeIndex+1)
          setFlag(true);
          getCartItems();

          // setTimeout(() => {
          //   navigate("/orders");
          // }, 1000);
        }
        navigate("/orders");
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <div className={classes.addressList}>
      {flag ? (
        <div className="finish">
          <p className="sent">Order Confirmed</p>
          <img src={Checkk} alt="Check" className="check" />
          <p className="contact">We will contact you</p>
        </div>
      ) : (
        <>
          <FormControl component="fieldset" style={{ width: "100%" }}>
            <RadioGroup
              aria-label="type"
              name="type"
              value={method}
              onChange={(e) => setPaymentMethod(e.target.value)}
              className={classes.group}
            >
              {map(methods, (el) => {
                return (
                  <div className="add-new addressD">
                    <div className="leftt">
                      <FormControlLabel
                        value={el.Id}
                        control={<Radio checkedIcon={<CheckCircleIcon />} />}
                        checked={el.Id == method}
                        label=""
                        classes={{ root: classes.radio }}
                      />
                      <div className="row">
                        <div className="col-md-4">
                          <img
                            src={el.ImageUrl}
                            alt={el.NameEn}
                            className="img-fluid w-100"
                          />
                        </div>
                        <div className="col-md-8 d-flex align-items-center">
                          <p className="mb-0">{el.NameEn}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </RadioGroup>
          </FormControl>

          {!method && <p style={{ color: "red" }}>{error}</p>}
          <div className="confirm">
            <button
              disabled={!method}
              style={{ backgroundColor: !method && "lightgray" }}
              className="add d-flex justify-content-center align-items-center"
              onClick={Confirm}
            >
              {isLoading ? (
                <HashLoader size={20} color="#00EC85" loading={isLoading} />
              ) : (
                "Confirm"
              )}
            </button>
          </div>
        </>
      )}
    </div>
  );
}
