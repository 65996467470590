import React, {
  useRef,
  useState,
  useEffect,
  CSSProperties,
  useContext,
} from 'react';
import FadeLoader from 'react-spinners/FadeLoader';
import axios from 'axios';
import './Login.css';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../assests/img/logo.png';
import UserLoginImg from '../../assests/img/loginUser.png';
import Facebook from '../../assests/img/facebook-login.png';
import Apple from '../../assests/img/apple-login.png';
import Profile from '../../assests/img/profile.png';
import Google from '../../assests/img/google-login.png';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Clicktick from '../../context/context';
import { BsTelephone } from 'react-icons/bs';
import Dropdown from 'react-bootstrap/Dropdown';
import { auth, provider, facebookProvider } from '../../firebase';
import {
  getAuth,
  signInWithPopup,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
} from 'firebase/auth';
import { Helmet } from 'react-helmet-async';

const LoginUser = () => {
  const override = {
    display: 'block',
    margin: 'auto',
  };

  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loadingInProgress, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const userRef = useRef();
  const { setUser, user, setUserTypeId } = useContext(Clicktick);

  const handleKeyPress = (event) => {
    const keyCode = event.which || event.keyCode;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^[0-9]*$/;

    if (!regex.test(keyValue)) {
      event.preventDefault();
      setErrMsg('Please enter a valid phone number');
    } else {
      setErrMsg('');
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  useEffect(() => {
    userRef?.current?.focus();
    localStorage.clear();
  }, []);

  const ProceedLogin = async (e) => {
    e.preventDefault();
    // Check if the email and password fields are not empty
    if (email.trim() === '' || password.trim() === '') {
      setErrMsg('Please fill in all the required fields.');
      return;
    }
    const regex = /^[0-9\b]+$/;
    if (!regex.test(email)) {
      setErrMsg('Please enter a valid phone number');
      return;
    }
    const sanitizedPhoneNumber = email.startsWith('0')
      ? email.substring(1)
      : email;
    setLoading(true);
    axios
      .post('Account/Login', {
        PhoneNumber: sanitizedPhoneNumber,
        Password: password,
        CountryCodeId: selectedCodeId?.Id || 3,
      })
      .then((res) => {
        localStorage.setItem('token', res.data.token);
        localStorage.setItem('username', res.data.Name);
        localStorage.setItem('email', res.data.Email);
        localStorage.setItem('userId', res.data.UserId);
        localStorage.setItem('currency', 'EGP');
        localStorage.removeItem('keySignUp');
        localStorage.removeItem('forgetKey');
        if (res.data.UserTypeId === 3) {
          localStorage.setItem('IsActiveExpert', res.data.IsActiveExpert);
          localStorage.setItem('IsCompleteProfile', res.data.IsCompleteProfile);
        }

        setUser(res.data);
        setUserTypeId(res.data.UserTypeId);

        if (res.data.UserTypeId === 2) {
          localStorage.setItem('role', 'User');
        } else if (res.data.UserTypeId === 3) {
          localStorage.setItem('role', 'Expert');
        }

        if (res.data.UserTypeId === 3 && res.data.IsCompleteProfile === true) {
          navigate('/');
        } else if (
          res.data.UserTypeId === 3 &&
          res.data.IsCompleteProfile === false
        ) {
          // navigate("/expert-complete-profile");
          navigate('/');
          // } else if (
          //   res.data.UserTypeId === 2 &&
          //   res.data.IsCompleteProfile === true
          // ) {
          //   navigate("/");
          // } else if (
          //   res.data.UserTypeId === 2 &&
          //   res.data.IsCompleteProfile === false
          // ) {
          // navigate("/complete-profile");
        } else {
          navigate('/');
        }
      })
      .catch((err) => {
        setLoading(false);

        const errors = err.response.data.Data;
        let error = '';
        for (const key in errors) {
          error += errors[key] + ',';
        }

        setErrMsg(error.slice(0, -1));
      });
  };

  const [countryCodes, setCountryCodes] = useState([]);
  const [selectedCode, setSelectedCode] = useState('+20');
  const [selectedCodeId, setSelectedCodeId] = useState('3');
  const handleDropdownSelect = (c) => {
    setSelectedCode(c.Code);
  };

  const handleDropdownSelectId = (id) => {
    setSelectedCodeId(id);
  };

  const getCountryCodes = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/Account/CountryCodes`)
      .then((res) => setCountryCodes(res.data.Data));
  };
  useEffect(() => {
    getCountryCodes();
  }, []);

  // signin with google
  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      console.log(result.user);
      const name = result.user.displayName;
      const email = result.user.email;
      const profilePic = result.user.photoURL;
      const token = result.user.accessToken;
      localStorage.setItem('username', name);
      localStorage.setItem('email', email);
      localStorage.setItem('profilePic', profilePic);
      localStorage.setItem('token', token);

      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/Account/SocialAuthLogin?Token=${token}`;
      const response = await axios.post(apiUrl);
      console.log(response.data);
      setUser(response.data);
      if (response.data.ShouldCompleteRegister) {
        localStorage.setItem('token', response.data.UserRecord.token);
        // navigate("/complete-profile");
        navigate('/');
      } else {
        localStorage.setItem('token', response.data.UserRecord.token);
        navigate('/');
      }
      localStorage.setItem('userId', response.data.UserRecord.UserId);
    } catch (error) {
      console.log(error);
      if (error.code === 'auth/account-exists-with-different-credential') {
        // Handle the case when an existing account with a different credential is detected
        setErrMsg(
          'An existing account with the same credential is detected. Please try another login method.'
        );
      } else {
        // Handle other errors
        console.log('Other error occurred:', error);
      }
    }
  };

  // signin with Apple
  const signInWithApple = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log(result.user);
        const name = result.user.displayName;
        const email = result.user.email;
        const profilePic = result.user.photoURL;
        const token = result.user.accessToken;
        localStorage.setItem('username', name);
        localStorage.setItem('email', email);
        localStorage.setItem('profilePic', profilePic);
        localStorage.setItem('token', token);
        navigate('/');
      })
      .catch((error) => {
        console.log(error);
        if (error.code === 'auth/account-exists-with-different-credential') {
          // Handle the case when an existing account with a different credential is detected
          setErrMsg(
            'This account is linked with Facebook and Google. Please use another account.'
          );
        } else {
          // Handle other errors
          console.log('Other error occurred:', error);
        }
      });
  };

  // signin with facebook
  const signInWithFacebook = async () => {
    try {
      const result = await signInWithPopup(auth, facebookProvider);
      console.log(result.user);
      const name = result.user.displayName;
      const email = result.user.email;
      const profilePic = result.user.photoURL;
      const token = result.user.accessToken;
      localStorage.setItem('username', name);
      localStorage.setItem('email', email);
      localStorage.setItem('profilePic', profilePic);
      localStorage.setItem('token', token);

      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/Account/SocialAuthLogin?Token=${token}`;
      const response = await axios.post(apiUrl);
      console.log(response.data);
      if (response.data.ShouldCompleteRegister) {
        localStorage.setItem('token', response.data.UserRecord.token);
        // navigate('/complete-profile');
        navigate('/');
      } else {
        localStorage.setItem('token', response.data.UserRecord.token);
        navigate('/');
      }
    } catch (error) {
      console.log(error);

      if (error.code === 'auth/account-exists-with-different-credential') {
        // Handle the case when an existing account with a different credential is detected
        setErrMsg(
          'This account is linked with Facebook and Google. Please use another account.'
        );
      } else {
        // Handle other errors
        console.log('Other error occurred:', error);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title> Login | Clicktick Login or Sign up</title>
        <meta name='title' content='Login | Clicktick Login or Sign up' />
        <meta
          name='description'
          content='Login with your account or sign up with your email, Gmail, or Facebook account to get more features | Clicktick'
        />
        <meta
          name='keywords'
          content=' Clicktick, Click tick, login, sign up, clicktick login, clicktick sign up, Google account, Facebook account'
        />
      </Helmet>
      <div className='login'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 d-flex align-items-center flex-column'>
              <div className='logo mb-4'>
                <Link to='/'>
                  <img src={Logo} alt='logo0' />
                </Link>
              </div>
              <div className='userLoginImg'>
                <img src={UserLoginImg} alt='user login' />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='userInfo'>
                {loadingInProgress ? (
                  <FadeLoader color={'#000'} cssOverride={override} />
                ) : (
                  <>
                    <div className='userLoginSocial'>
                      <p className='mb-0'>Sign in with</p>
                      <div className='socialLogin'>
                        <img
                          src={Facebook}
                          alt='facebook'
                          onClick={signInWithFacebook}
                        />
                        <img
                          src={Google}
                          alt='google'
                          onClick={signInWithGoogle}
                        />
                        {/* <img
                          src={Apple}
                          alt="google"
                          onClick={signInWithApple}
                        /> */}
                      </div>
                      <p className='or'>- OR -</p>
                    </div>
                    <div className='userLoginForm mt-4'>
                      <Form onSubmit={ProceedLogin}>
                        <Form.Group></Form.Group>
                        <Form.Group
                          className='mb-3 d-flex align-items-center mb-4'
                          controlId='formBasicEmail'
                        >
                          <BsTelephone className='loginFormIcons me-3' />
                          <Dropdown className='me-2 chosenCountry'>
                            <Dropdown.Toggle id='dropdown-basic' className=''>
                              {selectedCode ? `${selectedCode}` : 'Country'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {countryCodes?.map((code) => (
                                <Dropdown.Item
                                  key={code.Id}
                                  onClick={() => {
                                    handleDropdownSelect(code);
                                    handleDropdownSelectId(code);
                                  }}
                                >
                                  <img
                                    src={code.FlagUrl}
                                    alt=''
                                    className='me-2 w-10'
                                  />
                                  {`${code.Code}`}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                          <Form.Control
                            type='phone'
                            placeholder='Phone Number'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className='form-control'
                            ref={userRef}
                            onKeyPress={handleKeyPress} // add onKeyPress event listener
                          />
                        </Form.Group>

                        <Form.Group
                          className='mb-3 d-flex align-items-center position-relative'
                          controlId='formBasicPassword'
                        >
                          <LockOutlinedIcon className='loginFormIcons me-3' />

                          <Form.Control
                            type={passwordVisible ? 'text' : 'password'}
                            placeholder='Password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          {passwordVisible ? (
                            <VisibilityOutlinedIcon
                              className='eyeIcon position-absolute end-0'
                              onClick={togglePasswordVisibility}
                            />
                          ) : (
                            <VisibilityOffOutlinedIcon
                              className='eyeIcon position-absolute end-0'
                              onClick={togglePasswordVisibility}
                            />
                          )}
                        </Form.Group>
                        {errMsg && (
                          <p className='invalid text-center fw-bold'>
                            {errMsg}
                          </p>
                        )}

                        {/* <Form.Group className='mb-3 float-end '>
                          <Link
                            to='/forget-password'
                            className='forgotPassword'
                          >
                            Forgot Password?
                          </Link>
                        </Form.Group> */}
                        <Button className='loginButton' type='submit'>
                          Sign in
                        </Button>
                      </Form>
                    </div>
                    <div className='newAccount mt-4'>
                      <p className='text-center'>
                        New Account?&nbsp;
                        <Link to='/signupUser'>Sign up</Link>
                      </p>
                      {/* <p>
                        Are you an Expert?&nbsp;
                        <Link to="/signupexpert">Sign up</Link> 
                      </p>*/}
                    </div>
                    <div className='expertLink my-3'>
                      <img
                        src={Profile}
                        alt='expert profile'
                        className='me-2'
                      />
                      <Link to='/'>Guest</Link>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginUser;
