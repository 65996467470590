import { useState, useEffect, useRef } from 'react';
import "./Chat.css"


const EmojiDropdown = ({setMessage}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const emojiList = [
    '😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣', '😊', '😇',
    '🙂', '🙃', '😉', '😌', '😍', '🥰', '😘', '😗', '😙', '😚',
    '😋', '😛', '😝', '😜', '🤪', '🤨', '🧐', '🤓', '😎', '🤩',
    '🥳', '😏', '😒', '😞', '😔', '😟', '😕', '🙁', '☹️', '😣',
    '😖', '😫', '😩', '🥺', '😢', '😭', '😤', '😠', '😡', '🤬',
    '🤯', '😳', '🥵', '🥶', '😱', '😨', '😰', '😥', '😓', '🤗',
    '🤔', '🤭', '🤫', '🤥', '😶', '😐', '😑', '😬', '🙄', '😯',
    '😦', '😧', '😮', '😲', '🥱', '😴', '🤤', '😪', '😵', '🤢',
    '🤮', '🤧', '😷', '🤒', '🤕', '🤑', '🤠', '😈', '👿', '👹',
    '👺', '💀', '👻', '👽', '👾', '🤖', '😺', '😸', '😻', '😽',
    '🙀', '😿', '😹', '😾', '🤲', '👐', '🙌', '👏', '🤝', '👍',
    '👎', '👊', '✊', '🤛', '🤜', '🤞', '✌️', '🤟', '🤘', '🤙',
    '👈', '👉', '👆', '👇', '✋', '🤚', '🖐', '🖖', '👋', '🤏',
    '🤙', '💪', '🦵', '❤️', '🍕', '🌈','🦯', '🦽', 
    '🦼', '🛑', '🛐','🛎️', '🕳️', '💯', '💢', '♨️', '🚷', '🚯',
     '🚳', '🚱', '🔄','🔃'
  ];
  

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleEmojiClick = (emoji) => {
    setMessage(prevMessage => prevMessage + emoji )
  };

  const handleClickOutside = (event) => {
    // ref has
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      // Clicked outside the dropdown, close it
      setIsDropdownOpen(false);
    }
  };
  useEffect(() => {

    document.addEventListener('click', handleClickOutside);
    
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  return (
    <div className={`emoji-dropdown ${isDropdownOpen ? 'open' : ''}`} ref={dropdownRef}>
      <button type='button' onClick={toggleDropdown}>😊</button>
      {isDropdownOpen && (
        <div className="emoji-list">
          {emojiList?.map((emoji, index) => (
            <span key={index} onClick={() => handleEmojiClick(emoji)}>
              {emoji}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default EmojiDropdown;
