import axios from "axios";
import { useState, useEffect } from "react";
import AboutImg from "../assests/img/About-us-img.png";
import NavbarExpert from "./NavbarExpert";
import { BreadCrumb } from "../components/BreadCrumb";
import { HashLoader } from "react-spinners";
import serviceIcon from "../assests/img/serviceIcon.png";
import CountUp from "react-countup";

const AboutExpertView = () => {
  const [homeAbout, setHomeAbout] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [homeCounter, setHomeCounter] = useState([]);

  const homeAboutApi = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/Home/Web_AboutUs`)
      .then((res) => {
        setHomeAbout(res.data.Data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const homeCounterApi = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/Home/Web_HomeStatistics`)
      .then((res) => setHomeCounter(res.data.Data));
  };

  useEffect(() => {
    homeAboutApi();
    homeCounterApi();
  }, []);

  return (
    <div>
      <NavbarExpert />

      <BreadCrumb list={[{ name: "About Us", link: "" }]} />

      {isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "200px" }}
        >
          <HashLoader
            color={"#00EC85"}
            loading={isLoading}
          />
        </div>
      ) : (
        <section className="about mb-5 mb1Mob">
          <div className="heading mt-5">
            <p className="m-0 d-flex justify-content-center align-items-center">
              <span>clicktick</span>
              About Us
            </p>
          </div>

          <div className="container mt-5 mt1Mob ">
            <div className="row">
              <div
                className="col-md-6 position-relative"
                data-aos="zoom-in-up"
                data-aos-duration="2000"
                data-aos-once="true"
              >
                <div className="imgBefore"></div>

                <img
                  src={AboutImg}
                  alt="about"
                  className="about-img"
                />
              </div>

              <div
                className="col-md-6 aboutRight"
                data-aos="zoom-in-up"
                data-aos-duration="2000"
                data-aos-once="true"
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: homeAbout.ValueEn,
                  }}
                  className="fs-18"
                ></div>
              </div>
            </div>

            <div className="row text-center mt-5">
              {homeCounter?.map((counter) => {
                return (
                  <div
                    className="col-md-4"
                    key={counter.Id}
                  >
                    <div className="serviceCounter">
                      <img
                        src={serviceIcon}
                        alt="icon"
                      />

                      <span>+</span>

                      <CountUp
                        className="counter"
                        start={0}
                        end={counter.Count}
                        duration={8}
                      ></CountUp>

                      <p>{counter.TitleEn}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default AboutExpertView;
