import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import './Login.css';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../assests/img/logo.png';
import UserLoginImg from '../../assests/img/loginUser.png';
import Facebook from '../../assests/img/facebook-login.png';
import Google from '../../assests/img/google-login.png';
import CheckSuccess from '../../assests/img/checkSuccess.png';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import 'react-phone-input-2/lib/bootstrap.css';
import Dropdown from 'react-bootstrap/Dropdown';
import { auth, provider, facebookProvider } from '../../firebase';
import { signInWithPopup } from 'firebase/auth';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Helmet } from 'react-helmet-async';

const SignupUser = () => {
  const override = {
    display: 'block',
    margin: 'auto',
  };
  const navigate = useNavigate();
  const [loadingInProgress, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const userRef = useRef();
  const errRef = useRef();
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [countryCodes, setCountryCodes] = useState([]);
  const [selectedCode, setSelectedCode] = useState(null);
  const [selectedCodeId, setSelectedCodeId] = useState(countryCodes[0]?.Id);
  const [errMsgLogin, setErrMsgLogin] = useState('');

  const handleDropdownSelect = (c) => {
    setSelectedCode(c.Code);
  };
  const handleDropdownSelectId = (id) => {
    setSelectedCodeId(id);
  };

  const getCountryCodes = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/Account/CountryCodes`)
      .then((res) => {
        setCountryCodes(res.data.Data);
        setSelectedCode(res.data.Data[0].Code);
        setSelectedCodeId(res.data.Data[0].Id);
      });
  };
  useEffect(() => {
    getCountryCodes();
  }, []);

  const togglePasswordVisibility = () => {
    const passwordInput = document.getElementById('Password');
    const eyeIcon = document.querySelector('.eyeIcon');

    if (passwordInput.type === 'password') {
      passwordInput.type = 'text';
      eyeIcon.classList.remove('visibilityOn');
      eyeIcon.classList.add('visibilityOff');
    } else {
      passwordInput.type = 'password';
      eyeIcon.classList.remove('visibilityOff');
      eyeIcon.classList.add('visibilityOn');
    }
  };

  const [values, setValues] = useState({
    name: '',
    email: '',
    pass: '',
    phone: '',
    CountryCode: '',
  });

  useEffect(() => {
    userRef?.current?.focus();
    localStorage.clear();
  }, []);

  const validateName = (name) => {
    const regex = /^[A-Za-z ]+$/;
    return regex.test(name);
  };

  const validateEmail = (email) => {
    const regex = /^\S+@\S+\.\S+$/;
    return regex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateName(values.name)) {
      setErrMsg('Please enter a valid name');
      return;
    }
    if (!validateEmail(values.email)) {
      setErrMsg('Please enter a valid email address');
      return;
    }
    setLoading(true);

    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/Account/Register`, {
        FullName: values.name,
        UserName: values.name,
        Email: values.email,
        PhoneNumber: values.phone,
        Password: values.pass,
        CountryCodeId: selectedCodeId?.Id || 3,
        RegisterType: 2,
      })
      .then((res) => {
        localStorage.setItem('keySignUp', res.data.Data.Key);
        // navigate('/Verify');
        navigate('/');
      })
      .catch((err) => {
        setLoading(false);

        const errors = err.response.data.Data;
        let error = '';
        for (const key in errors) {
          error += errors[key] + ',';
        }

        setErrMsg(error.slice(0, -1));
      });
  };
  // signin with Apple
  const signInWithApple = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log(result.user);
        const name = result.user.displayName;
        const email = result.user.email;
        const profilePic = result.user.photoURL;
        const token = result.user.accessToken;
        localStorage.setItem('username', name);
        localStorage.setItem('email', email);
        localStorage.setItem('profilePic', profilePic);
        localStorage.setItem('token', token);
        navigate('/');
      })
      .catch((error) => {
        console.log(error);
        if (error.code === 'auth/account-exists-with-different-credential') {
          // Handle the case when an existing account with a different credential is detected
          setErrMsgLogin(
            'This account is linked with Facebook and Google. Please use another account.'
          );
        } else {
          // Handle other errors
          console.log('Other error occurred:', error);
        }
      });
  };
  // signin with google
  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      console.log(result.user);
      const name = result.user.displayName;
      const email = result.user.email;
      const profilePic = result.user.photoURL;
      const token = result.user.accessToken;
      localStorage.setItem('username', name);
      localStorage.setItem('email', email);
      localStorage.setItem('profilePic', profilePic);
      localStorage.setItem('token', token);

      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/Account/SocialAuthLogin?Token=${token}`;
      const response = await axios.post(apiUrl);
      console.log(response.data);
      if (response.data.ShouldCompleteRegister) {
        localStorage.setItem('token', response.data.UserRecord.token);
        // navigate("/complete-profile");
        navigate('/');
      } else {
        localStorage.setItem('token', response.data.UserRecord.token);
        navigate('/');
      }
    } catch (error) {
      console.log(error);
      if (error.code === 'auth/account-exists-with-different-credential') {
        // Handle the case when an existing account with a different credential is detected
        setErrMsgLogin(
          'An existing account with the same credential is detected. Please try another login method.'
        );
      } else {
        // Handle other errors
        console.log('Other error occurred:', error);
      }
    }
  };
  // signin with facebook
  const signInWithFacebook = async () => {
    try {
      const result = await signInWithPopup(auth, facebookProvider);
      console.log(result.user);
      const name = result.user.displayName;
      const email = result.user.email;
      const profilePic = result.user.photoURL;
      const token = result.user.accessToken;
      localStorage.setItem('username', name);
      localStorage.setItem('email', email);
      localStorage.setItem('profilePic', profilePic);
      localStorage.setItem('token', token);

      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/Account/SocialAuthLogin?Token=${token}`;
      const response = await axios.post(apiUrl);
      console.log(response.data);
      if (response.data.ShouldCompleteRegister) {
        localStorage.setItem('token', response.data.UserRecord.token);
        // navigate("/complete-profile");
        navigate('/');
      } else {
        localStorage.setItem('token', response.data.UserRecord.token);
        navigate('/');
      }
    } catch (error) {
      console.log(error);

      if (error.code === 'auth/account-exists-with-different-credential') {
        // Handle the case when an existing account with a different credential is detected
        setErrMsgLogin(
          'This account is linked with Facebook and Google. Please use another account.'
        );
      } else {
        // Handle other errors
        console.log('Other error occurred:', error);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Clicktick - Sign Up </title>
      </Helmet>
      <div className='login'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 d-flex align-items-center flex-column'>
              <div className='logo mb-4'>
                <Link to='/'>
                  <img src={Logo} alt='logo' />
                </Link>
              </div>
              <div className='userLoginImg'>
                <img src={UserLoginImg} alt='PhoneNumber login' />
              </div>
            </div>
            {registerSuccess ? (
              <div className='col-md-6'>
                <div id='card' className='animated animate__fadeIn'>
                  <img
                    src={CheckSuccess}
                    alt='success'
                    className='w-100 sucImg'
                  />
                  <div id='upper-side'>
                    <circle
                      fill='none'
                      stroke='#ffffff'
                      stroke-width='5'
                      stroke-miterlimit='10'
                      cx='109.486'
                      cy='104.353'
                      r='32.53'
                    />
                    <h3 id='status'>Success</h3>
                  </div>
                  <div id='lower-side'>
                    <p id='message'>
                      Congratulations, your account has been successfully
                      created.
                    </p>
                    <Link to='/LoginUser' id='contBtn'>
                      Login
                    </Link>
                  </div>
                </div>
              </div>
            ) : (
              <div className='col-md-6'>
                <div className='userInfo'>
                  <div className='userLoginSocial'>
                    <p className='mb-0'>Sign Up</p>
                    <div className='socialLogin'>
                      <img
                        src={Facebook}
                        alt='facebook'
                        onClick={signInWithFacebook}
                      />
                      <img
                        src={Google}
                        alt='google'
                        onClick={signInWithGoogle}
                      />
                      {/* <img
                        src={Apple}
                        alt="facebook"
                        onClick={signInWithApple}
                      /> */}
                    </div>
                    <p className='or'>- OR -</p>
                    {errMsg && <p className='invalid'>{errMsg}</p>}
                    {errMsgLogin && <p className='invalid'>{errMsgLogin}</p>}
                  </div>
                  <div className='userLoginForm'>
                    {/* <Form onSubmit={handleSubmit}>
                      <Form.Group className='mb-3 d-flex align-items-center mb-4'>
                        <AccountCircleOutlinedIcon className='loginFormIcons me-3' />
                        <Form.Control
                          type='text'
                          htmlFor='user name'
                          placeholder='User name'
                          id='FullName'
                          ref={userRef}
                          autoComplete='off'
                          onChange={(e) =>
                            setValues({ ...values, name: e.target.value })
                          }
                          required
                          aria-describedby='uidnote'
                        />
                      </Form.Group>
                      <Form.Group className='mb-3 d-flex align-items-center mb-4'>
                        <AlternateEmailOutlinedIcon className='loginFormIcons me-3' />
                        <Form.Control
                          type='email'
                          placeholder='Email'
                          autoComplete='off'
                          onChange={(e) =>
                            setValues({ ...values, email: e.target.value })
                          }
                          required
                        />
                      </Form.Group>

                      <Form.Group className='mb-3 d-flex align-items-center position-relative'>
                        <LockOutlinedIcon className='loginFormIcons me-3' />

                        <Form.Control
                          placeholder='Password'
                          id='Password'
                          onChange={(e) =>
                            setValues({ ...values, pass: e.target.value })
                          }
                          required
                          aria-describedby='pwdnote'
                          type='password' // Added "type" attribute with the value "password"
                        />
                        <VisibilityOffOutlinedIcon
                          className='eyeIcon position-absolute end-0 visibilityOff'
                          onClick={togglePasswordVisibility}
                        />
                      </Form.Group>

                      <Form.Group
                        className='d-flex mb-0 align-items-center justify-content-start'
                        controlId='formPhone'
                      >
                        <LocalPhoneOutlinedIcon className='me-3' />
                        <div className='d-flex align-items-center w-100'>
                          <Dropdown className='me-2 chosenCountry'>
                            <Dropdown.Toggle id='dropdown-basic' className=''>
                              {selectedCode ? `${selectedCode}` : 'Country'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {countryCodes.map((code) => (
                                <Dropdown.Item
                                  key={code.Id}
                                  onClick={() => {
                                    handleDropdownSelect(code);
                                    handleDropdownSelectId(code);
                                  }}
                                >
                                  <img
                                    src={code.FlagUrl}
                                    alt=''
                                    className='me-2 w-10'
                                  />
                                  {`${code.Code}`}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                          <Form.Control
                            type='tel'
                            placeholder='1234567890'
                            value={values.phone}
                            onChange={(e) =>
                              setValues({ ...values, phone: e.target.value })
                            }
                            required
                          />
                        </div>
                      </Form.Group>

                      <Button className='loginButton mt-4' type='submit'>
                        Continue
                      </Button>
                    </Form> */}
                    {/* <p className="userLoginLink mt-4">
                      Signup as an Expert ?
                      <Link
                        to="/signupexpert"
                        className="userLink"
                      >
                        Sign Up
                      </Link> 
                    </p>*/}
                    <p className='userLoginLink mt-4'>
                      Already have an account?
                      <Link to='/LoginUser' className='userLink'>
                        Log in
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SignupUser;
