import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  address: {
    "& .addressButtons": {
      "& button": {
        padding: "15px 35px",
        margin: "0 10px 5px 0",
        border: `1px solid lightgray`,
        backgroundColor: "transparent",
        color: "gray",
        cursor: "pointer",
        fontSize: "1rem",
        "&.active": {
          color: "white",
          backgroundColor: "black",
        },
      },
    },
  },

  addressForm: {
    padding: "20px 0",
    display: "flex",
    flexWrap: "wrap",
    "& div": {
      marginBottom: "5px",
    },

    "& .MuiTextField-root": {
      width: "96%",
    },
  },

  addressList: {
    padding: "20px 50px",
    "& .finish": {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      "& img": {
        width: "10rem",
        height: "10rem",
        margin:'3% 0 2% 0'
      },
      "& .sent": {
        fontWeight: "600",
        fontSize: "1.5rem",
        lineHeight: "24px",
        color: "#545454",
      },
      "& .contact": {
        fontWeight: "400",
        fontsize: "1.25rem",
        lineHeight: "24px",
        color: "#939393",
      },
    },
    "& .add-new": {
      backgroundColor: "#F6F6F6",
      borderRadius: "8px",
      padding: "3%",
      // textAlign: "center",
      cursor: "pointer",
      margin: "1.5% 0",
      width: "80%",
    },
    "& .addressD": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "80%",
      "& button": {
        border: "none",
        backgroundColor: "transparent",
      },
    },
    "& .leftt": {
      display: "flex",
      alignItems: "center",
      "& button": {
        border: "none",
        backgroundColor: "transparent",
      },
      "& .add-info":{
        display:"flex",
        flexDirection:"column"
      }
    },
    "& .confirm": {
      width: "100%",
      marginTop: "10%",

      fontSize: "1rem",
      background: "transparent",
      cursor: "pointer",
      display: "flex",
      justifyContent: "flex-end",
      "@media (max-width: 900px)": {
        width: "100%",
        // margin: "5px 0",
      },
      "& .add": {
        color: "white",
        backgroundColor: "#000056",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
        borderRadius: "10px",
        padding: "2%",
        width: "60%",
      },
    },
  },

  addressActions: {
    "& button": {
      width: "100%",
      margin: "20px 0",
      padding: "15px 30px",
      fontSize: "1rem",
      border: `1px solid lightgray`,
      background: "transparent",
      cursor: "pointer",
      "@media (max-width: 900px)": {
        width: "100%",
        margin: "5px 0",
      },
      "&.add": {
        color: "white",
        backgroundColor: "#000056",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
        borderRadius: "10px",
      },

      "&.pull": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& img": {
          width: "15px",
          marginRight: "10px",
        },
      },
    },
  },

  addressVerification: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    padding: "25px",
    backgroundColor: "#ffffff",
    "@media (max-width: 900px)": {
      width: "50%",
    },
    "@media (max-width: 600px)": {
      width: "90%",
    },
    "& .closeIcon": {
      display: "flex",
      justifyContent: "end",
      "& .MuiSvgIcon-root": {
        cursor: "pointer",
      },
    },

    "& .header": {
      textAlign: "center",
      fontWeight: 400,
      padding: "0",
      marginBottom: "15px",
    },

    "& .codeInput, & .resendText, & button": {
      marginBottom: "15px",
    },

    "& button.resend": {
      cursor: (props: any) => (props.counter > 0 ? "not-allowed" : "pointer"),
    },
  },

  map: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "80%",
  },
});
