import { useContext, useEffect, useState, useRef } from "react";

import AdminChatBox from "./AdminChatBox";
import Clicktick from "./../../context/context";

import styles from "./AdminChat.module.css";

import { doc, onSnapshot } from "firebase/firestore";

import { db } from "../../firebase";
const AdminChat = () => {
  const { user } = useContext(Clicktick);
  const chatRef = useRef();
  const buttonRef = useRef();
  const [showAdminChat, setShowAdminChat] = useState(false);
  const [isNewMessageNotification, setIsNewMessageNotification] =
    useState(false);

  const email = localStorage.getItem("email");
  
  const handleClickOutside = (event) => {
    if (
      chatRef.current &&
      !chatRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setShowAdminChat(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    let unsubscribe;
    if (localStorage.getItem("userId")) {
      const chatDocumentRef = doc(db, "chats", localStorage.getItem("userId"));

      unsubscribe = onSnapshot(chatDocumentRef, (chatDocumentSnapshot) => {
        const adminChatInfo = chatDocumentSnapshot.data();
        setIsNewMessageNotification(adminChatInfo?.client_has_new_message);
      });
    }
    return () => unsubscribe;
  }, []);
  return (
    <>
      {(Object.keys(user || {}).length > 0 || email) && (
        <button
          className={styles["chat-icon"]}
          onClick={() => setShowAdminChat((prevState) => !prevState)}
          ref={buttonRef}
        >
          {isNewMessageNotification && (
            <div className={styles["active-icon"]}></div>
          )}
          {/* <img src="./../../assests/icons/chat.svg" width={24} height={24} alt="chat" /> */}
          <svg
            width="35"
            height="35"
            viewBox="0 0 35 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.09543 29.6276H19.5757C20.7526 29.6276 20.7526 29.6358 21.3166 30.0526C21.8805 30.4695 28.3209 34.8584 28.3209 34.8584V29.6276H28.6233C32.0724 29.6276 34.8594 27.0286 34.8594 23.7921V6.71849C34.8594 3.48196 32.0642 0.858398 28.6233 0.858398H7.09543C3.65457 0.858398 0.859375 3.48196 0.859375 6.71849V23.7921C0.859375 27.0286 3.65457 29.6276 7.09543 29.6276Z"
              fill="#000056"
            />
          </svg>
        </button>
      )}

      {showAdminChat && (
        <AdminChatBox
          handleCloseChat={() => setShowAdminChat((prevState) => !prevState)}
          chatRef={chatRef}
        />
      )}
    </>
  );
};

export default AdminChat;
