import * as React from "react";

import {
  Select,
  FormControl,
  FormHelperText,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import { useStyles } from "./style";

export function SelectInput(props) {
  const { options } = props;
  const classes = useStyles();

  return (
    <FormControl
      className={classes.selectCont}
      /*error*/ required={props.required}
      style={props.style}
    >
      <InputLabel id="demo-simple-select-helper-label">
        {props.label}
      </InputLabel>
      <Select
        fullWidth
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        {...props}
        className={`${classes.basicSelect}`}
        defaultValue={props.customId && options[options?.length - 1]?.Id}
        MenuProps={{
          getContentAnchorEl: null,
          transformOrigin: { horizontal: "center", vertical: "top" },
          anchorOrigin: { horizontal: "center", vertical: "bottom" },
        }}
        // onChange={handleChange}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {options?.map((op) => (
          <MenuItem value={op.Id}>
            {props.custom
              ? `${op.BuildingNo} ${op.Street} st, ${op.AreaEn} ${op.DistrictEn}, ${op.CityEn}`
              : op.NameEn}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
