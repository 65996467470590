import { HashLoader } from "react-spinners";
import moment from "moment";

//! Utils
import { getStatusColor } from "../../../utils/getStatusColor";
import AttachmentsDetails from "../../../components/Shared/AttachmentsDetails";

const UserDealDetails = ({ selectedDeal, isLoading, handleConfirmAndPay }) => {
  const currency = localStorage.getItem("currency");

  return (
    <div className="p-4 flex-grow-1">
      <h3 className="text-center">{selectedDeal?.ServiceNameEn}</h3>

      <div className="requestDetailsAndCandel">
        <p>Request Details</p>

        <p style={{ color: getStatusColor(selectedDeal?.RequestStatusId) }}>
          {selectedDeal?.RequestStatusEn}
        </p>
      </div>

      <div className="row">
        <div className="col-lg-5">
          <div className="problemDescriptions">
            <div className="firstDiv">
              <p>Problem Description</p>

              <textarea
                style={{ resize: "none" }}
                type="text"
                name=""
                id=""
                disabled
                value={selectedDeal?.ProblemDescription}
              />
            </div>

            <div className="firstDiv">
              <p>Client Payment method</p>

              <input
                type="text"
                name=""
                id=""
                disabled
                value={selectedDeal?.ClientPaymentMethodEn}
              />
            </div>
          </div>

          <div className="firstDiv mt-3">
            <p>Estimated Delivery Date</p>

            <input
              type="text"
              name=""
              id=""
              disabled
              value={
                selectedDeal?.EstimatedTimeHours
                  ? moment
                      .unix(selectedDeal?.EstimatedTimeHours)
                      .format("DD/MM/YYYY")
                  : ""
              }
            />
          </div>

          <div className="firstDiv mt-3">
            <p>Cost ( {currency} )</p>

            <input
              type="text"
              name=""
              id=""
              disabled
              value={selectedDeal?.Cost}
            />
          </div>

          <div className="firstDiv mt-3">
            <p>Project Starting Time</p>

            <input
              type="text"
              name=""
              id=""
              disabled
              value={
                selectedDeal?.ProjectStartingTime
                  ? moment
                      .unix(selectedDeal?.ProjectStartingTime)
                      .format("DD/MM/YYYY")
                  : ""
              }
            />
          </div>

          {selectedDeal.DisplayConfirmAndPay && (
            <button
              className="acceptOfferBtn"
              disabled={isLoading}
              onClick={() => handleConfirmAndPay(selectedDeal, "deal")}
            >
              {isLoading ? (
                <HashLoader
                  size={20}
                  className="d-flex justify-content-center align-items-center"
                  color={"#00EC85"}
                  loading={isLoading}
                />
              ) : (
                "Confirm & Pay"
              )}
            </button>
          )}

          {selectedDeal?.DisplayFinishProject && (
            <button
              className="acceptOfferBtn"
              onClick={() => handleConfirmAndPay(selectedDeal, "deal")}
            >
              {isLoading ? (
                <HashLoader
                  size={20}
                  className="d-flex justify-content-center align-items-center"
                  color={"#00EC85"}
                  loading={isLoading}
                />
              ) : (
                "Confirm & Pay"
              )}
            </button>
          )}
        </div>

        <div className="col-lg-7 mt-3 mt-lg-0">
          <AttachmentsDetails
            details={selectedDeal}
            senderId={selectedDeal.UserId}
            receiverId={selectedDeal.ExpertUserId}
          />
        </div>
      </div>
    </div>
  );
};

export default UserDealDetails;
