import React from 'react';
import { BsChevronRight } from 'react-icons/bs';
import moment from 'moment';

import { getStatusColor } from '../../../utils/getStatusColor';

import ExpertImg from './../../../assests/img/engineer.png';

const UserDealsCard = ({ deal, selectedDeal, handleSelectedItem }) => {
  return (
    <div className='projectsCard'>
      <div className='d-flex flex-column flex-xl-row gap-3'>
        <div className='deal-img'>
          <img
            src={ExpertImg}
            alt='Expert'
          />
        </div>

        <div className='d-flex flex-column justify-content-center gap-2 mb-2 flex-grow-1'>
          <p
            style={{
              color: '#545454',
              fontSize: '1rem',
              fontWeight: '600',
              fontFamily: 'Poppins',
            }}
          >
            {deal.ExpertUserName}
          </p>

          <p
            style={{
              color: '#4E4E4E',
              fontWeight: '400',
              fontFamily: 'Poppins',
            }}
          >
            {deal.TitleEn}
          </p>

          <div className='d-flex flex-column flex-xxl-row justify-content-between'>
            <p
              style={{
                color: getStatusColor(deal.RequestStatusId),
                fontWeight: '500',
                fontFamily: 'Poppins',
              }}
            >
              {deal.RequestStatusEn}
            </p>

            <p
              style={{
                color: '#545454',
                fontWeight: '500',
                fontFamily: 'Poppins',
              }}
            >
              {deal.RequestActualDate === 0
                ? null
                : moment.unix(deal.RequestActualDate).format('DD/MM/YYYY')}
            </p>
          </div>
        </div>
      </div>

      <button
        onClick={() => handleSelectedItem(deal.Id, 'deal')}
        style={{
          backgroundColor: `${deal.Id === selectedDeal?.Id ? '#03be6c' : ''}`,
          color: `${deal.Id === selectedDeal?.Id ? 'white' : ''}`,
        }}
      >
        Details
        {deal.Id === selectedDeal?.Id && <BsChevronRight className='ms-2' />}
      </button>
    </div>
  );
};

export default UserDealsCard;
