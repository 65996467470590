import React from "react";
import { Box, makeStyles, Modal } from "@material-ui/core";


const useStyle = makeStyles({
  cont: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    padding: "25px",
    borderRadius:"17px",
    border:"none",
    height:"100%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
   
    },

});
const ModalComponent=(props)=> {
  const style = {};
  const classes = useStyle();
  const { RenderedComponent, open, title } = props;
  const Comp = RenderedComponent;
  return (
    <Modal
      open={open}
      title={props.title}
      onClose={props.onClose}
      onSubmit={props.onFormSubmit}
      disableEnforceFocus
      // keepMounted
    >
      <Box className={`${classes.cont} ${props.class}`}>
        <Comp {...props} />
        
      </Box>
    </Modal>
  );
}
export default ModalComponent