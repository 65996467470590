import { useStyles } from "./style";
import Item from "../../../components/item/Item";
import { Button } from "@material-ui/core";
import Clicktick from "../../../context/context";
import { useContext } from "react";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";

export default function CartItems({}) {
  const classes = useStyles();
  const { setCart, setAsyncLoading, user, cart } = useContext(Clicktick);
  const [items, setItems] = useState([]);
  const handleFlush = async () => {
    // setAsyncLoading(true);
    const response = await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/Order/DeleteAllCartItems`
    );

    if (response.data.Data) {
      setCart([]);
    }
    // setAsyncLoading(false);
  };
  useEffect(() => {
    setItems(cart);
  }, [cart]);
  return (
    <div className={classes.cartItemsC}>
      <div className={classes.cartItems}>
        {items?.map((item, i) => {
          return <Item key={i} item={item} type="cart" />;
        })}
      </div>
      {cart && (
        <Button
          variant="outlined"
          className={classes.flush}
          onClick={handleFlush}
        >
          Remove all
        </Button>
      )}
    </div>
  );
}
