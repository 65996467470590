import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLoader } from 'react-spinners';
import StarPicker from 'react-star-picker';
import { Helmet } from 'react-helmet';
import Slider from 'react-slick';
import axios from 'axios';

//! Components
import NavbarExpert from './NavbarExpert';
import Footer1 from '../components/Footer/Footer1';
import Footer2 from '../components/Footer/Footer2';
import ExpertRequestsCard from './ExpertRequestsCard';
import ExpertDealsCard from './ExpertDealsCard';

//! Images
import Start from '../assests/img/Start-up.png';
import ReviewImg from '../assests/img/Group.png';
import ExpertImg from '../assests/img/Group-1000004820.png';

//! Css
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ExpertView.css';

const settings = {
  className: 'slider variable-width',
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        // infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const HomeExpertView = () => {
  const [requests, setRequests] = useState({ RecentRequests: [], MyDeals: [] });
  const [expertInfo, setExpertInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const IsActiveExpert = localStorage.getItem('IsActiveExpert');
  const username = localStorage.getItem('username');

  const getRequests = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/ExpertView/ExpertAllRequests`)
      .then((res) => {
        setRequests(res?.data?.Data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getExpertInfo = async () => {
    setIsLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/ExpertView/GetExpertProfile`)
      .then((res) => {
        setExpertInfo(res.data.Data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getRequests();
    getExpertInfo();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Clicktick: Expert Home</title>
      </Helmet>

      <NavbarExpert />

      {isLoading ? (
        <div
          className='d-flex justify-content-center align-items-center'
          style={{ minHeight: '200px' }}
        >
          <HashLoader
            color={'#00EC85'}
            loading={isLoading}
          />
        </div>
      ) : (
        <>
          <div className='expertHome mt-5'>
            <div className='container'>
              <div className='headingExpert'>
                <div className='row'>
                  <div className='col-md-6 d-flex align-items-center justify-content-end center-mob'>
                    <p>
                      Start Now your <span>Expert Service</span>
                    </p>
                  </div>

                  <div className='col-md-6 d-flex align-items-center justify-content-center'>
                    <div>
                      <img
                        src={Start}
                        alt='start'
                        className='img-fluid'
                      />
                    </div>
                  </div>
                </div>
              </div>

              {IsActiveExpert === 'true' ? (
                <>
                  <div className='topper'>
                    <div className='d-flex align-items-center justify-content-center mt-5 flex-column'>
                      <img
                        src={ExpertImg}
                        alt='review'
                        className='img-fluid'
                        style={{ width: '7rem' }}
                      />

                      <p
                        style={{
                          fontWeight: '600',
                          fontSize: '1.5rem',
                          margin: '1.5rem 0',
                          color: '#545454',
                        }}
                      >
                        Welcome,
                        <span style={{ color: '#545454', fontWeight: '500' }}>
                          &nbsp;{expertInfo?.UserName}
                        </span>
                      </p>

                      <p>{expertInfo?.TitleEn}</p>

                      <div className='d-flex align-items-center justify-content-center mb-5'>
                        <StarPicker
                          value={expertInfo?.Rate}
                          disabled
                        />

                        <span className='ms-3'>{expertInfo?.Rate}</span>
                      </div>
                    </div>
                  </div>

                  <div className='requestsForExpert'>
                    <div className='topSection'>
                      <p>Recent Requests</p>

                      <Link to='/expert-requests?type=request'>See All</Link>
                    </div>

                    <div className='row'>
                      {requests?.RecentRequests?.length > 0 ? (
                        <Slider {...settings}>
                          {requests?.RecentRequests?.map((request) => (
                            <div
                              className='col-md-4 p-2'
                              key={request.Id}
                            >
                              <ExpertRequestsCard request={request} />
                            </div>
                          ))}
                        </Slider>
                      ) : (
                        <div
                          className='d-flex align-items-center justify-content-center'
                          style={{
                            minHeight: '10rem',
                            fontSize: '1.3rem',
                            color: '#969696',
                          }}
                        >
                          <p className='text-center mt-3'>
                            No requests available
                          </p>
                        </div>
                      )}
                    </div>

                    <div className='myProjects'>
                      <div className='headProjects'>
                        <p>My Deals</p>

                        <Link to='/expert-requests?type=deal'>See All</Link>
                      </div>

                      <div className='row'>
                        {requests?.MyDeals?.length > 0 ? (
                          <Slider {...settings}>
                            {requests?.MyDeals?.map((deal) => (
                              <div
                                className='col-md-4 p-2'
                                key={deal.Id}
                              >
                                <ExpertDealsCard deal={deal} />
                              </div>
                            ))}
                          </Slider>
                        ) : (
                          <div
                            className='d-flex align-items-center justify-content-center'
                            style={{
                              minHeight: '10rem',
                              fontSize: '1.3rem',
                              color: '#969696',
                            }}
                          >
                            <p className='text-center mt-3'>
                              No deals available
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className='d-flex align-items-center justify-content-center mt-5 flex-column'>
                  <p
                    style={{
                      fontWeight: '600',
                      fontSize: '1.5rem',
                      color: '#03BE6C',
                      marginBottom: '1.5rem',
                    }}
                  >
                    Welcome,
                    <span style={{ color: '#545454' }}>{username}</span>
                  </p>

                  <img
                    src={ReviewImg}
                    alt='review'
                    className='img-fluid'
                    style={{ width: '7rem' }}
                  />

                  <p
                    style={{
                      color: '#545454)',
                      fontWeight: '600',
                      margin: '1rem 0',
                      fontSize: '1.1rem',
                    }}
                  >
                    Your data is under review
                  </p>

                  <p
                    style={{
                      fontSize: '1rem',
                      margin: '3rem 0',
                      width: '29%',
                      color: '#454545',
                      fontWeight: '400',
                      textAlign: 'center',
                    }}
                  >
                    Your request has been submitted and it's currently pending
                    approval
                  </p>
                </div>
              )}
            </div>
          </div>

          <Footer1
            backgroundColor='#303030'
            colorWhite='#FFFFFF'
            colorGreen='rgba(0, 237, 133, 1)'
          />

          <Footer2 />
        </>
      )}
    </div>
  );
};

export default HomeExpertView;
