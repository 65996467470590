import React from "react";
import NavbarExpert from "./NavbarExpert";
import { Link } from "react-router-dom";
import Denied from "../assests/img/amico.png";
import { Helmet } from "react-helmet-async";

const AccessDenied = () => {
  return (
    <>
      <Helmet>
        <title>Clicktick: Access Denied</title>
      </Helmet>
      <NavbarExpert />
      <div className="accessDenied d-flex align-items-center justify-content-center flex-column gap-2">
        <h1
          style={{
            fontSize: "1.5rem",
            color: "#000056",
            fontWeight: "600",
          }}
        >
          Access Denied
        </h1>
        <p
          style={{
            color: "#717171",
            fontSize: "1rem",
            fontWeight: "400",
            width: "20%",
            textAlign: "center",
          }}
        >
          Sorry about that, but you don't have permission to access this page
        </p>
        <img
          src={Denied}
          alt="access denied"
          className="img-fluid"
          style={{ width: "15rem" }}
        />
        <p
          style={{ color: "#545454", fontSize: "1.1rem", textAlign: "center" }}
        >
          you can go back
        </p>
        <Link
          to="/"
          style={{
            color: "white",
            backgroundColor: "#000056",
            padding: "0.8rem 8rem",
            borderRadius: "0.625rem",
          }}
        >
          Home
        </Link>
      </div>
    </>
  );
};

export default AccessDenied;
