import React, { useState } from "react";
import "./TAC.css";
import Navbar from "../../components/Navbar/Navbar";
import Footer2 from "../../components/Footer/Footer2";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { DotLoader } from "react-spinners";
import { Helmet } from "react-helmet-async";

const TAC = () => {
  const [apiData, setApiData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getAbout = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/Home/Web_TermsAndConditions`)
      .then((res) => {
        setApiData(res.data.Data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getAbout();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Helmet>
        <title>Terms and Conditions | Clicktick</title>
        <meta name="title" content="Terms and Conditions | Clicktick" />
        <meta
          name="description"
          content=" Use of the Clicktick application and website is expressly conditioned with terms and conditions"
        />
        <meta
          name="keywords"
          content="Clicktick, Click tick, Terms and Conditions, Clicktick Terms and Conditions"
        />
        <link rel="canonical" href="https://clicktick.app/terms" />
      </Helmet>
      <Navbar />
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <DotLoader color={"#00EC85"} loading={isLoading} />
        </div>
      ) : (
        <section className="TAC">
          <div className="container-fluid BgColorCopyRight">
            <div className="CopyRightHeading">
              <h2>Terms And Conditions</h2>
            </div>
          </div>
          <div className="container mt-5 info">
            <div className="first justify-content-start">
              <p className="m-0">
                <div
                  dangerouslySetInnerHTML={{
                    __html: `<div>${apiData.ValueEn}</div>`,
                  }}
                ></div>
              </p>
            </div>
            {/* <div className="second mt-4">
              <h4>CONSENT</h4>
              <p>
                By using the Clicktick Website and App you consent to the use of
                your personal data in the manner set out in this privacy policy.
                Each time you use the Website and App you agree that the privacy
                policy current at that time shall apply to all data we hold
                about you.
              </p>
            </div>
            <div className="third mt-4">
              <h4>WHAT IS PERSONAL DATA?</h4>
              <p>
                Your personal data includes information such as your:
                <ul>
                  <li>Name</li>
                  <li>Address</li>
                  <li>Telephone number</li>
                  <li>Date of birth</li>
                  <li>Email address</li>
                  <li>
                    And any other data collected that could, directly or
                    indirectly, be used to identify you.
                  </li>
                </ul>
              </p>
            </div>
            <div className="fourth mt-4">
              <h4>WHAT PERSONAL DATA DO WE COLLECT AND HOW DO WE USE IT?</h4>
              <p>
                We may collect and process the following data about you:
                <ul>
                  <li>
                    Information that you provide to us by filling in forms on
                    the Website, including the information that we collect from
                    you when you register to use the Website (name, telephone
                    number, email address, home address, password and, in some
                    cases, billing address and credit card information). If you
                    attend classes at Clicktick and you wish to access your
                    account details through the Website or the App, you will be
                    asked to confirm your email address and password.
                  </li>
                  <li>
                    Details of any transactions you carry out or any orders you
                    make through the Website.
                  </li>
                  <li>
                    If you contact us, we may keep a record of our
                    correspondence, including when you report a problem with the
                    Website, provide feedback regarding any of our Services, or
                    apply for a job or volunteer position at Clicktick.
                  </li>
                  <li>
                    We may also ask you to complete surveys on our Services for
                    market research purposes and collect your answers to these
                    surveys. These surveys are not compulsory and you do not
                    have to respond to them.
                  </li>
                  <li>
                    We use this data for the purposes of delivering the Services
                    and providing you with prompt and helpful customer service.
                    In particular, we use your personal data to.
                  </li>
                  <li>
                    Provide you with news and updates on our Services, including
                    updates on our Website, the App, and any changes in our
                    policies, procedures or Terms and Conditions.
                  </li>
                  <li>Contact you for feedback about our Services.</li>
                  <li>Complete your purchases or contact form.</li>
                  <li>
                    Contact you for marketing and promotional purposes, unless
                    you have indicated that you do not wish to be contacted for
                    these purposes.
                  </li>
                </ul>
                If you provide us with personal information about others, for
                example for the purposes of our referral rewards scheme, we will
                only use that information for the purposes for which it was
                provided to us. We may also collect certain data automatically,
                including but not limited to details of your visits to the
                Website, your IP address, operating system and browser type.
                This information is aggregated and does not identify any
                individual. It is used for system administration and may also be
                reported to our advertisers.
              </p>
            </div>
            <div className="fifth mt-4">
              <h4>OPT OUT</h4>
              <p>
                We will give you the opportunity to opt out of receiving direct
                marketing communications from Clicktick. We will also give you
                the opportunity to unsubscribe from receiving such
                communications once you have opted in. This means we assume you
                have given us your consent to collect and use your information
                in accordance with this Privacy Policy unless you take
                affirmative action to indicate that you do not consent, either
                by unsubscribing or by contacting us at info@clicktick.com.
              </p>
            </div>
            <div className="sixth mt-4">
              <h4>COOKIE POLICY</h4>
              <p>
                A cookie is a small text file that is sent to your browser from
                a website and stored on your computer’s hard drive. When you
                visit the Website, a cookie will be downloaded to your computer.
                This may be used by Clicktick to maintain your user session,
                control access to certain features such as your password and
                account, to customize content areas or analyze website activity
                and user behavior. You can use your web browser’s settings to
                notify you whenever a cookie is set, or to disallow cookies.
                However, please note that by prohibiting the use of cookies, you
                will restrict your access to certain content and features of the
                Website.
              </p>
            </div>
            <div className="seventh mt-4">
              <h4>THIRD PARTIES</h4>
              <p>
                The Website may include links to third party websites. Clicktick
                cannot be responsible for the privacy policies or practices of
                such other websites. We recommend that you read the privacy
                policy of each site you visit. Cookies may also be issued by
                third party websites that you enter through links from our
                Website. Please refer to the privacy policy of these websites
                for information on how these cookies will be used.
              </p>
            </div>
            <div className="eighth mt-4">
              <h4>AGE RESTRICTIONS</h4>
              <p>
                Our Services are not intended for those under the age of 13 and
                we will not knowingly collect personal data from such persons.
                Any person who provides information to us through the Website or
                App represents to us that they are 13 years of age or older. If
                we discover that a person under the age of 13 has provided us
                with personal data, we will endeavor to delete such data as soon
                as reasonably practicable. Please contact us at
                info@clicktick.com you know or are concerned that a person under
                the age of 13 has provided us with personal data.
              </p>
            </div>
            <div className="ninth mt-4">
              <h4>DATA SECURITY AND RETENTION</h4>
              <p>
                As with all information transmitted over the Internet, security
                cannot be guaranteed. Any transmission is at your own risk. Once
                we have received your information, we will take reasonable
                precautions to try to prevent unauthorized access. Clicktick
                will retain personal data only for as long as necessary to
                provide the Services you have requested and, thereafter, only
                for legitimate legal or business purposes, including: As
                mandated by law, contract or similar obligations applicable to
                our business operations; To preserve, resolve, defend or enforce
                our legal or contractual rights; As necessary to maintain
                accurate business and financial records.
              </p>
            </div>
            <div className="tenth mt-4">
              <h4>DATA TRANSFER, SHARING AND DISCLOSURE</h4>
              <p>
                The personal data we collect from you may be transferred to and
                stored at a destination outside the EEA, including in the USA.
                Where necessary, it may also be shared with and processed by one
                of our trusted third party service providers, including our
                instructors, so that they may perform services on our behalf.
                Such services may include, but are not limited to:
                <ul>
                  <li>Conducting contests or surveys.</li>
                  <li>
                    Performing analysis of our Services and customer
                    demographics.
                  </li>
                  <li>
                    Communicating with you, such as by way email or survey
                    delivery.
                  </li>
                  <li>Customer relationship management.</li>
                </ul>
                We only share your personal data as necessary for any third
                party to provide the services as requested or as needed on our
                behalf. We will take reasonable steps to ensure that your data
                is treated in accordance with this policy by any party with whom
                it is shared. If we collect information from you in connection
                with a co-branded offer, it will be clear at the point of
                collection who is collecting the information and whose privacy
                policy applies. In addition, it will describe any options you
                have in regards to the use and/or sharing of your personal data
                with a co-branded partner, as well as how to exercise those
                options. We may disclose personal data if we believe in good
                faith that we are required to do so by law, regulation, court
                order or process, in connection with litigation, to prevent or
                stop illegal activity, or to protect our property and rights, or
                the property and rights of a third party, to protect the safety
                of the public or any person. By providing your personal data to
                us, you agree to any such transfer, sharing, disclosure and
                processing of that personal data, as described above.
              </p>
            </div>
            <div className="eleventh mt-4">
              <h4>ACCESS TO YOUR DATA</h4>
              <p>
                You may correct, amend, or delete your personal data by editing
                your profile on the Site or emailing us at
                <Link to="mailto:info@clicktick.com"> info@clicktick.com </Link>
                .You also have a right to access a copy of the personal data we
                hold about you. Any request may be subject to a fee to meet our
                costs in responding to such a request. For the purpose of the
                Data Protection Act 1 998, the data controller is Clicktick
              </p>
            </div>
            <div className="twelveth mt-4">
              <h4>CONTACT</h4>
              <p>
                If you have any queries regarding this Privacy Policy or for
                general enquiries, problems or comments relating to the use of
                our site, or if you wish to opt out of marketing mailings,
                please contact Clicktick
              </p>
            </div> */}
          </div>
        </section>
      )}

      <Footer2 />
    </>
  );
};

export default TAC;
