// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { useStyles } from "./style";

import "swiper/css";
import "swiper/css/pagination";

export default function Slider(props) {
  const classes = useStyles({ hide: props.hide });

  return (
    <Swiper
      modules={[Pagination]}
      spaceBetween={props.spaceBetween}
      slidesPerView={props.slidesPerView}
      breakpoints={props.breakpoints}
      pagination={props.pagination ?? true}
      navigation={props.hide ? false : true}
      className={props.className || classes.sliderWrapper}
      direction={props.direction || "horizontal"}
    >
      {props.content?.map((item, index) => (
        <SwiperSlide key={index}>
          <props.child item={item} onClick={props.onClick} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

Slider.defaultProps = {
  spaceBetween: 20,
  slidesPerView: 1,
  breakpoints: {
    600: { slidesPerView: 2 },
    900: { slidesPerView: 3 },
    1200: { slidesPerView: 4 },
  },
  pagination: { clickable: true },
  direction: "horizontal",
  hide: false,
};
