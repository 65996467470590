import React, { useState, useEffect } from "react";
import { BreadCrumb } from "../../../components/BreadCrumb";
import Navbar from "../../../components/Navbar/Navbar";
import axios from "axios";
import "./style.css";
import { DotLoader } from "react-spinners";
import Footer2 from "../../../components/Footer/Footer2";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function EditProfile() {
  const [successMsg, setSuccessMsg] = useState("");
  const [msg, setMsg] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [userInitialData, setUserInitialData] = useState([]);
  const navigate = useNavigate();

  const getProfile = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/Account/UserDetails`)
      .then((res) => {
        setIsLoading(false);
        setUserInitialData(res.data.Data);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getProfile();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const userData = {
      Email: userInitialData.Email,
      UserName: userInitialData.UserName,
    };
    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/Account/EditProfile`,
        userData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        setMsg("");
        setIsLoading(false);
        toast.success("Profile is updated successfully.");
        setTimeout(() => {
          navigate("/");
        }, 1500);
      })
      .catch((err) => {
        setMsg(err.response.data.Message);
        setIsLoading(false);
        toast.danger("An error has occured.");
      });
  };

  return (
    <>
      <Helmet>
        <title>Edit Profile | Edit Your Info With Clicktick</title>
        <meta name="title" content="Edit Profile | Edit Your Info With Clicktick" />
        <meta
          name="description"
          content="Edit your profile information such as name, phone number, and email with Clicktick easily"
        />
        <meta
          name="keywords"
          content="Clicktick, Click tick, profile, edit profile"
        />
      </Helmet>
      <Navbar />
      <BreadCrumb
        list={[
          {
            name: "Account",
            link: "",
          },
          {
            name: "Edit Profile",
            link: "",
          },
        ]}
      />
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            // alignItems: "center",
            height: "100vh",
          }}
        >
          <DotLoader color={"#00EC85"} loading={isLoading} />
        </div>
      ) : (
        <div className="container mb-5">
          <div className="editProfile">
            <form onSubmit={handleSubmit} className="w-100">
              <div className="firstEdit">
                <div className="left">
                  <label className="mb-1">Name</label>
                  <input
                    type="text"
                    placeholder="Name"
                    className="w-100"
                    value={userInitialData.UserName || ""}
                    onChange={(e) =>
                      setUserInitialData({
                        ...userInitialData,
                        UserName: e.target.value,
                      })
                    }
                  />
                </div>
                {/* <div className="col-md-6 PadSmall">
                  <div className="right">
                    <label className="mb-1">Number</label>
                    <input
                      type="text"
                      placeholder="Number"
                      className="w-75"
                      value={userInitialData.Phone || ""}
                      onChange={(e) =>
                        setUserInitialData({
                          ...userInitialData,
                          Phone: e.target.value,
                        })
                      }
                    />
                  </div>
                </div> */}
              </div>
              <div className="userData">
                <label className="mb-1">Phone</label>
                <input
                  disabled
                  type="text"
                  placeholder="Phone"
                  value={userInitialData?.Phone || ""}
                  onChange={(e) =>
                    setUserInitialData({
                      ...userInitialData,
                      DefaultAddress: {
                        ...userInitialData.DefaultAddress,
                        Street: e.target.value,
                      },
                    })
                  }
                />
                <label className="mb-1">Mail</label>
                <input
                  type="email"
                  placeholder="Email"
                  value={userInitialData.Email || ""}
                  onChange={(e) =>
                    setUserInitialData({
                      ...userInitialData,
                      Email: e.target.value,
                    })
                  }
                />
              </div>
              {msg && (
                <p
                  className="invalid fw-bold mb-0 text-center"
                  style={{ marginTop: " px" }}
                >
                  {msg}
                </p>
              )}
              <button type="submit">Save</button>
              {/* {errMsg && <p className="error-message">{errMsg}</p>} */}
            </form>
          </div>
        </div>
      )}
      <Footer2 />
    </>
  );
}
