import React, { useContext, useEffect, useState } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  useRoutes,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
// import { css } from "@emotion/react";
import { MoonLoader } from "react-spinners";
import axios from "axios";
import interceptor from "./interceptor";

//~ Contexts
import ClicktickProvider from "./context";
import Clicktick from "./context/context";

//~ Components
import Home from "./pages/Home/Home";

//~ User Account Components
import AboutUs from "./pages/Account/About-Us/AboutUs";
import ContactUs from "./pages/Account/Contact-Us/ContactUs";
import EditProfle from "./pages/Account/Edit-Profile/EditProfile";
import MyOrders from "./pages/Account/MyOrders/MyOrders";
import MyServices from "./pages/Account/MyServices/MyServices";
import Notifications from "./pages/Account/Notifications/Notifications";
import MyAddresses from "./pages/Account/Addresses/Addresses";
import SocialLogin from "./pages/Account/SocialLogin/SocialLogin";
import ChangePassword from "./pages/Account/ChangePassword/ChangePassword";
import MyExpertRequest from "./pages/Account/MyExpertRequests/MyExpertRequest";

//~ Login Components
import LoginUser from "./pages/Login/LoginUser";
import LoginExpert from "./pages/Login/LoginExpert";
import SignupUser from "./pages/Login/SignupUser";
import SignUpExpert from "./pages/Login/SignupExpert";
import ForgetPassword from "./pages/Login/ForgetPassword";
import ResetPassword from "./pages/Login/ResetPassWord";
import Verify from "./pages/Login/Verify";
import VerifyForgetPassword from "./pages/Login/VerifyForgetPassword";

//~ Experts Components (From User View)
import Experts from "./pages/Experts/Experts";
import AllExperts from "./pages/Experts/AllExperts";
import ExpertDetails from "./pages/Experts/ExpertDetails";

//~ Service Details Components
import ServiceDetails from "./pages/Service-Details/ServiceDetails";

//~ Services Components
import Services from "./pages/Services/Services";

//~ Shop Components
import Shop from "./pages/Shop/Shop";
import ShopProducts from "./pages/Shop/ShopProducts";

//~ Cart Components
import Cart from "./pages/Cart/Cart";

//~ Wishlist Components
import Wishlist from "./pages/Wishlist/Wishlist";

//~ Product components
import ProductDetails from "./pages/Product/Product";

//~ TAC Components
import TAC from "./pages/TAC/TAC";

//~ Privacy Components
import Privacy from "./pages/Privacy/Privacy";

//~ CopyRight Components
import CopyRight from "./pages/CopyRight/CopyRight";

//~ Checkout Components
import Checkout from "./pages/checkout/Checkout";
import CallBackResponse from "./pages/checkout/CallBackResponse";

//~ Careers Components
import Careers from "./pages/Careers/Careers";
import AvailableCareers from "./pages/Careers/AvailableCareers";
import JobDetails from "./pages/Careers/JobDetails";
import JobApply from "./pages/Careers/JobApply";

//~ ExpertView Components
import HomeExpertView from "./ExpertView/HomeExpertView";
import ProfileExpertView from "./ExpertView/ProfileExpertView";
import RequestsExpertView from "./ExpertView/RequestsExpertView";
import CareersExpertView from "./ExpertView/CareersExpertView";
import AvailableJobsExpertView from "./ExpertView/AvailableJobsExpertView";
import JobApplyExpertView from "./ExpertView/JobApplyExpertView";
import JobDetailsExpertView from "./ExpertView/JobDetailsExpertView";
import ContactUsExpertView from "./ExpertView/ContactUsExpertView";
import AccessDenied from "./ExpertView/AccessDenied";
import ReviewsExpertView from "./ExpertView/ReviewsExpertView";
import AboutExpertView from "./ExpertView/AboutExpertView";
import NotificationsExpertView from "./ExpertView/NotificationsExpertView";
import ExpertCompleteProfile from "./ExpertView/ExpertCompleteProfile";

//~ Global Components
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import ReturnPolicy from "./pages/ReturnPolicy/ReturnPolicy";
import ComingSoon from "./pages/ComingSoon/ComingSoon";
import { Notify } from "./components/Notify";
import AdminChat from "./components/Chat/AdminChat";

//~ Css
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

interceptor({});

// const spinnerStyles = css`
//   display: block;
//   margin: 0 auto;
// `;

const Layout = () => {
  const { notify, setCart } = useContext(Clicktick);

  useEffect(() => {
    const getCartItems = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/Order/GetCartList`
        );
        setCart(data?.Data);
      } catch (err) {
        console.log("🚀 ~ file: App.js:89 ~ getCartItems ~ err:", err);
      }
    };

    getCartItems();
  }, []);

  return (
    <div className="app">
      <Notify open={notify.open} message={notify.message} />

      <RoleBasedRoutes />
    </div>
  );
};

if (!localStorage.getItem("role")) {
  localStorage.setItem("role", "User");
}

function RoleBasedRoutes() {
  // const { UserTypeId } = useContext(Clicktick);

  const userRoutes = useRoutes([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/shop",
      element: <Shop />,
    },
    {
      path: "/experts",
      // element: <Experts />,
      element: <ComingSoon />,
    },
    {
      path: "/all-experts",
      element: <AllExperts />,
    },
    {
      path: "/expert-details/:Id/:Name",
      element: <ExpertDetails />,
    },
    {
      path: "/service-details/:Name/:Id",
      element: <ServiceDetails />,
    },
    {
      path: "/services",
      element: <Services />,
    },
    {
      path: "/about-us",
      element: <AboutUs />,
    },
    {
      path: "/contact-us",
      element: <ContactUs />,
    },
    {
      path: "/orders",
      element: <MyOrders />,
    },
    {
      path: "/expert-requests",
      element: <MyExpertRequest />,
    },
    {
      path: "/edit-profile",
      element: <EditProfle />,
    },
    {
      path: "/my-services",
      element: <MyServices />,
    },
    {
      path: "/notifications",
      element: <Notifications />,
    },
    {
      path: "/signupUser",
      element: <SignupUser />,
    },
    {
      path: "/signupexpert",
      element: <SignUpExpert />,
    },
    {
      path: "/LoginUser",
      element: <LoginUser />,
    },
    {
      path: "/LoginExpert",
      element: <LoginExpert />,
    },
    {
      path: "/Verify",
      element: <Verify />,
    },
    {
      path: "/VerifyForgetPassword",
      element: <VerifyForgetPassword />,
    },
    {
      path: "/reset-password",
      element: <ResetPassword />,
    },
    {
      path: "/change-password",
      element: <ChangePassword />,
    },
    {
      path: "/shop-products/:category/:catId",
      element: <ShopProducts />,
    },
    {
      path: "/cart",
      element: <Cart />,
    },
    {
      path: "/wishlist",
      element: <Wishlist />,
    },
    {
      path: "/productDetails/:Name/:Id",
      element: <ProductDetails />,
    },
    {
      path: "/careers",
      element: <Careers />,
    },
    {
      path: "/available-jobs",
      element: <AvailableCareers />,
    },
    {
      path: "/paymob/CallBackResponse",
      element: <CallBackResponse />,
    },
    {
      path: "/job-details",
      element: <JobDetails />,
    },
    {
      path: "/job-apply",
      element: <JobApply />,
    },
    {
      path: "/copy-right",
      element: <CopyRight />,
    },
    {
      path: "/coming-soon",
      element: <ComingSoon />,
    },
    {
      path: "/return-policy",
      element: <ReturnPolicy />,
    },
    {
      path: "/terms",
      element: <TAC />,
    },
    {
      path: "/privacy",
      element: <Privacy />,
    },
    {
      path: "/complete-profile",
      element: <SocialLogin />,
    },
    {
      path: "/my-address",
      element: <MyAddresses />,
    },
    {
      path: "/checkout",
      element: <Checkout />,
    },
    {
      path: "/forget-password",
      element: <ForgetPassword />,
    },
    { path: "/checkout", element: <Checkout /> },
    {
      path: "/*",
      element: <PageNotFound />,
    },
    {
      path: "*",
      element: <AccessDenied />,
    },
  ]);

  const expertRoutes = useRoutes([
    {
      path: "/",
      element: <HomeExpertView />,
    },
    {
      path: "/LoginUser",
      element: <LoginUser />,
    },
    { path: "/expert-home", element: <HomeExpertView /> },
    { path: "/expert-profile", element: <ProfileExpertView /> },
    { path: "/expert-requests/:requestId", element: <RequestsExpertView /> },
    { path: "/expert-requests", element: <RequestsExpertView /> },
    {
      path: "/career",
      element: <CareersExpertView />,
    },
    {
      path: "/available-jobs",
      element: <AvailableJobsExpertView />,
    },
    {
      path: "/job-apply",
      element: <JobApplyExpertView />,
    },
    {
      path: "/job-details",
      element: <JobDetailsExpertView />,
    },
    {
      path: "/contact",
      element: <ContactUsExpertView />,
    },
    {
      path: "/reviews",
      element: <ReviewsExpertView />,
    },
    { path: "/about-us", element: <AboutExpertView /> },
    { path: "/expert-notifications", element: <NotificationsExpertView /> },
    {
      path: "/expert-complete-profile",
      element: <ExpertCompleteProfile />,
    },
    {
      path: "/signupUser",
      element: <SignupUser />,
    },
    {
      path: "/Verify",
      element: <Verify />,
    },
    {
      path: "*",
      element: <AccessDenied />,
    },
    // ... Define expert-specific routes here
  ]);

  if (localStorage.getItem("role") === "User") {
    // Render user routes for UserTypeId 2
    return userRoutes;
  } else if (localStorage.getItem("role") === "Expert") {
    // Render expert routes for UserTypeId 3
    return expertRoutes;
  } else {
    // Handle any other role or invalid roles here
    return userRoutes;
  }
}

// function UserRoutes() {
//   return (
//     <>
//       <Home />
//       <Shop />
//       <LoginUser />
//     </>
//   );
// }

// function ExpertRoutes() {
//   return (
//     <>
//       <Services />
//       <Experts />
//       <LoginUser />
//     </>
//   );
// }

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/shop",
        element: <Shop />,
      },
      {
        path: "/experts",
        element: <Experts />,
      },
      {
        path: "/all-experts",
        element: <AllExperts />,
      },
      {
        path: "/expert-details/:Id/:Name",
        element: <ExpertDetails />,
      },
      {
        path: "/service-details/:Name/:Id",
        element: <ServiceDetails />,
      },
      {
        path: "/services",
        element: <Services />,
      },
      {
        path: "/about-us",
        element: <AboutUs />,
      },
      {
        path: "/contact-us",
        element: <ContactUs />,
      },
      {
        path: "/orders",
        element: <MyOrders />,
      },
      {
        path: "/expert-requests",
        element: <MyExpertRequest />,
      },
      {
        path: "/edit-profile",
        element: <EditProfle />,
      },
      {
        path: "/my-services",
        element: <MyServices />,
      },
      {
        path: "/notifications",
        element: <Notifications />,
      },
      {
        path: "/signupUser",
        element: <SignupUser />,
      },
      {
        path: "/signupexpert",
        element: <SignUpExpert />,
      },
      {
        path: "/LoginUser",
        element: <LoginUser />,
      },
      {
        path: "/LoginExpert",
        element: <LoginExpert />,
      },
      {
        path: "/Verify",
        element: <Verify />,
      },
      {
        path: "/VerifyForgetPassword",
        element: <VerifyForgetPassword />,
      },
      {
        path: "/reset-password",
        element: <ResetPassword />,
      },
      {
        path: "/change-password",
        element: <ChangePassword />,
      },
      {
        path: "/shop-products/:category/:catId",
        element: <ShopProducts />,
      },
      {
        path: "/cart",
        element: <Cart />,
      },
      {
        path: "/wishlist",
        element: <Wishlist />,
      },
      {
        path: "/productDetails/:Name/:Id",
        element: <ProductDetails />,
      },
      {
        path: "/careers",
        element: <Careers />,
      },
      {
        path: "/available-jobs",
        element: <AvailableCareers />,
      },
      {
        path: "/job-apply",
        element: <JobApply />,
      },
      {
        path: "/paymob/CallBackResponse",
        element: <CallBackResponse />,
      },
      {
        path: "/job-details",
        element: <JobDetails />,
      },
      {
        path: "/copy-right",
        element: <CopyRight />,
      },
      {
        path: "/coming-soon",
        element: <ComingSoon />,
      },
      {
        path: "/return-policy",
        element: <ReturnPolicy />,
      },
      {
        path: "/terms",
        element: <TAC />,
      },
      {
        path: "/privacy",
        element: <Privacy />,
      },
      {
        path: "/complete-profile",
        element: <SocialLogin />,
      },
      {
        path: "/expert-complete-profile",
        element: <ExpertCompleteProfile />,
      },
      {
        path: "/my-address",
        element: <MyAddresses />,
      },
      {
        path: "/checkout",
        element: <Checkout />,
      },
      {
        path: "/forget-password",
        element: <ForgetPassword />,
      },
      { path: "/checkout", element: <Checkout /> },
      { path: "/expert-home", element: <HomeExpertView /> },
      { path: "/expert-profile", element: <ProfileExpertView /> },
      { path: "/expert-requests/:requestId", element: <RequestsExpertView /> },
      { path: "/expert-requests", element: <RequestsExpertView /> },
      { path: "/career", element: <CareersExpertView /> },
      { path: "/about-us", element: <AboutExpertView /> },
      { path: "/expert-notifications", element: <NotificationsExpertView /> },
      {
        path: "/reviews",
        element: <ReviewsExpertView />,
      },
      {
        path: "/conatct",
        element: <ContactUsExpertView />,
      },
      {
        path: "/*",
        element: <PageNotFound />,
      },
    ],
  },
]);

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setLoading(false);
  }, []);

  return (
    <div style={{ overflowX: "hidden" }}>
      <ClicktickProvider>
        {loading && (
          <div className="loading-spinner">
            <MoonLoader color="#123abc" loading={loading} size={5000} />
          </div>
        )}

        <ToastContainer />

        <AdminChat />

        <RouterProvider router={router} />
      </ClicktickProvider>
    </div>
  );
}
export default App;
