import React from "react";
import "./ComingSoon.css";
import { motion, useAnimation } from "framer-motion";
import Navbar from "../../components/Navbar/Navbar";
import Footer2 from "../../components/Footer/Footer2";
import Soon from "../../assests/img/comingSoon.png";
import Ribbon from "../../assests/img/Ribbon.png";
import Arch from "../../assests/img/Arch.png";
import GreenDot from "../../assests/img/GreenDot.png";
import BlueDot from "../../assests/img/BlueDot.png";
import Badge from "../../assests/img/Badge.png";
import Dot from "../../assests/img/Dot.png";
import Triangle from "../../assests/img/Triangle.png";

const ComingSoon = () => {
  const bikeControls = useAnimation();

  const startBikeAnimation = async () => {
    await bikeControls.start({
      rotate: 360,
      scale: 1.2,
      transition: { duration: 2, ease: "easeInOut" },
    });

    await bikeControls.start({
      rotate: -360,
      scale: 0.8,
      transition: { duration: 2, ease: "easeInOut" },
    });

    await bikeControls.start({
      rotate: 0,
      scale: 1,
      transition: { duration: 1, ease: "easeInOut" },
    });
  };

  return (
    <>
      <Navbar />
      <div className="container mt-5">
        <div className="soon">
          <h4>WE ARE COMING SOON!!</h4>
          <p className="text1">Stay tuned for something amazing</p>
          <motion.img
            src={Triangle}
            alt="Triangle"
            className="img-fluid Triangle"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          />
          <motion.img
            src={Dot}
            alt="Dot"
            className="img-fluid Dot"
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
          />
          <div className="rightImgs">
            <div className="together">
              <motion.img
                src={GreenDot}
                alt="Green circle"
                className="img-fluid GreenDot"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
              />
              <motion.img
                src={Arch}
                alt="Arch"
                className="img-fluid Arch"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
              />
            </div>
          </div>
          <div className="soonImg">
            <motion.img
              src={Soon}
              alt="coming soon bike"
              className="img-fluid soonBike"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
            />
            <motion.img
              src={Badge}
              alt="Badge"
              className="img-fluid Badge"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1 }}
            />
            <motion.img
              src={Ribbon}
              alt="Ribbon"
              className="img-fluid Ribbon"
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1 }}
            />
          </div>
          {/* <p className="text2">
            Subscribe to our mailing list to get latest updates
          </p> */}
          <motion.img
            src={BlueDot}
            alt="Blue Dot"
            className="img-fluid BlueDot"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          />
          {/* <div className="mailList">
            <input
              type="text"
              name=""
              id=""
              className="soonInput"
              placeholder="Email"
            />
            <button>Subscribe</button>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ComingSoon;
