import { makeStyles } from "@material-ui/core";
// import { styleSettings } from "mongez/layout";

export const useStyles = makeStyles({
	sliderWrapper: {
		margin: "0 !important",
		paddingBottom: (props: any) =>
			props.hide ? "20px !important" : "70px !important",
		"& .swiper-pagination-bullet": {
			// backgroundColor: `${styleSettings.get("colors.black_color")}`,
			opacity: "0.7",
		},
		"& .swiper-button-next, .swiper-button-prev":{
			textRendering: 'auto',
			opacity:1
		  },
		"& .swiper-pagination-bullet-active": {
			// backgroundColor: `${styleSettings.get("colors.black_color")}`,
			opacity: "1",
		},
	},
});
