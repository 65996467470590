import { useState } from "react";
import { useStyles } from "./style";
import AddressForm from "./AddressForm";
import AddressList from "./AddressList";
import { useContext } from "react";
import CheckoutStore from "../../context/context";

export default function Address() {
  const classes = useStyles();
  const { addressMethod, setAddressMethod } = useContext(CheckoutStore);

  return (
    <div className={classes.address}>
      {addressMethod === "newAddress" ? (
        <AddressForm fromService={false} setAddressMethod={setAddressMethod} />
      ) : (
        <AddressList setAddressMethod={setAddressMethod} />
      )}
    </div>
  );
}
