import axios from "axios";
import { last } from "lodash";

const Interceptor = (props, store) => {
  axios.interceptors.request.use(
    (config) => {
      const CountryId = localStorage.getItem("CountryId");

      const Token = localStorage.getItem("token");
      if (Token) {
        config.headers.Authorization = `bearer ${Token}`;
        config.headers.CountryId = CountryId || 3;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      if (response.status === 401) {
        // return <Alert color="danger">You are not authorized</Alert>;
      }

      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export default Interceptor;
