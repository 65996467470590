import { useEffect, useRef, useState } from "react";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { BsCheckLg, BsPlus } from "react-icons/bs";
import { HashLoader } from "react-spinners";
import StarPicker from "react-star-picker";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";

import axios from "axios";

import Footer1 from "../components/Footer/Footer1";
import Footer2 from "../components/Footer/Footer2";
import NavbarExpert from "./NavbarExpert";

import ExpertImg from "../assests/img/Group-1000004820.png";
import DelIcon from "../assests/img/Trash-Bin-inimalistic.png";

import "./ExpertView.css";

const ProfileExpertView = () => {
  const [expertInfo, setExpertInfo] = useState({});
  const [certificates, setCertificates] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const [isEditingSummary, setIsEditingSummary] = useState(false);
  const [isEditingHourRate, setIsEditingHourRate] = useState(false);

  const fileInputRef = useRef(null);
  const currency = localStorage.getItem("currency");

  const getExpertInfo = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/ExpertView/GetExpertProfile`
      );

      setExpertInfo(response?.data?.Data);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async () => {
    setIsRequestLoading(true);

    const formData = new FormData();

    formData.append("ExpertSummary", expertInfo.Summary);
    formData.append("HourRate", expertInfo.HourRate);
    certificates.forEach((certificate) =>
      formData.append("Certifications", certificate)
    );

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/ExpertView/EditExpertProfile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setExpertInfo(response?.data?.Data);
      setIsEditingSummary(false);
      setIsEditingHourRate(false);
      setCertificates([]);

      toast.success("Profile updated successfully");
    } catch (error) {
      toast.error("Something went wrong, please try again");
    } finally {
      setIsRequestLoading(false);
    }
  };

  useEffect(() => {
    getExpertInfo();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Clicktick: Expert Profile</title>
      </Helmet>

      <NavbarExpert />

      {isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "200px" }}
        >
          <HashLoader
            color={"#00EC85"}
            loading={isLoading}
          />
        </div>
      ) : (
        <>
          <div className="expertProfile">
            <div className="container">
              <div className="row">
                <div className="col-md-5">
                  <div className="leftSide">
                    <img
                      src={ExpertImg}
                      alt="expert"
                      className="img-fluid"
                    />

                    <p style={{ fontWeight: "600" }}>
                      Welcome, <span>{expertInfo?.UserName}</span>
                    </p>

                    <p>{expertInfo?.TitleEn}</p>

                    <div className="d-flex align-items-center justify-content-center mb-5">
                      <StarPicker
                        value={expertInfo?.Rate}
                        disabled
                      />

                      <span className="ms-3">{expertInfo?.Rate}</span>
                    </div>

                    <div className="expertServices">
                      <p
                        style={{
                          fontSize: "1.2rem",
                          fontWeight: "600",
                          textAlign: "center",
                          margrinBottom: "30px",
                        }}
                      >
                        Your Services
                      </p>

                      {expertInfo?.Services?.length > 0 ? (
                        expertInfo.Services?.map((service) => (
                          <div
                            className="expertService"
                            key={service.Id}
                          >
                            <p>
                              <BsCheckLg className="check-icon" />
                              {service.ServiceNameEn}
                            </p>
                          </div>
                        ))
                      ) : (
                        <p>You have no services yet!</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-7">
                  <div className="rightSide">
                    <div className="expertViewDetails">
                      {!isEditingSummary && (
                        <>
                          <p className="title">
                            Your Summary
                            <MdOutlineModeEditOutline
                              className="ms-3"
                              onClick={() => setIsEditingSummary(true)}
                            />
                          </p>

                          <p className="expertDesc">{expertInfo?.Summary}</p>
                        </>
                      )}

                      {isEditingSummary && (
                        <div className="d-flex justify-content-center flex-column mb-3">
                          <input
                            className="rounded"
                            type="text"
                            value={expertInfo?.Summary || ""}
                            onChange={(e) =>
                              setExpertInfo((prevState) => ({
                                ...prevState,
                                Summary: e.target.value,
                              }))
                            }
                            style={{
                              width: "100%",
                              minHeight: "5rem",
                              border: "1px solid #545454",
                            }}
                          />

                          <div className="mt-3 ">
                            <button
                              onClick={handleUpdate}
                              style={{
                                color: "white",
                                backgroundColor: "#03BE6C",
                                border: "none",
                                borderRadius: "0.52238rem",
                                padding: "0.5rem 2rem",
                              }}
                            >
                              {isRequestLoading ? (
                                <HashLoader
                                  size={20}
                                  className="d-flex justify-content-center align-items-center"
                                  color="#fff"
                                  loading={isRequestLoading}
                                  disabled={isRequestLoading}
                                />
                              ) : (
                                "Save"
                              )}
                            </button>

                            <button
                              onClick={() => {
                                setIsEditingSummary(false);
                              }}
                              style={{
                                color: "#545454",
                                backgroundColor: "transparent",
                                border: "1.045px solid #9C9C9C",
                                borderRadius: "0.52238rem",
                                padding: "0.5rem 2rem",
                                marginLeft: "1rem",
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}

                      <div className="expertViewPrice">
                        {isEditingHourRate ? (
                          <>
                            <input
                              type="text"
                              required
                              pattern="[0-9]*"
                              value={expertInfo?.HourRate || ""}
                              onChange={(e) => {
                                if (/^[0-9]*$/.test(e.target.value)) {
                                  setExpertInfo((prevState) => ({
                                    ...prevState,
                                    HourRate: +e.target.value,
                                  }));
                                }
                              }}
                              style={{
                                maxWidth: "5rem",
                              }}
                            />

                            <div className="mt-3 d-inline ms-4">
                              <button
                                onClick={handleUpdate}
                                style={{
                                  color: "white",
                                  backgroundColor: "#03BE6C",
                                  border: "none",
                                  borderRadius: "0.52238rem",
                                  padding: "0.1rem 1rem",
                                  fontSize: "0.9rem",
                                }}
                              >
                                {isRequestLoading ? (
                                  <HashLoader
                                    size={20}
                                    className="d-flex justify-content-center align-items-center"
                                    color="#fff"
                                    loading={isRequestLoading}
                                    disabled={isRequestLoading}
                                  />
                                ) : (
                                  "Save"
                                )}
                              </button>

                              <button
                                onClick={() => {
                                  setIsEditingHourRate(false);
                                }}
                                style={{
                                  color: "#545454",
                                  backgroundColor: "transparent",
                                  border: "1.045px solid #9C9C9C",
                                  borderRadius: "0.52238rem",
                                  padding: "0.1rem 1rem",
                                  marginLeft: "1rem",
                                  fontSize: "0.9rem",
                                }}
                              >
                                Cancel
                              </button>
                            </div>
                          </>
                        ) : (
                          <>
                            <span>{expertInfo?.HourRate} </span>
                            {currency}/hr
                            <MdOutlineModeEditOutline
                              className="ms-3"
                              onClick={() => setIsEditingHourRate(true)}
                            />
                          </>
                        )}
                      </div>
                    </div>

                    <div className="expertViewCertificates">
                      <p className="title">Your Certificates</p>

                      <div className="row">
                        {expertInfo?.Certifications?.length > 0 ? (
                          expertInfo?.Certifications?.map((certificate) => (
                            <div
                              className="col-md-4"
                              key={certificate.Id}
                            >
                              <img
                                src={certificate.Url}
                                alt={certificate.TitleEn}
                                className="img-fluid"
                              />
                            </div>
                          ))
                        ) : (
                          <p
                            style={{
                              color: "#7e8588",
                              fontFamily: "Poppins",
                              fontSize: "1.2rem",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "2.46944rem",
                            }}
                          >
                            You have no certificates yet!
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="uploadCetrificates">
                      <div className="file-input-container">
                        <p
                          className="mb-1"
                          style={{
                            color: "#545454",
                            fontSize: "1.3rem",
                            marginBottom: "30px",
                          }}
                        >
                          {certificates.length > 0
                            ? "File Uploaded!"
                            : "Upload Certificates "}
                        </p>

                        <label
                          className="custom-file-input-label"
                          htmlFor="file-upload"
                        >
                          <BsPlus />
                          <div className="d-flex align-items-start justify-content-start flex-column">
                            <span
                              style={{
                                color:
                                  certificates.length > 0
                                    ? "#03BE6C"
                                    : "#545454",
                                fontWeight: "bold",
                                fontSize: "15px",
                              }}
                            >
                              {certificates.length > 0
                                ? "File Uploaded!"
                                : "Upload Certificates"}
                            </span>

                            <span
                              style={{
                                color: "#545454",
                                fontSize: "14px",
                              }}
                            >
                              {certificates[certificates.length - 1]?.name ||
                                "Upload more certificates"}
                            </span>
                          </div>

                          {certificates.length > 0 ? (
                            <img
                              src={DelIcon}
                              alt="delete icon"
                              className="img-fluid"
                              onClick={() => {
                                setCertificates([]);
                                fileInputRef.current.value = null;
                              }}
                            />
                          ) : null}

                          <input
                            ref={fileInputRef}
                            id="file-upload"
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={(e) =>
                              setCertificates((prevState) => [
                                ...prevState,
                                e.target.files[0],
                              ])
                            }
                          />
                        </label>

                        <button
                          className="expert-upload-certificates"
                          onClick={handleUpdate}
                        >
                          {isRequestLoading ? (
                            <HashLoader
                              size={20}
                              className="d-flex justify-content-center align-items-center"
                              color="#fff"
                              loading={isRequestLoading}
                              disabled={isRequestLoading}
                            />
                          ) : (
                            "Upload"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer1
            backgroundColor="#303030"
            colorWhite="#FFFFFF"
            colorGreen="rgba(0, 237, 133, 1)"
          />
          <Footer2 />
        </>
      )}
    </div>
  );
};

export default ProfileExpertView;
