import React from "react";
import { Helmet } from "react-helmet-async";
import "./CopyRight.css";
import Navbar from "../../components/Navbar/Navbar";
import Footer2 from "../../components/Footer/Footer2";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import DotLoader from "react-spinners/DotLoader";

const CopyRight = () => {
  const [apiData, setApiData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getAbout = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/Home/Web_CopyRight`)
      .then((res) => {
        setApiData(res.data.Data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getAbout();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Helmet>
        <title>Copyright | Clicktick Copyright Reserved</title>
        <meta name="title" content="Copyright | Clicktick Copyright Reserved" />
        <meta
          name="description"
          content="All files and information contained in this Website or Application are copyright by Clicktick"
        />
        <meta
          name="keywords"
          content="Clicktick, Click tick, copyright, clicktick copyright"
        />
      </Helmet>
      <Navbar />
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <DotLoader color={"#00EC85"} loading={isLoading} />
        </div>
      ) : (
        <section className="CopyRight">
          <div className="container-fluid BgColorCopyRight">
            <div className="CopyRightHeading">
              <h2>COPY RIGHT</h2>
            </div>
          </div>
          <div className="container">
            <div className="CopyRightParagraph">
              <p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `<div>${apiData.ValueEn}</div>`,
                  }}
                ></div>
              </p>
            </div>
          </div>
        </section>
      )}

      <Footer2 />
    </>
  );
};

export default CopyRight;
