import { useState, useContext, useEffect } from "react";
import { useStyles } from "./style";
import axios from "axios";
import Clicktick from "../../context/context";

export default function QuantityButton({
  item,
  id,
  count,
  setCount,
  max,
  type,
}) {
  const classes = useStyles();

  const { setInvoice, setCart } = useContext(Clicktick);
  useEffect(() => {
    getCartItems();
    // getInvoice();
  }, [count]);
  // const getInvoice = async () => {
  //   await axios
  //     .get(`${process.env.REACT_APP_API_BASE_URL}/Order/GetInvoice`)
  //     .then(({ data }) => setInvoice(data.Data));
  // };
  const getCartItems = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/Order/GetCartList`
    );
    setCart(data.Data);
  };
  const increaseCount = () => {
    if (count == max) return;
    else {
      setCount(count + 1);
    }
    if (type !== "wishlist") {
      axios.post(`${process.env.REACT_APP_API_BASE_URL}/Order/AddItemCart`, {
        ProductCountryId: item.ProductCountryId,
        ProductAttributeId: item.ProductAttributeId,
        Count: count + 1,
      });
    }
  };

  const decreaseCount = () => {
    if (count === 1) {
      return;
    } else {
      setCount(count - 1);
      if (type !== "wishlist") {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/Order/AddItemCart`, {
          ProductCountryId: item.ProductCountryId,
          Count: count - 1,
        });

        // getInvoice()
        // getCartItems()
      }
    }
  };

  return (
    <>
      <div className={classes.quntityBtn}>
        <div
          className="minus"
          style={{ backgroundColor: count == 1 && "lightgray" }}
          onClick={decreaseCount}
        >
          -
        </div>
        <div className="amount"> {count || 1} </div>
        <div className="plus" onClick={increaseCount}>
          +
        </div>
      </div>

      {/* <Notify /> */}
    </>
  );
}
