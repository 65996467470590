import React, { useEffect, useState } from "react";
import NavbarExpert from "./NavbarExpert";
import Footer2 from "../components/Footer/Footer2";
import { BreadCrumb } from "../components/BreadCrumb";
import { Link } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { DotLoader } from "react-spinners";
import { Helmet } from "react-helmet-async";

const AvailableJobsExpertView = () => {
  // search functionality
  const [keyword, setKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [jobsData, setJobsData] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [filteredJobsData, setFilteredJobsData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const getJobs = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/Vacancy/Vacancies`)
        .then((response) => {
          setJobsData(response.data.Data);
          setFilteredJobsData(response.data.Data);
          const filteredJobsCount = response.data.Data?.length;
          const newPageCount = Math.ceil(filteredJobsCount / jobsPerPage);
          setCurrentPage(0);
          setPageCount(newPageCount);
          setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getJobs();
  }, []);

  // Number of jobs to display per page
  const jobsPerPage = 6;

  // Get the current page's job data
  const getCurrentJobs = () => {
    const startIndex = currentPage * jobsPerPage;
    const endIndex = startIndex + jobsPerPage;
    const filteredJobs = filteredJobsData.filter((job) =>
      job.TitleEn.toLowerCase().includes(keyword.toLowerCase())
    );
    return filteredJobs.slice(startIndex, endIndex);
  };

  // Handle search input change
  const handleSearchInputChange = (event) => {
    const keyword = event.target.value;
    setSearchKeyword(keyword);

    if (keyword.trim() === "") {
      setFilteredJobsData(jobsData);
    } else {
      const filteredData = jobsData.filter((job) =>
        job.TitleEn.toLowerCase().includes(keyword.toLowerCase())
      );
      setFilteredJobsData(filteredData);
    }

    setCurrentPage(0);
  };

  // Handle page change
  const handlePageChange = ({ selected }) => {
    window.scrollTo({ top: 200, behavior: "smooth" });
    setCurrentPage(selected);
  };

  // Custom pagination button component
  const CustomPaginationButton = ({ selected, children }) => {
    return (
      <div className={`custom-pagination-button ${selected ? "selected" : ""}`}>
        {children}
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>Clicktick: Open Vacancies</title>
      </Helmet>
      <NavbarExpert />
      <div className="careerHeader">
        <p className="m-0">Open Vacancies</p>
      </div>
      <div className="container"></div>
      <BreadCrumb list={[{ name: "Careers", link: "" }]} />

      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            // alignItems: "center",
            height: "100vh",
          }}
        >
          <DotLoader color={"#00EC85"} loading={isLoading} />
        </div>
      ) : (
        <div className="container">
          <div className="availableCareers mb-3">
            <div className="row">
              <div className="col-md-12">
                <div className="right">
                  <div className="search-box">
                    <input
                      type="text"
                      placeholder="What are you looking for?"
                      value={searchKeyword}
                      onChange={handleSearchInputChange}
                    />
                    <CiSearch />
                  </div>
                  <div className="jobsNumber">
                    <p className="m-0">
                      {filteredJobsData?.length === 0 ? (
                        <p>
                          No Job are available now, please check again later.
                        </p>
                      ) : (
                        <p>{`${filteredJobsData?.length} jobs are available`}</p>
                      )}
                    </p>
                  </div>
                  {filteredJobsData?.length === 0 ? (
                    <span></span>
                  ) : (
                    getCurrentJobs()?.map((job, index) => (
                      <div className="jobCard" key={index}>
                        <div className="row">
                          <div className="col-md-8">
                            <h4>{job?.TitleEn}</h4>
                            <p>{job?.LocationEn}</p>
                          </div>
                          <div className="col-md-4 d-flex align-items-center justify-content-center">
                            <Link to={`/job-details?id=${job.Id}`}>
                              Apply Now
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                  {filteredJobsData?.length > jobsPerPage && (
                    <div className="pagination-container">
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        pageCount={Math.ceil(
                          filteredJobsData?.length / jobsPerPage
                        )}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        previousClassName={"previous"}
                        nextClassName={"next"}
                        pageClassName={"page"}
                        breakClassName={"break-me"}
                        pageLinkClassName={"page-link"}
                        previousLinkClassName={"page-link"}
                        nextLinkClassName={"page-link"}
                        disabledClassName={"disabled"}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={2}
                        forcePage={currentPage}
                        disableInitialCallback={true}
                        breakLabel={"..."}
                        previousLinkRenderer={() => (
                          <CustomPaginationButton>{"<"}</CustomPaginationButton>
                        )}
                        nextLinkRenderer={() => (
                          <CustomPaginationButton>{">"}</CustomPaginationButton>
                        )}
                        pageLinkRenderer={({ selected, children }) => (
                          <CustomPaginationButton selected={selected}>
                            {children}
                          </CustomPaginationButton>
                        )}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer2 />
    </>
  );
};

export default AvailableJobsExpertView;
