import { Link } from 'react-router-dom';
import moment from 'moment';
import { BsChevronRight, BsClock } from 'react-icons/bs';
import { IoPersonOutline, IoWalletOutline } from 'react-icons/io5';

//!Utils
import { getStatusColor } from '../utils/getStatusColor';

const ExpertDealsCard = ({ deal, selectedDeal, handleSelectedItem }) => {
  const currency = localStorage.getItem('currency');

  return (
    <div className='projectsCard'>
      <div className='d-flex flex-column flex-xxl-row justify-content-between'>
        <p
          style={{
            color: '#03BE6C',
            fontWeight: '700',
            fontFamily: 'Poppins',
          }}
        >
          {deal.ServiceNameEn}
        </p>

        <p style={{ color: getStatusColor(deal.RequestStatusId) }}>
          {deal.RequestStatusEn}
        </p>
      </div>

      <p
        style={{
          color: '#4D4D4D',
          fontSize: '0.95rem',
          fontWeight: '600',
          fontFamily: 'Poppins',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <IoPersonOutline className='me-2' />
        {deal.UserName}
      </p>

      <div className='row flex-column flex-lg-row'>
        {deal.ActualDeliveryDate === 0 ? null : (
          <div className='col-lg-6'>
            <p
              style={{
                color: '#4D4D4D',
                fontSize: '0.95rem',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <BsClock className='me-2' />
              {moment.unix(deal.ActualDeliveryDate).format('DD/MM/YYYY')}
            </p>
          </div>
        )}

        <div className='col-lg-6'>
          <p
            style={{
              color: '#4D4D4D',
              fontSize: '0.95rem',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IoWalletOutline
              className='me-2'
              style={{ fontSize: '1.3rem' }}
            />
            {deal.ActualBudget} {currency}
          </p>
        </div>
      </div>

      {handleSelectedItem ? (
        <button
          onClick={() => handleSelectedItem(deal.Id, 'deal')}
          style={{
            backgroundColor: `${deal.Id === selectedDeal?.Id ? '#03be6c' : ''}`,
            color: `${deal.Id === selectedDeal?.Id ? 'white' : ''}`,
          }}
        >
          Details
          {deal.Id === selectedDeal?.Id && <BsChevronRight className='ms-2' />}
        </button>
      ) : (
        <Link
          to={`/expert-requests?type=deal&id=${deal.Id}`}
          style={{ color: 'inherit', display: 'flex', alignItems: 'center' }}
        >
          <button>
            Details
            <BsChevronRight className='ms-2' />
          </button>
        </Link>
      )}
    </div>
  );
};

export default ExpertDealsCard;
