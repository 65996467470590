import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import "./PageNotFound.css";
import Page404 from "../../assests/img/Page-not-found.png";
import { Link } from "react-router-dom";
const NotFoundPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <Navbar />
      <div className="not-found-container p-4">
        <div className="not-found-content">
          <img src={Page404} alt="404 Error Page" className="not-found-img" />
          <h1 className="not-found-title">Oops! Page not found</h1>
          <p className="not-found-text">
            The page you are looking for does not exist.
          </p>
          <Link to="/" className="not-found-link">
            Go back to home
          </Link>
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
