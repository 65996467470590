import { useContext, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

import Clicktick from "../context/context";

import Logo from "../assests/img/logo.png";
import Profile from "../assests/img/profile.png";

function BasicExample() {
  const [session, setSession] = useState("");
  const [url, setUrl] = useState(null);

  const location = useLocation();

  const { setUser } = useContext(Clicktick);

  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  const logoutFunc = () => {
    localStorage.setItem("token", "loggedOut");
    localStorage.removeItem("username");
    localStorage.removeItem("profilePic");
    localStorage.removeItem("email");
    localStorage.removeItem("role");
    setSession("loggedOut");
    setUser(null);
  };

  return (
    <Navbar
      expand="md"
      className="bg-body-tertiary navbar-expert"
    >
      <div className="container-fluid">
        <div>
          <Link
            className="navbar-brand ms-2"
            to="/"
          >
            <img
              src={Logo}
              alt="logo"
              className="logoImgMob"
            />
          </Link>
        </div>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse
          id="basic-navbar-nav"
          className="d-flex flex-row align-items-center justify-content-around"
        >
          <Nav>
            <Link
              to="/"
              className={url === "/" ? " nav-link active" : "nav-link"}
            >
              Home
            </Link>

            <Link
              to="/expert-requests?type=request"
              className={
                url === "/expert-requests" ? " nav-link active" : "nav-link"
              }
            >
              Requests
            </Link>

            <Link
              to="/expert-profile"
              className={
                url === "/expert-profile" ? " nav-link active" : "nav-link"
              }
            >
              Profile
            </Link>

            <Link
              to="/career"
              className={url === "/career" ? " nav-link active" : "nav-link"}
            >
              Careers
            </Link>

            <Link
              to="/contact"
              className={url === "/contact" ? " nav-link active" : "nav-link"}
            >
              Contact Us
            </Link>

            <div className="dropdown__links">
              <Link
                to="/reviews"
                className={url === "/reviews" ? " nav-link active" : "nav-link"}
              >
                Reviews
              </Link>

              <Link
                to="/about-us"
                className={
                  url === "/about-us" ? " nav-link active" : "nav-link"
                }
              >
                About Us
              </Link>

              <Link
                to="/expert-notifications"
                className={
                  url === "/expert-notifications"
                    ? " nav-link active"
                    : "nav-link"
                }
              >
                Notifications
              </Link>

              <Link
                onClick={logoutFunc}
                to="/LoginUser"
                className="nav-link"
              >
                Logout
              </Link>
            </div>
          </Nav>

          <NavDropdown
            title={
              <img
                src={Profile}
                alt="profile"
                className="img-fluid"
              />
            }
            id="basic-nav-dropdown"
            className="expert_dropdown"
          >
            <Link
              to="/reviews"
              className="dropdown-item"
            >
              Reviews
            </Link>

            <Link
              to="/about-us"
              className="dropdown-item"
            >
              About Us
            </Link>

            <Link
              to="/expert-notifications"
              className="dropdown-item"
            >
              Notifications
            </Link>

            <hr />

            <Link
              onClick={logoutFunc}
              className="dropdown-item"
              to="/LoginUser"
            >
              Logout
            </Link>
          </NavDropdown>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}

export default BasicExample;
