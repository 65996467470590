import { useState, useRef } from "react";
import { HashLoader } from "react-spinners";
import { BsPlus } from "react-icons/bs";
import moment from "moment";

//! Components
import AttachmentsDetails from "../components/Shared/AttachmentsDetails";

//! Images
import DelIcon from "../assests/img/Trash-Bin-inimalistic.png";

//! Utils
import { getStatusColor } from "../utils/getStatusColor";

const DealDetails = ({
  selectedDeal,
  isLoading,
  handleStartProject,
  handleProjectStatus,
}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const fileInputRef = useRef(null);

  const currency = localStorage.getItem("currency");

  const handleSendFile = (type) => {
    const formData = new FormData();

    formData.append("Id", selectedDeal?.Id);
    selectedFiles.forEach((file) => formData.append("Files", file));

    if (type === "summary") {
      handleProjectStatus(
        formData,
        "ExpertSendProjectSummary",
        "Project Summary sent successfully"
      );
    } else if (type === "milestone") {
      handleProjectStatus(
        formData,
        "MilestoneReachedExpertRequest",
        "Project Milestone sent successfully"
      );
    } else if (type === "final") {
      handleProjectStatus(
        formData,
        "CompleteExpertRequest",
        "Project Completed successfully"
      );
    }

    setSelectedFiles([]);
  };

  return (
    <div className="p-4 flex-grow-1">
      <h3
        style={{ color: "#0E0E5F" }}
        className="text-center"
      >
        {selectedDeal?.ServiceNameEn}
      </h3>

      <div className="requestDetailsAndCandel">
        <p>Request Details</p>

        <p style={{ color: getStatusColor(selectedDeal?.RequestStatusId) }}>
          {selectedDeal?.RequestStatusEn}
        </p>
      </div>

      <div className="row">
        <div className="col-lg-5">
          <div className="problemDescriptions">
            <div className="firstDiv">
              <p>Problem Description</p>

              <textarea
                style={{ resize: "none" }}
                type="text"
                name=""
                id=""
                disabled
                value={selectedDeal?.ProblemDescription}
              />
            </div>

            <div className="firstDiv">
              <p>Client Payment method</p>

              <input
                type="text"
                name=""
                id=""
                disabled
                value={selectedDeal?.ClientPaymentMethodEn}
              />
            </div>
          </div>

          <div className="firstDiv mt-3">
            <p>Estimated Delivery Date</p>

            <input
              type="text"
              name=""
              id=""
              disabled
              value={
                selectedDeal?.ActualDeliveryDate
                  ? moment
                      .unix(selectedDeal?.ActualDeliveryDate)
                      .format("DD/MM/YYYY")
                  : ""
              }
            />
          </div>

          <div className="firstDiv mt-3">
            <p>Cost ( {currency} )</p>

            <input
              type="text"
              name=""
              id=""
              disabled
              value={selectedDeal?.ActualBudget}
            />
          </div>

          <div className="firstDiv mt-3">
            <p>Project Starting Time</p>

            <input
              type="text"
              name=""
              id=""
              disabled
              value={
                selectedDeal?.ProjectStartingTime
                  ? moment
                      .unix(selectedDeal?.ProjectStartingTime)
                      .format("DD/MM/YYYY")
                  : ""
              }
            />
          </div>

          {selectedDeal?.DisplayStartProject && (
            <button
              className="acceptOfferBtn"
              onClick={() => handleStartProject(selectedDeal?.Id)}
            >
              {isLoading ? (
                <HashLoader
                  size={20}
                  className="d-flex justify-content-center align-items-center"
                  color="#00EC85"
                  loading={isLoading}
                />
              ) : (
                "Start Project"
              )}
            </button>
          )}

          {selectedDeal?.DisplaySendProjectSummary && (
            <>
              <div className="file-input-container text-center">
                <p
                  style={{ color: "#03BE6C", fontWeight: 600 }}
                  className="mb-2 mt-4"
                >
                  Upload The Summary File
                  <br />
                  <span
                    style={{
                      color: "#676767",
                      fontWeight: 500,
                      fontSize: "14px",
                    }}
                  >
                    Upload the summary and send it to finish the project
                  </span>
                </p>

                <label className="custom-file-input-label">
                  <BsPlus />

                  <div className="d-flex align-items-start justify-content-start flex-column">
                    <span
                      style={{
                        color: selectedFiles.length > 0 ? "#03BE6C" : "#545454",
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                    >
                      {selectedFiles.length > 0
                        ? `${selectedFiles.length} File${
                            selectedFiles.length > 1 ? "s" : ""
                          } Uploaded!`
                        : "Upload Files "}
                    </span>

                    <span
                      style={{
                        color: "#545454",
                        fontSize: "14px",
                      }}
                    >
                      {selectedFiles.length > 0
                        ? selectedFiles[selectedFiles.length - 1]?.name
                        : "Upload file to client"}
                    </span>
                  </div>

                  {selectedFiles.length > 0 ? (
                    <img
                      src={DelIcon}
                      alt="delete icon"
                      className="img-fluid"
                      onClick={() => {
                        setSelectedFiles([]);
                        fileInputRef.current.value = null;
                      }}
                    />
                  ) : null}

                  <input
                    ref={fileInputRef}
                    id="file-upload"
                    type="file"
                    multiple
                    onChange={(e) => {
                      setSelectedFiles((prevState) => [
                        ...prevState,
                        e.target.files[0],
                      ]);
                    }}
                  />
                </label>
              </div>

              <button
                className="acceptOfferBtn"
                onClick={() => handleSendFile("summary")}
              >
                {isLoading ? (
                  <HashLoader
                    size={20}
                    className="d-flex justify-content-center align-items-center"
                    color="#00EC85"
                    loading={isLoading}
                  />
                ) : (
                  "Send Project Summary"
                )}
              </button>
            </>
          )}

          {selectedDeal?.DisplayMilestoneReached && (
            <>
              <div className="file-input-container text-center">
                <p
                  style={{ color: "#03BE6C", fontWeight: 600 }}
                  className="mb-2 mt-4"
                >
                  Upload The Summary File
                  <br />
                  <span
                    style={{
                      color: "#676767",
                      fontWeight: 500,
                      fontSize: "14px",
                    }}
                  >
                    Upload the Summary and send it to mark Milestone Reached
                  </span>
                </p>

                <label className="custom-file-input-label">
                  <BsPlus />

                  <div className="d-flex align-items-start justify-content-start flex-column">
                    <span
                      style={{
                        color: selectedFiles.length > 0 ? "#03BE6C" : "#545454",
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                    >
                      {selectedFiles.length > 0
                        ? `${selectedFiles.length} File${
                            selectedFiles.length > 1 ? "s" : ""
                          } Uploaded!`
                        : "Upload Files "}
                    </span>

                    <span
                      style={{
                        color: "#545454",
                        fontSize: "14px",
                      }}
                    >
                      {selectedFiles.length > 0
                        ? selectedFiles[selectedFiles.length - 1]?.name
                        : "Upload file to client"}
                    </span>
                  </div>

                  {selectedFiles.length > 0 ? (
                    <img
                      src={DelIcon}
                      alt="delete icon"
                      className="img-fluid"
                      onClick={() => {
                        setSelectedFiles([]);
                        fileInputRef.current.value = null;
                      }}
                    />
                  ) : null}

                  <input
                    ref={fileInputRef}
                    id="file-upload"
                    type="file"
                    multiple
                    onChange={(e) => {
                      setSelectedFiles((prevState) => [
                        ...prevState,
                        e.target.files[0],
                      ]);
                    }}
                  />
                </label>
              </div>

              <button
                className="acceptOfferBtn"
                onClick={() => handleSendFile("milestone")}
              >
                {isLoading ? (
                  <HashLoader
                    size={20}
                    className="d-flex justify-content-center align-items-center"
                    color="#00EC85"
                    loading={isLoading}
                  />
                ) : (
                  "Milestone Reached"
                )}
              </button>
            </>
          )}

          {selectedDeal?.DisplayCompleteTheProject && (
            <>
              <div className="file-input-container text-center">
                <p
                  style={{ color: "#03BE6C", fontWeight: 600 }}
                  className="mb-2 mt-4"
                >
                  Upload The Final File <br />
                  <span
                    style={{
                      color: "#676767",
                      fontWeight: 500,
                      fontSize: "14px",
                    }}
                  >
                    Upload the final file and send it to mark finish the project
                  </span>
                </p>

                <label className="custom-file-input-label">
                  <BsPlus />

                  <div className="d-flex align-items-start justify-content-start flex-column">
                    <span
                      style={{
                        color: selectedFiles.length > 0 ? "#03BE6C" : "#545454",
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                    >
                      {selectedFiles.length > 0
                        ? `${selectedFiles.length} File${
                            selectedFiles.length > 1 ? "s" : ""
                          } Uploaded!`
                        : "Upload Files "}
                    </span>

                    <span
                      style={{
                        color: "#545454",
                        fontSize: "14px",
                      }}
                    >
                      {selectedFiles.length > 0
                        ? selectedFiles[selectedFiles.length - 1]?.name
                        : "Upload file to client"}
                    </span>
                  </div>

                  {selectedFiles.length > 0 ? (
                    <img
                      src={DelIcon}
                      alt="delete icon"
                      className="img-fluid"
                      onClick={() => {
                        setSelectedFiles([]);
                        fileInputRef.current.value = null;
                      }}
                    />
                  ) : null}

                  <input
                    ref={fileInputRef}
                    id="file-upload"
                    type="file"
                    multiple
                    onChange={(e) => {
                      setSelectedFiles((prevState) => [
                        ...prevState,
                        e.target.files[0],
                      ]);
                    }}
                  />
                </label>
              </div>

              <button
                className="acceptOfferBtn"
                onClick={() => handleSendFile("final")}
              >
                {isLoading ? (
                  <HashLoader
                    size={20}
                    className="d-flex justify-content-center align-items-center"
                    color="#00EC85"
                    loading={isLoading}
                  />
                ) : (
                  "Complete The Project"
                )}
              </button>
            </>
          )}
        </div>

        <div className="col-lg-7 mt-3 mt-lg-0">
          <AttachmentsDetails
            details={selectedDeal}
            senderId={selectedDeal.ExpertUserId}
            receiverId={selectedDeal.UserId}
          />
        </div>
      </div>
    </div>
  );
};

export default DealDetails;
