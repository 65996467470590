import React, { useEffect, useState } from 'react';
import { BreadCrumb } from '../../components/BreadCrumb';
import Star from '../../assests/icons/star.svg';
import Model from '../../modals';
import ServiceType from '../../modals/serviceType';
import './ServiceDetails.css';
import { Container, Grid, Typography } from '@material-ui/core';
import { CheckCircleOutlineOutlined } from '@mui/icons-material';
import axios from 'axios';
import Navbar from '../../components/Navbar/Navbar';
import Footer2 from '../../components/Footer/Footer2';
import Footer1 from '../../components/Footer/Footer1';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import DotLoader from 'react-spinners/DotLoader';
import { Helmet } from 'react-helmet-async';
import SiteLogo from '../../assests/img/favicon.ico';

const Services = () => {
  const [open, setOpen] = useState(false);
  const [service, setService] = useState(false);
  const queryString = window.location.search;
  const [isLoadingMain, setIsLoadingMain] = useState(true);

  // const params = new URLSearchParams(queryString);
  const { Id } = useParams();

  const getService = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/Service/ServiceDetails?ServiceId=${Id}`
      );
      setService(response.data.Data);
      setIsLoadingMain(false);
    } catch (error) {
      setIsLoadingMain(false);
    }
  };

  useEffect(() => {
    getService();
  }, []);

  const navigate = useNavigate();

  async function getUserPhone() {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/Account/UserDetails`
      );
      return res.data.Data.Phone;
    } catch (error) {
      console.log(error);
    }
  }

  const checkLogin = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token || token === 'loggedOut') {
        toast.warn('Please log in first!');

        setTimeout(() => {
          navigate('/loginuser');
        }, 1000);
        return;
      } else {
        const userPhone = await getUserPhone();
        if (userPhone) setOpen(true);
        else {
          navigate('/complete-profile');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let pageTitle = service.NameEn || 'Service Details';
  let serviceId = service.Id;
  return (
    <>
      <Helmet>
        <title>{pageTitle} Service | Clicktick </title>
        <meta
          name='title'
          content='Clicktick - TECH Solutions and Services in Egypt'
        />
        <meta
          name='description'
          content={`Clicktick provides ${pageTitle} solution, Request online or at your location to save your valuable time.`}
        />
        <meta
          name='keywords'
          content={`Clicktick, Click tick, ${pageTitle}, Egypt`}
        />
        <link rel='icon' type='image/png' href={SiteLogo} />
        <link
          rel='canonical'
          href={`https://clicktick.app/service-details/${pageTitle}/${serviceId}`}
        />
      </Helmet>

      <Navbar />
      <BreadCrumb
        list={[
          { name: 'Services', link: '/services' },
          { name: service?.NameEn || 'Service', link: '' },
        ]}
      />

      {isLoadingMain ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <DotLoader color={'#00EC85'} loading={isLoadingMain} />
        </div>
      ) : (
        <>
          <Container maxWidth='lg'>
            <Grid container spacing={3} className='p-2'>
              <Grid item xs={12} md={6} className='imgC'>
                <img
                  src={service?.ImageUrl}
                  alt='Service'
                  className='img-fluid'
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                className='d-flex flex-column justify-content-between secondPart'
              >
                <div className='wordsC'>
                  <div className='words'>
                    <Typography>{`${service?.NameEn}`}</Typography>
                  </div>
                  <p className='creation'>{service?.SubTitleEn}</p>
                  {/* <div className="clients">
                    <CheckCircleOutlineOutlined className="me-3" />
                    <div>
                      <span>{service?.NumberOfClients}+</span>
                      <span>Clients</span>
                    </div>
                  </div> */}
                  {/* <div className="review">
                    <img src={Star} alt="service?" className="me-3" />
                    <span>{service?.Rate}</span>
                    <span>{`(${service?.ReviewsCount} Review)`}</span>
                  </div> */}
                </div>
                <div className='d-flex align-items-center justify-content-center'>
                  <button
                    className='request mt-3'
                    onClick={() => {
                      checkLogin();
                    }}
                  >
                    Get In Touch
                  </button>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3} className='p-2 down my-5'>
              <p className='header'>Description of Service</p>
              <p
                className='artical'
                dangerouslySetInnerHTML={{
                  __html: `<div>${service?.DescriptionEn}</div>`,
                }}
              ></p>
              <div className='serviceExample'>
                <p>Tags:</p>
                <ul>
                  {service.Tags?.map((tag) => {
                    return (
                      <li key={tag.Id} className='ms-3'>
                        {tag.TagEn}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Grid>
            <Model
              RenderedComponent={ServiceType}
              open={open}
              onClose={() => setOpen(false)}
              setOpen={setOpen}
              ServiceId={service?.Id}
            />
          </Container>
          <Footer1
            backgroundColor='#303030'
            colorWhite='#FFFFFF'
            colorGreen='rgba(0, 237, 133, 1)'
          />
          <Footer2 />
        </>
      )}
    </>
  );
};

export default Services;
