import React, { useEffect } from "react";
import "./CallBackResponse.css";
import { useNavigate } from "react-router-dom";

const CallBackResponse = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const success = queryParameters.get("success");
  useEffect(() => {
    setTimeout(() => {
      navigate("/orders");
    }, 5000);
  }, [success]);

  return (
    <div className="card">
      {success == "true" ? (
        <div className="circle success-bg">
          <i className="success">✓</i>
        </div>
      ) : (
        <div className="circle failed-bg">
          <p className="failed p">x</p>
        </div>
      )}
      <h1 className={success == "true" ? "h1Success" : "h1Failed"}>
        {success == "true" ? "Success" : "Failed"}
      </h1>
      <p>
        {success == "true"
          ? "We received your purchase request; we'll be in touch shortly!"
          : "Oops! Something went wrong; Your payment wasn't complete"}
      </p>
    </div>
  );
};

export default CallBackResponse;
