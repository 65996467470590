import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  cartItemsC: {
    display: "flex",
    flexDirection: "column",
  },
  cartItems: {
    padding: "15px 30px 15px 30px",
    border: "1px solid #e4e4e4",
    "@media (max-width: 600px)": {
      padding: "15px 5px",
    },
  },
  flush: {
    margin: "15px 0",
    alignSelf: "flex-end",
    color: "#0D6EFD !important",
    textTransform: "initial !important",
  },
});
