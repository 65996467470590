import { useContext, useEffect } from "react";
import Clicktick from "../../context/context";
import { Container, Grid } from "@material-ui/core";
import { BreadCrumb } from "../../components/BreadCrumb/index";
import WishlistItems from "./wishlistItems/wishlistItems";
import axios from "axios";
import { useState } from "react";
import { EmptyComponent } from "../../components/EmptyComponents";
import { isEmpty } from "lodash";
import Navbar from "../../components/Navbar/Navbar";
import { DotLoader } from "react-spinners";
import { Helmet } from "react-helmet-async";

export default function Favourites() {
  const { setFavourites, favourites } = useContext(Clicktick);
  const [isLoading, setIsLoading] = useState(true);

  const getFavItems = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/Account/UserFavoriteProduct`)
      .then((res) => {
        setFavourites(res.data.Data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getFavItems();
  }, []);

  return (
    <>
      <Helmet>
        <title>Wishlist | Your Fav Items With Clicktick</title> 
        <meta name="title" content="Wishlist | Your Fav Items With Clicktick" />
        <meta
          name="description"
          content="Add your favorite items to Wishlist to buy later with Clicktick | Shop Now"
        />
        <meta
          name="keywords"
          content="Clicktick, Click tick, Wishlist, Favorite"
        />
      </Helmet>
      <Navbar />
      <BreadCrumb list={[{ name: "Wishlist", link: "" }]} />
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            // alignItems: "center",
            height: "100vh",
          }}
        >
          <DotLoader color={"#00EC85"} loading={isLoading} />
        </div>
      ) : (
        <div style={{ paddingBottom: "30px" }}>
          <Container maxWidth="lg">
            {favourites?.length === 0 ? (
              <EmptyComponent content={"Your Wishlist is Empty"} />
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <WishlistItems items={favourites} />
                </Grid>
              </Grid>
            )}
          </Container>
        </div>
      )}
    </>
  );
}
