import React, { useEffect, useState } from "react";
import { Modal, Tab, Tabs } from "react-bootstrap";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { DotLoader, HashLoader } from "react-spinners";
import { Helmet } from "react-helmet-async";

import axios from "axios";

import Navbar from "../../../components/Navbar/Navbar";
import { BreadCrumb } from "../../../components/BreadCrumb/index";
import UserRequestDetails from "./UserRequestDetails";
import UserDealDetails from "./UserDealDetails";
import UserRequestsCard from "./UserRequestsCard";
import UserDealsCard from "./UserDealsCard";

import "./MyExpertRequest.css";

const MyExpertRequest = () => {
  const [requests, setRequests] = useState({ MyRequests: [], MyProjects: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [isCancelLoading, setIsCancelLoading] = useState(false);

  const [isloadingMain, setIsLoadingMain] = useState(false);
  const [isRequestLoading, setIsRequestLoading] = useState(true);

  const [showModal, setShowModal] = useState(false);
  const [paymentFrame, setPaymentFrame] = useState("");

  const [selectedDeal, setSelectedDeal] = useState(null);
  const [selectedRequest, setSelectedRequest] = useState(null);

  const [searchParams] = useSearchParams();
  const queryType = searchParams.get("type");

  //   const retryPayment = () => {
  //     setIsLoading(true);
  //     axios
  //       .post(
  //         `${process.env.REACT_APP_API_BASE_URL}/Order/PaymentRetry?OrderId=${selectedOrder}`
  //       )
  //       .then((response) => {
  //         setIsLoading(false);
  //         window.location.href = response.data.Data.IframeLink;
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         setIsLoading(false);
  //         setErrPaymentMsg("an error has accured, please try again later.");
  //       });
  //   };

  // let currency = localStorage.getItem("currency");
  // const sortedRequests = request?.sort((a, b) => b.CreatedDate - a.CreatedDate);

  const getUserExpertAllRequests = async () => {
    setIsLoadingMain(true);

    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/Account/UserExpertRequests`)
      .then((res) => {
        res?.data?.Data?.MyRequests?.reverse();
        res?.data?.Data?.MyProjects?.reverse();
        setRequests(res?.data?.Data);
        setIsLoading(false);
        setIsLoadingMain(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setIsLoadingMain(false);
      });
  };

  const getRequestDetails = async (id, type) => {
    setSelectedDeal(null);
    setSelectedRequest(null);
    setIsRequestLoading(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/Expert/ExpertUserRequestDetails?RequestId=${id}`
      );

      type === "request"
        ? setSelectedRequest(response?.data?.Data)
        : setSelectedDeal(response?.data?.Data);
      setIsRequestLoading(false);
    } catch (error) {
      console.error("Error fetching request details", error);
      toast.error("Something went wrong, please try again");
    }
  };

  const handleSelectedItem = async (id, type) => {
    window.scrollTo(0, 0);
    setIsLoading(true);
    await getRequestDetails(id, type);
    setIsLoading(false);
  };

  const handleConfirmAndPay = async (request, type) => {
    try {
      setIsLoading(true);

      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/Expert/PayExpertRequest`,
        {
          Id: request.Id,
          PaymentMethodId: request.ClientPaymentMethodId,
        }
      );

      setIsLoading(false);
      getUserExpertAllRequests();

      if (type === "request") {
        setIsRequestLoading(true);
        setIsLoading(true);
        setSelectedRequest(null);
      } else {
        getRequestDetails(request.Id, "deal");
      }

      setShowModal(true);
      setPaymentFrame(res.data?.Data?.IframeLink);

      toast.success(type === "Request accepted successfully");
    } catch (error) {
      toast.error("Error accepting the request, please try again");
      setIsLoading(false);
    }
  };

  const handleCancelRequest = async (id) => {
    try {
      setIsCancelLoading(true);

      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/Expert/CancelExpertRequestByUser?RequestId=${id}`
      );

      toast.success("Request cancelled successfully");

      setIsCancelLoading(false);
      setIsRequestLoading(true);
      setSelectedRequest(null);

      getUserExpertAllRequests();
    } catch (error) {
      toast.error("Error canceling the request");
      setIsCancelLoading(false);
    }
  };

  useEffect(() => {
    getUserExpertAllRequests();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size="lg"
      >
        <Modal.Body className="text-center h-100">
          <iframe
            src={paymentFrame}
            frameborder="0"
            title="payment"
            className="w-100"
            style={{ height: "500px" }}
          ></iframe>
        </Modal.Body>
      </Modal>

      <Helmet>
        <title>Clicktick - My Expert Requests</title>
      </Helmet>

      <Navbar />

      <BreadCrumb list={[{ name: "My Expert Request", link: "" }]} />

      {isloadingMain ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <DotLoader
            color="#00EC85"
            loading={isloadingMain}
          />
        </div>
      ) : (
        <div className="expertRequestsPage mb-5">
          <div className="container">
            <div className="row gap-3 gap-md-0">
              <div className="col-md-4">
                <div className="requestsTabs">
                  <Tabs
                    defaultActiveKey={`${queryType}`}
                    id="fill-tab-example"
                    className="mb-3 border-none gap-3"
                    fill
                  >
                    <Tab
                      eventKey={`${queryType === "requests" ? "requests" : ""}`}
                      title="Recent Requests"
                    >
                      {requests?.MyRequests?.length > 0 ? (
                        requests?.MyRequests?.map((request) => (
                          <UserRequestsCard
                            key={request.Id}
                            request={request}
                            selectedRequest={selectedRequest}
                            handleSelectedItem={handleSelectedItem}
                          />
                        ))
                      ) : (
                        <p className="text-center mt-3">
                          No requests available
                        </p>
                      )}
                    </Tab>

                    <Tab
                      eventKey={`${queryType === "deal" ? "deal" : ""}`}
                      title="My Deals"
                    >
                      {requests?.MyProjects?.length > 0 ? (
                        requests?.MyProjects?.map((deal) => (
                          <UserDealsCard
                            key={deal.Id}
                            deal={deal}
                            selectedDeal={selectedDeal}
                            handleSelectedItem={handleSelectedItem}
                          />
                        ))
                      ) : (
                        <p className="text-center mt-3">
                          No Projects available
                        </p>
                      )}
                    </Tab>
                  </Tabs>
                </div>
              </div>

              <div className="col-md-8">
                <div className="requestsDetailsRight mt-4 mt-md-0 d-flex justify-content-center align-items-center">
                  {isRequestLoading && (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ minHeight: "200px" }}
                    >
                      <HashLoader
                        color={"#00EC85"}
                        loading={isLoading}
                        width={20}
                      />
                    </div>
                  )}

                  {selectedRequest && (
                    <UserRequestDetails
                      selectedRequest={selectedRequest}
                      isLoading={isLoading}
                      isCancelLoading={isCancelLoading}
                      handleCancelRequest={handleCancelRequest}
                      handleConfirmAndPay={handleConfirmAndPay}
                    />
                  )}

                  {selectedDeal && (
                    <UserDealDetails
                      selectedDeal={selectedDeal}
                      isLoading={isLoading}
                      handleConfirmAndPay={handleConfirmAndPay}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MyExpertRequest;
