import "./Chat.css";
import { LuFileDown } from "react-icons/lu";

const Message = ({ message, senderId }) => {
  return (
    <div className={`chat-bubble ${message.userId == senderId ? "right" : ""}`}>
      <div className="chat-bubble__right">
        <p className="user-message">
          {message?.type === "image" ? (
            <a href={message?.message} target="_blank" rel="noreferrer">
              <img src={message?.message} alt="attach img" width={300} />
            </a>
          ) : message?.type === "file" ? (
            <a href={message?.message} target="_blank" rel="noreferrer">
              <span>File &nbsp;&nbsp;&nbsp;</span>
              <LuFileDown color="#D83F30" size="16px" />
            </a>
          ) : (
            message?.message
          )}
        </p>
      </div>
    </div>
  );
};
export default Message;
