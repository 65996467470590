import { useState } from "react";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Clicktick from "../../../context/context";
import { Button, Typography } from "@material-ui/core";

import DiscountCode from "../discountCode/DiscountCode";
import { useStyles } from "./style";
import axios from "axios";

export default function Receipt() {
  const { cart, asyncLoading, invoice, setInvoice } = useContext(Clicktick);
  const classes = useStyles({ asyncLoading });
  const [code, setCode] = useState("");
  const navigate = useNavigate();

  let currency = localStorage.getItem("currency");

  const getInvoice = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/Order/GetInvoice`)
      .then(({ data }) => setInvoice(data.Data));
  };
  // const [response, error, isLoading] = useRequest(accountServices.cartService);

  async function getUserPhone() {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/Account/UserDetails`
      );
      return res.data.Data.Phone;
    } catch (error) {
      console.log(error);
    }
  }

  async function handleCheckout() {
    try {
      const userPhone = await getUserPhone();

      if (userPhone) navigate("/checkout");
      else {
        navigate("/complete-profile");
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getInvoice();
  }, [cart]);

  return (
    <>
      <div className={`${classes.coupon}`}>
        <DiscountCode
          code={code}
          setCode={setCode}
          invoice={invoice}
          setInvoice={setInvoice}
          type="couponCode"
          codeDiscount={cart?.coupon || 555}
          codeTotal={5555}
          question={"couponCodeQuestion"}
          placeholder={"enterCouponCode"}
        />
      </div>

      <div className={`${classes.receipt}`}>
        <div>
          <div className="receipt-item">
            <Typography className="key">Subtotal:</Typography>
            <Typography className="key">{invoice?.SubTotal}</Typography>
          </div>
          <div className="receipt-item">
            <Typography className="key"> Voucher:</Typography>
            <Typography className="value"> {invoice?.Voucher}</Typography>
          </div>
          <div className="receipt-item">
            <Typography className="key">Delivery fee:</Typography>
            <Typography className="value"> {invoice?.DeliveryFees} </Typography>
          </div>
          <div className="receipt-item ">
            <Typography className="total">Total:</Typography>
            <div>
              <Typography className="total val"> {invoice?.Total} </Typography>
              <Typography className="key">{currency}</Typography>
            </div>
          </div>
          <div className="receipt-item tax">
            <Typography className="key">Discount:</Typography>
            <Typography className="value">{invoice?.TotalDiscount}</Typography>
          </div>
          <Button onClick={handleCheckout}>
            <Typography>Checkout</Typography>
          </Button>
        </div>

        {/* {restTotalsArr.map((total: any, index) => (
        <div className="receipt-item" key={index}>
          <Typography variant="body2">{total.text}</Typography>
          <Typography variant="body2" className="value">
            {total.valueText}
          </Typography>
        </div>
      ))} */}
        {/* 
      <Link to={urls.account.checkout}>
        <BasicButton
          text={trans("purchase")}
          type="button"
          fullWidth
          padding={15}
        />
      </Link>

      {asyncLoading && <LoadingOverlay />} */}
      </div>
    </>
  );
}
