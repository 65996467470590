import React, { useEffect, useState } from 'react';
import './SocialLogin.css';
import Logo from '../../../assests/img/logo.png';
import axios from 'axios';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

const SocialLogin = () => {
  const [countryCodes, setCountryCodes] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState('');
  const [PhoneNumber, setPhoneNumber] = useState('');
  const [formError, setFormError] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const navigate = useNavigate();

  const getCountryCodes = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/Account/CountryCodes`
      );
      setCountryCodes(response.data.Data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCountryCodes();
  }, []);

  const handleCountryChange = (event) => {
    setSelectedCountryId(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value.replace(/\D/, ''));
    setInputError(false); // Hide the input error message
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    // Check if the required fields are empty
    if (!selectedCountryId || !PhoneNumber) {
      setFormError(true);
      return;
    }

    // Check if the phone number contains letters
    if (!/^\d+$/.test(PhoneNumber)) {
      setInputError(true);
      return;
    }

    // Reset the form error state
    setFormError(false);
    setInputError(false);

    // Use the selectedCountryId and PhoneNumber in your API request
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/Account/SocialCompleteRegister`,
        {
          PhoneNumber,
          CountryCodeId: parseInt(selectedCountryId, 10),
        }
      )
      .then((res) => {
        console.log(res);
        localStorage.setItem('keySignUp', res.data.Data.Key);
        // navigate("/verify");
        navigate('/');
        localStorage.setItem('social', true);
      })
      .catch((err) => {
        setErrMsg(err.response.data.Message);
      });
  };

  return (
    <div className='container'>
      <div className='completeLogo'>
        <img src={Logo} alt='' />
      </div>

      <div className='socialLoginPage'>
        <p>Complete your data to continue</p>
        <form onSubmit={handleFormSubmit} className='w-50'>
          <div className='d-flex align-items-center'>
            <div className='form-group w-100'>
              <Form.Select
                id='countryCode'
                value={selectedCountryId}
                onChange={handleCountryChange}
                className='select-dropdown'
              >
                <option value=''>Country Code</option>
                {countryCodes?.map((country) => (
                  <option key={country.Id} value={country.Id}>
                    {country.Code}
                  </option>
                ))}
              </Form.Select>

              <InputGroup className='input-field'>
                <FormControl
                  placeholder='Phone Number...'
                  onChange={handlePhoneNumberChange}
                />
              </InputGroup>
            </div>
          </div>
          {formError && (
            <p className='text-danger fs-6'>
              Please select a country code and enter a phone number.
            </p>
          )}
          {inputError && (
            <p className='text-danger fs-6'>
              Phone number should only contain digits.
            </p>
          )}
          {errMsg && <p className='text-danger fs-6 text-center'>{errMsg}</p>}
          <button type='submit'>Sign up</button>
        </form>
      </div>
    </div>
  );
};

export default SocialLogin;
