import React, { useState } from "react";
import axios from "axios";
import "./Login.css";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assests/img/logo.png";
import VerifySMS from "../../assests/img/verifySMS.png";
import { MdAlternateEmail } from "react-icons/md";
import Footer2 from "../../components/Footer/Footer2";
import Dropdown from "react-bootstrap/Dropdown";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

const ForgetPassword = () => {
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const [selectedCode, setSelectedCode] = useState(null);
  const [selectedCodeId, setSelectedCodeId] = useState(null);
  const [countryCodes, setCountryCodes] = useState([]);

  const navigate = useNavigate();

  const handleDropdownSelect = (c) => {
    setSelectedCode(c.Code);
  };
  const handleDropdownSelectId = (id) => {
    setSelectedCodeId(id);
  };
  const getCountryCodes = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/Account/CountryCodes`)
      .then((res) => setCountryCodes(res.data.Data));
  };
  useEffect(() => {
    getCountryCodes();
  }, []);

  const handleChange = (e) => {
    const inputValue = e.target.value;
    setPhone(inputValue);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Check if phone is empty or contains non-digit characters
    // if (phone === "" || /\D/.test(phone)) {
    //   setError("Please enter a valid phone number.");
    //   return;
    // }
    // Send the phone number to the API using Axios
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/Account/ForgetPassword`, {
        params: {
          PhoneNumber: phone,
          CountryCodeId: selectedCodeId?.Id,
        },
      })
      .then((response) => {
        // Handle the API response if needed
        localStorage.setItem("forgetKey", response.data.Data.Key);
        navigate("/VerifyForgetPassword");
      })
      .catch((error) => {
        // Handle any errors that occurred during the API request
        console.error(error.response.data.Message);
        setError(error.response.data.Message);
      });
    setPhone("");
    setError("");
  };

  return (
    <>
      <Helmet>
        <title>Clicktick - Forget Password</title>
      </Helmet>
      <div className="login forgetP mb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6 d-flex align-items-center flex-column">
              <div className="logo mb-4">
                <Link to="/">
                  <img src={Logo} alt="logo" />
                </Link>
              </div>
              <div className="userLoginImg mt-5">
                <img src={VerifySMS} alt="user login" className="w-100" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="forget">
                <h4>Forget Password</h4>
                <p>
                  Don't worry it happens. Please enter your phone number that is
                  associated with your account.
                </p>
                <form className="reset" onSubmit={handleSubmit}>
                  <div className="d-flex align-items-center justify-content-start">
                    <MdAlternateEmail />
                    <Dropdown className="me-2 ms-2 chosenCountry">
                      <Dropdown.Toggle id="dropdown-basic" className="d-inline">
                        {selectedCode ? `${selectedCode}` : "Country"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {countryCodes?.map((code) => (
                          <Dropdown.Item
                            key={code.Id}
                            onClick={() => {
                              handleDropdownSelect(code);
                              handleDropdownSelectId(code);
                            }}
                          >
                            <img
                              src={code.FlagUrl}
                              alt=""
                              className="me-2 w-10"
                            />
                            {`${code.Code}`}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                    <input
                      type="text"
                      name="phone"
                      placeholder="Phone Number"
                      className="w-75 ms-2"
                      value={phone}
                      onChange={handleChange}
                    />
                  </div>

                  {error && <p className="error-message ms-4">{error}</p>}
                  <button type="submit" className="mt-4">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
};

export default ForgetPassword;
