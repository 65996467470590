import React, { useEffect, useState } from "react";
import "./Login.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assests/img/logo.png";
import VerifySMS from "../../assests/img/verifySMS.png";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import OtpInput from "react18-input-otp";
import axios from "axios";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";

const VerifyForgetPassword = () => {
  const [otp, setOtp] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();

  const handleChange = (enteredOtp) => {
    setOtp(enteredOtp);
  };

  const location = useLocation();
  const key = localStorage.getItem("forgetKey");

  const OTPApi = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/Account/ValidateForgetPasswordOTP`,
        {
          Key: key,
          OTP: otp,
        }
      );

      let resKey = res.data.Data.Key;
      navigate("/reset-password", { state: { resKey } });
    } catch (error) {
      setErrMsg(error.response.data.Message);
    }
  };

  const [resendTimer, setResendTimer] = useState(30);
  const [isResendDisabled, setIsResendDisabled] = useState(false);

  const handleResend = async (e) => {
    e.preventDefault();

    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/Account/ResendOtp?Key=${key}`
      );
      setResendTimer(30);
      setIsResendDisabled(true);
      toast.success("OTP has been re-sent!");
    } catch (error) {
      console.log(error);
      toast.error("There has been an error re-sending the OTP");
    }
  };

  useEffect(() => {
    let timerId;

    if (resendTimer > 0) {
      timerId = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setIsResendDisabled(false);
    }

    return () => {
      clearInterval(timerId);
    };
  }, [resendTimer]);

  return (
    <>
      <Helmet>
        <title>Clicktick - Verify OTP</title>
      </Helmet>
      <div className="login">
        <div className="container">
          <div className="row">
            <div className="col-md-6 d-flex align-items-center flex-column">
              <div className="logo mb-4">
                <Link to="/">
                  <img src={Logo} alt="logo" />
                </Link>
              </div>
              <div className="userLoginImg mt-5">
                <img src={VerifySMS} alt="user login" className="w-100" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="userInfo mt-5">
                <div className="userLoginSocial mb-4">
                  <p className="mb-3 ">Enter your Code</p>
                  <p className="vetifyP mt-2">
                    We have sent you a SMS with a 6-digit code
                  </p>
                </div>
                <div className="userLoginForm">
                  <Form>
                    <OtpInput
                      containerStyle="otpNumbers"
                      inputStyle="otpNumber"
                      value={otp}
                      onChange={handleChange}
                      numInputs={6}
                      shouldAutoFocus
                      separator={<span>&nbsp;</span>}
                    />
                    <p className="m-0 invalid text-center">{errMsg}</p>

                    <div className="resendOTP">
                      {resendTimer > 0 ? (
                        <p className="mb-0">
                          Resend OTP in {resendTimer} seconds
                        </p>
                      ) : (
                        <p className="mb-0">
                          Resend OTP&nbsp;
                          <Link
                            to=""
                            onClick={handleResend}
                            className="text-right"
                          >
                            Resend
                          </Link>
                        </p>
                      )}
                    </div>

                    <div className="textSMS"></div>
                    <Button
                      className="loginButton"
                      type="submit"
                      onClick={OTPApi}
                    >
                      Verify
                    </Button>
                  </Form>
                </div>
                <div className="newAccount mt-4">
                  <p>
                    Joined before?&nbsp;
                    <Link to="/loginuser">Sign in</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyForgetPassword;
