//! 3rd Libraries
import { useState } from "react";
import DatePicker from "react-date-picker";
import { CiCalendar } from "react-icons/ci";
import { HashLoader } from "react-spinners";
import moment from "moment";

//! Components
import AttachmentsDetails from "../components/Shared/AttachmentsDetails";

const RequestDetails = ({
  selectedRequest,
  isLoading,
  isCancelLoading,
  handleCancelRequest,
  handleAcceptRequest,
}) => {
  const [expertFields, setExpertFields] = useState({
    estimatedDeliveryDate: "",
    projectStartingDate: "",
    actualBudget: 0,
  });

  const [deliveryDateError, setDeliveryDateError] = useState(null);
  const [budgetError, setBudgetError] = useState(null);
  const [startingDateError, setStartingDateError] = useState(null);

  console.log("expertFieldss", expertFields);

  const currency = localStorage.getItem("currency");

  const validateInputs = () => {
    let isValid = true;

    if (!expertFields.estimatedDeliveryDate) {
      setDeliveryDateError("Please select a date");
      isValid = false;
    } else {
      setDeliveryDateError(null);
    }

    if (isNaN(expertFields.actualBudget) || expertFields.actualBudget <= 0) {
      setBudgetError("Please enter a valid budget");
      isValid = false;
    } else {
      setBudgetError(null);
    }

    if (!expertFields.projectStartingDate) {
      setStartingDateError("Please select a date");
      isValid = false;
    } else {
      setStartingDateError(null);
    }

    return isValid;
  };

  const handleBudget = (e) => {
    if (e.target.value === "") {
      setExpertFields((prevState) => ({
        ...prevState,
        actualBudget: 0,
      }));

      setBudgetError(null);
    } else if (isNaN(e.target.value) || e.target.value <= 0) {
      setBudgetError("Please enter a valid budget");
    } else {
      setExpertFields((prevState) => ({
        ...prevState,
        actualBudget: +e.target.value,
      }));
      setBudgetError(null);
    }
  };

  const handleSendOffer = () => {
    if (validateInputs()) {
      handleAcceptRequest(selectedRequest.Id, expertFields);
    }
  };

  return (
    <div className="p-4 flex-grow-1">
      <h3
        style={{ color: "#0E0E5F" }}
        className="text-center"
      >
        {selectedRequest?.ServiceNameEn}
      </h3>

      <div className="requestDetailsAndCandel">
        <p>Request Details</p>

        {selectedRequest?.IsRecent && (
          <button
            onClick={() => handleCancelRequest(selectedRequest.Id)}
            disabled={isCancelLoading}
          >
            {isCancelLoading ? (
              <HashLoader
                size={20}
                className="d-flex justify-content-center align-items-center"
                color={"#00EC85"}
                loading={isCancelLoading}
              />
            ) : (
              "Cancel"
            )}
          </button>
        )}
      </div>

      <div className="row">
        <div className="col-lg-5">
          <div className="problemDescriptions">
            <div className="firstDiv">
              <p>Problem Description</p>

              <textarea
                style={{ resize: "none" }}
                type="text"
                name=""
                id=""
                disabled
                value={selectedRequest?.ProblemDescription}
              />
            </div>

            <div className="firstDiv">
              <p>Expected Delivery Date</p>

              <input
                type="text"
                name=""
                id=""
                disabled
                value={
                  selectedRequest?.ExpectedDeliveryDate
                    ? moment
                        .unix(selectedRequest?.ExpectedDeliveryDate)
                        .format("DD/MM/YYYY")
                    : ""
                }
              />
            </div>

            <div className="firstDiv">
              <p>Expected Budget ( {currency} )</p>

              <input
                type="text"
                name=""
                id=""
                value={selectedRequest?.ExpectedBudget || ""}
                disabled
              />
            </div>

            <div className="firstDiv">
              <p>Client Payment method</p>

              <input
                type="text"
                name=""
                id=""
                disabled
                value={selectedRequest?.ClientPaymentMethodEn}
              />
            </div>
          </div>

          <div className="mt-3">
            <p
              style={{
                marginBottom: "10px",
                color: "#676767",
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
              }}
            >
              Estimated Delivery Date
            </p>

            <DatePicker
              minDate={new Date()}
              value={expertFields.estimatedDeliveryDate}
              onChange={(date) => {
                setExpertFields((prevState) => ({
                  ...prevState,
                  estimatedDeliveryDate: date,
                }));
                setDeliveryDateError(null);
              }}
              format="dd/MM/yyyy"
              dayPlaceholder="DD"
              monthPlaceholder="MM"
              yearPlaceholder="YYYY"
              calendarIcon={
                <CiCalendar style={{ fontSize: "1.5rem", color: "#313131" }} />
              }
            />
            {deliveryDateError && (
              <p style={{ color: "red" }}>{deliveryDateError}</p>
            )}
          </div>

          <div className="firstDiv mt-3">
            <p>Cost ( {currency} )</p>

            <input
              type="text"
              placeholder="EGP"
              className="expectBudget"
              value={expertFields.actualBudget || ""}
              onChange={(e) => handleBudget(e)}
            />
            {budgetError && (
              <p style={{ color: "red", fontWeight: 400 }}>{budgetError}</p>
            )}
          </div>

          <div className="mt-3">
            <p
              style={{
                marginBottom: "10px",
                color: "#676767",
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
              }}
            >
              Project Starting Time
            </p>

            <DatePicker
              minDate={new Date()}
              maxDate={new Date(expertFields.estimatedDeliveryDate)}
              disabled={!expertFields.estimatedDeliveryDate}
              value={expertFields.projectStartingDate}
              onChange={(date) => {
                setExpertFields((prevState) => ({
                  ...prevState,
                  projectStartingDate: date,
                }));
                setStartingDateError(null);
              }}
              format="dd/MM/yyyy"
              dayPlaceholder="DD"
              monthPlaceholder="MM"
              yearPlaceholder="YYYY"
              calendarIcon={
                <CiCalendar style={{ fontSize: "1.5rem", color: "#313131" }} />
              }
            />
            {startingDateError && (
              <p style={{ color: "red" }}>{startingDateError}</p>
            )}
          </div>

          <button
            className="acceptOfferBtn"
            disabled={isLoading}
            onClick={handleSendOffer}
          >
            {isLoading ? (
              <HashLoader
                size={20}
                className="d-flex justify-content-center align-items-center"
                color={"#00EC85"}
                loading={isLoading}
              />
            ) : (
              "Send Offer"
            )}
          </button>
        </div>

        <div className="col-lg-7 mt-3 mt-lg-0">
          <AttachmentsDetails
            details={selectedRequest}
            senderId={selectedRequest.ExpertUserId}
            receiverId={selectedRequest.UserId}
          />
        </div>
      </div>
    </div>
  );
};

export default RequestDetails;
