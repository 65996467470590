import { useContext, useEffect, useState } from "react";
import { HashLoader } from "react-spinners";
import { toast } from "react-toastify";

import axios from "axios";

import { filter, isEmpty } from "lodash";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import CheckoutStore from "../../context/context";
import Clicktick from "../../../../context/context";

import Edit from "../../../../assests/icons/edit.svg";
import Delete from "../../../../assests/icons/delete.svg";

import { useStyles } from "./style";

export default function AddressList({ setAddressMethod }) {
  const classes = useStyles();
  const { setNotify } = useContext(Clicktick);
  const [addresses, setAddresses] = useState([]);
  const [active, setAddress] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { setAddressID, setActiveIndex, activeIndex } =
    useContext(CheckoutStore);

  const addNew = async () => {
    setAddressMethod("newAddress");
  };

  const Confirm = (e) => {
    if (!active) {
      return setError("Choose Address First !");
    }
    setActiveIndex(activeIndex + 1);
    setAddressID(active);
  };

  useEffect(() => {
    const getAddresses = async () => {
      try {
        setIsLoading(true);

        const { data } = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/UserAddress/GetUserAddresses`
        );

        setAddresses(data.Data);
      } catch (error) {
        toast.error("Something went wrong, please try again");
      } finally {
        setIsLoading(false);
      }
    };
    getAddresses();
  }, [setAddressMethod]);

  const deleteAddress = async (id) => {
    await axios
      .delete(
        `${process.env.REACT_APP_API_BASE_URL}/UserAddress/DeleteAddress`,
        { params: { AddressId: id } }
      )
      .then((res) => {
        setNotify({
          open: true,
          message: "Address Deleted Successfully",
          severity: "success",
        });
        const newADD = filter(addresses, (d) => d.Id != id);
        setAddresses(newADD);
      });
  };

  return (
    <div className={classes.addressList}>
      {!isEmpty(addresses) && <p>Select Address</p>}
      {isLoading ? (
        <HashLoader
          size={50}
          className="d-flex justify-content-center align-items-center w-100"
          color={"#00EC85"}
        />
      ) : (
        <FormControl component="fieldset" style={{ width: "100%" }}>
          <RadioGroup
            aria-label="type"
            name="type"
            value={active}
            onChange={(e) => setAddress(e.target.value)}
            className={classes.group}
          >
            {addresses?.map((address) => {
              return (
                <div className="add-new addressD">
                  <div className="leftt">
                    <FormControlLabel
                      value={address.Id}
                      control={<Radio checkedIcon={<CheckCircleIcon />} />}
                      checked={address.Id == active}
                      label=""
                      classes={{ root: classes.radio }}
                    />
                    <div className="add-info">
                      <Typography variant="body1">{`${address.BuildingNo} ${address.Street} st, ${address.AreaEn}`}</Typography>
                      <Typography variant="body1">{`${address.DistrictEn}, ${address.CityEn}`}</Typography>
                    </div>
                  </div>
                  <div>
                    <button
                      onClick={() => {
                        setAddressID(address);
                        addNew();
                      }}
                    >
                      <img src={Edit} alt="edit" />
                    </button>
                    <button onClick={() => deleteAddress(address.Id)}>
                      <img src={Delete} alt="delete" />
                    </button>
                  </div>
                </div>
              );
            })}
          </RadioGroup>
        </FormControl>
      )}
      <div className="add-new" style={{ textAlign: "center" }} onClick={addNew}>
        Add New Address
      </div>
      {!active && <p style={{ color: "red" }}>{error}</p>}
      <div className="confirm">
        <button
          disabled={!active}
          style={{ backgroundColor: !active && "lightgray" }}
          className="add"
          onClick={Confirm}
        >
          Confirm
        </button>
      </div>
    </div>
  );
}
