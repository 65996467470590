import React, { useState } from "react";
import { serverTimestamp } from "firebase/firestore";
import styles from "./AdminChat.module.css";
import { ImAttachment } from "react-icons/im";
import {
  sendFileToFireBase,
  sendMessageToFirebase,
} from "../../utils/firebaseHelper";
import axios from "axios";

const SendAdminMessage = ({ scroll, setIsMessageLoading }) => {
  const [message, setMessage] = useState("");
  const chatName = localStorage.getItem("userId");

  const handleSendMessage = async (event) => {
    try {
      event.preventDefault();
      const newMessage = {
        collectionName: "chats",
        documentName: chatName,
        documentData: {
          admin_has_new_message: true,
          client_has_new_message: false,
          id: chatName,
          imageURL: "",
          isFinished: false,
          lastMessageDate: serverTimestamp(),
          name: localStorage.getItem("username"),
        },
        secondDocumentName: "chat",
        secondDocumentData: {
          insertDate: serverTimestamp(),
          isFromAdmin: false,
          isVisible: true,
          message: message,
          userId: chatName,
        },
      };
      await sendMessageToFirebase(newMessage);
      setMessage("");
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/Notification/PushAdminChatNotification`
      );

      scroll.current.scrollTop = scroll.current.scrollHeight;
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileChange = async (e) => {
    try {
      const imageRegex =
        /^https:\/\/[^\s\/]+\/[^\s]+\.(png|jpe?g|gif|bmp)(\?.*)?$/i;
      for (let file of e.target.files) {
        if (!file?.name) return;
        if (!file) {
          console.error("Please select a file.");
          return;
        }
        setIsMessageLoading(true);
        const imageUrl = await sendFileToFireBase(file);
        const type = imageRegex.test(imageUrl) ? "image" : "file";

        const newMessage = {
          collectionName: "chats",
          documentName: chatName,
          documentData: {
            admin_has_new_message: false,
            client_has_new_message: false,
            id: chatName,
            imageURL: "",
            isFinished: false,
            lastMessageDate: serverTimestamp(),
            name: localStorage.getItem("username"),
          },
          secondDocumentName: "chat",
          secondDocumentData: {
            insertDate: serverTimestamp(),
            isFromAdmin: false,
            isVisible: true,
            message: imageUrl,
            userId: chatName,
            type: type,
          },
        };
        // Save image metadata in Firestore
        await sendMessageToFirebase(newMessage);
        setIsMessageLoading(false);
      }
      setMessage("");
    } catch (error) {
      console.error("Error uploading image: ", error);
    } finally {
      scroll.current.scrollTop = scroll.current.scrollHeight;
    }
  };

  return (
    <form
      onSubmit={(event) => handleSendMessage(event)}
      className={styles["send-message"]}
    >
      {/* Files and Images */}
      {/* <input
        type="file"
        className={styles["fileInput"]}
        id="fileInput"
        onChange={handleFileChange}
        multiple
      />
      <label htmlFor="fileInput" className={styles["attach"]}>
        <ImAttachment color="#000056" />
      </label> */}

      <input
        id="messageInput"
        name="messageInput"
        type="text"
        className={styles["form-input__input"]}
        placeholder="Ask something..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      ></input>

      <button
        type="submit"
        disabled={!message || !message.trim()}
        className={styles["submit-message"]}
      >
        {/* <img src="assests/img/send.svg" /> */}
        <svg
          width="17"
          height="18"
          viewBox="0 0 17 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.3998 9.07619C16.4004 9.2989 16.3414 9.51772 16.2291 9.71002C16.1167 9.90231 15.955 10.0611 15.7607 10.1699L2.64277 17.6707C2.45429 17.7776 2.24146 17.8341 2.0248 17.8348C1.82499 17.8344 1.62819 17.7861 1.4509 17.6939C1.27361 17.6018 1.12099 17.4685 1.00583 17.3052C0.890673 17.1419 0.816337 16.9534 0.789053 16.7555C0.761769 16.5575 0.782332 16.356 0.849018 16.1676L2.98574 9.92151C3.00679 9.85971 3.04667 9.80606 3.09978 9.76809C3.15288 9.73011 3.21655 9.70973 3.28183 9.70979H8.8998C8.98548 9.70997 9.07028 9.69254 9.14894 9.65858C9.2276 9.62461 9.29844 9.57484 9.35705 9.51234C9.41566 9.44985 9.4608 9.37597 9.48966 9.29529C9.51851 9.21462 9.53048 9.12887 9.5248 9.04338C9.51063 8.88267 9.43627 8.73324 9.3166 8.62502C9.19694 8.5168 9.04081 8.45778 8.87949 8.45979H3.2873C3.22211 8.4599 3.15852 8.43963 3.10542 8.40181C3.05233 8.36398 3.01239 8.3105 2.99121 8.24885L0.847455 1.99885C0.763973 1.75936 0.755317 1.50014 0.822638 1.25561C0.889959 1.01108 1.03007 0.792819 1.22437 0.629803C1.41866 0.466787 1.65795 0.366731 1.91046 0.342923C2.16296 0.319115 2.41674 0.37268 2.63808 0.496506L15.7631 7.98791C15.9561 8.09661 16.1168 8.25466 16.2287 8.44589C16.3406 8.63712 16.3996 8.85464 16.3998 9.07619Z"
            fill="#fff"
          />
        </svg>
      </button>
    </form>
  );
};

export default SendAdminMessage;
