export const getStatusColor = (statusId) => {
  switch (statusId) {
    case 1:
      // waiting
      return "#CE7C00";
    case 2:
      // Canceled by User
      return "#EE0000";
    case 3:
      // waiting for approval
      return "#CE7C00";
    case 4:
      // canceled by Admin
      return "#EE0000";
    case 5:
      // approved by expert
      return "#01BC8F";
    case 6:
      // canceled by expert
      return "#EE0000";
    case 7:
      // In Progress
      return "#01BC8F";
    case 8:
      // Completed
      return "#000056";
    case 9:
      // confirmed by user
      return "#01BC8F";
    case 10:
      // Payment Revision
      return "#CE7C00";
    case 11:
      // Pending
      return "#CE7C00";
    default:
      return "";
  }
};
