import styles from "./AdminChat.module.css";

const Message = ({ message }) => {
  return (
    <div
      className={`${styles["chat-bubble"]} ${
        message.isFromAdmin ? "" : styles["right"]
      }`}
    >
      <div className="chat-bubble__right">
        <p className={"user-message"}>
          {message?.type ==="image" ? (
            <a href={message?.message} target="_blank" rel="noreferrer">
              <img
                src={message?.message}
                alt="attach img"
                width={300}
              />
            </a>
          ) : message?.type === "file" ? (
            <a href={message?.message} target="_blank" rel="noreferrer">
              {message?.message}
            </a>
          ) : (
            message?.message
          )}
        </p>
      </div>
    </div>
  );
};
export default Message;
