import { useState } from "react";
import { useStyles } from "./style";
import { Button } from "@material-ui/core";
import axios from "axios";
import { toast } from "react-toastify";

export default function DiscountCode(props) {
  const { code, setCode, invoice, setInvoice, type } = props;
  const classes = useStyles({ type });
  const [isApplyClicked, setIsApplyClicked] = useState(false);
  const [isRemove, setIsRemove] = useState(false);

  const handlePromo = async (type) => {
    if (code && type === "apply") {
      await axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/Order/GetInvoice`, {
          params: { Voucher: code },
        })
        .then(({ data }) => setInvoice(data.Data))
        .catch((error) => {
          toast.error("Invalid Coupon");
          setIsApplyClicked(false);
        });

      localStorage.setItem("Voucher", code);
    } else {
      await axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/Order/GetInvoice`)
        .then(({ data }) => {
          setCode("");
          setInvoice(data.Data);
        })
        .catch((error) => {
          toast.error("Invalid Coupon");
          setIsApplyClicked(false);
        });
    }
  };
  // const { setNotify, setCart, setAsyncLoading } =
  //   useContext(Clicktick);

  // useEffect(() => {
  //   if (Boolean(code)) {
  //     const timeoutID = setTimeout(() => {
  //       addCode(type, code);
  //     }, 1000);

  //     return () => clearTimeout(timeoutID);
  //   }
  // }, [code]);

  // async function addCode(type, code) {
  //   setAsyncLoading(true);
  //   const response = await getCartItems({ [type]: code });

  //   if (response.data.errors) {
  //     const errorMessage = response.data.errors[0].value;
  //     setNotify({
  //       open: true,
  //       message: errorMessage,
  //       severity: "error",
  //     });
  //     setAsyncLoading(false);
  //     return;
  //   }

  //   if (response.data.success) {
  //     setCart(response.data.cart);
  //     setAsyncLoading(false);
  //   }
  // }

  return (
    <>
      <div className={`${classes.codeInput}`}>
        <p>Have a coupon?</p>
        <div className="inputC">
          <input
            type="text"
            placeholder="Promo Code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            disabled={isApplyClicked}
          />

          {isRemove? (
            <Button
              onClick={() => {
                handlePromo("remove");
                setIsApplyClicked(false);
                setIsRemove(false)
              }}
              style={{ backgroundColor: "#BB1327", marginLeft: "5px" }}
            >
              Remove
            </Button>
          ) : (
            <Button
              style={{ backgroundColor: !code ? "lightgray" : undefined }}
              onClick={() => {
                handlePromo("apply");
                setIsApplyClicked(true);
                setIsRemove(true)
              }}
              disabled={!code}
            >
              Apply
            </Button>
          )}
        </div>
        {invoice.PromoCodeIsValid ? (
          <span style={{ color: "#03BE6C" }}>{`${code} is Valid`}</span>
        ) : (
          code &&
          invoice.PromoCodeIsValid == false && (
            <span style={{ color: "red" }}>{`${code} is not Valid`}</span>
          )
        )}
      </div>
      {/* <Notify /> */}
    </>
  );
}
