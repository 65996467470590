import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import DotLoader from "react-spinners/DotLoader";
import OwlCarousel from "react-owl-carousel";

import axios from "axios";

import Navbar from "../../components/Navbar/Navbar";
import Footer1 from "../../components/Footer/Footer1";
import Footer2 from "../../components/Footer/Footer2";
import ShopItem from "../../components/ShopItem/ShopItem";

import "./Shop.css";

const Shop = () => {
  const [categoryAPI, setCategoryAPI] = useState([]);
  const [homeOffers, setHomeOffers] = useState([]);
  const [recProducts, setRecProducts] = useState([]);
  const [homeParteners, setHomeParteners] = useState([]);
  const [shopBanner, setShopBanner] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMain, setIsLoadingMain] = useState(true); // New state variable
  const navigate = useNavigate();
  const Category = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/Home/HomeCategories`)
      .then((res) => {
        setCategoryAPI(res.data.Data);
        setIsLoading(false);
      })
      .then((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const HomeOffer = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/Home/HomeOffers?PageSize=12`)
      .then((res) => {
        setHomeOffers(res.data.Data);
        setIsLoading(false);
      });
  };

  const homeRec = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/Product/Web_RecommendedProducts?PageSize=6`
      )
      .then((res) => {
        setRecProducts(res.data.Data);
        setIsLoading(false);
      });
  };

  const homePartenersApi = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/Home/HomeParteners`)
      .then((res) => {
        setHomeParteners(res.data.Data);
        setIsLoading(false);
      });
  };

  const ShopBanner = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/Home/Banners`)
      .then((res) => {
        setShopBanner(res.data.Data);
        setIsLoading(false);
        setIsLoadingMain(false);
      });
  };
  useEffect(() => {
    ShopBanner();
    homePartenersApi();
    homeRec();
    HomeOffer();
    Category();
  }, []);

  const sortedCategories = categoryAPI.sort((a, b) => {
    return a.DisplayOrder - b.DisplayOrder;
  });

  //Owl Carousel Settings
  const options = {
    autoplay: true,
    autoplayTimeout: 2000,
    items: 1,
    center: true,
    margin: 500,
    responsiveClass: true,
    nav: false,
    navText: ["Prev", "Next"],
    smartSpeed: 1000,
  };
  const categoriesSettings = {
    infinite: true,
    loop: true,
    speed: 500,
    slidesToShow: Math.min(sortedCategories?.length, 4),
    slidesToScroll: 1,
    centerMode: false,
    dots: true,
    centerPadding: "20px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          dots: true,
          centerMode: false,
          centerPadding: "50px",
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          dots: true,
          centerMode: false,
          centerPadding: "50px",
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const offersSettings = {
    infinite: true,
    autoplay: true,
    loop: true,
    speed: 500,
    slidesToShow: Math.min(homeOffers?.length, 3),
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    arrows: false,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          dots: true,
          centerMode: false,
          centerPadding: "0px",
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const recomendedProductsSlider = {
    infinite: true,
    autoplay: true,
    loop: true,
    speed: 500,
    slidesToShow: Math.min(homeOffers?.length, 3),
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    arrows: false,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          dots: true,
          centerMode: false,
          centerPadding: "0px",
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const offersSettings2 = {
    infinite: true,
    loop: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "40px",
    arrows: false,
    dots: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          dots: true,
          centerMode: false,
          centerPadding: "0px",
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const partnerSettings = {
    loop: true,
    speed: 500,
    slidesToShow: Math.min(homeParteners?.length, 5),
    slidesToScroll: 1,
    centerMode: true,
    dots: true,
    arrows: false,
    centerPadding: "10px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: true,
          arrows: false,
          centerMode: false,
          centerPadding: "40px",
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: true,
          arrows: false,
          centerMode: false,
          centerPadding: "40px",
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    // Simulating loading time
    setTimeout(() => {
      setIsLoadingMain(false);
    }, 2500);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Clicktick - Buy Laptops, Mobiles, Gaming and All Top-Tier TECH
          Products
        </title>
        <meta
          name="title"
          content="Clicktick - Buy Laptops, Mobiles, Gaming and All Top-Tier TECH Products"
        />
        <meta
          name="description"
          content="Clicktick offers all TECH Products, smartphones, laptops, desktops, and computers hardware. Buy now in cash or installments high-tech products."
        />
        <meta
          name="keywords"
          content="Clicktick, Click tick, Laptops, Computers, Desktops, Screens, Mobiles, Smartphones, Accessories, Gaming, offers, iPhone, Samsung, Apple, Macbook, Lenovo, Dell, HP"
        />
        <link rel="canonical" href="https://clicktick.app/shop" />
      </Helmet>
      <Navbar />
      {/* hero banner */}

      {isLoadingMain ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <DotLoader color={"#00EC85"} loading={isLoadingMain} />
        </div>
      ) : (
        <>
          <div className="shopSlider slidersBG">
            <OwlCarousel className="owl-theme" loop margin={10} {...options}>
              {shopBanner?.map((banner) => {
                return (
                  <section className="shopSlider mbCust" key={banner.Id}>
                    <div className="container ">
                      <div className="row">
                        <div className="col-md-6 order2Mob afterCircleShop">
                          <div className="secondLineShop1">
                            <p className="m-0 fs-40Mob">
                              {banner.DescriptionEn}
                            </p>
                            {/* <p className="m-0 fs-40Mob">Smart Laptop</p> */}
                          </div>
                          <button className="tabletBtnShop">
                            {banner.BannerType === 1 && (
                              <Link
                                to={`/shop-products/${banner.BannerWebRedirectNameEn}/${banner.BannerRedirectId}`}
                                // replace
                              >
                                <span className="mb-0 oderFont">Shop Now</span>
                              </Link>
                            )}
                            {banner.BannerType === 2 && (
                              <Link
                                to={`/productDetails/${banner.BannerWebRedirectNameEn}/${banner.BannerRedirectId}`}
                              >
                                <span className="mb-0 oderFont">Shop Now</span>
                              </Link>
                            )}
                            {banner.BannerType === 3 && (
                              <Link
                                to={`/service-details/${banner.BannerWebRedirectNameEn}/${banner.BannerRedirectId}`}
                              >
                                <span className="mb-0 oderFont">Shop Now</span>
                              </Link>
                            )}
                            {banner.BannerType === 4 && (
                              <Link to={`/shop-products/All/0`}>
                                <span className="mb-0 oderFont">Shop Now</span>
                              </Link>
                            )}
                          </button>
                        </div>
                        <div className="col-md-6 align-items-center justify-content-center">
                          <div className="heroImg my-5 heroImgAfter1 position-relative">
                            <img
                              src={banner.ImageUrl}
                              alt="banner"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                );
              })}
            </OwlCarousel>
          </div>

          {/* Categories */}
          <section className="homeCategories my-5">
            <div className="container position-relative">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex justify-content-center">
                    <h6 className="clicktitle">Categories</h6>
                    <div
                      className="seeAll"
                      onClick={() => {
                        navigate("/shop-products/All/0");
                      }}
                    >
                      See All
                    </div>
                  </div>
                  {isLoading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                      }}
                    >
                      <DotLoader color={"#00EC85"} loading={isLoadingMain} />
                    </div>
                  ) : categoryAPI?.length === 0 ? (
                    <p>No data</p>
                  ) : (
                    <Slider {...categoriesSettings}>
                      {sortedCategories.map((category) => (
                        <Link
                          to={`/shop-products/${category.NameEn.replaceAll(
                            " ",
                            ""
                          )}/${category.Id}`}
                          key={category.Id}
                          className="categoryShop"
                        >
                          <div className="categoryImageWrapper">
                            <img src={category.ImageUrl} alt="" />
                          </div>
                          <p>{category.NameEn}</p>
                        </Link>
                      ))}
                    </Slider>
                  )}
                </div>
              </div>
            </div>
          </section>

          {/* offers */}
          <section className="offers">
            <div className="container position-relative">
              <div className="offersHeading d-flex align-items-center justify-content-center mb-3">
                <p className="m-0 text-center text-white">Shop Offers</p>
                <Link
                  className="seeAll text-white"
                  to="/shop-products/Offers/-1"
                >
                  See All
                </Link>
              </div>
            </div>
            <div className="cardsSlide">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    {homeOffers?.length > 0 ? (
                      <Slider {...offersSettings}>
                        {homeOffers?.map((product) => {
                          return <ShopItem item={product} key={product.Id} />;
                        })}
                      </Slider>
                    ) : (
                      <p className="text-center text-white">No data...</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* recommended products */}
          <section className="offers recProd">
            <div className="container position-relative">
              <div className="offersHeading d-flex align-items-center justify-content-center">
                <p className="m-0 text-center">Recommended</p>
                {/* <Link className="seeAll" to="/shop-products">
                  See All
                </Link> */}
              </div>
            </div>
            <div className="cardsSlide">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    {recProducts?.length > 0 ? (
                      <Slider {...recomendedProductsSlider}>
                        {recProducts?.map((recommended) => (
                          <div key={recommended.Id}>
                            <ShopItem item={recommended} />
                          </div>
                        ))}
                      </Slider>
                    ) : (
                      <p className="text-center">No data...</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* first footer */}
          <Footer1
            backgroundColor="#303030"
            colorWhite="#FFFFFF"
            colorGreen="#0ECB78"
          />
          {/* Partners */}
          {/* <section className=" homePartners py-5 ">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h6 className="clicktitle text-white">Partners</h6>
                  <Slider {...partnerSettings}>
                    {homeParteners.map((partner) => {
                      return (
                        <Link
                          key={partner.id}
                          className="d-flex align-items-center"
                          onClick={(e) => e.preventDefault()}
                        >
                          <img
                            src={partner.ImageUrl}
                            alt={partner.NameEn}
                            className="img-fluid d-flex align-items-center"
                          />
                        </Link>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </div>
          </section> */}
          <Footer2 />
        </>
      )}
    </>
  );
};

export default Shop;
