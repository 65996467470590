import React, { useEffect, useRef, useState } from "react";
import "./Home.css";
import "../../components/HeroBanners/HeroBanner.css";
import Slider from "react-slick";
import Navbar from "../../components/Navbar/Navbar";
import { Link, useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AboutImg from "../../assests/img/About-us-img.png";
import serviceIcon from "../../assests/img/serviceIcon.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Footer1 from "../../components/Footer/Footer1";
import Footer2 from "../../components/Footer/Footer2";
import axios from "axios";
import CountUp from "react-countup";
import ExpertImg from "../../assests/img/expertImg.png";
import moneyIcon from "../../assests/img/homeExpertMoney.png";
import starIcon from "../../assests/img/homeExpertStar.png";
import checkIcon from "../../assests/img/homeExpertCheck.png";
import { HashLoader } from "react-spinners";
import DotLoader from "react-spinners/DotLoader";
import ShopItem from "../../components/ShopItem/ShopItem";
import { CiSearch } from "react-icons/ci";
import ProfileImg from "../../assests/img/profile.png";
import { Helmet } from "react-helmet-async";

const Home = () => {
  const [homeParteners, setHomeParteners] = useState([]);
  const [homeServices, setHomeServices] = useState([]);
  const [homeAbout, setHomeAbout] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [isLoadingMain, setIsLoadingMain] = useState(true); // New state variable
  const [categoryAPI, setCategoryAPI] = useState([]);
  const [homeExpert, setHomeExpert] = useState([]);
  const [homeOffers, setHomeOffers] = useState([]);
  const [homeCounter, setHomeCounter] = useState([]);
  // const { setActiveProduct } = useContext(Clicktick);

  const homePartenersApi = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/Home/HomeParteners`)
      .then((res) => setHomeParteners(res.data.Data));
  };

  const homeCounterApi = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/Home/Web_HomeStatistics`)
      .then((res) => setHomeCounter(res.data.Data));
  };

  const HomeExpert = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/Expert/GetRecommendedExpertList
      `
      )
      .then((res) => {
        setHomeExpert(res.data.Data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const homeServicesApi = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/Home/Web_HomeServices?pageSize=15`
      )
      .then((res) => {
        setHomeServices(res.data.Data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const homeAboutApi = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/Home/Web_AboutUs`)
      .then((res) => {
        setHomeAbout(res.data.Data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const Category = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/Home/HomeCategories`)
      .then((res) => {
        setCategoryAPI(res.data.Data);
        setIsLoading(false);
      })
      .then((err) => {});
  };
  const HomeOffer = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/Home/HomeOffers?PageSize=12`)
      .then((res) => {
        setHomeOffers(res.data.Data);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    homeAboutApi();
    Category();
    homeServicesApi();
    HomeExpert();
    homePartenersApi();
    HomeOffer();
    homeCounterApi();
  }, []);

  const sortedCategories = categoryAPI.sort((a, b) => {
    return a.DisplayOrder - b.DisplayOrder;
  });

  const partnerSettings = {
    infinite: true,
    dots: true,
    loop: true,
    arrows: false,
    speed: 500,
    slidesToShow: Math.min(homeParteners?.length, 4),
    slidesToScroll: 1,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: true,
          centerMode: false,
          centerPadding: "40px",
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: true,
          centerMode: false,
          centerPadding: "40px",
          slidesToShow: 2,
        },
      },
    ],
  };

  const expertSettings = {
    infinite: true,
    loop: true,
    speed: 500,
    dots: true,
    slidesToShow: Math.min(homeExpert?.length, 4),
    slidesToScroll: 1,
    centerMode: false,
    centerPadding: "50px",
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          dots: true,
          centerMode: false,
          centerPadding: "50px",
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          dots: true,
          centerMode: false,
          centerPadding: "50px",
          slidesToShow: 1,
        },
      },
    ],
  };
  const categoriesSettings = {
    infinite: true,
    loop: true,
    speed: 500,
    slidesToShow: Math.min(categoryAPI?.length, 4),
    slidesToScroll: 1,
    centerMode: false,
    dots: true,
    centerPadding: "20px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          dots: true,
          centerMode: false,
          centerPadding: "50px",
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          dots: true,
          centerMode: false,
          centerPadding: "50px",
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const servicesSettings = {
    infinite: true,
    loop: true,
    speed: 500,
    slidesToShow: Math.min(homeServices?.length, 5),
    slidesToScroll: 1,
    centerMode: false,
    centerPadding: "60px",
    dots: true,
    responsive: [
      {
        breakpoint: 1024, // Target tablets (768px and above)
        settings: {
          slidesToShow: 2, // Adjust the number of slides to show on tablets
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          dots: true,
          centerMode: false,
          centerPadding: "50px",
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          dots: true,
          centerMode: false,
          centerPadding: "50px",
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const offersSettings = {
    infinite: true,
    autoplay: true,
    loop: true,
    speed: 500,
    slidesToShow: Math.min(homeOffers?.length, 3),
    slidesToScroll: 1,
    centerMode: false,
    centerPadding: "0px",
    arrows: false,
    dots: true,
    responsive: [
      {
        breakpoint: 1024, // Target tablets (768px and above)
        settings: {
          slidesToShow: 2, // Adjust the number of slides to show on tablets
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Target smaller tablets (between 480px and 767px)
        settings: {
          slidesToShow: 2, // Adjust the number of slides to show on smaller tablets
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // Target mobile devices (below 480px)
        settings: {
          slidesToShow: 2, // Adjust the number of slides to show on mobile devices
          slidesToScroll: 1,
          centerMode: false, // You can customize other settings as needed
          centerPadding: "0px",
        },
      },
    ],
  };
  //Owl Carousel Settings
  const options = {
    items: 1,
    center: true,
    margin: 500,
    responsiveClass: true,
    nav: false,
    autoplay: true,
    navText: ["Prev", "Next"],
    smartSpeed: 1000,
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoadingMain(false);
    }, 1500);
  }, []);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // });

  let currency = localStorage.getItem("currency");

  // search
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState(null);
  const searchResultsRef = useRef(null);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const fetchSearchResults = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/Home/Search`,
        {
          params: {
            keyword: searchTerm,
            pageSize: 5,
          },
        }
      );
      setSearchResults(response.data.Data);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  useEffect(() => {
    if (searchTerm) {
      fetchSearchResults();
    } else {
      setSearchResults(null); // Clear search results when input is cleared
    }
  }, [searchTerm]);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = (event) => {
    if (
      searchResultsRef.current &&
      !searchResultsRef.current.contains(event.target)
    ) {
      setSearchResults(null);
    }
  };
  const [homeBannerData, setHomeBannerData] = useState([]);
  const BannerApi = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/Home/WebBanners`)
      .then((res) => setHomeBannerData(res.data.Data));
  };
  useEffect(() => {
    BannerApi();
  }, []);
  return (
    <>
      <Helmet>
        <title>Clicktick - Buy TECH Products and Solutions in Egypt</title>
        <meta
          name="title"
          content="Clicktick - Buy TECH Products and Solutions in Egypt"
        />
        <meta
          name="description"
          content="Discover a wide range of TECH products and services in Egypt. From smartphones, laptops, accessories, and gaming to essential TECH solutions, Shop with Clicktick."
        />
        <meta
          name="keywords"
          content="Clicktick, Click tick, Laptops, Computers, Desktops, Screens, Mobiles, Smartphones, Accessories, Gaming, offers, Technology, IT Solutions, Technical Support Services, Smart Home Solutions"
        />
        <meta
          name="google-site-verification"
          content="HpBnVJMRLyAas2F9UNaK0zgBL9wLKAE3vG_xBLnuTeg"
        />
        <link rel="canonical" href="https://clicktick.app" />
      </Helmet>
      <Navbar />
      {/* hero banner */}
      {isLoadingMain ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <DotLoader color={"#00EC85"} loading={isLoadingMain} />
        </div>
      ) : (
        <>
          <div className="heroBanner heroBackground">
            <div className="hero mbCust">
              <OwlCarousel
                className="owl-theme w-100"
                loop
                margin={10}
                {...options}
              >
                {homeBannerData?.map((banner, index) => {
                  return (
                    <div className="row" key={index}>
                      <div className="col-md-12 afterCircle">
                        <a href={banner.BannerRedirectUrl}>
                          <img
                            src={banner.ImageUrl}
                            alt="banner"
                            className="img-fluid banner--img"
                          />
                        </a>
                      </div>
                    </div>
                  );
                })}
              </OwlCarousel>
            </div>
          </div>
          {/* Search bar */}
          <section className="homeSearchBar">
            <div className="container">
              <div className="searchBox">
                <input
                  type="text"
                  placeholder="What are you looking for?"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      navigate(`/shop-products/All/0?keyword=${searchTerm}`);
                    }
                  }}
                />

                <CiSearch
                  onClick={() => {
                    navigate(`/shop-products/All/0?keyword=${searchTerm}`);
                  }}
                />
              </div>
              {searchResults && (
                <div className="searchResults" ref={searchResultsRef}>
                  <>
                    {searchResults.Products?.length > 0 ? (
                      <>
                        <p>Products</p>
                        <ul>
                          {searchResults.Products?.map((product) => (
                            <li
                              key={product.Id}
                              onClick={() => {
                                // bake_cookie("product", product);
                                // setActiveProduct(product);
                                navigate(
                                  `/productDetails/${product.URLName}/${product.Id}`
                                );
                                // window.location.reload();
                              }}
                              className="d-flex align-items-center"
                            >
                              <div className="row">
                                <div className="col-lg-2 d-flex align-items-center justify-content-center">
                                  <img
                                    src={product.DefaultImageUrl}
                                    alt={product.NameEn}
                                    className="w-25"
                                  />
                                </div>
                                <div className="col-md-10 text-lg-start text-center ">
                                  <span>{product.NameEn}</span>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                        <hr />
                      </>
                    ) : null}

                    {searchResults.Services?.length > 0 ? (
                      <>
                        <p>Services:</p>
                        <ul>
                          {searchResults.Services?.map((service) => (
                            <li
                              key={service.Id}
                              onClick={() =>
                                navigate(
                                  `/service-details/${service.URLName}/${service.Id}`
                                )
                              }
                            >
                              <div className="row">
                                <div className="col-md-2 d-flex align-items-center justify-content-center">
                                  <img
                                    src={service.DefaultImageUrl}
                                    alt={service.NameEn}
                                    className="w-25"
                                  />
                                </div>
                                <div className="col-md-10">
                                  <span>{service.NameEn}</span>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                        <hr />
                      </>
                    ) : null}
                    {searchResults.Experts?.length > 0 ? (
                      <>
                        <p>Experts:</p>
                        <ul>
                          {searchResults.Experts?.map((expert) => (
                            <li key={expert.Id}>
                              <div className="row">
                                <div className="col-md-2 d-flex align-items-center justify-content-center">
                                  <img
                                    src={ProfileImg}
                                    alt="profile"
                                    className="w-25"
                                  />
                                </div>
                                <div className="col-md-10">
                                  <span> {expert.UserName}</span>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </>
                    ) : null}

                    {searchResults.Products?.length === 0 &&
                    searchResults.Services?.length === 0 &&
                    searchResults.Experts?.length === 0 ? (
                      <p>No results found.</p>
                    ) : null}
                  </>
                </div>
              )}
            </div>
          </section>

          {/* Categories */}
          <section className="homeCategories my-5">
            <div className="container position-relative">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex justify-content-center">
                    <h6 className="clicktitle">Categories</h6>
                    <Link className="seeAll" to="/shop-products/All/0">
                      See All
                    </Link>
                  </div>
                  {isLoading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                      }}
                    >
                      <DotLoader color={"#00EC85"} loading={isLoadingMain} />
                    </div>
                  ) : categoryAPI?.length === 0 ? (
                    <p>No data</p>
                  ) : (
                    <Slider {...categoriesSettings}>
                      {sortedCategories?.map((category) => (
                        <Link
                          to={`/shop-products/${category.NameEn.replaceAll(
                            " ",
                            ""
                          )}/${category.Id}`}
                          key={category.Id}
                          className="categoryShop"
                        >
                          <div className="categoryImageWrapper">
                            <img src={category.ImageUrl} alt="" />
                          </div>
                          <p>{category.NameEn}</p>
                        </Link>
                      ))}
                    </Slider>
                  )}
                </div>
              </div>
            </div>
          </section>

          {/* offers */}
          <section
            className="offers"
            style={{
              background: "linear-gradient(145deg, #0BCC77 0%, #08A761 100%)",
              paddingBottom: "1rem",
            }}
          >
            <div className="container position-relative">
              <div className="offersHeading d-flex align-items-center justify-content-center">
                <p className="m-0 text-center text-white">Best Offers</p>
                <Link
                  className="seeAll text-white"
                  to="/shop-products/Offers/-1"
                >
                  See All
                </Link>
              </div>
            </div>
            <div className="cardsSlide">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    {homeOffers?.length > 0 ? (
                      <Slider {...offersSettings}>
                        {homeOffers?.map((product) => {
                          return <ShopItem item={product} key={product.Id} />;
                        })}
                      </Slider>
                    ) : (
                      <p className="text-center text-white">No data...</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Footer1
            backgroundColor="#303030"
            colorWhite="#FFFFFF"
            colorGreen="rgba(0, 237, 133, 1)"
          />
          {/* services */}
          <section className="services my-5">
            <div className="servicesHeading">
              <p>
                <span>clicktick</span>
                Services
              </p>
            </div>
            <div className="container">
              <div className="row">
                <Slider {...servicesSettings}>
                  {homeServices?.map((service) => {
                    return (
                      <Link
                        to={`/service-details/${service.URLName}/${service.Id}`}
                        className="col-md-4 mb-4 pt-2"
                        key={service.Id}
                        // onClick={() =>
                        //   navigate(`/service-details/${service.NameEn}/${service.Id}`)
                        // }
                      >
                        <div
                          className="servicesCard h-100"
                          data-aos="flip-right"
                          data-aos-duration="1500"
                          data-aos-once="true"
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            <img
                              src={service.ImageUrl}
                              alt={service.NameEn}
                              className="m-auto servicesImg"
                            />
                          </div>
                          <div className="headingCard mt-2">
                            <Link
                              to={`/service-details/${service.URLName}/${service.Id}`}
                            >
                              {service.NameEn}
                            </Link>
                          </div>
                          {/* <div className="detailsCard mt-2">
                          <p className="px-4">{service.DescriptionEn}</p>
                        </div> */}
                        </div>
                      </Link>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </section>

          {/* recommended experts */}
          {/* <div className="experts mt-5">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="container position-relative mx-3 mx0Mob mb-4">
                    <div className="expertsHeading">
                      <p className="m-0 text-center recomExperts text-white">
                        Recommended Experts
                      </p>
                      <Link
                        className="seeAll expertMob text-muted"
                        to="/experts"
                      >
                        See All
                      </Link>
                    </div>
                  </div>
                  <div>
                    {isLoading ? (
                      <HashLoader color={"#36d7b7"} loading={isLoading} />
                    ) : homeExpert?.length > 0 ? (
                      <div className="slider-container">
                        <Slider {...expertSettings}>
                          {homeExpert?.map((expert) => {
                            return (
                              <div
                                className="expertCard ms-4 my-5"
                                key={expert.Id}
                              >
                                <div>
                                  <img
                                    src={ExpertImg}
                                    alt="expert"
                                    className="expertImg"
                                  />
                                </div>
                                <div className="expertName">
                                  <p>{expert.UserName}</p>
                                </div>
                                <div className="jobTitle">
                                  <p>{expert.TitleEn}</p>
                                </div>
                                <div className="container">
                                  <div className="row expertIcons">
                                    <div className="col-md-4 col-4 expertFlex">
                                      <img src={moneyIcon} alt="icon" />
                                      <p>{expert.HourRate}</p>
                                      <p>{currency}/h</p>
                                    </div>
                                    <div className="col-md-4 col-4 expertFlex">
                                      <img src={starIcon} alt="icon" />
                                      <p>{expert.Rate}</p>
                                      <p>From 5</p>
                                    </div>
                                    <div className="col-md-4 col-4 expertFlex">
                                      <img src={checkIcon} alt="icon" />
                                      <p>{expert.ProjectCount}</p>
                                      <p>Done Projects</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                      </div>
                    ) : (
                      <p className="text-center text-white">No Data....</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* about ClickTick */}
          <section className="about mb-5 mb1Mob">
            <div className="heading mt-5">
              <p className="m-0 d-flex justify-content-center align-items-center">
                <span>clicktick</span>
                About Us
              </p>
            </div>
            <div className="container mt-5 mt1Mob ">
              <div className="row">
                <div
                  className="col-md-6 position-relative"
                  data-aos="zoom-in-up"
                  data-aos-duration="2000"
                  data-aos-once="true"
                >
                  <div className="imgBefore"></div>
                  <img src={AboutImg} alt="about" className="about-img" />
                </div>
                <div
                  className="col-md-6 aboutRight"
                  data-aos="zoom-in-up"
                  data-aos-duration="2000"
                  data-aos-once="true"
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: homeAbout.ValueEn,
                    }}
                    className="fs-18"
                  ></div>
                </div>
              </div>
              {/* Counter */}
              {/* <div className="row text-center mt-5">
                {homeCounter.map((counter) => {
                  return (
                    <div className="col-md-4" key={counter.Id}>
                      <div className="serviceCounter">
                        <img src={serviceIcon} alt="icon" />
                        <span>+</span>
                        <CountUp
                          className="counter"
                          start={0}
                          end={counter.Count}
                          duration={8}
                        ></CountUp>
                        <p>{counter.TitleEn}</p>
                      </div>
                    </div>
                  );
                })}
              </div> */}
            </div>
          </section>

          {/* Partners */}
          {/* <section className="homePartners pb-5">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h6 className="clicktitle text-white">Partners</h6>
                  <Slider {...partnerSettings}>
                    {homeParteners.map((partner, index) => {
                      return (
                        <Link
                          key={partner.Id}
                          onClick={(e) => e.preventDefault()}
                        >
                          <img
                            src={partner.ImageUrl}
                            alt={partner.NameEn}
                            className="d-flex align-items-center img-fluid"
                          />
                        </Link>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </div>
          </section> */}

          <Footer2 />
        </>
      )}
    </>
  );
};

export default Home;
