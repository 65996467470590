import React, { useContext, useEffect } from "react";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@mui/material";
import Clicktick from "../../context/context";
import { CheckCircleOutline } from "@mui/icons-material";

export const Notify = ({ open, message }) => {
  const { setNotify , notify} = useContext(Clicktick    );

  useEffect(() => {
    if (open) {
      const autoCloseID = setTimeout(() => {
        setNotify({ open: false, message: "" });
      }, 3000);

      return () => clearTimeout(autoCloseID);
    }
  }, [open]);
  return (

     notify.open&&
    <Snackbar open={open} autoHideDuration={3000}       anchorOrigin={{ vertical: "top", horizontal:"left" }}
    key={"TOPLEFT"}
    >
  <Alert   iconMapping={{
    success: <CheckCircleOutline fontSize="inherit" />,
  }} lg={{ width: '100%' }}>
  {message}
  </Alert>
</Snackbar>
  );
};
