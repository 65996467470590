import React, { useEffect, useState } from "react";
import { BreadCrumb } from "../../components/BreadCrumb";
import { map } from "lodash";
import Footer1 from "../../components/Footer/Footer1";
import Footer2 from "../../components/Footer/Footer2";

import "../Home/Home.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Navbar from "../../components/Navbar/Navbar";
import { HashLoader } from "react-spinners";
import { Helmet } from "react-helmet-async";

const Services = () => {
  const [open, setOpen] = useState(false);
  const [services, setServices] = useState(false);
  const queryString = window.location.search;
  const navigate = useNavigate();
  const params = new URLSearchParams(queryString);
  const [isLoading, setIsLoading] = useState(true);
  const getServices = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/Home/Web_HomeServices?pageSize=15`
      )
      .then((res) => {
        setServices(res.data.Data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getServices();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  });
  console.log(services);
  return (
    <>
      <Helmet>
        <title> Clicktick - TECH Solutions and Services in Egypt</title>
        <meta
          name="title"
          content="Clicktick - TECH Solutions and Services in Egypt"
        />
        <meta
          name="description"
          content="Clicktick provides full TECH services and solutions, Technical Support Services, Maintenance Service, Networking Services, Request online or at your location."
        />
        <meta
          name="keywords"
          content="Clicktick, Click tick, Technology Solutions, Egypt, Tech Services, Software Services, Network Solutions, Technical Support and Maintenance, Tech Consultancy"
        />
        <link rel="canonical" href="https://clicktick.app/services" />
      </Helmet>
      <Navbar />
      <BreadCrumb list={[{ name: "Services", link: "" }]} />

      <section className="services my-5">
        <div className="servicesHeading">
          <p>
            <span>clicktick</span>
            Services
          </p>
        </div>
        <div className="container">
          <div className="row">
            {isLoading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: "200px" }}
              >
                <HashLoader color={"#00EC85"} loading={isLoading} />
              </div>
            ) : services?.length > 0 ? (
              services?.map((service) => (
                <div
                  className="col-md-4 mb-4"
                  key={service.Id}
                  onClick={() =>
                    navigate(
                      `/service-details/${service.URLName}/${service.Id}`
                    )
                  }
                >
                  <div
                    className="servicesCard h-100"
                    data-aos="flip-right"
                    data-aos-duration="1500"
                    data-aos-once="true"
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      <Link
                        to={`/service-details/${service?.URLName}/${service?.Id}`}
                      >
                        <img
                          src={service?.ImageUrl}
                          alt={service?.NameEn}
                          className="m-auto servicesImg"
                        />
                      </Link>
                    </div>
                    <div className="headingCard mt-2">
                      <Link
                        to={`/service-details/${service?.URLName}/${service?.Id}`}
                      >
                        {service?.NameEn}
                      </Link>
                    </div>
                    <div className="detailsCard mt-2">
                      <p
                        className="px-4"
                        dangerouslySetInnerHTML={{
                          __html: `<div>${service?.SubTitleEn}</div>`,
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center">No Services Found for your Country</p>
            )}
          </div>
        </div>
      </section>
      <Footer1
        backgroundColor="#303030"
        colorWhite="#FFFFFF"
        colorGreen="rgba(0, 237, 133, 1)"
      />
      <Footer2 />
    </>
  );
};

export default Services;
