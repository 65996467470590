import Navbar from "../../components/Navbar/Navbar";
import CheckoutProvider from "./context";
import CheckoutStep from "./components";
import { BreadCrumb } from "../../components/BreadCrumb";
import { Helmet } from "react-helmet-async";
export default function Checkout() {
  return (
    <>
      <Helmet>
        <title> Payment Method | Select Your Payment With Clicktick</title>
        <meta
          name="title"
          content="Payment Method | Select Your Payment With Clicktick"
        />
        <meta
          name="description"
          content="Select your payment method to continue your purchasing process with Clicktick | Check Out"
        />
        <meta
          name="keywords"
          content=" Clicktick, Click tick, Payment, Payment Method, Check out, Cash, Bank Card, Credit Card, Valu, Forsa, Aman"
        />
      </Helmet>
      <CheckoutProvider>
        <Navbar />
        <CheckoutStep />
      </CheckoutProvider>
    </>
  );
}
