import React, { useState, useEffect } from "react";
import "./ReturnPolicy.css";
import Navbar from "../../components/Navbar/Navbar";
import { Helmet } from "react-helmet-async";
import DotLoader from "react-spinners/DotLoader";
import axios from "axios";
import Footer2 from "../../components/Footer/Footer2";

const ReturnPolicy = () => {
  const [apiData, setApiData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getAbout = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/Home/Web_ReturnPolicyWeb`)
      .then((res) => {
        setApiData(res.data.Data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getAbout();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Helmet>
        <title> Our Returns Policy | Clicktick</title>
        <meta name="title" content="Our Returns Policy | Clicktick" />
        <meta
          name="description"
          content=" Clicktick accepts returns or exchanges your order under returns policy within 14 days"
        />
        <meta
          name="keywords"
          content="Clicktick, Click tick, Returns Policy, Clicktick Returns Policy"
        />
      </Helmet>
      <Navbar />
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <DotLoader color={"#00EC85"} loading={isLoading} />
        </div>
      ) : (
        <section className="returnPolicy mb-5">
          <div className="container-fluid BgColorCopyRight">
            <div className="CopyRightHeading">
              <h2>Returns Policy</h2>
            </div>
          </div>
          <div className="container">
            <div className="return my-3">
              <div
                style={{
                  letterSpacing: "0.7px",
                  lineHeight: "2.2rem",
                }}
                dangerouslySetInnerHTML={{
                  __html: `<div>${apiData.ValueEn}</div>`,
                }}
              ></div>
            </div>
          </div>
        </section>
      )}
      <Footer2 />
    </>
  );
};

export default ReturnPolicy;
