//! 3rd Libraries
import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { HashLoader } from "react-spinners";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import axios from "axios";

//! Components
import NavbarExpert from "./NavbarExpert";
import { BreadCrumb } from "../components/BreadCrumb";
import ExpertRequestsCard from "./ExpertRequestsCard";
import ExpertDealsCard from "./ExpertDealsCard";
import RequestDetails from "./RequestDetails";
import DealDetails from "./DealDetails";
import Footer1 from "../components/Footer/Footer1";
import Footer2 from "../components/Footer/Footer2";

//! Css
import "./ExpertView.css";

const RequestsExpertView = () => {
  const [requests, setRequests] = useState({ RecentRequests: [], MyDeals: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [isRequestLoading, setIsRequestLoading] = useState(true);
  const [isCancelLoading, setIsCancelLoading] = useState(false);

  const [selectedRequest, setSelectedRequest] = useState(null);
  const [selectedDeal, setSelectedDeal] = useState(null);

  const [searchParams] = useSearchParams();
  const queryType = searchParams.get("type");
  const queryId = searchParams.get("id");

  const getExpertAllRequests = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/ExpertView/ExpertAllRequests`)
      .then((res) => {
        res?.data?.Data?.RecentRequests?.reverse();
        res?.data?.Data?.MyDeals?.reverse();
        setRequests(res?.data?.Data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getRequestDetails = async (id, type) => {
    setSelectedDeal(null);
    setSelectedRequest(null);
    setIsRequestLoading(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/ExpertView/${
          type === "request"
            ? "ExpertRecentRequestDetails"
            : "ExpertMyDealRequestDetails"
        }?RequestId=${id}`
      );

      type === "request"
        ? setSelectedRequest(response?.data?.Data)
        : setSelectedDeal(response?.data.Data);
      setIsRequestLoading(false);
    } catch (error) {
      toast.error("Something went wrong, please try again");
    }
  };

  const handleSelectedItem = async (id, type) => {
    window.scrollTo(0, 0);
    setIsLoading(true);
    await getRequestDetails(id, type);
    setIsLoading(false);
  };

  const handleAcceptRequest = async (id, expertFields) => {
    try {
      setIsLoading(true);

      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/ExpertView/AcceptExpertRequest`,
        {
          Id: id,
          ProjectStartingAt: expertFields.projectStartingDate,
          ActualBudget: expertFields.actualBudget,
          EstimatedTimeHours: expertFields.estimatedDeliveryDate,
        }
      );

      toast.success("Offer sent successfully");

      setIsLoading(false);
      setSelectedRequest(null);
      setIsRequestLoading(true);

      getExpertAllRequests();
    } catch (error) {
      toast.error("Error sending the offer");
      setIsLoading(false);
    }
  };

  const handleCancelRequest = async (id) => {
    try {
      setIsCancelLoading(true);

      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/ExpertView/CancelExpertRequest?RequestId=${id}`
      );

      toast.success("Request cancelled successfully");

      setIsCancelLoading(false);
      setSelectedRequest(null);
      setIsRequestLoading(true);

      getExpertAllRequests();
    } catch (error) {
      toast.error("Error canceling the request");

      setIsCancelLoading(false);
    }
  };

  const handleStartProject = async (id) => {
    try {
      setIsLoading(true);

      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/ExpertView/StartExpertRequest?RequestId=${id}`
      );

      setIsLoading(false);
      getExpertAllRequests();
      getRequestDetails(id, "deal");

      toast.success("Project started successfully");
    } catch (error) {
      toast.error("Error starting the project");
      setIsLoading(false);
    }
  };

  const handleProjectStatus = async (formData, endPoint, message) => {
    try {
      setIsLoading(true);

      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/ExpertView/${endPoint}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setIsLoading(false);
      getExpertAllRequests();
      getRequestDetails(formData.get("Id"), "deal");

      toast.success(message);
    } catch (error) {
      toast.error("Error sending the request");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getExpertAllRequests();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (queryId) {
      getRequestDetails(queryId, queryType);
    }
  }, [queryId, queryType]);

  return (
    <div>
      <Helmet>
        <title>Clicktick: Expert Requests</title>
      </Helmet>

      <NavbarExpert />

      <BreadCrumb list={[{ name: "Requests", link: "" }]} />

      <div className="expertRequestsPage mb-5">
        <div className="container">
          <div className="row gap-3 gap-md-0">
            <div className="col-md-4">
              <div className="requestsTabs">
                <Tabs
                  defaultActiveKey={`${queryType}`}
                  id="fill-tab-example"
                  className="mb-3 border-none gap-3"
                  fill
                >
                  <Tab
                    eventKey={`${queryType === "request" ? "request" : ""}`}
                    title="Recent Requests"
                  >
                    {requests?.RecentRequests?.length > 0 ? (
                      requests?.RecentRequests?.map((request) => (
                        <ExpertRequestsCard
                          key={request.Id}
                          request={request}
                          selectedRequest={selectedRequest}
                          handleSelectedItem={handleSelectedItem}
                        />
                      ))
                    ) : (
                      <p className="text-center mt-3">No requests available</p>
                    )}
                  </Tab>

                  <Tab
                    eventKey={`${queryType === "deal" ? "deal" : ""}`}
                    title="My Deals"
                  >
                    {requests?.MyDeals?.length > 0 ? (
                      requests?.MyDeals?.map((deal) => (
                        <ExpertDealsCard
                          key={deal.Id}
                          deal={deal}
                          selectedDeal={selectedDeal}
                          handleSelectedItem={handleSelectedItem}
                        />
                      ))
                    ) : (
                      <p className="text-center mt-3">No Deals available</p>
                    )}
                  </Tab>
                </Tabs>
              </div>
            </div>

            <div className="col-md-8">
              <div className="requestsDetailsRight d-flex justify-content-center align-items-center">
                {isRequestLoading && (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "200px" }}
                  >
                    <HashLoader
                      color={"#00EC85"}
                      loading={isLoading}
                    />
                  </div>
                )}

                {selectedRequest && (
                  <RequestDetails
                    selectedRequest={selectedRequest}
                    isLoading={isLoading}
                    isCancelLoading={isCancelLoading}
                    handleCancelRequest={handleCancelRequest}
                    handleAcceptRequest={handleAcceptRequest}
                  />
                )}

                {selectedDeal && (
                  <DealDetails
                    selectedDeal={selectedDeal}
                    isLoading={isLoading}
                    handleStartProject={handleStartProject}
                    handleProjectStatus={handleProjectStatus}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer1
        backgroundColor="#303030"
        colorWhite="#FFFFFF"
        colorGreen="rgba(0, 237, 133, 1)"
      />
      <Footer2 />
    </div>
  );
};

export default RequestsExpertView;
