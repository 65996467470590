import React, { useEffect, useState } from "react";
import "./Careers.css";
import Navbar from "../../components/Navbar/Navbar";
import Footer2 from "../../components/Footer/Footer2";
import { BreadCrumb } from "../../components/BreadCrumb";
import { Link } from "react-router-dom";
import { DotLoader } from "react-spinners";
import { Helmet } from "react-helmet-async";
import axios from "axios";

const Careers = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [apiData, setApiData] = useState([]);

  const getPageData = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/Vacancy/CareersStaticPage`)
        .then((response) => {
          setApiData(response.data.Data);
          setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getPageData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      <Helmet>
        <title>Clicktick Careers | Apply Now</title>
        <meta name="title" content="Clicktick Careers | Apply Now" />
        <meta
          name="description"
          content=": Find your suitable job at Clicktick with many benefits and cool work environment | Apply Now"
        />
        <meta
          name="keywords"
          content="Clicktick, Click tick, Clicktick Careers, Clicktick jobs, apply job"
        />
        <link rel="canonical" href="https://clicktick.app/careers" />
      </Helmet>
      <Navbar />
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <DotLoader color={"#00EC85"} loading={isLoading} />
        </div>
      ) : (
        <>
          <div className="careerHeader">
            <p className="m-0">Careers</p>
          </div>
          <BreadCrumb list={[{ name: "Careers", link: "" }]} />
          <div className="container">
            <div className="careers mt-3">
              <div className="join mb-5">
                <div className="row">
                  <div className="col-md-6">
                    <h3>Join us Now</h3>
                    <p>Apply on our Careers portal</p>
                  </div>
                  <div className="col-md-6 d-flex align-items-center justify-content-end flex-start-Mob">
                    <Link to="/available-jobs">Apply Now</Link>
                  </div>
                </div>
              </div>
              <div className="choice">
                <div
                  style={{
                    letterSpacing: "0.7px",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: `<div>${apiData.ValueEn}</div>`,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </>
      )}
      <Footer2 />
    </>
  );
};

export default Careers;
