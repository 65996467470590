// ShopItem component
import { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import axios from "axios";
import { isEmpty, isNull, map } from "lodash";

import Clicktick from "../../context/context";

import Favorite from "../../assests/img/fav.png";
import { toast } from "react-toastify";
import True from "../../assests/img/true.png";
import BTrue from "../../assests/icons/blackTrue.svg";

import "./ShopItem.css";

const handleFavourite = (
  e,
  item,
  setNotify,
  setFav,
  fav,
  navigate,
  activeColor
) => {
  e.preventDefault();
  e.stopPropagation();
  const token = localStorage.getItem("token");
  if (!token || token === "loggedOut") {
    toast.warn("Please log in first!");
    setTimeout(() => {
      navigate("/loginuser");
    }, 1000);
    return;
  }
  axios
    .post(`${process.env.REACT_APP_API_BASE_URL}/Product/ChangeToFavorite`, {
      ProductCountryId: item.ProductCountryId,
      ProductAttributeId: activeColor?.ProductAttributeId || 0,
    })
    .then((res) => {
      setNotify({
        open: true,
        message: "Item Changed Successfully",
        severity: "success",
      });
      // setFav(!item.IsFavorite);
      setFav(!fav);
    });
};

const ShopItem = ({ item }) => {
  const { setNotify } = useContext(Clicktick);
  const navigate = useNavigate();
  const [fav, setFav] = useState(item.IsFavorite);
  const [Total, setTotal] = useState(
    item.Colors ? item?.Colors[0]?.TotalPrice : null
  );

  const [activeColor, SetActiveColor] = useState(
    item.Colors ? item?.Colors[0] : null
  );

  let currency = localStorage.getItem("currency");

  useEffect(() => {
    setFav(item.IsFavorite);
    SetActiveColor(item.Colors && item?.Colors[0]);
  }, [item]);

  return (
    <Link
      className="cardItem bg-white m-4"
      to={`/productDetails/${item.URLName}/${item.Id}`}
    >
      <div className="px-2 py-2">
        <div className="itemImg">
          <div className="first me-3">
            {item.HasOffer && (
              <span className="discount text-white d-flex">
                <span className="m-auto">{`${item.OfferPercentageDiscount}%`}</span>
              </span>
            )}
            <div
              className="favIcon"
              onClick={(e) =>
                handleFavourite(
                  e,
                  item,
                  setNotify,
                  setFav,
                  fav,
                  navigate,
                  activeColor
                )
              }
            >
              {fav ? (
                <img src={Favorite} alt="Favorite" className="heartIcon" />
              ) : (
                <FavoriteBorderOutlinedIcon className="heartIcon" />
              )}
            </div>
          </div>
          <Link to={`/productDetails/${item.URLName}/${item.Id}`}>
            <img
              src={item?.DefaultImageUrl}
              alt="shop item"
              className="max-100 mx-auto imgTop"
            />
          </Link>
        </div>

        <div className="itemDetails mx-3 mt-3">
          {!isEmpty(item.Colors) && !isNull(item.Colors) ? (
            <div className="colour d-flex justify-content-center">
              <div>
                {map(item.Colors, (c, i) => {
                  if (c?.Color[0] == "#")
                    return (
                      <div
                        key={i}
                        className="colorsCont"
                        onClick={(e) => {
                          e.stopPropagation();
                          localStorage.setItem("color", c.ProductAttributeId);
                          SetActiveColor(c);
                          setTotal(c?.TotalPrice);
                          navigate(
                            `/productDetails/${item.URLName}/${item.Id}`
                          );
                        }}
                      >
                        <p
                          className="pp"
                          style={{
                            backgroundColor: c.Color,
                            border:
                              activeColor?.ProductAttributeId ==
                              c.ProductAttributeId
                                ? "1px solid lightgreen"
                                : "1px solid palevioletred",
                          }}
                        ></p>
                        <img
                          style={{
                            display:
                              activeColor?.ProductAttributeId ==
                              c.ProductAttributeId
                                ? "flex"
                                : "none",
                            position:
                              activeColor?.ProductAttributeId ==
                                c.ProductAttributeId && "absolute",
                          }}
                          src={
                            ["#ffffff", "#FFFFFF"].includes(c.Color)
                              ? BTrue
                              : True
                          }
                          alt="true"
                        />
                      </div>
                    );
                })}
              </div>
            </div>
          ) : (
            <div
              className="colour d-flex justify-content-center"
              style={{ height: "28px" }}
            ></div>
          )}
          <div className="">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div>
                <div className="itemName">
                  <Link to={`/productDetails/${item.URLName}/${item.Id}`}>
                    {item?.NameEn}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="itemPrice d-flex align-items-center ms-3 mb-3">
          <span className="actualPrice">{Total || item.Price}</span>
          <span className="ms-1 me-2 mb-0 text-muted">{currency}</span>
          {item?.OldPrice !== 0 && (
            <s className="text-muted">{item?.OldPrice}</s>
          )}
        </div>
        <div className="pb-3 flex-large">
          <button
            className="addToCardBut"
            // onClick={(e) => sendToCart(e)}
          >
            Add to cart
          </button>
        </div>
      </div>
    </Link>
  );
};

export default ShopItem;
