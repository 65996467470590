import { Container, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  content: {
    padding: "100px 0",
    margin: "auto",
    textAlign: "center",
    color:"#575757"
  },
});

export function EmptyComponent({ content }) {
  const classes = useStyles();

  return (
    <>
      <Container maxWidth="lg">
        <h2 className={classes.content}>{content}</h2>
      </Container>
    </>
  );
}
