import { useContext, useEffect } from "react";
import { Button, p, Typography } from "@material-ui/core";
// import { BasicButton, LoadingOverlay } from "app/components";
// import { trans } from "mongez/localization";
// import { Link } from "mongez/components";
import { useStyles } from "./style";
import axios from "axios";
import { useState } from "react";
import Clicktick from "../../../../context/context";
import DiscountCode from "../../../Cart/discountCode/DiscountCode";
import { map } from "lodash";
import { Style } from "@mui/icons-material";
import CheckoutStore from "../../context/context";

// import urls from "app/helpers/urls";
export default function Receipt(props) {
  const { cart, asyncLoading, invoice, setInvoice } = useContext(Clicktick);
  const { addressID } = useContext(CheckoutStore);

  const classes = useStyles({ asyncLoading });
  const [code, setCode] = useState("");

  const getInvoice = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/Order/GetInvoice?UserAddressId=${addressID}`,{
          params: { Voucher: localStorage.getItem("Voucher") },
        }
      )
      .then(({ data }) => setInvoice(data.Data));
  };
  // const [response, error, isLoading] = useRequest(accountServices.cartService);

  useEffect(() => {
    getInvoice();
  }, [addressID]);
  let currency = localStorage.getItem("currency");
  return (
    <>
      <div className={`${classes.receipt} `} style={{ marginBottom: "5%" }}>
        <div>
          {map(cart, (el) => {
            return (
              <div className="receipt-item">
                <Typography className="key">
                  {`${el.Count} ${el.NameEn}`}
                </Typography>
                <Typography className="key">{el.Price}</Typography>
              </div>
            );
          })}
        </div>
      </div>
      <div className={`${classes.receipt}`}>
        <div>
          <div className="receipt-item">
            <Typography className="key">Subtotal:</Typography>
            <Typography className="key">{invoice?.SubTotal}</Typography>
          </div>
          <div className="receipt-item">
            <Typography className="key"> Voucher:</Typography>
            <Typography className="value"> {invoice?.Voucher}</Typography>
          </div>
          <div className="receipt-item">
            <Typography className="key">Delivery fee:</Typography>
            <Typography className="value"> {invoice?.DeliveryFees} </Typography>
          </div>
          <div className="receipt-item ">
            <Typography className="total">Total:</Typography>
            <div>
              <Typography className="total val"> {invoice?.Total} </Typography>
              <Typography className="key">{currency}</Typography>
            </div>
          </div>
          <div className="receipt-item tax">
            <Typography className="key">Discount:</Typography>
            <Typography className="value">{invoice?.TotalDiscount}</Typography>
          </div>
          {/* <Button><Typography>Checkout</Typography></Button> */}
        </div>

        {/* {restTotalsArr.map((total: any, index) => (
        <div className="receipt-item" key={index}>
          <Typography variant="body2">{total.text}</Typography>
          <Typography variant="body2" className="value">
            {total.valueText}
          </Typography>
        </div>
      ))} */}
        {/* 
      <Link to={urls.account.checkout}>
        <BasicButton
          text={trans("purchase")}
          type="button"
          fullWidth
          padding={15}
        />
      </Link>

      {asyncLoading && <LoadingOverlay />} */}
      </div>
    </>
  );
}
