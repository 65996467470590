//! 3rd Libraries
import { HashLoader } from "react-spinners";
import moment from "moment";

//! Utils
import { getStatusColor } from "../../../utils/getStatusColor";
import AttachmentsDetails from "../../../components/Shared/AttachmentsDetails";

const UserRequestDetails = ({
  selectedRequest,
  isLoading,
  isCancelLoading,
  handleCancelRequest,
  handleConfirmAndPay,
}) => {
  const currency = localStorage.getItem("currency");

  return (
    <>
      <div className="p-4 flex-grow-1">
        <div className="d-flex align-items-center justify-content-between">
          <h3
            style={{ color: "#000056" }}
            className="text-center"
          >
            {selectedRequest?.ServiceNameEn}
          </h3>

          <p style={{ color: getStatusColor(selectedRequest.RequestStatusId) }}>
            {selectedRequest?.RequestStatusEn}
          </p>
        </div>

        <div className="requestDetailsAndCandel mt-0">
          <p>Request Details</p>

          {selectedRequest?.DisplayCancel && (
            <button
              onClick={() => handleCancelRequest(selectedRequest.Id)}
              disabled={isCancelLoading}
            >
              {isCancelLoading ? (
                <HashLoader
                  size={20}
                  className="d-flex justify-content-center align-items-center"
                  color="#00EC85"
                  loading={isCancelLoading}
                />
              ) : (
                "Cancel"
              )}
            </button>
          )}
        </div>

        <div className="row">
          <div className="col-lg-5">
            <div className="problemDescriptions">
              <div className="firstDiv">
                <p>Problem Description</p>

                <textarea
                  style={{ resize: "none" }}
                  type="text"
                  name=""
                  id=""
                  disabled
                  value={selectedRequest?.ProblemDescription}
                />
              </div>

              <>
                <div className="firstDiv">
                  <p>Expected Delivery Date</p>

                  <input
                    type="text"
                    name=""
                    id=""
                    disabled
                    value={
                      selectedRequest?.ExpectedDeliveryDate
                        ? moment
                            .unix(selectedRequest?.ExpectedDeliveryDate)
                            .format("DD/MM/YYYY")
                        : ""
                    }
                  />
                </div>

                <div className="firstDiv">
                  <p>Expected Budget ( {currency} )</p>

                  <input
                    type="text"
                    name=""
                    id=""
                    value={
                      selectedRequest?.ExpectedBudget
                        ? selectedRequest?.ExpectedBudget
                        : ""
                    }
                    disabled
                  />
                </div>
              </>

              <div className="firstDiv">
                <p>Client Payment method</p>

                <input
                  type="text"
                  name=""
                  id=""
                  disabled
                  value={selectedRequest?.ClientPaymentMethodEn}
                />
              </div>
            </div>

            <div className="firstDiv mt-3">
              <p>Estimated Delivery Date</p>

              <input
                placeholder={
                  selectedRequest?.EstimatedTimeHours
                    ? moment
                        .unix(selectedRequest?.EstimatedTimeHours)
                        .format("DD/MM/YYYY")
                    : ""
                }
                disabled
              />
            </div>

            <div className="firstDiv mt-3">
              <p>Cost ( {currency} )</p>

              <input
                type="text"
                placeholder={selectedRequest?.Cost}
                className="expectBudget"
                disabled
              />
            </div>

            <div className="firstDiv mt-3">
              <p>Project Starting Time</p>

              <input
                placeholder={
                  selectedRequest?.ProjectStartingTime
                    ? moment
                        .unix(selectedRequest?.ProjectStartingTime)
                        .format("DD/MM/YYYY")
                    : ""
                }
                disabled
              />
            </div>

            {selectedRequest.DisplayConfirmAndPay && (
              <button
                className="acceptOfferBtn"
                disabled={isLoading}
                onClick={() => handleConfirmAndPay(selectedRequest, "request")}
              >
                {isLoading ? (
                  <HashLoader
                    size={20}
                    className="d-flex justify-content-center align-items-center"
                    color="#00EC85"
                    loading={isLoading}
                  />
                ) : (
                  "Confirm & Pay"
                )}
              </button>
            )}
          </div>

          <div className="col-lg-7 mt-3 mt-lg-0">
            <AttachmentsDetails
              details={selectedRequest}
              senderId={selectedRequest.UserId}
              receiverId={selectedRequest.ExpertUserId}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UserRequestDetails;
