import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  codeInput: {
    "& p": { marginBottom: "10px",color:"#505050", fontSize:"1rem"},
    "& .inputC":{
      display:"flex",
      justifyContent:"space-between",
      "& input": {
        border: "1px solid rgba(0, 0, 0, 0.22)",
        borderRadius:" 6px",
        padding: "5px",
        width: "70%",
        outline: "none",
        "&::placeholder": {
          color:"#A4A4A4",
          fontSize:"0.75rem"
        },
  
      },
      
      "& button":{
        backgroundColor: "#03BE6C ",
        textTransform: "initial !important",
        color:"#ffffff"
      },
      "& span":{
        fontSize:"0.8rem"
      },
  
      "& hr": {
        borderColor: "#fafafa6e",
      },
    },
  },
 
});
