import { TextField } from "@material-ui/core";
import { useStyles } from "./style";

export function BasicInput(props) {
  const classes = useStyles();

  return (
    <TextField
      {...props}
      className={`${classes.basicInput} ${props.className}`}
    />
  );
}
