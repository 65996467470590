import React, { useState, useRef, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import DatePicker from "react-date-picker";
import { BsPlus } from "react-icons/bs";
import { CiCalendar } from "react-icons/ci";
import { DotLoader } from "react-spinners";
import { toast } from "react-toastify";
import { format } from "date-fns";
import axios from "axios";

import DelIcon from "../../assests/img/Trash-Bin-inimalistic.png";

import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

const SendRequestModal = ({ show, handleClose, Id }) => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(1);

  const [problemDescription, setProblemDescription] = useState("");
  const [expectedDate, setExpectedDate] = useState("");
  const [expectedBudget, setExpectedBudget] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [budgetError, setBudgetError] = useState("");

  const fileInputRef = useRef(null);

  const userName = localStorage.getItem("username");

  const getPaymentMethods = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/Expert/GetClientPaymentMethods`
      )
      .then((res) => {
        setPaymentMethods(res?.data?.Data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleBudget = (e) => {
    if (isNaN(e.target.value) || e.target.value < 0) {
      setBudgetError("please enter a valid budget");
    } else {
      setExpectedBudget(+e.target.value);
      setBudgetError("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();

    formData.append(
      "ExpectedDeliveryDate",
      expectedDate ? format(expectedDate, "yyyy-MM-dd") : ""
    );
    formData.append("ExpectedBudget", expectedBudget);
    formData.append("ExpertId", Id);
    formData.append("ProblemDescription", problemDescription);
    formData.append("ClientPaymentMethodId", selectedPaymentMethodId || 1);
    selectedFiles.forEach((file) => formData.append("Files", file));

    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/Expert/SubmitExpertRequest`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            CountryId: 3,
          },
        }
      )
      .then((response) => {
        console.log(response);
        setIsLoading(false);

        handleClose();

        setSelectedFiles([]);
        setProblemDescription("");
        setExpectedDate("");
        setExpectedBudget(0);
        setSelectedPaymentMethodId(1);

        toast.success("Request sent successfully");
      })
      .catch((error) => {
        toast.error("Something went wrong, please try again");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getPaymentMethods();
  }, []);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="expertsPageModal"
      >
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DotLoader
              color={"#00EC85"}
              loading={isLoading}
              size={80}
            />
          </div>
        ) : (
          <>
            <Modal.Header closeButton>
              <Modal.Title>
                Expert <span>Request</span>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <form>
                <div className="userName">
                  <div>
                    <input
                      type="text"
                      className="inputText"
                      disabled
                      value={userName}
                    />
                  </div>
                </div>

                <div className="problemDesc">
                  <label htmlFor="problem">Problem Description </label>

                  <textarea
                    name="problem"
                    id=""
                    cols="10"
                    rows="1"
                    placeholder="Write what do you need"
                    value={problemDescription}
                    onChange={(e) => setProblemDescription(e.target.value)}
                    required
                  ></textarea>
                </div>

                <div className="expectedDate">
                  <div className="expectedLabel">
                    <p>Expected Delivery Date</p>
                    <p>Optional</p>
                  </div>

                  <div>
                    <DatePicker
                      minDate={new Date()}
                      onChange={setExpectedDate}
                      value={expectedDate}
                      format="dd/MM/yyyy"
                      dayPlaceholder="DD"
                      monthPlaceholder="MM"
                      yearPlaceholder="YYYY"
                      calendarIcon={
                        <CiCalendar
                          style={{ fontSize: "1.5rem", color: "#313131" }}
                        />
                      }
                    />
                  </div>
                </div>

                <div className="expectedDate">
                  <div className="expectedLabel">
                    <p>Expected Budget ( EGP )</p>
                    <p>Optional</p>
                  </div>

                  <div>
                    <input
                      type="text"
                      placeholder="EGP"
                      className="expectBudget"
                      value={expectedBudget || ""}
                      onChange={handleBudget}
                    />
                    {budgetError && (
                      <p style={{ color: "red", fontWeight: 400 }}>
                        {budgetError}
                      </p>
                    )}
                  </div>
                </div>

                <div className="expectedDate">
                  <div className="expectedLabel">
                    <p>Client Payment method</p>
                  </div>

                  <div>
                    <select
                      className="form-select w-100"
                      value={selectedPaymentMethodId}
                      onChange={(e) =>
                        setSelectedPaymentMethodId(+e.target.value)
                      }
                      required
                    >
                      {paymentMethods?.map((item) => (
                        <option
                          key={item.Id}
                          value={item.Id}
                        >
                          {item.NameEn}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="file-input-container">
                  <p className="mb-1">
                    {selectedFiles.length > 0
                      ? "File Uploaded!"
                      : "Upload Files "}
                  </p>

                  <label className="custom-file-input-label">
                    <BsPlus />

                    <div className="d-flex align-items-start justify-content-start flex-column">
                      <span
                        style={{
                          color:
                            selectedFiles.length > 0 ? "#03BE6C" : "#545454",
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                      >
                        {selectedFiles.length > 0
                          ? `${selectedFiles.length} File${
                              selectedFiles.length > 1 ? "s" : ""
                            } Uploaded!`
                          : "Upload Files "}
                      </span>

                      <span
                        style={{
                          color: "#545454",
                          fontSize: "14px",
                        }}
                      >
                        {selectedFiles.length > 0
                          ? selectedFiles[selectedFiles.length - 1]?.name
                          : "Upload Files to Expert"}
                      </span>
                    </div>

                    {selectedFiles.length > 0 ? (
                      <img
                        src={DelIcon}
                        alt="delete icon"
                        className="img-fluid"
                        onClick={() => {
                          setSelectedFiles([]);
                          fileInputRef.current.value = null;
                        }}
                      />
                    ) : null}

                    <input
                      ref={fileInputRef}
                      id="file-upload"
                      type="file"
                      multiple
                      onChange={(e) =>
                        setSelectedFiles((prevState) => [
                          ...prevState,
                          e.target.files[0],
                        ])
                      }
                    />
                  </label>
                </div>
              </form>
            </Modal.Body>

            <Modal.Footer>
              <Button
                onClick={handleSubmit}
                disabled={!problemDescription}
                style={{
                  background: problemDescription
                    ? "linear-gradient(103deg, #0BCC77 10.76%, #08A761 85.94%)"
                    : "#545454",
                  color: "#fff",
                  cursor: !problemDescription ? "not-allowed" : "pointer",
                }}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
};

export default SendRequestModal;
