import { useContext, useEffect } from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";

import axios from "axios";
import { reject, filter } from "lodash";
import CloseIcon from "@mui/icons-material/Close";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Typography } from "@material-ui/core";

import Clicktick from "../../context/context";

import QuantityButton from "../quantityBtn/QuantityBtn";

import Cart from "../../assests/img/cart.png";
import Favorite from "../../assests/img/fav.png";
import NewFav from "../../assests/icons/new-fav.svg";
import Favv from "../../assests/icons/favo.svg";
import Bin from "../../assests/icons/binn.svg";

import { useStyles } from "./style";

export default function Item({ item, type, setTemp, temp }) {
  const classes = useStyles();
  const { cart, setCart, setNotify, setFavourites, favourites } =
    useContext(Clicktick);
  const [fav, setFav] = useState(true);
  const [show, setShow] = useState(false);
  // console.log(fav, "vvvv");
  // const [response, error, isLoading] = useRequest(accountServices.cartService);
  // const history = useHistory();
  

  const handleFavourite = (e) => {
    let temp = favourites;
    temp = filter(
      favourites,
      (f) =>
        f?.ProductAttributeId != item?.ProductAttributeId &&
        f?.ProductCountryId != item?.ProductCountryId
    );
    e.preventDefault();
    e.stopPropagation();
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/Product/ChangeToFavorite`, {
        ProductCountryId: item?.ProductCountryId,
        ProductAttributeId: item?.ProductAttributeId || 0,
      })
      .then((res) => {
        setNotify({
          open: true,
          message: "Item Changed Successfully",
          severity: "success",
        });
        console.log(item, temp);
        axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/Account/UserFavoriteProduct`)
        .then((res) => {
          setFavourites(res.data.Data);
        })
        // setFav(!item.IsFavorite);
        setFav(!fav); 
        // console.log(fav);
        // setFavourites((prevFavorites) =>
        //   prevFavorites.filter(
        //     (favItem) => favItem.ProductAttributeId !== item.ProductAttributeId
        //   )
        // );
      });
  };
  const [count, setCount] = useState();
  useEffect(() => {
    if (type === "wishlist") {
      let res = temp;
      console.log(item)
      res[item.ProductAttributeId || item.ProductCountryId] = {
        count: count || 1,
        ProductAttributeId: item?.ProductAttributeId,
        IsAvailable: item.IsAvailable,
        ProductCountryId:item.ProductCountryId
      };
      setTemp({ ...res, ...temp });
    }
  }, [count]);


  useEffect(() => {
    setCount(item.Count);
    setFav(item.IsFavorite || true);
  }, [item]);

  const handleRemoval = async () => {
    let newCart = cart;
    // setAsyncLoading(true);
    const response = await axios
      .delete(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/Order/DeleteItemCart?ProductCountryId=${
          item.ProductCountryId || 3
        }&ProductAttributeId=${item?.ProductAttributeId}`
      )
      .then((res) => {
        newCart = reject(
          cart,
          (r) =>
            r.ProductCountryId == item?.ProductCountryId &&
            r.ProductAttributeId == item?.ProductAttributeId
        );
        setCart(newCart);
        //  navigate(`/cart`, {
        //   replace: true,
        // })
      });
  };
  const handleFavouriteFromCart = (e) => {
    // console.log(item);
    let temp = cart;
    e.preventDefault();
    e.stopPropagation();
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/Product/MoveFromCartToFavorite`,
        {
          ProductCountryId: item?.ProductCountryId,
          ProductAttributeId: item?.ProductAttributeId,
        }
      )
      .then((res) => {
        setNotify({
          open: true,
          message: "Item Moved to Wishlist Successfully",
          severity: "success",
        });
        temp = reject(
          temp,
          (r) => r.ProductAttributeId === item.ProductAttributeId && r.ProductCountryId === item.ProductCountryId
        );
        // console.log("hello",temp);
        setCart(temp);
      });
  };

  let currency = localStorage.getItem("currency");
  return (
    <div className={classes.item}>
      <div className="itemInfo">
        <img src={item?.DefaultImageUrl || Cart} alt={item?.name} />
        <div className="info">
          <Typography gutterBottom>{item.NameEn}</Typography>
          <Typography>{`${item?.BrandEn || ""} ${
            item?.CategoryEn || ""
          }`}</Typography>
          <div className="priceInfo">
            <Typography className="price">{`${item?.Price}`}</Typography>
            <Typography>{currency}</Typography>
          </div>
          {item?.ProductAttributeId && (
            <div className="priceInfo">
              <Typography className="price">Color</Typography>
              <div
                style={{
                  background: item?.AttributeOptionEn,
                  border: "1px solid palevioletred",
                }}
              ></div>
            </div>
          )}
          {!item?.IsAvailable && (
            <div className="text-danger fw-bold d-flex align-items-center">
              <CloseIcon />
              <Typography className="text-danger">Out of Stock</Typography>
            </div>
          )}
        </div>
      </div>
      <div className="righ">
        {type === "cart" && (
          <div className="d-flex align-items-center">
            <div
              className="moveToWishlist"
              onClick={(e) => handleFavouriteFromCart(e)}
            >
              <img src={NewFav} alt="Favorite" className="fav" />
              <span>move to wishlist</span>
            </div>
            <div onClick={() => setShow(true)}>
              <HighlightOffIcon
                className="close"
                style={{ cursor: "pointer" }}
              />
            </div>
            <Modal show={show} onHide={() => setShow(false)}>
              <Modal.Header closeButton className="border-none">
                <Typography>Remove from cart</Typography>
              </Modal.Header>
              <Modal.Body className="text-center ">
                <p className={classes.p}>
                  Do You really want to remove this item from cart?
                </p>
              </Modal.Body>
              <Modal.Footer className="d-flex align-items-center justify-content-center border-none">
                <button
                  className={classes.removeFav}
                  onClick={(e) => {
                    handleFavouriteFromCart(e);
                    setShow(false);
                  }}
                >
                  <img src={Favv} alt="Favorite" className="fav" />
                  Save for later
                </button>
                <button
                  className={classes.removeBin}
                  onClick={(e) => {
                    handleRemoval();
                    setShow(false);
                  }}
                >
                  <img src={Bin} alt="Favorite" className="fav" />
                  Remove
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        )}
        {type === "wishlist" && (
          <div className="favIcon" onClick={(e) => handleFavourite(e)}>
            {fav ? (
              <img src={Favorite} alt="Favorite" className="fav" />
            ) : (
              <FavoriteBorderOutlinedIcon
                style={{ color: "#313131" }}
                className="fav"
              />
            )}
          </div>
        )}

        <QuantityButton
          item={item}
          id={item.Id || 0}
          quantity={item.Count || 1}
          count={count || 1}
          setCount={setCount}
          max={item.MaxCountPerOrder}
          type={type}
        />
      </div>
    </div>
  );
}
